import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';

import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { Button, IconButton } from '@tcomponents/ui/button';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { useSurveyContext } from '../SurveyProvider';
import { Label } from '@/tcomponents/ui/label';
import CustomImageField from '../components/CustomImageField';
import { Switch } from '@/tcomponents/ui/switch';
import { cn, handlePrependHttps } from '@/lib/utils';
import { ImageTypes, useUploadImage } from '../hooks/useUploadImage';
import { InfoPopover } from '@/tcomponents/custom/InfoPopover';
import submitPageInfoImage from '@/assets/images/surveyInfoImages/sumbit_page.png';
import submitPolicyInfoImage from '@/assets/images/surveyInfoImages/sumbit_policy.png';
import submitAgreeInfoImage from '@/assets/images/surveyInfoImages/sumbit_agree.png';

const SubmitTab: React.FC = () => {
  const { formContent, setFormContent } = useSurveyContext();
  const { setIsDirty } = useSaveActionContext();

  const agreements = formContent.submit.agreements;

  const { image, handleImageUpload, handleImageRemove } = useUploadImage(
    ImageTypes.submit_image
  );

  const handleAgreementInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const copyAgreements = [...agreements];
    const copyAgreement = { ...copyAgreements[idx] };

    copyAgreement.value = e.target.value;
    copyAgreements[idx] = copyAgreement;

    const obj = { ...formContent };
    obj.submit.agreements = copyAgreements;
    setFormContent(obj);
    setIsDirty(true);
  };

  const handleAgreementCheckedChange = (val: boolean, idx: number) => {
    const copyAgreements = [...agreements];
    const copyAgreement = { ...copyAgreements[idx] };

    copyAgreement.required = val;
    copyAgreements[idx] = copyAgreement;

    const obj = { ...formContent };
    obj.submit.agreements = copyAgreements;
    setFormContent(obj);
    setIsDirty(true);
  };

  const handleAgreementDelete = (idx: number) => {
    const copyAgreements = [...agreements];
    copyAgreements.splice(idx, 1);
    const obj = { ...formContent };
    obj.submit.agreements = copyAgreements;
    setFormContent(obj);
    setIsDirty(true);
  };

  const addAggrement = () => {
    const copyAgreements = [...agreements];
    copyAgreements.push({
      key: agreements.length + 1,
      value: '',
      required: false,
    });
    const obj = { ...formContent };
    obj.submit.agreements = copyAgreements;
    setFormContent(obj);
    setIsDirty(true);
  };

  return (
    <>
      <div className="flex flex-wrap w-full space-x-2">
        {/* page content fields section */}
        <Card className="w-[49%] h-fit">
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Page Content</CardTitle>
              <InfoPopover>
                <div>
                  <h6 className="mb-1">Example Submit Page</h6>
                  <img alt="Example Submit Page" src={submitPageInfoImage} />
                </div>
              </InfoPopover>
            </div>
            <h6 className="text-gray-400 ">
              The confirmation page shown before a user submits their survey
            </h6>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex flex-col gap-y-2">
                <Label>Title</Label>
                <Input
                  value={formContent.submit.page_content.title}
                  defaultValue="Submit your answers"
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.submit.page_content.title = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label>Statement</Label>
                <Textarea
                  value={formContent.submit.page_content.statement}
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.submit.page_content.statement = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label>Image</Label>
                <CustomImageField
                  image={image}
                  handleImageUpload={handleImageUpload}
                  handleImageRemove={handleImageRemove}
                />
              </div>
            </div>
          </CardContent>
        </Card>
        {/* policy fields section */}
        <Card className="w-[49%] h-fit">
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Policy Links</CardTitle>
              <InfoPopover>
                <div>
                  <h6 className="mb-1">Example Policy Links</h6>
                  <img alt="Example Policy Links" src={submitPolicyInfoImage} />
                </div>
              </InfoPopover>
            </div>
            <h6 className="text-gray-400 ">
              Optional links to key policy documents
            </h6>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex flex-col gap-y-2">
                <Label>Privacy Policy</Label>
                <Input
                  value={formContent.submit.policy_links.privacy_policy}
                  defaultValue="https://cavendishconsulting.com/privacy"
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.submit.policy_links.privacy_policy = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                  onBlur={e => {
                    let value = handlePrependHttps(e.target.value);
                    const obj = { ...formContent };
                    obj.submit.policy_links.privacy_policy = value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label>Terms & Conditions</Label>
                <Input
                  value={formContent.submit.policy_links.terms_and_conditions}
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.submit.policy_links.terms_and_conditions =
                      e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                  onBlur={e => {
                    let value = handlePrependHttps(e.target.value);
                    const obj = { ...formContent };
                    obj.submit.policy_links.terms_and_conditions = value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {/* agreements field section */}
      <div className="flex flex-wrap w-full mt-2 space-x-2">
        <Card className="w-full h-fit">
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Agreements</CardTitle>
              <InfoPopover>
                <div>
                  <h6 className="mb-1">Example Agreements</h6>
                  <img alt="Example Agreements" src={submitAgreeInfoImage} />
                </div>
              </InfoPopover>
            </div>
            <h6 className="text-gray-400 ">
              Optional and required user agreements before survey submission
            </h6>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex flex-col w-full space-y-2">
                <div className="flex min-w-full gap-2">
                  <p className="font-bold grow">Fields</p>
                  <p className="font-bold mr-[55px]">Required</p>
                </div>
                {agreements?.map((agreement, idx) => {
                  return (
                    <div
                      className={cn(
                        'flex min-w-full pt-2 border-t gap-x-8',
                        idx === agreements.length - 1 && 'border-b pb-2'
                      )}
                      key={idx}
                    >
                      <Input
                        className="grow"
                        value={agreement.value}
                        onChange={e => handleAgreementInputChange(e, idx)}
                      />
                      <Switch
                        onCheckedChange={val =>
                          handleAgreementCheckedChange(val, idx)
                        }
                        checked={agreement.required}
                      />
                      <IconButton
                        className="p-2 mr-1 text-xl text-white bg-red-500 rounded-lg fa-2xl"
                        icon={faXmark}
                        onClick={() => handleAgreementDelete(idx)}
                        tooltip="Delete"
                        testingAttribute="delete-agreement"
                      />
                    </div>
                  );
                })}
                <Button className="bg-info" onClick={addAggrement}>
                  + Add Agreement
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default SubmitTab;
