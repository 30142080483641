import React, { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { workflowPermissions } from '@/stringConsts/permissionConsts';

// Integrations
const Integrations = lazy(() => import('@/pages/dashboard/integrations/View'));
const NewIntegration = lazy(() => import('@/pages/dashboard/integrations/New'));
const EditIntegration = lazy(
  () => import('@/pages/dashboard/integrations/Edit')
);

export const integrationsRoutes = [
  {
    element: (
      <ProtectedRoute
        permissions={[workflowPermissions.system_administration.integrations]}
      />
    ),
    children: [
      { path: 'integrations', element: <Integrations /> },
      { path: 'integrations/New', element: <NewIntegration /> },
      { path: 'integrations/:id', element: <EditIntegration /> },
    ],
  },
];
