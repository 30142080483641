import React from 'react';
import { cn } from 'lib/utils';

type LayoutContainerProps = {
  navbarChildren?: React.ReactNode;
  children: React.ReactNode;
  fullscreen?: boolean;
};
export default function ScrollableLayout(props: LayoutContainerProps) {
  return (
    <div className="flex flex-col w-full h-[calc(100dvh)]  overflow-hidden grow">
      <div className="flex flex-col flex-wrap">{props.navbarChildren}</div>
      <div
        className={cn(
          'flex flex-col grow overflow-y-auto',
          !props.fullscreen ? 'px-[20px] pt-4 md:px-[40px] 2xl:px-[90px]' : ''
        )}
      >
        {props.children}
      </div>
    </div>
  );
}
