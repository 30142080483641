import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { StackedBarWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';
import { getKeys } from '../../utils/helperFunctions';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type StackedBarChartProps = {
  widgetData: StackedBarWidgetOptions;
  chartData?: any;
};

export default function StackedBarChart(props: StackedBarChartProps) {
  const { widgetData, chartData } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;

  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (chartData?.length === 0) return <NoData />;

  const isAggregatedChartDataArray = Array.isArray(finalData);
  if (!isAggregatedChartDataArray) return <NoData />;

  const barDataKeys = getKeys(finalData);
  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const stackedView = widgetData.chartOptions.stackedBars !== false;
      const processedPayload = stackedView
        ? payload.slice().reverse()
        : payload;
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${label} :`}</p>
          {processedPayload.map((item: any, index: number) => (
            <div className="flex items-center gap-x-1">
              <span
                className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
                style={{ backgroundColor: `${item.fill}` }}
              ></span>
              <p key={`item-${index}`}>{`${item.name} : ${item.value} ${
                widgetData.chartOptions.unit ?? ''
              }`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="flex flex-wrap justify-center gap-x-5 gap-y-2 sm:ml-0">
        {payload.map((item: any, index: number) => (
          <div key={`item-${index}`} className="flex items-center gap-x-1">
            <div
              className="w-10 h-5 rounded-md"
              style={{ backgroundColor: item?.color }}
            />
            <p className="text-sm">{item?.value}</p>
          </div>
        ))}
      </div>
    );
  };
  const getLongestLabel = () => {
    let maxLength = 0;
    if (finalData && Array.isArray(finalData)) {
      finalData.forEach((a: { _name: string | any[] }) => {
        if (typeof a._name !== 'undefined' && a._name.length > maxLength) {
          maxLength = a._name.length;
        }
      });
    }

    return maxLength;
  };

  const getLongestYLabel = () => {
    let maxLength = 0;
    if (finalData && Array.isArray(finalData)) {
      finalData.forEach((a: { _name: string | any[] }) => {
        let keys = Object.keys(a);
        keys.forEach(key => {
          if (
            key !== '_name' &&
            // @ts-ignore
            parseInt(a[key]).toString().length > maxLength
          ) {
            // @ts-ignore
            maxLength = parseInt(a[key]).toString().length;
          }
        });
      });
    }
    return maxLength * (maxLength / 2.5);
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="isChart">
      <BarChart
        data={finalData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: widgetData.chartOptions.groupLabelsVertical
            ? 10 + getLongestLabel() * 5.2
            : 20,
        }}
      >
        <CartesianGrid strokeDasharray="1 5" />
        <XAxis
          dataKey="_name"
          label={{
            value: widgetData.chartOptions.xAxisLabel,
            dy: widgetData.chartOptions.groupLabelsVertical ? 50 : 20,
          }}
          tickLine={false}
          axisLine={false}
          interval={0}
          dy={
            widgetData.chartOptions.groupLabelsVertical
              ? getLongestLabel() * 3.5
              : 0
          }
          angle={widgetData.chartOptions.groupLabelsVertical ? -90 : 0}
        />
        <YAxis
          label={{
            value:
              widgetData.chartOptions.yAxisLabel &&
              widgetData.chartOptions.yAxisLabel +
                (widgetData.chartOptions.unit
                  ? ` (${widgetData.chartOptions.unit})`
                  : ''),
            angle: -90,
            dy: 0,
            dx: -Math.abs(15 + getLongestYLabel()),
          }}
          tickLine={false}
          axisLine={false}
        />
        <Tooltip content={<CustomTooltip />} />
        {widgetData.chartOptions?.showKeys !== false && (
          <Legend
            wrapperStyle={{
              paddingTop: 30,
            }}
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            iconType="rect"
            content={<CustomLegend />}
          />
        )}
        {barDataKeys.map((key: string, index: number) => (
          <Bar
            isAnimationActive={false}
            key={`bar-${index}`}
            dataKey={key}
            stackId={
              widgetData.chartOptions.stackedBars !== false ? 'a' : undefined
            }
            fill={widgetData.dataStyling?.[index]}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
