import { ModalContext } from '@/context/ModalContext';
import {
  useUpdateVisibilityMutation,
  useCreateEmbeddedLinkMutation,
  useDeleteEmbeddedLinkMutation,
} from '@/store/services/projects/featureApi';
import { projectApi } from '@/store/services/projects/projectApi';
import { featureApi } from '@/store/services/projects/featureApi';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import {
  FeatureDetails,
  MapFeatureDetails,
} from '@/types/feature/featureDetails';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useUpdateFeatureVisibility = () => {
  const [updateFeatureVisibility, { isSuccess: isUpdateVisibilitySuccess }] =
    useUpdateVisibilityMutation();
  const { showDialog } = useContext(ModalContext);
  const dispatch = useDispatch();

  const [embedLink, { isSuccess: isEmbedSuccess }] =
    useCreateEmbeddedLinkMutation();
  const [removeLink, { isSuccess: isRemoveEmbedSuccess }] =
    useDeleteEmbeddedLinkMutation();

  useEffect(() => {
    if (isUpdateVisibilitySuccess) {
      dispatch(projectApi.util.invalidateTags(['Project']));
    }
  }, [isUpdateVisibilitySuccess]);

  useEffect(() => {
    if (isEmbedSuccess || isRemoveEmbedSuccess) {
      dispatch(featureApi.util.invalidateTags(['Feature']));
    }
  }, [isEmbedSuccess, isRemoveEmbedSuccess]);

  const handleEmbeddableLinks = (featureDetails: MapFeatureDetails) => {
    if (!featureDetails) return;
    showDialog({
      title:
        featureDetails.embeddable_links.length > 0
          ? 'Remove Embed Map?'
          : 'Embed Map?',
      message:
        featureDetails.embeddable_links.length > 0
          ? 'This will remove the URL that will allow anyone to access the content of this map, breaking any currently embedded. Are you sure you want to proceed?'
          : 'This will generate a URL that will allow anyone to access the content of this map, are you sure you want to proceed?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () =>
          featureDetails.embeddable_links.length > 0
            ? removeLink({
                featureId: featureDetails.id,
                linkId: featureDetails.embeddable_links[0].id,
              })
            : embedLink({ featureId: featureDetails.id }),
      },
    });
  };

  const updateVisibilityWithDialogWarning = (
    featureDetails: FeatureDetails
  ) => {
    if (!featureDetails) return;
    showDialog({
      title:
        featureDetails.public === true
          ? 'Confirm feature unpublishing'
          : 'Confirm feature publishing',
      message: `Are you sure you want to ${
        featureDetails.public === true ? 'unpublish' : 'publish'
      } feature ${featureDetails.name}?`,
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () =>
          updateFeatureVisibility({
            id: featureDetails.id,
            visibility: !featureDetails.public,
          }),
      },
    });
  };

  return {
    updateVisibilityWithDialogWarning,
    handleEmbeddableLinks,
  };
};
