import ProtectedRoute from '@/routes/ProtectedRoute';
import { userPermissions } from '@/stringConsts/permissionConsts';
import React, { lazy } from 'react';

const UsersList = lazy(() => import('../../pages/dashboard/users/List'));
const UserDetails = lazy(
  () => import('../../pages/dashboard/users/UserDetails')
);
const AuditTrailList = lazy(() => import('../../pages/dashboard/audit/List'));

export const userManagementRoutes = [
  {
    element: <ProtectedRoute permissions={[userPermissions.general.read]} />,
    children: [
      {
        path: 'users',
        element: <UsersList />,
      },
    ],
  },
  {
    element: <ProtectedRoute permissions={[userPermissions.general.create]} />,
    children: [
      {
        path: 'users/new',
        element: <UserDetails />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute
        permissions={[
          userPermissions.general.read,
          userPermissions.general.edit,
        ]}
      />
    ),
    children: [
      {
        path: 'users/:id',
        element: <UserDetails />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute
        permissions={[userPermissions.system_administration.audit_trail]}
      />
    ),
    children: [
      {
        path: 'audit-trail',
        element: <AuditTrailList />,
      },
    ],
  },
  {
    path: 'user/profile',
    element: <UserDetails ownProfile={true} />,
  },
];
