import React, { FC, useEffect, useRef, useState } from 'react';
import '../../../../assets/scss/dropdown.scss';

interface ISelectedOptionProps {
  value: string;
  label: string;
}

interface IDropdownProps {
  placeHolder: string;
  options: Array<ISelectedOptionProps>;
  isMulti?: boolean;
  isSearchable?: boolean;
  onChange: (callback: any) => void;
}

const Select: FC<IDropdownProps> = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] =
    useState<Array<ISelectedOptionProps> | null>(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue('');
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    // @ts-ignore
    const handler = e => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  });

  // @ts-ignore
  const handleInputClick = e => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return (
        <>
          <i className="filter icon"></i>
          {placeHolder}
        </>
      );
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          <div>
            <i className="filter icon"></i>
          </div>
          {selectedValue.map(option => (
            <div key={option.value} className="dropdown-tag-item ui label">
              {option.label}
              <i
                onClick={e => onTagRemove(e, option)}
                className="delete icon"
              ></i>
            </div>
          ))}
        </div>
      );
    }

    // @ts-ignore
    return (
      <>
        <i className="filter icon"></i>
        {/*@ts-ignore*/}
        {selectedValue.label}
      </>
    );
  };

  // @ts-ignore
  const removeOption = option => {
    // @ts-ignore
    return selectedValue.filter(o => o.value !== option.value);
  };

  // @ts-ignore
  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  // @ts-ignore
  const onItemClick = option => {
    let newValue;
    if (isMulti) {
      // @ts-ignore
      if (selectedValue.findIndex(o => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        // @ts-ignore
        newValue = [...selectedValue, option];
      }
    } else {
      // Allow resetting single select value
      newValue =
        JSON.stringify(selectedValue) !== JSON.stringify(option) ? option : '';
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  // @ts-ignore
  const isSelected = option => {
    if (isMulti) {
      // @ts-ignore
      return selectedValue.filter(o => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    // @ts-ignore
    return selectedValue.value === option.value;
  };

  // @ts-ignore
  const onSearch = e => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      option =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className="dropdown-container">
      <div ref={inputRef} onClick={handleInputClick} className="dropdown-input">
        <div className="dropdown-selected-value">{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <i className="dropdown icon"></i>
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="dropdown-menu">
          {isSearchable && (
            <div className="search-box">
              <input onChange={onSearch} value={searchValue} ref={searchRef} />
            </div>
          )}
          {getOptions().map(option => (
            <div
              onClick={() => onItemClick(option)}
              key={option.value}
              className={`dropdown-item ${isSelected(option) && 'selected'}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
