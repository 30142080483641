import { FC, MouseEvent, useCallback, useRef, useContext } from 'react';
import SidebarLogo from '../../assets/images/sidebar-logo.png';
import SidebarMenu from './SidebarMenu';
import PoweredByComponent from './PoweredByComponent';
import Profile from './Profile';
import SidebarTypes from '../../types/enums/common/SidebarTypes';
import { Link } from 'react-router-dom';
import SidebarDataInterface from '../../types/interfaces/SidebarDataInterface';
import SidebarMenuItem from './SidebarMenuItem';
import FeatureGroupInterface from '../../types/interfaces/api-response/FeatureGroupInterface';
import { cn } from 'lib/utils';
import { IconButton } from '@/tcomponents/ui/button';
import {
  faChevronLeft,
  faGear,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import LogoutMobile from './LogoutMobile';
import { FullscreenContext } from '@/context/FullscreenContext';
import { FeatureDetails } from '@/types/feature/featureDetails';
import {
  useUnFavouriteMutation,
  useFavouriteMutation,
} from '@/store/services/projects/projectApi';
import { toast } from '@tcomponents/ui/toast/use-toast';
import useAuth from '@/hooks/UseAuth';
import { projectPermissions } from '@/stringConsts/permissionConsts';

interface ISidebarProps {
  sidebarData?: SidebarDataInterface;
  setIsMobileSidebarOpen?: (isOpen: boolean) => void;
}

export interface IFavouriteResponse {
  message: string;
}

const Sidebar: FC<ISidebarProps> = ({
  sidebarData,
  setIsMobileSidebarOpen,
}) => {
  const { handle } = useContext(FullscreenContext);
  const sidebarContainerRef = useRef(null);

  const [favouriteProject, { data: favouriteProjectResponse }] =
    useFavouriteMutation();
  const [unFavouriteProject, { data: unFavouriteProjectResponse }] =
    useUnFavouriteMutation();
  const { hasPermission } = useAuth();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (!sidebarContainerRef.current || !setIsMobileSidebarOpen) return;
      const target = e.target as HTMLElement;
      if (target.closest('a')) {
        setIsMobileSidebarOpen(false);
      }
    },
    [sidebarContainerRef, setIsMobileSidebarOpen]
  );

  const doFavouriteAndUnfavourite = (
    projectId: string,
    isAlreadyFavourite: boolean
  ): void => {
    if (isAlreadyFavourite) {
      unFavouriteProject(projectId)
        .then(() => {
          if (unFavouriteProjectResponse?.message) {
            toast({
              duration: 3000,
              title: 'Success!',
              description: unFavouriteProjectResponse.message,
            });
          }
        })
        .catch(() => {
          toast({
            duration: 24 * 60 * 60000,
            className: 'bg-red-500',
            variant: 'destructive',
            title: 'Uh oh! Something went wrong',
            description: 'Unable to remove project from favourites',
          });
        });
    } else {
      favouriteProject(projectId)
        .then(() => {
          if (favouriteProjectResponse?.message) {
            toast({
              duration: 3000,
              title: 'Success!',
              description: favouriteProjectResponse?.message,
            });
          }
        })
        .catch(() => {
          toast({
            duration: 24 * 60 * 60000,
            className: 'bg-red-500',
            variant: 'destructive',
            title: 'Uh oh! Something went wrong',
            description: 'Unable to add project to favourites',
          });
        });
    }
  };

  const renderProjectStrip = () => {
    if (sidebarData) {
      const projectData: any = sidebarData?.data;
      return (
        <>
          {/* project heading & buttons strip */}
          <div
            className={cn(
              `-ml-[15px] -mr-[5px] pl-6 pr-3 py-3 items-center font-bold rounded-r-[8px] flex z-10`
            )}
            style={{ backgroundColor: projectData.colour }}
          >
            <Link to="/">
              <IconButton
                onClick={() => {}}
                icon={faChevronLeft}
                className="px-2 hover:text-brand text-[12px]"
                testingAttribute={
                  'sidebar-projectback-' +
                  projectData?.name?.replace(/[^0-9a-z]/gi, '').toLowerCase()
                }
              />
            </Link>
            <h6 className="flex-1 ml-4">{projectData.name}</h6>
            <IconButton
              onClick={() =>
                doFavouriteAndUnfavourite(projectData.id, projectData.favourite)
              }
              icon={faHeart}
              className={
                'flex hover:text-brand text-[12px] mr-2 ' +
                (projectData.favourite ? 'text-red-600' : '')
              }
              tooltip={
                projectData.favourite
                  ? 'Click to remove project from favourites'
                  : 'Click to add project to favourites'
              }
              testingAttribute={
                'sidebar-projectfavourite-' +
                projectData?.name?.replace(/[^0-9a-z]/gi, '').toLowerCase()
              }
            />
            {hasPermission(projectPermissions.general.settings_access) && (
              <Link to={'/projects/' + projectData.id + '/settings'}>
                <IconButton
                  onClick={() => {}}
                  icon={faGear}
                  className="flex hover:text-brand text-[12px]"
                  tooltip="Project Settings"
                  testingAttribute={
                    'sidebar-projectsettings-' +
                    projectData?.name?.replace(/[^0-9a-z]/gi, '').toLowerCase()
                  }
                />
              </Link>
            )}
          </div>
          {/* featuer group list */}
          <ul className="mt-[15px] flex flex-col gap-y-5 pl-[25px] pr-[15px] overflow-y-auto grow ">
            {typeof projectData.feature_groups !== 'undefined' &&
              projectData.feature_groups.length > 0 &&
              projectData.feature_groups.map(function (
                group: FeatureGroupInterface
              ) {
                return (
                  <li key={group.id} className="flex flex-col">
                    {group.features.length > 0 && (
                      <h5 className="text-sidebar-icon">{group.name}</h5>
                    )}
                    {/* feature list */}
                    <ul>
                      {group.features.length > 0 &&
                        group.features.map(function (feature: FeatureDetails) {
                          return (
                            <SidebarMenuItem
                              key={feature.id}
                              dimmed={
                                feature.feature_subtype === 'Survey'
                                  ? false
                                  : !feature.public
                              }
                              link={
                                '/projects/' +
                                projectData.id +
                                '/feature-groups/' +
                                group.id +
                                '/' +
                                feature.id +
                                '/' +
                                feature.feature_subtype +
                                '/view'
                              }
                              name={feature.name}
                              icon={feature.icon}
                            />
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </>
      );
    }
  };

  // Sidebar is not visible in fullscreen mode
  if (handle.active) {
    return null;
  }

  // main sidebar layout
  return (
    <div
      className="z-50 overflow-visible flex md:w-[250px]"
      ref={sidebarContainerRef}
      onClick={handleClick}
    >
      <div className="relative flex flex-col w-full h-[calc(100dvh)]  bg-sidebar-background">
        {/* logo & headline */}
        <div className="flex flex-col p-[25px] shrink-0">
          <Link to="/projects">
            <img className="mb-[15px]" alt="Logo" src={SidebarLogo} />
          </Link>
        </div>
        {/* menu or project/feature deatails section */}
        {sidebarData?.type === SidebarTypes.PROJECT && renderProjectStrip()}
        {(sidebarData?.type === SidebarTypes.DEFAULT || !sidebarData) && (
          <SidebarMenu />
        )}
        <div className="flex flex-col m-6 gap-y-5 shrink-0">
          <Profile />
          <LogoutMobile />
          <PoweredByComponent />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
