import { ModalContext } from '@/context/ModalContext';
import { useCreateOrUpdateFeatureMutation } from '@/store/services/projects/featureApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ProjectResponseInterface from '@/types/interfaces/api-response/ProjectResponseInterface';
import { useDispatch } from 'react-redux';
import { projectApi } from '@/store/services/projects/projectApi';

type PropTypes = {
  projectDetails?: ProjectResponseInterface;
  featureSubType?: string;
};

export const useCreateOrUpdateFeature = (props: PropTypes) => {
  const { projectDetails, featureSubType } = props;
  // here featureType is actually featureSubType according the the architecture
  let { featureId, featureGroupId, projectId, featureType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useContext(ModalContext);
  const [
    createOrUpdateFeature,
    {
      data: storingData,
      isSuccess: isStoringSuccess,
      isError: isStoringError,
      reset: resetStoringState,
    },
  ] = useCreateOrUpdateFeatureMutation();

  // use Effect for creating a new feature
  useEffect(() => {
    if (featureId === 'new' && isStoringSuccess) {
      ReactGA.event('feature_created', {
        feature_name: storingData.data.name,
        feature_type: featureSubType ?? featureType,
        project_name: projectDetails?.name,
      });

      navigate(
        `/projects/${projectId}/feature-groups/${featureGroupId}/${
          storingData.data.id
        }/${featureSubType ?? featureType}`
      );
      toast({
        description: 'Feature created',
        duration: 3000,
      });
    }
    if (featureId === 'new' && isStoringError) {
      showDialog({
        title: 'Error while creating the feature.',
        type: ModalTypes.DANGER,
        message: 'Something went wrong.',
        cancel: true,
      });
    }
    // Invalidate the project details cache to update the sidebar, it will refetch the project details
    dispatch(projectApi.util.invalidateTags(['Project']));
    resetStoringState();
    hideDialog();
  }, [isStoringSuccess, isStoringError, featureId, storingData]);

  // useEffect for updating a feature
  useEffect(() => {
    if (featureId !== 'new' && isStoringSuccess) {
      toast({
        description: 'Your changes have been saved',
        duration: 3000,
      });
      dispatch(projectApi.util.invalidateTags(['Project']));
    }
    if (featureId !== 'new' && isStoringError) {
      showDialog({
        title: 'Error while updating the feature.',
        type: ModalTypes.DANGER,
        message: 'Something went wrong.',
        cancel: true,
      });
    }
    hideDialog();
  }, [isStoringSuccess, isStoringError, featureId, storingData]);

  const createFeature = (values: any) => {
    createOrUpdateFeature(values);
  };

  const updateFeature = (id: number | string, values: any) => {
    createOrUpdateFeature({ ...values, id }).then(() => {});
  };

  return {
    createFeature,
    updateFeature,
    isStoringSuccess,
    isStoringError,
  };
};
