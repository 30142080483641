function titleCase(string: string) {
  const words = string.toString().split('_');

  return words
    .map((word: string) => {
      return word[0].toUpperCase() + word.toLowerCase().substring(1);
    })
    .join(' ');
}

export default titleCase;
