import { useMemo, RefObject, createRef, useCallback } from 'react';

export interface SaveRefHandle {
  save: () => void;
}

interface SaveTabsProps {
  tabCount: number;
}

export function useSaveTabs(props: SaveTabsProps) {
  // TODO: https://stackoverflow.com/questions/74033844/reacts-useref-hook-doesnt-take-a-function
  const tabRefs = useMemo<RefObject<SaveRefHandle>[]>(() => {
    return Array.from({ length: props.tabCount }).map(() => createRef());
  }, [props.tabCount]);

  const saveAllTabs = useCallback(() => {
    tabRefs.forEach(ref => {
      ref.current?.save();
    });
  }, [tabRefs]);

  return {
    tabRefs,
    saveAllTabs,
  };
}
