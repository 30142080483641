import { ChoiceQuestion, Question } from '../Helpers/Types';
import { Input } from '@/tcomponents/ui/input';
import { Button, IconButton } from '@/tcomponents/ui/button';
import { faImage, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@/tcomponents/ui/label';
import { useMemo } from 'react';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import React from 'react';
import { useUploadSurveyAssetMutation } from '@/store/services/projects/surveyApi';
import { useSurveyContext } from '../SurveyProvider';
import { Switch } from '@tcomponents/ui/switch';
import { v4 as uuid } from 'uuid';

interface ChoiceQuestionProps {
  question: ChoiceQuestion;
  updateQuestion: (data: Question) => void;
}
const ACCEPTED_IMAGE_TYPES = ['image/jpg', 'image/png'];

export const ChoiceQue = (props: ChoiceQuestionProps) => {
  const { question, updateQuestion } = props;
  const { surveyConfigId } = useSurveyContext();
  const [uploadSurveyAsset] = useUploadSurveyAssetMutation();
  // hidden input refs for image upload, one for each answer
  const fileInputRefs = useMemo(
    () =>
      Array(question.answers.length)
        .fill(null)
        .map(() => React.createRef<HTMLInputElement>()),
    [question.answers.length]
  );

  const addAnswer = () => {
    const newAnswer = { label: '', value: '', image_url: '' };
    updateQuestion({
      ...question,
      answers: [...question.answers, newAnswer],
    });
  };

  const deleteAnswer = (index: number) => {
    const newAnswers = question.answers.filter((_, i) => i !== index);
    updateQuestion({ ...question, answers: newAnswers });
  };

  const handleImageUpload = (
    e: InputEvent,
    file: Blob | string,
    index: number
  ) => {
    e.preventDefault();
    let formBody = new FormData();
    formBody.append('id', surveyConfigId?.toString() ?? '');
    formBody.append('type', `que-${question.id}-answer-${index}-${uuid()}`);
    formBody.append('asset', file);

    uploadSurveyAsset(formBody)
      .unwrap()
      .then(response => {
        toast({
          duration: 3000,
          description: 'Uploaded the image',
        });
        updateQuestion({
          ...question,
          answers: question.answers.map((ans, i) => {
            if (i === index) {
              return {
                ...ans,
                image_url: response.path,
              };
            }
            return ans;
          }),
        });
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh!',
          description: 'Could not upload the image',
        });
        updateQuestion({
          ...question,
          answers: question.answers.map((ans, i) => {
            if (i === index) {
              return {
                ...ans,
                image_url: null,
              };
            }
            return ans;
          }),
        });
      });
  };

  const handleImageChange = (event: any, index: number) => {
    if (event.target?.files && event.target.files.length) {
      //1Mb in bytes = 1024 * 1024
      if (
        event.target.files.length > 0 &&
        event.target?.files[0].size <= 1024 * 1024 * 8
      ) {
        handleImageUpload(event, event.target?.files[0], index);
      } else {
        toast({
          title: 'Uh oh! File Too Big',
          variant: 'destructive',
          description: 'The image should be 8Mb or less',
          duration: 24 * 60 * 60000,
        });
      }
    }
  };

  const handleImageRemove = (index: number) => {
    const newAnswers = question.answers.map((ans, i) => {
      if (i === index) {
        return {
          ...ans,
          image_url: '',
        };
      }
      return ans;
    });
    updateQuestion({
      ...question,
      answers: newAnswers,
    });
  };

  const handleShuffleChange = (checked: boolean) => {
    updateQuestion({
      ...question,
      shuffle: checked,
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row gap-x-8">
        <div>
          <Label>Answers Required</Label>
          <Input
            className="w-[60px]"
            type="number"
            min={0}
            value={question.max_answers}
            onChange={e => {
              updateQuestion({
                ...question,
                max_answers: parseInt(e.target.value),
              });
            }}
          />
        </div>
        <div>
          <Label>Shuffle Answers</Label>
          <Switch
            checked={question.shuffle}
            onCheckedChange={checked => {
              handleShuffleChange(checked);
            }}
            checkedLabel="Yes"
            unCheckedLabel="No"
          />
        </div>
      </div>
      <div>
        <Label className="w-full">Answers</Label>
        <div className="flex flex-col mt-1 divide-y gap-y-2">
          {question.answers.map((answer, index) => {
            const isFirstItem = index === 0;
            const isLastItem = index === question.answers.length - 1;
            return (
              <div key={index}>
                <div
                  className={`flex py-2.5 space-x-2 ${
                    isFirstItem ? 'border-t' : ''
                  } ${isLastItem ? 'border-b' : ''}`}
                >
                  <Input
                    className="grow"
                    value={answer.value}
                    type="text"
                    onChange={e => {
                      const newAnswers = question.answers.map((ans, i) => {
                        if (i === index) {
                          return {
                            ...ans,
                            value: e.target.value,
                            label: e.target.value,
                          };
                        }
                        return ans;
                      });
                      updateQuestion({ ...question, answers: newAnswers });
                    }}
                  />
                  <div>
                    <IconButton
                      tabIndex={-1}
                      className="p-2 text-2xl text-white rounded-lg bg-primary fa-2xl"
                      icon={faImage}
                      tooltip="Add Image"
                      testingAttribute="Add Image"
                      onClick={() => {
                        fileInputRefs[index].current?.click();
                      }}
                    />
                    <Input
                      type="file"
                      className="items-center justify-center hidden cursor-pointer"
                      accept={ACCEPTED_IMAGE_TYPES.join(', ')}
                      multiple={false}
                      ref={fileInputRefs[index]}
                      onChange={e => handleImageChange(e, index)}
                    />
                  </div>
                  <IconButton
                    tabIndex={-1}
                    className="p-2 text-2xl text-white rounded-lg bg-destructive fa-2xl"
                    icon={faXmark}
                    onClick={() => deleteAnswer(index)}
                    tooltip="Delete Asnwer"
                    testingAttribute="delete-answer"
                    disabled={question.answers.length === 1}
                  />
                </div>
                {answer.image_url && (
                  <div className="relative flex w-fit justify-start max-h-[200px] max-w-full">
                    <img
                      src={answer.image_url}
                      alt="answer"
                      className="object-contain object-center max-h-[200px] max-w-full"
                    />
                    <button
                      className="absolute top-1 right-2 cursor-pointer text-[14px] font-bold"
                      onClick={() => handleImageRemove(index)}
                      title="Remove Image"
                    >
                      ×
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Button className="w-full mt-0.5 bg-info" onClick={addAnswer}>
        + Add Answer
      </Button>
    </div>
  );
};
