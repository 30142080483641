import { useChartDashboardContext } from '@/pages/dashboard/projects/chartDashboard/ChartDashboardProvider';
import {
  useGetDataStoresQuery,
  useGetWorkflowsQuery,
} from '@/store/services/projects/chartApi';
import { ChartType } from '@/pages/dashboard/projects/chartDashboard/types/chart-types';
import { useEffect } from 'react';

export const useWidgetBuilderSetupOptions = (projectId: string | undefined) => {
  const {
    widgetData,
    setIsReadyToShow,
    updateWidgetData,
    rawDataFromDataStore,
  } = useChartDashboardContext();

  const workflows = useGetWorkflowsQuery(parseInt(projectId as string));

  const firstDataRow = rawDataFromDataStore?.length
    ? rawDataFromDataStore[0]
    : {};

  const dataStores = useGetDataStoresQuery(
    parseInt(widgetData.dataSource?.workflow as string),
    { skip: !widgetData.dataSource?.workflow }
  );

  const filteredDataStoresByChartType = dataStores.data?.data.filter(
    (dataStore: any) => {
      if (
        widgetData.chartType === ChartType.Pie ||
        widgetData.chartType === ChartType.Statistics ||
        widgetData.chartType === ChartType.HorizontalBar ||
        widgetData.chartType === ChartType.SimpleBar ||
        widgetData.chartType === ChartType.Rank
      ) {
        return ['list', 'row'].includes(dataStore.format);
      } else if (
        widgetData.chartType === ChartType.StackedBar ||
        widgetData.chartType === ChartType.Line ||
        widgetData.chartType === ChartType.Area ||
        widgetData.chartType === ChartType.StackedHorizontalBar
      ) {
        return ['list-grouped', 'row'].includes(dataStore.format);
      } else {
        return true;
      }
    }
  );

  const isLookup = () =>
    widgetData?.rowFormat?.agg_list_calculation === 'lookup';

  const isGroupedListRow = () => {
    const isCorrectChartType =
      widgetData.chartType !== ChartType.Pie &&
      widgetData.chartType !== ChartType.Statistics &&
      widgetData.chartType !== ChartType.HorizontalBar &&
      widgetData.chartType !== ChartType.SimpleBar &&
      widgetData.chartType !== ChartType.Rank;

    const isRowType =
      filteredDataStoresByChartType &&
      filteredDataStoresByChartType.find(
        (element: any) => element.value === widgetData.dataSource?.dataStore
      )?.format === 'row';

    return isCorrectChartType && isRowType;
  };

  const getDataStoreType = () => {
    return (
      filteredDataStoresByChartType &&
      filteredDataStoresByChartType.find(
        (element: any) => element.value === widgetData.dataSource?.dataStore
      )?.format
    );
  };

  const isListTypeChart = (): boolean => {
    return (
      widgetData.chartType === ChartType.Pie ||
      widgetData.chartType === ChartType.Statistics ||
      widgetData.chartType === ChartType.HorizontalBar ||
      widgetData.chartType === ChartType.SimpleBar ||
      widgetData.chartType === ChartType.Rank
    );
  };

  const isSingleSelect = () => {
    if (getDataStoreType() !== 'row') {
      return false;
    }

    const isCount =
      (widgetData?.rowFormat?.agg_list_calculation ?? 'count') === 'count';
    const isLookup =
      (widgetData?.rowFormat?.agg_list_calculation ?? 'count') === 'lookup';
    const isOrder =
      (widgetData?.rowFormat?.agg_list_calculation ?? 'count') === 'order';

    const isGroupCount =
      (widgetData?.rowFormat?.agg_calculation ?? 'count') === 'count';
    const isGroupLookup =
      (widgetData?.rowFormat?.agg_calculation ?? 'count') === 'lookup';
    const isGroupOrder =
      (widgetData?.rowFormat?.agg_calculation ?? 'count') === 'order';

    return isListTypeChart()
      ? isCount || isLookup || isOrder
      : isGroupCount || isGroupLookup || isGroupOrder;
  };

  const isMultiSelect = (): boolean => {
    if (isSingleSelect() || getDataStoreType() !== 'row') {
      return false;
    }

    const isCount =
      (widgetData?.rowFormat?.agg_list_calculation ?? 'count') === 'count';
    const isLookup =
      (widgetData?.rowFormat?.agg_list_calculation ?? 'count') === 'lookup';

    const isGroupCount =
      (widgetData?.rowFormat?.agg_calculation ?? 'count') === 'count';
    const isGroupLookup =
      (widgetData?.rowFormat?.agg_calculation ?? 'count') === 'lookup';

    return isListTypeChart()
      ? !isCount && !isLookup
      : !isGroupCount && !isGroupLookup;
  };

  const isReadyToShow = () => {
    if (
      (widgetData?.dataSource?.workflow ?? '') === '' ||
      (widgetData?.dataSource?.dataStore ?? '') === ''
    ) {
      return false;
    }

    if (getDataStoreType() !== 'row') {
      return true;
    }

    let hasGroupBy = true;
    let hasValuesFrom = true;

    if (isGroupedListRow()) {
      hasValuesFrom = false;
      hasGroupBy = (widgetData?.rowFormat?.agg_group_by ?? '') !== '';

      if (widgetData?.rowFormat?.agg_values_from ?? null) {
        hasValuesFrom = true;
      }
    } else {
      if ((widgetData.rowFormat?.agg_values_from ?? null) === null) {
        hasValuesFrom = false;
      }
    }

    return hasValuesFrom && hasGroupBy;
  };

  useEffect(() => {
    setIsReadyToShow(isReadyToShow());
  }, [widgetData]);

  const calculateAggType = () => {
    const needle = widgetData.chartType;

    if (needle === undefined) {
      return 'list';
    }

    const haystack = [
      ChartType.Statistics,
      ChartType.Pie,
      ChartType.HorizontalBar,
      ChartType.SimpleBar,
      ChartType.Rank,
    ];

    if (haystack.includes(needle)) {
      return 'list';
    }

    return 'group';
  };

  const multiselectData: any[] = Object.keys(firstDataRow).map(key => ({
    value: key,
    label: key,
  }));

  const isRowTypeDataStore = getDataStoreType() === 'row';

  const fieldsToShow = {
    title: {
      active: true,
      options: null,
    },
    description: {
      active: widgetData.chartType !== ChartType.Statistics,
      options: null,
    },
    chartType: {
      active: true,
      options: null,
    },
    workflow: {
      active: true,
      options: null,
    },
    datastore: {
      active: true,
      options: null,
    },
    groupBy: {
      active: isGroupedListRow(),
      options: null,
    },
    calculation: {
      active: getDataStoreType() === 'row',
      options:
        !isGroupedListRow() && getDataStoreType() === 'row' ? 'list' : 'group',
    },
    keysFrom: {
      active: isLookup(),
      options: null,
    },
    valuesFrom: {
      active: true,
      options: {
        isSingleSelect: isSingleSelect(),
        isMultiSelect: isMultiSelect(),
      },
    },
    sortBy: {
      active: isRowTypeDataStore,
      options: null,
    },
  };

  return {
    widgetData,
    updateWidgetData,
    workflows,
    filteredDataStoresByChartType,
    calculateAggType,
    multiselectData,
    firstDataRow,
    fieldsToShow,
  };
};
