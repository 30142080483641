import { useUploadSpreadsheetMutation } from '@/store/services/projects/graphApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { useEffect } from 'react';

export const useUploadSpreadsheet = () => {
  const [uploadSpreadsheet, { isSuccess, isError, isLoading }] =
    useUploadSpreadsheetMutation();

  useEffect(() => {
    if (isSuccess) {
      toast({
        duration: 3000,
        title: 'File Upload Complete',
        description:
          'Your data is now being processed, this may take a few minutes',
      });
    }
    if (isError) {
      toast({
        duration: 24 * 60 * 60000,
        className: 'bg-red-500',
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      });
    }
  }, [isSuccess, isError]);

  return { uploadSpreadsheet, isFileUploading: isLoading };
};
