import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@tcomponents/ui/popover';
import { Button } from '@/tcomponents/ui/button';
import { PopoverClose } from '@radix-ui/react-popover';
import {
  faCaretDown,
  faFloppyDisk,
  faGear,
  faPenToSquare,
  faPlus,
  faTableColumns,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import { Prompt } from '@/utils/use-prompt';
import { generateSchemaFromData } from '../lib/generateSchemaFromData';
import { useTableSettingsView } from '../hooks/useTableSettingsView';
import React from 'react';
import { faEraser } from '@fortawesome/pro-solid-svg-icons';

export type Schema = {
  configuration: Record<string, any>[];
  enforce_schema: boolean;
  process_updates: boolean;
};

type TableSettingsViewProps = {
  selectedRowTypeDataStore: { title: string; uuid: string } | undefined;
  setTableView: React.Dispatch<
    React.SetStateAction<'data-view' | 'settings-view'>
  >;
  schema?: Schema;
  rawDataResponse: any;
};

export const TableSettingsView = (props: TableSettingsViewProps) => {
  const { setTableView, selectedRowTypeDataStore, schema, rawDataResponse } =
    props;

  const {
    tabulatorRef,
    tableDivRef,
    addNewRow,
    updateDataStoreSchemaFn,
    isTableDirty,
    isSaveButtonDirty,
    setIsSaveButtonDirty,
    enforceSchema,
    processUpdates,
    setEnforceSchema,
    setProcessUpdates,
    showWipeModal,
  } = useTableSettingsView({
    schema,
    dataStoreId: selectedRowTypeDataStore?.uuid || '',
    setTableView,
  });

  Prompt({
    message: 'Are you sure you want to leave without saving?',
    when: isTableDirty || isSaveButtonDirty,
  });

  return (
    <div className="flex flex-col h-full max-w-full p-8 mb-8 bg-white border-2 rounded-md border-danger-error">
      <div className="flex justify-between">
        <div className="flex gap-x-12">
          <div className="flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={faGear}
              className="text-[32px] text-danger-error"
            />
            <div>
              <h3>Data Store Settings</h3>
              <p className="text-gray-500 ">
                {selectedRowTypeDataStore?.title}
              </p>
            </div>
          </div>
          <div className="flex gap-x-7">
            <div>
              <Label>Enforce Fields</Label>
              <Switch
                className="mt-1"
                checked={enforceSchema}
                checkedLabel="Yes"
                unCheckedLabel="No"
                onCheckedChange={checked => {
                  setEnforceSchema(checked);
                  setIsSaveButtonDirty(true);
                }}
              />
            </div>
            <div>
              <Label>Process Updates</Label>
              <Switch
                className="mt-1"
                checked={processUpdates}
                checkedLabel="Yes"
                unCheckedLabel="No"
                onCheckedChange={checked => {
                  setProcessUpdates(checked);
                  setIsSaveButtonDirty(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-4">
          <Popover>
            <PopoverTrigger>
              <Button variant={'warning'}>
                Actions
                <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              side="bottom"
              className="p-0 text-[14px] w-fit min-w-[200px]"
            >
              <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                <Button
                  onClick={() => {
                    generateSchemaFromData(rawDataResponse.data, tabulatorRef);
                    setIsSaveButtonDirty(true);
                  }}
                  icon={faTableColumns}
                  iconClassName="text-gray-400 -ml-0.5"
                  variant="ghost"
                  className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full gap-x-1"
                >
                  Generate Columns From Data
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  iconClassName="text-gray-400 -ml-0.5"
                  onClick={showWipeModal}
                  className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full gap-x-1"
                  icon={faEraser}
                  disabled={rawDataResponse.data?.length === 0}
                  tooltip={
                    rawDataResponse.data?.length === 0
                      ? 'There is no data to wipe'
                      : ''
                  }
                >
                  Wipe Data
                </Button>
                <Button
                  onClick={() => setTableView('data-view')}
                  icon={faPenToSquare}
                  iconClassName="text-gray-400 -ml-0.5"
                  variant="ghost"
                  className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full gap-x-1"
                >
                  Back To Data View
                </Button>
              </PopoverClose>
            </PopoverContent>
          </Popover>
          <Button icon={faPlus} onClick={addNewRow}>
            Add Field
          </Button>
          <Button
            icon={faFloppyDisk}
            disabled={!isTableDirty && !isSaveButtonDirty}
            onClick={updateDataStoreSchemaFn}
          >
            Save
          </Button>
        </div>
      </div>
      <div ref={tableDivRef} className="table-component striped" />
    </div>
  );
};
