import { IconButton } from '@tcomponents/ui/button';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { cn } from '@/lib/utils';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useHandleBoundaryTab } from '../hooks/useHandleBoundaryTab';
import { Boundary } from './Boundary';

export const BoundariesTab = () => {
  const {
    boundariesArray,
    handleDragEnd,
    addBoundary,
    deleteBoundary,
    handleSingleFieldValueChange,
    handleBoundaryTypeChange,
    handleDataStoreChange,
    isDragging,
    setIsDragging,
    handleThemingOnKeysFromOrValuesFromChange,
    handleThemingColorChange,
  } = useHandleBoundaryTab();

  return (
    <div className="w-full">
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable droppableId="list">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="relative"
            >
              {boundariesArray.map((boundary, idx) => (
                <Boundary
                  key={boundary.id}
                  boundary={boundary}
                  idx={idx}
                  handleSingleFieldValueChange={handleSingleFieldValueChange}
                  handleBoundaryTypeChange={handleBoundaryTypeChange}
                  handleDataStoreChange={handleDataStoreChange}
                  deleteBoundary={deleteBoundary}
                  addBoundary={addBoundary}
                  isDragging={isDragging}
                  handleThemingOnKeysFromOrValuesFromChange={
                    handleThemingOnKeysFromOrValuesFromChange
                  }
                  handleThemingColorChange={handleThemingColorChange}
                />
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {boundariesArray.length === 0 && (
        <div
          className={cn(
            'flex items-center w-full py-3 transition-opacity duration-200 ease-in-out gap-x-4'
          )}
        >
          <span className="w-full h-[1px] bg-gray-300 grow -mr-3"></span>
          <IconButton
            onClick={addBoundary}
            icon={faCirclePlus}
            tooltip="Add Overlay"
            className="-mr-5 text-gray-300 rounded-full cursor-pointer hover:text-gray-800"
          />
        </div>
      )}
    </div>
  );
};
