import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { useEffect, useState } from 'react';

export const useHandleBoundaryVisibility = (
  boundaries: MapFeatureDetails['boundaries'] | undefined
) => {
  const [boundariesVisibility, setBoundariesVisibility] = useState<
    Record<string, boolean>
  >({});
  const [boundariesLoading, setBoundariesLoading] = useState<
    Record<string, boolean>
  >({});

  const visibleBoundaryConfigData = boundaries?.find(
    boundary => boundariesVisibility[boundary.id]
  );

  useEffect(() => {
    if (boundaries) {
      const visibilityStates: Record<string, boolean> = {};
      const loadingStates: Record<string, boolean> = {};
      boundaries.forEach(boundary => {
        visibilityStates[boundary.id] = boundary.startEnabled ?? false;
        loadingStates[boundary.id] = false;
      });
      setBoundariesVisibility(visibilityStates);
      setBoundariesLoading(loadingStates);
    }
  }, [boundaries]);

  const toggleBoundaryVisibility = (id: string) => {
    setBoundariesVisibility(prevVisibility => {
      const newVisibility = Object.keys(prevVisibility).reduce(
        (acc, key) => ({
          ...acc,
          [key]: key === id ? !prevVisibility[key] : false,
        }),
        {}
      );
      return newVisibility;
    });
  };

  const setBoundariesLoadingState = ({
    id,
    loading,
  }: {
    id: string;
    loading: boolean;
  }) => {
    setBoundariesLoading(prevLoading => ({
      ...prevLoading,
      [id]: loading,
    }));
  };

  return {
    boundariesVisibility,
    toggleBoundaryVisibility,
    boundariesLoading,
    setBoundariesLoadingState,
    visibleBoundaryConfigData,
  };
};
