import { createSlice } from '@reduxjs/toolkit';

export interface RequestState {
  requestLoading: {
    value: boolean;
  };
}

const initialLoading = {
  value: false,
};

export const requestSlice = createSlice({
  name: 'request',
  initialState: initialLoading,
  reducers: {
    requestLoading: state => {
      state.value = true;
    },
    requestFinished: state => {
      state.value = false;
    },
  },
});

export const { requestLoading, requestFinished } = requestSlice.actions;
export const actionTypes = requestSlice.actions;
export default requestSlice.reducer;

export const getRequestLoadingValue = (state: RequestState) =>
  state.requestLoading.value;
