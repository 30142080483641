import React, { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { rolePermissions } from '@/stringConsts/permissionConsts';

const RolesList = lazy(() => import('../../pages/dashboard/roles/RolesList'));
const RoleManager = lazy(
  () => import('../../pages/dashboard/roles/RoleManager')
);

export const roleManagementRoutes = [
  {
    element: <ProtectedRoute permissions={[rolePermissions.general.read]} />,
    children: [{ path: 'roles', element: <RolesList /> }],
  },
  {
    element: <ProtectedRoute permissions={[rolePermissions.general.create]} />,
    children: [{ path: 'roles/new', element: <RoleManager /> }],
  },
  {
    element: (
      <ProtectedRoute
        permissions={[
          rolePermissions.general.edit,
          rolePermissions.general.read,
        ]}
      />
    ),
    children: [{ path: 'roles/:id', element: <RoleManager /> }],
  },
];
