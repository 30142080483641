import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Integration'],
  endpoints: builder => ({
    createIntegration: builder.mutation({
      query: (name: string) => ({
        url: '/integrations',
        method: 'POST',
        body: { name },
      }),
    }),
    updateIntegration: builder.mutation({
      query: ({ user_key, name }) => ({
        url: `/integrations/${user_key}`,
        method: 'PUT',
        body: { name },
      }),
      invalidatesTags: ['Integration'],
    }),
    getIntegration: builder.query({
      query: (user_key: string) => ({
        url: `/integrations/${user_key}`,
        method: 'GET',
      }),
      providesTags: ['Integration'],
    }),
    toggleIntegration: builder.mutation({
      query: (user_key: string) => ({
        url: `/integrations/${user_key}/toggle`,
        method: 'GET',
      }),
      invalidatesTags: ['Integration'],
    }),
    deleteIntegration: builder.mutation({
      query: (user_key: string) => ({
        url: `/integrations/${user_key}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Integration'],
    }),
    regenerateIntegration: builder.mutation({
      query: (user_key: string) => ({
        url: `/integrations/${user_key}/regenerate`,
        method: 'GET',
      }),
      invalidatesTags: ['Integration'],
    }),
  }),
});

export const {
  useCreateIntegrationMutation,
  useUpdateIntegrationMutation,
  useGetIntegrationQuery,
  useLazyGetIntegrationQuery,
  useToggleIntegrationMutation,
  useDeleteIntegrationMutation,
  useRegenerateIntegrationMutation,
} = integrationApi;
