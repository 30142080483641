import FeatureSurveyConfiguration from './SurveyConfiguration';
import SurveyProvider from './SurveyProvider';

interface Props {
  children?: React.ReactNode;
}

export const SurveyContainer = (props: Props) => {
  return (
    <SurveyProvider>
      <FeatureSurveyConfiguration />
    </SurveyProvider>
  );
};
