import React, { FC } from 'react';

interface IAnalyticsProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}

const Analytics: FC<IAnalyticsProps> = props => {
  return props.children;
};

export default Analytics;
