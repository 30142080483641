import { useEffect, useState } from 'react';
import { useValidateSubdomainQuery } from '@/store/services/projects/surveyApi';
import { useSurveyContext } from '../SurveyProvider';

export const useURLCheck = () => {
  const [url, setUrl] = useState('');
  const [debouncedUrl, setDebouncedUrl] = useState('');
  const [subdomainIsOk, setSubdomainIsOk] = useState(false);
  const { surveyConfigId } = useSurveyContext();
  const [hasEverChecked, setHasEverChecked] = useState<boolean>(false);

  const { data, isFetching, isSuccess, isError } = useValidateSubdomainQuery(
    { subdomain: debouncedUrl, id: surveyConfigId },
    {
      skip: !debouncedUrl || !surveyConfigId,
    }
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isSuccess) {
      const isSubdomainValid = data?.status;
      //eslint-disable-next-line
      const regex = /^[a-z0-9-]+$/g;

      if (!regex.test(url)) {
        setSubdomainIsOk(false);
        setHasEverChecked(true);
      } else {
        setSubdomainIsOk(isSubdomainValid);
        setHasEverChecked(true);
      }
    }
    if (isError) {
      setSubdomainIsOk(false);
    }
  }, [isSuccess, isError, data]);

  useEffect(() => {
    const delay = 300;
    const debounceTimeout = setTimeout(() => {
      setDebouncedUrl(url);
    }, delay);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [url]);

  return {
    url,
    setUrl,
    subdomainIsOk,
    subdomainIsChecking: isFetching,
    hasEverChecked,
  };
};
