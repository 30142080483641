import React, { FC } from 'react';
import Sizes from '../../types/enums/common/Sizes';

interface IIconProps {
  size?: Sizes;
  icon: string;
  style?: Object;
  className?: string;
  onClick?: () => void;
}
const Icon: FC<IIconProps> = ({
  size,
  icon,
  style = {},
  className = '',
  onClick,
}) => {
  return (
    <i
      onClick={onClick}
      style={style}
      className={`${size || ''} ${icon} fa-fw ${className}`}
    ></i>
  );
};

export default Icon;
