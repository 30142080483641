import React, { FC, ReactNode } from 'react';
import Title from './Title';
import styled from 'styled-components';

interface ISegmentProps {
  children: ReactNode | string;
  style?: Object;
  className?: string;
  title?: string;
}

const SegmentTitle = styled(Title)`
  line-height: 2.5em !important;
  padding-bottom: 5px !important;
  border-bottom: 1px solid #e2e2e3 !important;
`;

const Segment: FC<ISegmentProps> = ({
  children,
  style,
  className = '',
  title = null,
}) => {
  return (
    <div style={style} className={'ui segment ' + className}>
      {title && <SegmentTitle size={3}>{title}</SegmentTitle>}
      {children}
    </div>
  );
};

export default Segment;
