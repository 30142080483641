import React from 'react';
import CavendishLogo from '../../assets/images/cavendish-logo.png';
import { Link } from 'react-router-dom';

const PoweredByComponent = () => {
  return (
    <Link
      to="https://cavendishconsulting.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center justify-end text-white hover:text-white hover:opacity-60"
    >
      <span className="font-bold">Powered by</span>
      <img
        className="w-[76px] h-[16px] ml-1"
        src={CavendishLogo}
        alt="Cavendish"
      />
    </Link>
  );
};

export default PoweredByComponent;
