import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { SimpleBarWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type SimpleBarChartProps = {
  widgetData: SimpleBarWidgetOptions;
  chartData?: any;
};

export default function SimpleBarChart(props: SimpleBarChartProps) {
  const { widgetData, chartData } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;

  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (chartData?.length === 0) return <NoData />;

  const isAggregatedChartDataArray = Array.isArray(finalData);
  if (!isAggregatedChartDataArray) return <NoData />;

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${label} :`}</p>
          {payload.map((item: any, index: number) => (
            <div className="flex items-center gap-x-1">
              <span
                className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
                style={{ backgroundColor: `${payload[0]?.payload?.fill}` }}
              ></span>
              <p key={`item-${index}`}>{`${item.value} ${
                widgetData.chartOptions.unit ?? ''
              }`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const getLongestLabel = () => {
    let maxLength = 0;
    if (finalData && Array.isArray(finalData)) {
      finalData.forEach((a: { _name: string | any[] }) => {
        if (typeof a._name !== 'undefined' && a._name.length > maxLength) {
          maxLength = a._name.length;
        }
      });
    }

    return maxLength;
  };

  const getLongestYLabel = () => {
    let maxLength = 0;
    if (finalData && Array.isArray(finalData)) {
      finalData.forEach((a: { _name: string | any[] }) => {
        let keys = Object.keys(a);
        keys.forEach(key => {
          if (
            key !== '_name' &&
            // @ts-ignore
            parseInt(a[key]).toString().length > maxLength
          ) {
            // @ts-ignore
            maxLength = parseInt(a[key]).toString().length;
          }
        });
      });
    }
    return maxLength * (maxLength / 2.5);
  };

  const dataWithColors = finalData?.map((data: any, index: number) => ({
    ...data,
    fill: widgetData.dataStyling?.[index % widgetData.dataStyling.length],
  }));

  return (
    <ResponsiveContainer width="100%" height="100%" className="isChart">
      <BarChart
        data={dataWithColors}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: widgetData.chartOptions.verticalLabels
            ? 10 + getLongestLabel() * 5.2
            : 20,
        }}
      >
        <CartesianGrid strokeDasharray="1 5" />
        <XAxis
          dataKey="_name"
          label={{
            value: widgetData.chartOptions.xAxisLabel,
          }}
          tickLine={false}
          axisLine={false}
          interval={0}
          tickMargin={10}
          tick={(props: any) => {
            const { x, y, payload } = props;
            const isVerticalLabels = widgetData.chartOptions?.verticalLabels;
            return (
              <Text
                x={x}
                y={y}
                width={100}
                angle={isVerticalLabels ? -90 : 0}
                textAnchor={isVerticalLabels ? 'end' : 'middle'}
                verticalAnchor="middle"
              >
                {payload.value}
              </Text>
            );
          }}
        />
        <YAxis
          label={{
            value:
              widgetData.chartOptions.yAxisLabel &&
              widgetData.chartOptions.yAxisLabel +
                (widgetData.chartOptions.unit
                  ? ` (${widgetData.chartOptions.unit})`
                  : ''),
            angle: -90,
            dy: 0,
            dx: -Math.abs(15 + getLongestYLabel()),
          }}
          tickLine={false}
          axisLine={false}
          dataKey={'_value'}
        />
        <Tooltip content={<CustomTooltip />} />

        <Bar isAnimationActive={false} dataKey={'_value'} />
      </BarChart>
    </ResponsiveContainer>
  );
}
