import { SpreadsheetNodeData } from '../../types/node-types';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { Button } from '@tcomponents/ui/button';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import { useGetGraphQuery } from '@/store/services/projects/graphApi';
import { Loader2 } from 'lucide-react';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import { useParams } from 'react-router-dom';
import { useUploadSpreadsheet } from '../../hooks/useUploadSpreadsheet';
import { useRef } from 'react';

const ACCEPTED_FILE_TYPES = [
  'csv',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
];

const SpreadsheetNodeFormFields = () => {
  const { featureId } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { uploadSpreadsheet, isFileUploading } = useUploadSpreadsheet();

  const { data: graphData } = useGetGraphQuery(Number(featureId), {
    skip: !featureId,
  });

  const serverNodes = graphData?.data?.nodes;

  // find the id of the node is in the serverNodes
  const nodeExistsInServer = !!serverNodes?.find(
    (node: any) => node.id === activeNode.id
  );

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  const { descriptionTitle, descriptionUrl, description, license, reviewDate } =
    activeNode.data as SpreadsheetNodeData;

  const onSubmit = (inputData: { spreadsheet: FileList }) => {
    let bodyFormData = new FormData();
    bodyFormData.append('file', inputData.spreadsheet[0]);
    bodyFormData.append('node_uuid', activeNode.id);
    uploadSpreadsheet(bodyFormData);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Triggered when user uploaded a new file
    // FileList is immutable, so we need to create a new one
    const dataTransfer = new DataTransfer();

    // Add newly uploaded images
    Array.from(event.target.files!).forEach(image =>
      dataTransfer.items.add(image)
    );

    // Validate and update uploaded file
    const newFiles = dataTransfer.files;
    onSubmit({ spreadsheet: newFiles });
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      {/* configuration block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex gap-x-2">
            <h5 className="font-bold">Source Data</h5>
            <span className="font-extrabold text-gray-400">
              {'(xlsx, csv, ods)'}
            </span>
          </div>
          <Input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept={ACCEPTED_FILE_TYPES.join(', ')}
            multiple={false}
            disabled={!nodeExistsInServer}
            onChange={handleFileChange}
          />
          <Button
            variant={'warning'}
            icon={!isFileUploading ? faUpload : undefined}
            className="w-full mt-1.5"
            disabled={!nodeExistsInServer || isFileUploading}
            onClick={() => fileInputRef.current?.click()}
          >
            {!isFileUploading && 'Upload Spreadsheet'}
            {isFileUploading && (
              <Loader2 className="w-4 h-4 ml-2 animate-spin" />
            )}
          </Button>
        </div>
      </div>

      {/* source details block */}
      <div
        className={
          'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
          (isExpanded ? ' w-1/2' : ' w-full')
        }
      >
        <h5 className="font-bold ">Source Details</h5>
        <div className="flex flex-col py-2 gap-y-4">
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Title</label>
            <Input
              value={descriptionTitle}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionTitle',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>

          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">URL</label>
            <Input
              value={descriptionUrl}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionUrl',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>

          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Description</label>
            <Textarea
              value={description}
              onChange={e =>
                handleSingleInputFieldChange(
                  'description',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">License</label>
            <Input
              value={license}
              onChange={e =>
                handleSingleInputFieldChange('license', e.target.value)
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Review Date</label>
            <Input
              value={reviewDate}
              onChange={e =>
                handleSingleInputFieldChange('reviewDate', e.target.value)
              }
              className="flex flex-1 bg-white"
              type="date"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpreadsheetNodeFormFields;
