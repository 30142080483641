import React, { useCallback, useEffect } from 'react';
import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';

function usePrompt(
  message: string | null | undefined | false,
  { beforeUnload }: { beforeUnload?: boolean } = {}
) {
  let blocker = useBlocker(
    useCallback(
      () => (typeof message === 'string' ? !window.confirm(message) : false),
      [message]
    )
  );
  let prevState = React.useRef(blocker.state);
  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  const alertUser = useCallback(
    (e: any) => {
      if (typeof message === 'string') {
        e.preventDefault();
        e.returnValue = message;
      }
    },
    [message]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [message, alertUser]);

  useBeforeUnload(
    useCallback(
      event => {
        if (beforeUnload && typeof message === 'string') {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload]
    ),
    { capture: true }
  );
}

interface PromptProps {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
}

export function Prompt({ when, message, ...props }: PromptProps) {
  usePrompt(when ? message : false, props);
  return null;
}
