import React, { useContext, useEffect } from 'react';
import { SaveActionContext } from '@/context/SaveActionContext';
import { useCustomGraphContext } from './CustomGraphProvider';

type CanvasBreadCrumbProps = {
  subgraphActive: boolean;
  toggleNewSubGraph: (value: boolean) => void;
  activePipelineNode: any;
  handleGraphSave: () => void;
};

export default function CanvasBreadCrumb(props: CanvasBreadCrumbProps) {
  // @ts-ignore
  const { setSaveAction } = useContext(SaveActionContext);
  const { handleGraphSave } = props;
  const { activeNode, fullWidthSettingsView: isNodeExpanded } =
    useCustomGraphContext();

  useEffect(() => {
    setSaveAction({
      callback: () => {
        handleGraphSave();
      },
    });
  }, [handleGraphSave, setSaveAction]);

  return (
    <div className="flex items-center px-5 bg-white border-b gap-x-2">
      {props.subgraphActive ? (
        <>
          <button
            onClick={() => props.toggleNewSubGraph(false)}
            className="py-4 pr-0 text-base font-bold text-green-600 hover:text-gray-600"
          >
            Workflow
          </button>
          <span className="text-base font-bold text-gray-600">
            {'> '}
            {props.activePipelineNode?.data.heading}
          </span>
          {isNodeExpanded && (
            <span className="text-base font-bold text-gray-600">
              {'> '}
              {activeNode?.data.heading}
            </span>
          )}
        </>
      ) : (
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-row items-center flex-1 gap-x-2">
            <h3 className="p-4 px-0 text-base font-bold text-gray-600 ">
              Workflow
            </h3>
            {isNodeExpanded && (
              <span className="text-base font-bold text-gray-600">
                {'> '}
                {activeNode?.data.heading}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
