import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { StackedHorizontalBarWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';
import { getKeys } from '../../utils/helperFunctions';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type StackedHorizontalBarChartProps = {
  widgetData: StackedHorizontalBarWidgetOptions;
  chartData?: any;
  renderedFromDashboard?: boolean;
};

export default function StackedHorizontalBarChart(
  props: StackedHorizontalBarChartProps
) {
  const { widgetData, chartData, renderedFromDashboard } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;

  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (chartData?.length === 0) return <NoData />;

  const isAggregatedChartDataArray = Array.isArray(finalData);
  if (!isAggregatedChartDataArray) return <NoData />;

  const barDataKeys = getKeys(finalData);

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${label} :`}</p>
          {payload.map((item: any, index: number) => (
            <div className="flex items-center gap-x-1">
              <span
                className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
                style={{ backgroundColor: `${item.fill}` }}
              ></span>
              <p key={`item-${index}`}>{`${item.name} : ${item.value} ${
                widgetData.chartOptions.unit ?? ''
              }`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="flex flex-wrap justify-center gap-x-5 gap-y-2 sm:ml-0">
        {payload
          .slice()
          .reverse()
          .map((item: any, index: number) => (
            <div key={`item-${index}`} className="flex items-center gap-x-1">
              <div
                className="w-10 h-5 rounded-md"
                style={{ backgroundColor: item?.color }}
              />
              <p className="text-sm">{item?.value}</p>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between mx-[15px] gap-x-10">
        <h6>{widgetData.chartOptions.yAxisLabel}</h6>
        <h6>{widgetData.chartOptions.xAxisLabel}</h6>
        <h6>{widgetData.chartOptions.detailNameLabel}</h6>
      </div>
      <div
        className={`flex-1 w-full h-full mt-2 mb-12 overflow-x-hidden overflow-y-auto ${
          renderedFromDashboard ? '' : 'max-h-[350px]'
        }`}
      >
        <ResponsiveContainer
          minHeight={finalData.length * 50}
          className="isChart"
        >
          <BarChart
            layout="vertical"
            data={finalData}
            margin={{
              top: 10,
              right: 15,
              left: 20,
              bottom: 10,
            }}
            barCategoryGap={'30%'}
          >
            <YAxis
              mirror={true}
              dataKey="_name"
              type="category"
              yAxisId={0}
              axisLine={false}
              tick={({ x, y, payload }) => {
                return (
                  <text
                    x={x}
                    y={y}
                    dy={`${-25 / finalData.length}%`}
                    dx={-5}
                    textAnchor="start"
                    fill="#666"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {payload.value}
                  </text>
                );
              }}
              tickMargin={0}
            />
            {widgetData.chartOptions?.showKeys !== false && (
              <Legend
                wrapperStyle={{
                  paddingTop: 30,
                }}
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
                iconType="rect"
                content={<CustomLegend />}
              />
            )}

            <XAxis xAxisId={0} axisLine={false} type="number" />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: 'transparent' }}
            />
            {barDataKeys.map((key: string, index: number) => (
              <Bar
                isAnimationActive={false}
                dataKey={key}
                key={`bar-${index}`}
                // we will only apply radius to the first and last bar
                radius={
                  index === 0
                    ? [5, 0, 0, 5]
                    : index === barDataKeys.length - 1
                    ? [0, 5, 5, 0]
                    : [0, 0, 0, 0]
                }
                stackId={'a'}
                fill={widgetData.dataStyling?.[index]}
                // we need to apply the background fill color to the first bar only, if we apply on every bar then the colors get mixed up because of z-index issue
                //https://github.com/recharts/recharts/issues/2826
                background={index === 0 ? { fill: '#F7FAFB' } : undefined}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
