import React, { useEffect } from 'react';
import { useAuthUserDetailsQuery } from '@/store/services/users/authApi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';
import { setUserData } from '@/store/features/users/userSlice';
import AppSplashLoading from '@/tcomponents/custom/AppSplashLoading';

const AuthenticatedRoutes = () => {
  const { data, isSuccess, isError, isLoading } = useAuthUserDetailsQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserData(data?.data.auth.user));
      navigate(location.pathname);
    }
    if (isError) {
      navigate('/login');
    }
  }, [data, isSuccess]);

  if (isLoading) {
    return <AppSplashLoading />;
  }

  return <Outlet />;
};

export default AuthenticatedRoutes;
