import React, { FC, ReactNode } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/tcomponents/ui/tabs';
import { cn } from '@/lib/utils';
import { handleHorizontalScroll } from '@/lib/handleHorizontalScrolling';

interface ITabbedNavigationBarProps {
  elements: Array<ReactNode>;
  onChange: (index: number) => void;
  selectedTabIndex: number;
  tabsContainerClassName?: string;
  tabsListClassName?: string;
  tabsTriggerClassName?: string;
}
const TabbedNavigationBar: FC<ITabbedNavigationBarProps> = ({
  elements,
  selectedTabIndex = 0,
  onChange,
  tabsContainerClassName,
  tabsListClassName,
  tabsTriggerClassName,
}) => {
  return (
    <Tabs
      onWheel={handleHorizontalScroll}
      className={cn(
        'flex bg-white border-b-1 !border-t-0 gap-x-3 border px-[20px] max-w-full  md:px-[40px] 2xl:px-[90px] overflow-x-auto whitespace-nowrap scrollbar ::-webkit-scrollbar-w-[15px] scrollbar-thin',
        tabsContainerClassName
      )}
    >
      {elements.map((e: any, index) => (
        <TabsList
          key={'tabbed-navigation-element-' + index}
          className={cn('h-auto p-0', tabsListClassName)}
        >
          <TabsTrigger
            className={cn('text-base font-normal py-3', tabsTriggerClassName)}
            disabled={e.props.disabled}
            hidden={e.props.hidden}
            onClick={() => onChange(index)}
            value={'tabbed-navigation-element-' + index}
            data-state={selectedTabIndex === index ? 'active' : ''}
            aria-label="settings"
          >
            {e}
          </TabsTrigger>
        </TabsList>
      ))}
    </Tabs>
  );
};

export default TabbedNavigationBar;
