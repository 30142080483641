import { defaultDataStyling } from '@/pages/dashboard/features/forms/RankedList/consts';

interface Answer {
  answer: string;
  color: string;
}

interface Question {
  question: string;
  answers: Answer[];
}

type QuestionsMeta = Question[];

function processAnswer(
  answer: string,
  existingQuestion: Question,
  idx: number
) {
  let existingAnswer = existingQuestion.answers.find(a => a.answer === answer);

  if (!existingAnswer) {
    existingQuestion.answers.push({
      answer: answer,
      color: defaultDataStyling[idx % defaultDataStyling.length],
    });
  }
}

function processQuestion(
  key: string,
  answer: string,
  idx: number,
  transformedData: QuestionsMeta
) {
  let existingQuestion = transformedData.find(q => q.question === key);

  if (existingQuestion) {
    processAnswer(answer, existingQuestion, idx);
  } else {
    transformedData.push({
      question: key,
      answers: [
        {
          answer: answer,
          color: defaultDataStyling[idx % defaultDataStyling.length],
        },
      ],
    });
  }
}

export function prepareQuestionsMeta(
  data: Record<string, any>[]
): QuestionsMeta {
  const transformedData: QuestionsMeta = [];

  data &&
    data.forEach((item, idx) => {
      const keys = Object.keys(item);

      keys.forEach(key => {
        if (!item[key]) {
          return;
        }
        // Convert the answer to an array if it's not already one
        const answers = Array.isArray(item[key]) ? item[key] : [item[key]];

        answers.forEach((answer: string) => {
          processQuestion(key, answer, idx, transformedData);
        });
      });
    });
  return transformedData;
}
