import { toast } from '@/tcomponents/ui/toast/use-toast';
import { navigateToLogout } from '@/utils/navigateToLogout';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';

/** server err object shape which we are handling in the catch block:
 * {
    "error": {
        "status": 404,
        "data": {
            "message": "No query results for model [App\\Models\\FeatureGroup] 111",
            
    "isUnhandledError": false,
    "meta": {
        "request": {},
        "response": {}
    }
}
 */

export function createErrorHandler(msg?: string) {
  async function handleQueryStarted(
    id: number,
    { dispatch, queryFulfilled }: any
  ) {
    try {
      await queryFulfilled;
    } catch (err: any) {
      if (isApiErrorResponse(err?.error) && err.error.status === 401) {
        navigateToLogout();
      } else if (isApiErrorResponse(err?.error) && err.error.status !== 403) {
        // `onError` side-effect
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: msg || err.error.data.message,
          duration: 100000,
        });
      }
    }
  }
  return handleQueryStarted;
}

export function fireSuccessOrErrorToast({
  successTitle,
  successMessage,
  errorTitle,
  errorMessage,
}: {
  successTitle?: string;
  successMessage: string;
  errorTitle?: string;
  errorMessage: string;
}) {
  async function handleQueryStarted({ queryFulfilled }: any) {
    try {
      await queryFulfilled;
      toast({
        title: successTitle ?? '',
        description: successMessage,
      });
    } catch (err: any) {
      if (isApiErrorResponse(err?.error) && err.error.status === 401) {
        navigateToLogout();
      } else if (isApiErrorResponse(err?.error) && err.error.status !== 403) {
        toast({
          title: errorTitle ?? 'Uh oh!',
          variant: 'destructive',
          description: errorMessage || err.error.data.message,
          duration: 100000,
        });
      }
    }
  }
  return handleQueryStarted;
}
