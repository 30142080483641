import { UserDetailsInterface } from '@/types/interfaces/api-response/UserDetailsInterface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface AuthUser {
  id: number;
  name: string;
  first_name: string | null;
  last_name: string | null;
  enabled: boolean;
  last_login: string;
  email: string;
  email_verified_at: string | null;
  roles: {
    id: number;
    name: string;
    description: string;
    updated_at: string;
  }[];
  permissions: string[];
  organisations: {
    id: number;
    name: string;
    description: string;
    user_count: number;
    project_count: number;
    created_at: string;
  }[];
}

interface UserAuthResponseInterface {
  auth: {
    user: AuthUser;
  };
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Auth'],
  endpoints: builder => ({
    userLogin: builder.mutation({
      query: ({ email, password }) => {
        return {
          url: '/auth/login',
          method: 'POST',
          body: { email, password },
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: '/auth/logout',
          method: 'POST',
        };
      },
    }),
    resendVerificationEmail: builder.query({
      query: ({ email }) => {
        return {
          url: '/auth/verify/resend',
          method: 'GET',
          params: { email },
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => {
        return {
          url: '/auth/forgot-password',
          method: 'POST',
          body: { email },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ email, password, passwordConfirm, token }) => {
        return {
          url: '/auth/reset-password',
          method: 'POST',
          body: {
            email,
            password,
            password_confirmation: passwordConfirm,
            token,
          },
        };
      },
    }),
    sendInvite: builder.mutation<
      UserDetailsInterface,
      {
        id?: number;
        email: string;
        name: string;
        organisations: number[];
        roles: number[];
      }
    >({
      query: ({ id, email, name, organisations, roles }) => {
        return {
          url: '/auth/invite',
          method: 'POST',
          body: { id, email, name, organisations, roles },
        };
      },
    }),
    authUserDetails: builder.query<{ data: UserAuthResponseInterface }, void>({
      query: () => {
        return {
          url: '/auth/details',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useUserLoginMutation,
  useLogoutMutation,
  useResendVerificationEmailQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSendInviteMutation,
  useAuthUserDetailsQuery,
} = authApi;
