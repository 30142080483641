import React, { useContext, useEffect } from 'react';
import {
  StorageNodeData,
  CustomNodeData,
  CustomNodeType,
} from '../../types/node-types';
import { toast } from '@tcomponents/ui/toast/use-toast';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import listSampleFormat from '../../static/list-sample-format.js';
import groupedListSampleFormat from '../../static/grouped-list-sample-format.js';
import rowSampleFormat from '../../static/row-sample-format.js';
import { useParams } from 'react-router-dom';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { Node } from 'reactflow';
import { faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button, buttonVariants } from '@tcomponents/ui/button';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import ReactGA from 'react-ga4';
import { useGetProjectSettingsQuery } from '@/store/services/projects/projectApi';
import { Input } from '@tcomponents/ui/input';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import {
  useDeleteNodeDataMutation,
  useLazyGetNodeDataQuery,
  useLazyTriggerProcessNodeQuery,
} from '@/store/services/projects/graphApi';
import { ModalContext } from '@/context/ModalContext';
import { Switch } from '@tcomponents/ui/switch';
import { faBoltLightning } from '@fortawesome/pro-solid-svg-icons';
import { cn } from '@/lib/utils';

export function ProcessNodeFormFields(props: {
  subGraphParentNode?: Node<CustomNodeData>;
}) {
  const {
    activeNode: processNode,
    pipelineData,
    setPipelineData,
  } = useCustomGraphContext();

  const { featureId, projectId } = useParams();
  const feature = useGetFeatureQuery(Number(featureId), {
    skip: !featureId || projectId === 'new',
  });
  const project = useGetProjectSettingsQuery(Number(projectId), {
    skip: !projectId,
  });

  const [getNodeData] = useLazyGetNodeDataQuery();
  const { showDialog } = useContext(ModalContext);

  // we need to find storage node for this process node in the pipeline data and get the format from it
  const childStorageNode = pipelineData[processNode.id]?.nodes.find(
    node => node.type === CustomNodeType.Storage
  );

  const { format } = childStorageNode?.data as StorageNodeData;
  let { behaviour, indexFields, editable } =
    childStorageNode?.data as StorageNodeData;

  const handleFieldChangeFromProcessNode = (
    field: string,
    val: string | boolean | string[]
  ) => {
    const newSubgraphData = {
      ...pipelineData,
      [processNode.id]: {
        ...pipelineData[processNode.id],
        nodes: pipelineData[processNode.id].nodes.map(node => {
          if (node.type === CustomNodeType.Storage) {
            let dataObj = {
              ...node.data,
              [field]: val,
            };

            // Reset the index field when the behaviour has changed to anything other than update
            if (field === 'behaviour' && val !== 'update') {
              dataObj.indexFields = ['id'];
            }

            return {
              ...node,
              data: dataObj,
            };
          }
          return node;
        }),
      },
    };
    setPipelineData(newSubgraphData);
  };

  const handleAddIndex = () => {
    let indexFields = pipelineData[processNode.id].nodes.filter(
      node => node.type === CustomNodeType.Storage
    )[0].data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields, ''];
    } else {
      indexFields = [indexFields, ''];
    }

    handleFieldChangeFromProcessNode('indexFields', indexFields);
  };

  const updateIndexField = (index: number, value: string) => {
    let indexFields = pipelineData[processNode.id].nodes.filter(
      node => node.type === CustomNodeType.Storage
    )[0].data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields];
    } else {
      indexFields = [indexFields];
    }

    indexFields[index] = value;

    handleFieldChangeFromProcessNode('indexFields', indexFields);
  };

  const removeIndexField = (index: number) => {
    let indexFields = pipelineData[processNode.id].nodes.filter(
      node => node.type === CustomNodeType.Storage
    )[0].data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields];
    } else {
      indexFields = [indexFields];
    }

    indexFields.splice(indexFields.indexOf(index), 1);
    handleFieldChangeFromProcessNode('indexFields', indexFields);
  };

  if (typeof behaviour === 'undefined') {
    behaviour = 'replace';
  }

  if (typeof indexFields === 'undefined') {
    handleAddIndex();
    updateIndexField(0, 'id');
  }

  if (typeof editable === 'undefined') {
    handleFieldChangeFromProcessNode('editable', true);
  }

  const downloadData = async () => {
    if (!childStorageNode) return;
    ReactGA.event('workflow_data_download', {
      storage_name: processNode?.data?.heading,
      workflow_name: feature?.data?.data.name,
      project_name: project?.data?.data.name,
    });
    getNodeData({
      nodeId: childStorageNode.id,
      type: 'download',
    })
      .then((response: any) => {
        if (response.isSuccess) {
          const filename =
            feature?.data?.data?.name +
            ' - ' +
            processNode?.data?.heading +
            '.json';
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong',
          description: 'Unable to fetch node data.',
        });
      });
  };

  const renderSamples = () => {
    switch (format) {
      case 'unformatted':
        return <></>;
      case 'row':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{rowSampleFormat}</pre>
          </div>
        );
      case 'list':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{listSampleFormat}</pre>
          </div>
        );
      case 'list-grouped':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{groupedListSampleFormat}</pre>
          </div>
        );
    }
  };

  const showIndexFieldInput = () => {
    if (behaviour === 'update') {
      return (
        <div className="flex flex-col flex-1 gap-y-2">
          <label className="text-xs font-bold pt-2 mb-0 text-[#333333DE]">
            Index Field
          </label>
          {indexFields?.map((indexField, idx) => {
            return (
              <div className="flex flex-row gap-x-2">
                <Input
                  key={idx}
                  value={indexField}
                  onChange={e => updateIndexField(idx, e.target.value)}
                  className="flex flex-1 w-[200px]"
                  type={'text'}
                />
                {idx !== 0 && (
                  <button
                    className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm mt-1.5"
                    onClick={() => removeIndexField(idx)}
                  >
                    X
                  </button>
                )}
              </div>
            );
          })}
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddIndex}
            data-testing="new_param"
            aria-label="new param"
          >
            + New Index
          </button>
        </div>
      );
    }

    return <></>;
  };

  const showNodeWipeModal = () => {
    showDialog({
      title: 'Confirm data deletion',
      message:
        'Are you sure you want to wipe all the data in this node, this cannot be undone?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => callDeleteNodeDataRequest(childStorageNode?.id as string),
      },
    });
  };

  const [deleteNodeData, { isSuccess, isError }] = useDeleteNodeDataMutation();

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: 'Storage successfully wiped',
        duration: 3000,
      });
    }

    if (isError) {
      showDialog({
        title: 'Uh oh! Something went wrong',
        type: ModalTypes.DANGER,
        message: 'Error while clearing node data',
        cancel: true,
      });
    }
  }, [isSuccess, isError]);

  const callDeleteNodeDataRequest = async (id: string) => {
    await deleteNodeData(id);
  };

  const [triggerNode] = useLazyTriggerProcessNodeQuery();
  const triggerNodeFn = async () => {
    triggerNode(processNode.id)
      .then((response: any) => {
        if (response.isSuccess) {
          toast({
            duration: 3000,
            title: 'Success!',
            description: response.data.success,
          });
        }
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong',
          description: 'Unable to trigger node.',
        });
      });
  };

  return (
    <div>
      <div className="flex flex-col divide-y-[1px] gap-2 border p-2 text-sm shadow-md m-1 bg-white">
        <div className="flex items-center justify-between">
          <h4 className="font-bold ml-1 text-[15px]">Data Storage</h4>
          <Button
            variant="warning"
            size="sm"
            onClick={triggerNodeFn}
            icon={faBoltLightning}
          >
            Trigger
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={showNodeWipeModal}
            icon={faTrashCan}
          >
            Wipe
          </Button>
        </div>
        <div>
          <Button
            className="w-full py-4 mt-2"
            onClick={downloadData}
            icon={faSave}
          >
            Download Data
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-2 p-2 m-1 text-sm bg-white border shadow-md">
        <h4 className="font-bold ml-1 text-[15px]">Data Processing</h4>
        <p className={'text-xs font-bold pt-2 mb-0 text-[#333333DE]'}>
          New Data Behaviour
        </p>
        <Select
          value={behaviour}
          onValueChange={val =>
            handleFieldChangeFromProcessNode('behaviour', val)
          }
        >
          <SelectTrigger className="w-[230px] !border-b-[1px]">
            <SelectValue placeholder="Replace" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup className={'overflow-y-auto max-h-96'}>
              <SelectItem value="replace">Replace</SelectItem>
              <SelectItem value="append">Append</SelectItem>
              <SelectItem value="update">Update</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        {showIndexFieldInput()}
      </div>
      <div className="flex flex-col divide-y-[1px] gap-2 border p-2 text-sm shadow-md m-1 bg-white">
        <h4 className="font-bold ml-1 text-[15px]">Data Structure</h4>
        <div className="flex flex-row gap-2">
          <div>
            <p className="text-xs font-bold pt-2 text-[#333333DE] mb-0">
              Format
            </p>
            <Select
              value={format}
              onValueChange={val =>
                handleFieldChangeFromProcessNode('format', val)
              }
            >
              <SelectTrigger className="w-[180px] !border-b-[1px]">
                <SelectValue placeholder="Unformatted" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup className={'overflow-y-auto max-h-96'}>
                  <SelectItem value="unformatted">Unformatted</SelectItem>
                  <SelectItem value="row">Row</SelectItem>
                  <SelectItem value="list">List</SelectItem>
                  <SelectItem value="list-grouped">Grouped List</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          {format === 'row' && (
            <div>
              <p className="text-xs font-bold pt-2 text-[#333333DE] mb-3">
                Editable
              </p>
              <Switch
                checked={editable}
                onCheckedChange={checked =>
                  handleFieldChangeFromProcessNode('editable', checked)
                }
                checkedLabel="Yes"
                unCheckedLabel="No"
              />
            </div>
          )}
        </div>
        {renderSamples()}
      </div>
    </div>
  );
}
