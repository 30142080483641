import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { BorealisNodeData } from '../types/node-types';

const BorealisNode = ({ data, ...rest }: Node<BorealisNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className="p-1 text-gray-600">
        <p>
          {' '}
          <span className="font-bold">Engagement ID:</span> {data.engagementId}
        </p>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default BorealisNode;
