import React from 'react';
import Routes from './routes';
import { useAnalytics } from '@/hooks/UseAnalytics';
import Analytics from '@/pages/Analytics';

const App: React.FC = () => {
  const { initialized } = useAnalytics();

  return (
    <div className="">
      <Analytics initialized={initialized}>
        <Routes />
      </Analytics>
    </div>
  );
};

export default App;
