import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { IRankedListConfig } from './RankedListDisplay';

export interface HorizontalBarChartData {
  name: string;
  keys: Record<string, number>;
}

type HorizontalBarChartProps = {
  data: HorizontalBarChartData[];
  config?: IRankedListConfig;
};

const BAR_BG_COLOR = '#F7FAFB';
const barBackgroundProp = { fill: BAR_BG_COLOR };

export default function HorizontalStackedBarChart(
  props: HorizontalBarChartProps
) {
  const CustomTooltip = (props: any) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          {payload.map((item: any, index: number) => (
            <div key={`item-${index}`}>
              <p className="text-[11px] font-bold">{`${
                item.dataKey.split('.')[1]
              }`}</p>
              <p className=" text-[10px] font-bold">{`${item.value}`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer
      width="100%"
      height={props.config && props.config.ranking_bars.length * 15}
    >
      <BarChart
        layout="vertical"
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        barCategoryGap={5}
      >
        <YAxis dataKey="name" type="category" hide={true} />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ fill: 'transparent' }}
          shared={false}
        />
        {props.config?.ranking_bars.map((keys: string[], barIndex) => (
          <XAxis
            key={`xAxis-${barIndex}`}
            xAxisId={barIndex}
            hide={true}
            type="number"
            domain={[0, props.config?.max_values[barIndex] ?? 0]}
          />
        ))}
        {props.config?.ranking_bars.map((keys: string[], barIndex) =>
          keys.map((key: string, idx) => {
            return (
              <>
                <Bar
                  xAxisId={barIndex}
                  key={`bar-${props.data[0].name}-${key}-${barIndex}`}
                  dataKey={`keys.${key}`}
                  stackId={barIndex + 1}
                  fill={props.config?.data_styling[key]}
                  background={idx === 0 ? barBackgroundProp : undefined} // only set background color for first bar in stack https://github.com/recharts/recharts/issues/2826#issuecomment-1728068948
                />
              </>
            );
          })
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
