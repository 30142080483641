import { useContext, useEffect, useState } from 'react';
import {
  ChoiceQuestion,
  ContentInfo,
  Info,
  Question,
  Step,
  Steps,
} from '../Helpers/Types';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { v4 as uniqueId } from 'uuid';
import { useSurveyContext } from '../SurveyProvider';
import { ModalContext } from '@/context/ModalContext';
import ModalTypes from '@/types/enums/modal/ModalTypes';

const newChoiceQuestion: ChoiceQuestion = {
  step: 'question',
  id: uniqueId(),
  type: 'choice',
  title: '',
  key: '',
  max_answers: 1,
  shuffle: false,
  answers: [
    {
      label: '',
      value: '',
      image_url: '',
    },
  ],
};

export const useSteps = () => {
  const { setIsDirty } = useSaveActionContext();
  const { showDialog } = useContext(ModalContext);
  const { formContent, setFormContent } = useSurveyContext();
  const [steps, setSteps] = useState<Steps>(
    formContent.steps.length ? formContent.steps : [newChoiceQuestion]
  );

  const addQuestionStep = (prevStepIndex: number) => {
    const newId = uniqueId();
    const newChoiceQuestion: ChoiceQuestion = {
      step: 'question',
      id: newId,
      type: 'choice',
      instruction: '',
      title: '',
      key: '',
      max_answers: 1,
      shuffle: false,
      answers: [
        {
          label: '',
          value: '',
          image_url: '',
        },
      ],
    };
    const newSteps = [...steps];
    newSteps.splice(prevStepIndex, 0, newChoiceQuestion);
    setSteps(newSteps);
    setIsDirty(true);
  };

  const addInfoStep = (prevStepIndex: number) => {
    const newId = uniqueId();
    const newInfoStep: ContentInfo = {
      id: newId,
      title: '',
      step: 'info',
      type: 'content',
      image: '',
      crop: true,
      body: '',
    };
    const newSteps = [...steps];
    newSteps.splice(prevStepIndex, 0, newInfoStep);
    setSteps(newSteps);
    setIsDirty(true);
  };

  const updateStep = (stepData: Step) => {
    if (stepData.id) {
      const newSteps = steps.map(step =>
        step.id === stepData.id ? stepData : step
      );
      setSteps(newSteps);
      setIsDirty(true);
      return;
    }
  };

  const deleteStep = (stepId: string | number) => {
    const newSteps = steps.filter(step => step.id !== stepId);
    setSteps(newSteps);
    setIsDirty(true);
  };

  const handleDeleteStep = (stepId: string | number) => {
    showDialog({
      title: 'Delete Step',
      message: 'Are you sure you want to proceed?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => {
          deleteStep(stepId);
        },
      },
    });
  };

  const handleQueTypeChange = (val: Question['type'], question: Question) => {
    let newQuestion: Question;
    switch (val) {
      case 'choice':
        newQuestion = {
          step: 'question',
          id: question.id,
          title: question.title,
          instruction: question.instruction,
          key: question.key,
          type: 'choice',
          max_answers: 1,
          shuffle: false,
          answers: [
            {
              label: '',
              value: '',
              image_url: '',
            },
          ],
        };
        break;
      case 'paragraph':
        newQuestion = {
          step: 'question',
          id: question.id,
          title: question.title,
          instruction: question.instruction,
          key: question.key,
          type: 'paragraph',
          max_characters: 1000,
        };
        break;
      case 'number':
        newQuestion = {
          step: 'question',
          id: question.id,
          title: question.title,
          instruction: question.instruction,
          key: question.key,
          type: 'number',
          default: 0,
          min: 0,
          max: 0,
          increment: 1,
        };
        break;
      case 'order':
        newQuestion = {
          step: 'question',
          id: question.id,
          title: question.title,
          instruction: question.instruction,
          key: question.key,
          type: 'order',
          shuffle: false,
          answers: [
            {
              label: '',
              value: '',
            },
          ],
        };
        break;
      case 'form':
        newQuestion = {
          step: 'question',
          id: question.id,
          title: question.title,
          instruction: question.instruction,
          type: 'form',
          fields: [
            {
              personal: false,
              type: 'text',
              label: '',
              key: '',
            },
          ],
        };
        break;
    }
    const newSteps = steps.map(step => {
      if (step.id === question.id) {
        return newQuestion;
      }
      return step;
    });
    setSteps(newSteps);
    setIsDirty(true);
  };
  const handleInfoTypeChange = (val: Info['type'], info: Info) => {
    let newInfo: Info;
    switch (val) {
      case 'content':
        newInfo = {
          id: info.id,
          title: info.title,
          step: 'info',
          type: 'content',
          image: '',
          crop: false,
          body: '',
        };
        break;
      case 'gallery':
        newInfo = {
          id: info.id,
          title: info.title,
          step: 'info',
          type: 'gallery',
          description: '',
          images: [{ image: '', caption: '' }],
        };
        break;
    }
    const newSteps = steps.map(step => {
      if (step.id === info.id) {
        return newInfo;
      }
      return step;
    });
    setSteps(newSteps);
    setIsDirty(true);
  };

  // useEffect to change formContent steps field when the steps array changes
  useEffect(() => {
    const formCopy = { ...formContent };
    formCopy.steps = steps;
    setFormContent(formCopy);
  }, [steps]);

  return {
    steps,
    setSteps,
    addQuestionStep,
    addInfoStep,
    updateStep,
    handleDeleteStep,
    handleQueTypeChange,
    handleInfoTypeChange,
  };
};
