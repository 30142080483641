import { Card } from '@/tcomponents/ui/card';
import { WidgetBuilderTopbar } from './WidgetBuilderTopbar';
import WidgetBuilderSetup from './WidgetBuilderSetup';
import { WidgetChartPreview } from './WidgetChartPreview';
import { WidgetBuilderOptions } from './WidgetBuilderOptions';

export default function ChartDialogContent() {
  return (
    <Card className="w-full h-full border-none shadow-none">
      <div className="flex flex-col h-full">
        {/* widget top section, heading & buttons */}
        <WidgetBuilderTopbar />
        <div className={`grid grid-cols-4 grow`}>
          {/* Widget setup section */}
          <WidgetBuilderSetup />
          {/* Chart preview section */}
          <WidgetChartPreview />
          {/* chart options section */}
          <WidgetBuilderOptions />
        </div>
      </div>
    </Card>
  );
}
