import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';

import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';

import { useSurveyContext } from '../SurveyProvider';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { Label } from '@/tcomponents/ui/label';
import CustomImageField from '../components/CustomImageField';
import { ImageTypes, useUploadImage } from '../hooks/useUploadImage';
import { handlePrependHttps } from '@/lib/utils';
import { InfoPopover } from '@/tcomponents/custom/InfoPopover';
import welcomePageInfoImage from '@/assets/images/surveyInfoImages/welcome_page.png';
import dataProtectionInfoImage from '@/assets/images/surveyInfoImages/data_protection.png';

const WelcomeTab: React.FC = () => {
  const { formContent, setFormContent } = useSurveyContext();
  const { setIsDirty } = useSaveActionContext();
  const { image, handleImageUpload, handleImageRemove } = useUploadImage(
    ImageTypes.welcome_image
  );

  return (
    <div className="flex flex-wrap w-full space-x-2">
      {/* page content section */}
      <Card className="w-[49%] h-fit">
        <CardHeader>
          <div className="flex gap-x-3">
            <CardTitle>Page Content</CardTitle>
            <InfoPopover>
              <div>
                <h6 className="mb-1">Example Welcome Page</h6>
                <img alt="Example Welcome Page" src={welcomePageInfoImage} />
              </div>
            </InfoPopover>
          </div>
          <h6 className="text-gray-400 ">
            The first page displayed to users when they visit the survey
          </h6>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {/* page content title field */}
            <div className="flex flex-col gap-y-2">
              <Label>Title</Label>
              <Input
                value={formContent.welcome.page_content.title}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.page_content.title = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            {/* page content subtitle field */}
            <div className="flex flex-col gap-y-2">
              <Label>Subtitle</Label>
              <Input
                value={formContent.welcome.page_content.subtitle}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.page_content.subtitle = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            {/* page content description field */}
            <div className="flex flex-col gap-y-2">
              <Label>Description</Label>
              <Textarea
                value={formContent.welcome.page_content.description}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.page_content.description = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Image</Label>
              <CustomImageField
                image={image}
                handleImageUpload={handleImageUpload}
                handleImageRemove={handleImageRemove}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {/* how we use your feedback section */}
      <Card className="w-[49%] h-fit">
        <CardHeader>
          <div className="flex gap-x-3">
            <CardTitle>Data Protection Details</CardTitle>
            <InfoPopover>
              <div>
                <h6 className="mb-1">Example Data Protection Statement</h6>
                <img
                  alt="Example Data Protection Statement"
                  src={dataProtectionInfoImage}
                />
              </div>
            </InfoPopover>
          </div>
          <h6 className="text-gray-400 ">
            Contents of the data protection statement
          </h6>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col gap-y-2">
              <Label>Client Name</Label>
              <Input
                value={formContent.welcome.data_protection_details.client_name}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.data_protection_details.client_name =
                    e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <Label>Client Email</Label>
              <Input
                value={formContent.welcome.data_protection_details.client_email}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.data_protection_details.client_email =
                    e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <Label>Client Phone</Label>
              <Input
                value={formContent.welcome.data_protection_details.client_phone}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.data_protection_details.client_phone =
                    e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <Label>Privacy Policy</Label>
              <Input
                value={
                  formContent.welcome.data_protection_details.client_privacy
                }
                onChange={e => {
                  const obj = { ...formContent };
                  obj.welcome.data_protection_details.client_privacy =
                    e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
                onBlur={e => {
                  let value = handlePrependHttps(e.target.value);
                  const obj = { ...formContent };
                  obj.welcome.data_protection_details.client_privacy = value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default WelcomeTab;
