import React, { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { aiWizardPermissions } from '@/stringConsts/permissionConsts';

const AiResourcesList = lazy(
  () => import('../../pages/aiTool/audit/AIResourcesList')
);

const AiTemplateList = lazy(() => import('../../pages/aiTool/AITemplateList'));

const AiTemplateView = lazy(() => import('../../pages/aiTool/AITemplateView'));

const AiTemplateSettings = lazy(
  () => import('../../pages/aiTool/AITemplateSettings')
);

export const aiWizardRoutes = [
  {
    element: (
      <ProtectedRoute permissions={[aiWizardPermissions.general.read]} />
    ),
    children: [
      {
        path: 'ai-wizard',
        element: <AiTemplateList />,
      },
      {
        path: 'ai-wizard/:id/view',
        element: <AiTemplateView />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute
        permissions={[aiWizardPermissions.general.settings_access]}
      />
    ),
    children: [
      {
        path: 'ai-wizard/:id',
        element: <AiTemplateSettings />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute permissions={[aiWizardPermissions.general.create]} />
    ),
    children: [
      {
        path: 'ai-wizard/new',
        element: <AiTemplateSettings />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute
        permissions={[aiWizardPermissions.system_administration.monitor]}
      />
    ),
    children: [
      {
        path: 'ai-resources',
        element: <AiResourcesList />,
      },
    ],
  },
];
