import { useEffect } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import { GeoSearchControl } from 'leaflet-geosearch';
import { SearchResult } from 'leaflet-geosearch/dist/providers/provider';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { CustomMapSearchbarProvider } from '../utils/CustomMapSeachbarProvider';
import { FeatureCollection } from 'geojson';
import { shapeFileFeatureKeysToLowerCase } from '../helpers/caseConversion';

type MapSearchBarProps = {
  enabledBoundaryShapeFileData?: FeatureCollection;
  visibleBoundaryConfigData?: MapFeatureDetails['boundaries'][0];
};
// custom logic here to determine if the marker should be shown, we don't want to show the marker if the result is from the shape file
const doNotShowMarker = (result: SearchResult) => {
  return result.raw.source === 'shapeFile';
};

export const MapSearchBar = (props: MapSearchBarProps) => {
  const { enabledBoundaryShapeFileData, visibleBoundaryConfigData } = props;
  const searchProperty = visibleBoundaryConfigData?.searchProperty;
  const visibleBoundaryTitle = visibleBoundaryConfigData?.title;
  const map = useMap();
  const normalizedShapeFileData = shapeFileFeatureKeysToLowerCase(
    enabledBoundaryShapeFileData
  );
  const provider = new CustomMapSearchbarProvider(
    {},
    searchProperty ?? '',
    normalizedShapeFileData
  );

  const customIcon = L.divIcon({
    className: 'border-none outline-none',
    html: `<i class="fas fa-crosshairs text-2xl"></i>`,
  });

  const searchBar = GeoSearchControl({
    provider: provider,
    style: 'bar',
    animateZoom: true,
    marker: {
      icon: customIcon,
    },
    resultFormat: (result: { result: SearchResult }) => {
      return result.result.raw.source === 'shapeFile'
        ? `${result.result.label} - ${visibleBoundaryTitle}`
        : result.result.label;
    },
    searchLabel: 'Find Postcode or Location',
    notFoundMessage: 'No matching location could be found',
    maxMarkers: 1,
    maxSuggestions: 10,
  });

  useEffect(() => {
    if (!map) return;

    map.addControl(searchBar);

    // we are setting the global classes to remove the icon: In index.css- [data-lpignore] ~ div[data-lastpass-icon-root] {display: none;}, means if the input has data-lpignore attribute then hide the icon
    const searchInput = document.querySelector(
      '.leaflet-control-geosearch input'
    );
    if (searchInput) {
      searchInput.setAttribute('data-lpignore', 'true');
    }

    // remove the marker if the result is from the shape file
    map.on('geosearch/showlocation', (result: any) => {
      if (doNotShowMarker(result.location)) {
        map.eachLayer(layer => {
          if (layer instanceof L.Marker && layer.options.icon === customIcon) {
            map.removeLayer(layer);
          }
        });
      }
    });

    return () => {
      map.removeControl(searchBar);
    };
  }, [map, enabledBoundaryShapeFileData, searchProperty]);

  return null;
};
