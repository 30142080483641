import React, { FC, RefObject, useEffect, useMemo, useRef } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@tcomponents/ui/form';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import {
  useGetDataStoresQuery,
  useGetWorkflowsQuery,
} from '@/store/services/projects/chartApi';
import { useParams } from 'react-router-dom';
import useTableForm, { ApiSubmitHandler } from './hooks/useTableForm';
import { Switch } from '@tcomponents/ui/switch';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { reactSelectStyles } from '@/pages/dashboard/features/forms/RankedList/consts';
import { Controller } from 'react-hook-form';
import { getKeys } from '@/pages/dashboard/projects/utils/helperFunctions';
import { useGetDataFromDataStoreQuery } from '@/store/services/projects/rankedListApi';
import { TableFeatureDetails } from '@/types/feature/featureDetails';

interface IRankedListFormProps {
  onSubmitHandler: ApiSubmitHandler;
  data: TableFeatureDetails | undefined;
  setIsDirty: (isDirty: boolean) => void;
  setRefSubmitButton: (submitButtonRef: RefObject<HTMLButtonElement>) => void;
}

const TableForm: FC<IRankedListFormProps> = props => {
  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const { projectId } = useParams();
  const { form, handleSubmit } = useTableForm(props.onSubmitHandler);
  const {
    reset,
    control,
    formState: { errors, isDirty },
    getValues,
  } = form;

  //@ts-ignore
  const selectedWorkflow = form.getValues('workflow');

  const workflows = useGetWorkflowsQuery(parseInt(projectId as string), {
    skip: !projectId,
  });
  const workflowData = workflows.data?.data;
  const dataStores = useGetDataStoresQuery(
    parseInt(selectedWorkflow as string),
    { skip: !selectedWorkflow }
  );

  const filteredListGroupedDataStores = dataStores.data?.data.filter(
    (dataStore: any) => dataStore.format === 'row'
  );

  useEffect(() => {
    if (props.data) {
      reset({
        name: props.data.name,
        header_info: props.data.header_info ?? '',
        description: props.data.description ?? '',
        icon: props.data.icon,
        workflow: props.data.data_source.workflow ?? '',
        data_store: props.data.data_source.data_store_id ?? '',
        showSources: props.data.showSources ?? false,
        hidden_fields: props.data.hidden_fields ?? [],
      });
    }
  }, [props.data]);

  const onWorkflowChange = (val: string) => {
    reset(
      {
        ...getValues(),
        data_store: '',
        workflow: val,
        hidden_fields: [],
      },
      {
        keepDefaultValues: true,
      }
    );
  };

  const onDataSourceChange = (val: string) => {
    reset(
      {
        ...getValues(),
        data_store: val,
        hidden_fields: [],
      },
      {
        keepDefaultValues: true,
      }
    );
  };

  useEffect(() => {
    props.setRefSubmitButton(refSubmitButton);
  }, []);

  useEffect(() => {
    props.setIsDirty(isDirty);
  }, [isDirty]);

  const selectedDataStore = form.getValues('data_store');

  const apiReturnedData = useGetDataFromDataStoreQuery(
    selectedDataStore as string,
    {
      skip: !selectedDataStore,
      refetchOnMountOrArgChange: true,
    }
  );

  const parsedData = useMemo(
    () =>
      apiReturnedData && apiReturnedData.data
        ? JSON.parse(apiReturnedData.data)?.data
        : undefined,

    [apiReturnedData.data]
  );

  const parsedDataKeys = useMemo(
    () => getKeys(parsedData, true).sort(),
    [parsedData]
  );

  const dataForReactSelect = parsedDataKeys?.map((item: string) => ({
    value: item,
    label: item,
  }));

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
          <div className="flex flex-col w-full space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Feature Details</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel required>Name</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.name} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="header_info"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel>Header Info</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.header_info} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel>Description</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Textarea {...field} error={errors.description} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="icon"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel required>Icon</FormLabel>
                          <FormMessage />
                        </div>
                        <div className="flex">
                          <i
                            className={
                              field.value +
                              ' w-10 h-10 border rounded-l-md p-1 flex items-center justify-center bg-gray-500 text-white text-xl'
                            }
                          ></i>
                          <FormControl>
                            <Input
                              {...field}
                              error={errors.icon}
                              className="rounded-l-none "
                            />
                          </FormControl>
                        </div>
                      </FormItem>
                    )}
                  />

                  <button hidden={true} ref={refSubmitButton} type={'submit'} />
                </div>
              </CardContent>
            </Card>
            {form.getValues('workflow') && form.getValues('data_store') && (
              <Card>
                <CardHeader>
                  <CardTitle>Column Setup</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-4">
                    <FormLabel>Hidden Fields</FormLabel>
                    <Controller
                      name="hidden_fields"
                      control={control}
                      render={({ field }) => (
                        <ReactSelect
                          isClearable={true}
                          className="w-full"
                          {...field}
                          ref={field.ref}
                          value={Array.isArray(field.value) ? field.value : []}
                          isMulti
                          options={dataForReactSelect}
                          placeholder="Select options..."
                          components={{
                            ...makeAnimated(),
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            ClearIndicator: () => null,
                          }}
                          styles={reactSelectStyles}
                        />
                      )}
                    />
                  </div>
                </CardContent>
              </Card>
            )}
          </div>
          <div className="flex flex-col w-full space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Display Setup</CardTitle>
              </CardHeader>
              <CardContent>
                <FormField
                  control={form.control}
                  name="showSources"
                  render={({ field }) => (
                    <FormItem className="flex flex-col w-1/2">
                      <FormLabel className="text-base">Show Sources</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          // @ts-ignore
                          onCheckedChange={field.onChange}
                          checkedLabel="Yes"
                          unCheckedLabel="No"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Data Source</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="workflow"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Workflow</FormLabel>
                        <Select
                          {...field}
                          value={field.value?.toString()}
                          onValueChange={val => {
                            // callback is triggered somehow when data is loaded where val still refers to the default value ''.
                            // so to prevent updating the state with that value, we check if val is truthy
                            // the same goes for the data store select below
                            if (val) {
                              onWorkflowChange(val);
                              field.onChange(val);
                            }
                          }}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Choose Workflow" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectGroup className={'overflow-y-auto max-h-96'}>
                              <SelectLabel>Workflows</SelectLabel>
                              {workflowData &&
                                workflowData.map(
                                  // @ts-ignore
                                  workflow => (
                                    <SelectItem
                                      key={workflow.value}
                                      value={workflow.value.toString()}
                                    >
                                      {workflow.text}
                                    </SelectItem>
                                  )
                                )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="data_store"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Data Store</FormLabel>
                        <Select
                          {...field}
                          value={field.value?.toString()}
                          onValueChange={val => {
                            if (val) {
                              onDataSourceChange(val);
                              field.onChange(val);
                            }
                          }}
                          disabled={!selectedWorkflow}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  selectedWorkflow
                                    ? 'Choose Datastore'
                                    : 'Select workflow to choose data store'
                                }
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectGroup className={'overflow-y-auto max-h-96'}>
                              <SelectLabel>Data Stores</SelectLabel>
                              {filteredListGroupedDataStores &&
                                filteredListGroupedDataStores.map(
                                  // @ts-ignore
                                  (dataStore, index) => (
                                    <SelectItem
                                      key={`dataStore-${index} - ${dataStore.value}`}
                                      value={dataStore.value}
                                    >
                                      {dataStore.text}
                                    </SelectItem>
                                  )
                                )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default TableForm;
