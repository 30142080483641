import { useState, useCallback, useEffect } from 'react';
import { useMapSettingsContext } from '../MapSettingsProvider';
import { v4 as uniqueId } from 'uuid';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { defaultColors } from '@/tcomponents/custom/ColorPicker';

type BoundariesArrayTypes = MapFeatureDetails['boundaries'];
export type BoundaryType = BoundariesArrayTypes[0];

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const layerTypes = {
  Boundary: 'Boundary',
  Overlay: 'Overlay',
};

export const boundaryTypes = {
  Simple: 'Simple',
  Heatmap: 'Heatmap',
  Category: 'Category',
};
export const useHandleBoundaryTab = () => {
  const { formContent, setFormContent } = useMapSettingsContext();
  const { setIsDirty } = useSaveActionContext();
  const [isDragging, setIsDragging] = useState(false);
  const [boundariesArray, setBoundariesArray] = useState<BoundariesArrayTypes>(
    formContent.boundaries.boundariesArray
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    result => {
      setIsDragging(false);
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const reorderedData = reorder(
        boundariesArray,
        result.source.index,
        result.destination.index
      );
      setBoundariesArray(reorderedData);
      setFormContent({
        ...formContent,
        boundaries: {
          ...formContent.boundaries,
          boundariesArray: reorderedData,
        },
      });
      setIsDirty(true);
    },
    [boundariesArray]
  );

  const addBoundary = (boundaryIdx?: number) => {
    setIsDirty(true);
    const newBoundary: BoundaryType = {
      id: uniqueId(),
      title: '',
      startEnabled: false,
      color: '#265F81',
      type: 'Simple',
      shapeSource: 'System',
      shapeFile: '',
      keyProperty: '',
      searchProperty: '',
      hoverProperties: [
        {
          value: '',
          label: '',
        },
      ],
      dataStoreHoverKeys: [],
      workflow: '',
      dataStore: '',
      calculation: 'count',
      valuesFrom: '',
      keysFrom: '',
      units: '',
      minColor: '',
      maxColor: '',
      theming: [],
      dataStoreType: 'row',
    };
    if (boundaryIdx) {
      const newBoundariesArray = [...boundariesArray];
      newBoundariesArray.splice(boundaryIdx, 0, newBoundary as BoundaryType);
      setBoundariesArray(newBoundariesArray);

      return;
    }
    setBoundariesArray([
      ...boundariesArray,
      newBoundary,
    ] as BoundariesArrayTypes);
  };

  const deleteBoundary = (index: number) => {
    const newBoundariesArray = boundariesArray.filter(
      (_, idx) => idx !== index
    );
    setBoundariesArray(newBoundariesArray);
    setIsDirty(true);
  };

  const handleDataStoreChange = (
    idx: number,
    value: string,
    filteredDataStores: { value: string; text: string; format: string }[]
  ) => {
    const selectedDataStoreType = filteredDataStores.find(
      dataStore => dataStore.value === value
    )?.format;
    const boundary = boundariesArray[idx];
    const updatedBoundary = {
      ...boundary,
      dataStore: value,
      datsStoreType: selectedDataStoreType,
      valuesFrom: '',
      keysFrom: '',
      theming: [],
    };

    const newBoundariesArray = [...boundariesArray];
    newBoundariesArray[idx] = { ...updatedBoundary } as BoundaryType;
    setBoundariesArray(newBoundariesArray);
    setIsDirty(true);
    return;
  };

  const handleBoundaryTypeChange = (
    idx: number,
    type: BoundaryType['type']
  ) => {
    const boundary = boundariesArray[idx];
    let newBoundary: BoundaryType = { ...boundary };
    if (type === boundaryTypes.Simple) {
      newBoundary = {
        ...boundary,
        type: type,
        color: '#265F81',
        calculation: 'count',
        valuesFrom: '',
        keysFrom: '',
        units: '',
        minColor: '',
        maxColor: '',
        theming: [],
        dataStoreType: 'row',
        workflow: '',
        dataStore: '',
      };
    }
    if (type === boundaryTypes.Heatmap) {
      newBoundary = {
        ...boundary,
        type: type,
        color: '',
        calculation: 'count',
        valuesFrom: '_value',
        units: '',
        minColor: '#265F81',
        maxColor: '#DB2828',
        theming: [],
      };
    }
    if (type === boundaryTypes.Category) {
      newBoundary = {
        ...boundary,
        type: type,
        color: '',
        minColor: '',
        maxColor: '',
        calculation: 'lookup',
        valuesFrom: '',
        keysFrom: '',
        theming: [],
      };
    }
    const newBoundariesArray = [...boundariesArray];
    newBoundariesArray[idx] = newBoundary;
    setBoundariesArray(newBoundariesArray);
    setIsDirty(true);
    return;
  };

  useEffect(() => {
    setFormContent({
      ...formContent,
      boundaries: {
        ...formContent.boundaries,
        boundariesArray: boundariesArray,
      },
    });
  }, [boundariesArray]);
  const handleSingleFieldValueChange = (
    idx: number,
    field: keyof BoundaryType,
    value: string | boolean | { value: string; label: string }[]
  ) => {
    const newBoundriesArray = [...boundariesArray];
    // here we need to toggle the rest of the boundaries to startEnabled false if one is enabled, all can stay disabled but only one can be enabled at a time
    if (field === 'startEnabled') {
      const newBoundriesArray = boundariesArray.map((boundary, i) => ({
        ...boundary,
        startEnabled: i === idx ? (value as boolean) : false,
      }));
      setBoundariesArray(newBoundriesArray);
      setIsDirty(true);
      return;
    }
    // if the field is calculation then we need to reset the valuesFrom, keysFrom and hoverProperties
    if (field === 'calculation') {
      newBoundriesArray[idx] = {
        ...newBoundriesArray[idx],
        [field as string]: value,
        valuesFrom: '',
        keysFrom: '',
        dataStoreHoverKeys: [],
      };
      setBoundariesArray(newBoundriesArray);
      setIsDirty(true);
      return;
    }
    // if the field is shapeFile then we need to reset the keyProperty and hoverProperties
    if (field === 'shapeFile') {
      newBoundriesArray[idx] = {
        ...newBoundriesArray[idx],
        [field as string]: value,
        hoverProperties: [],
        dataStoreHoverKeys: [],
        searchProperty: '',
        keyProperty: '',
      };
      setBoundariesArray(newBoundriesArray);
      setIsDirty(true);
      return;
    }

    newBoundriesArray[idx] = { ...newBoundriesArray[idx], [field]: value };
    setBoundariesArray(newBoundriesArray);
    setIsDirty(true);
  };

  const handleThemingOnKeysFromOrValuesFromChange = (
    idx: number,
    uniqueStringsArray: string[]
  ) => {
    if (!uniqueStringsArray.length) {
      return;
    }
    const boundary = boundariesArray[idx];
    const existingTheming = boundary.theming;
    const theming =
      uniqueStringsArray
        .map((string, index) => {
          const existingTheme = existingTheming.find(
            theme => theme.key === string
          );
          if (existingTheme) {
            return existingTheme;
          } else {
            if (string) {
              setIsDirty(true);
              return {
                key: string,
                color: defaultColors[index % defaultColors.length],
              };
            } else {
              return { key: '', color: '' };
            }
          }
        })
        .filter(theme => theme.key)
        .sort((a, b) => String(a.key).localeCompare(String(b.key))) ?? [];

    const updatedBoundary = {
      ...boundary,
      theming,
    };
    const newBoundariesArray = [...boundariesArray];
    newBoundariesArray[idx] = { ...updatedBoundary } as BoundaryType;
    setBoundariesArray(newBoundariesArray);
  };

  const handleThemingColorChange = (
    idx: number,
    themingIdx: number,
    color: string
  ) => {
    const boundary = boundariesArray[idx];
    const theming = boundary.theming.map((theme, index) => ({
      ...theme,
      color: index === themingIdx ? color : theme.color,
    }));
    const updatedBoundary = {
      ...boundary,
      theming,
    };
    const newBoundariesArray = [...boundariesArray];
    newBoundariesArray[idx] = { ...updatedBoundary } as BoundaryType;
    setBoundariesArray(newBoundariesArray);
    setIsDirty(true);
  };

  return {
    boundariesArray,
    handleDragEnd,
    addBoundary,
    deleteBoundary,
    handleSingleFieldValueChange,
    handleBoundaryTypeChange,
    handleDataStoreChange,
    isDragging,
    setIsDragging,
    handleThemingOnKeysFromOrValuesFromChange,
    handleThemingColorChange,
  };
};
