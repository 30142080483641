import { CustomNode } from '@/pages/dashboard/projects/graph/nodes/index';
import { Node, Position } from 'reactflow';
import React from 'react';
import { SenderNodeData } from '@/pages/dashboard/projects/graph/types';
import ConnectionLimitedHandle from '@/pages/dashboard/projects/graph/nodes/handles/ConnectionLimitedHandle';

const SenderNode = ({ data, ...rest }: Node<SenderNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <ConnectionLimitedHandle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
        isConnectable={1}
      />
    </CustomNode>
  );
};

export default SenderNode;
