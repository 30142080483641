import { useState, useCallback, useEffect } from 'react';
import { useMapSettingsContext } from '../MapSettingsProvider';
import { v4 as uniqueId } from 'uuid';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { OnDragEndResponder } from 'react-beautiful-dnd';
import { MapFeatureDetails } from '@/types/feature/featureDetails';

type OverlaysArrayTypes = MapFeatureDetails['overlays'];
export type OverlayType = OverlaysArrayTypes[0];

const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useHandleOverlayTab = () => {
  const { formContent, setFormContent } = useMapSettingsContext();
  const { setIsDirty } = useSaveActionContext();
  const [isDragging, setIsDragging] = useState(false);
  const [overlaysArray, setOverlaysArray] = useState<OverlaysArrayTypes>(
    formContent.overlays.overlaysArray.length
      ? formContent.overlays.overlaysArray
      : []
  );
  const handleDragEnd = useCallback<OnDragEndResponder>(
    result => {
      setIsDragging(false);
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const reorderedData = reorder(
        overlaysArray,
        result.source.index,
        result.destination.index
      );
      setOverlaysArray(reorderedData);
      setFormContent({
        ...formContent,
        overlays: { ...formContent.overlays, overlaysArray: reorderedData },
      });
      setIsDirty(true);
    },
    [overlaysArray]
  );

  const addOverlay = (overlayIdx?: number) => {
    setIsDirty(true);
    const newOverlay = {
      id: uniqueId(),
      title: '',
      startEnabled: false,
      color: '#265F81',
      shapeSource: 'System',
      shapeFile: '',
      hoverProperties: [
        {
          value: '',
          label: '',
        },
      ],
    };
    if (overlayIdx) {
      const newOverlaysArray = [...overlaysArray];
      newOverlaysArray.splice(overlayIdx, 0, newOverlay as OverlayType);
      setOverlaysArray(newOverlaysArray);

      return;
    }
    setOverlaysArray([...overlaysArray, newOverlay] as OverlaysArrayTypes);
  };

  const deleteOverlay = (overlayIdx: number) => {
    const newOverlaysArray = overlaysArray.filter(
      (_, idx) => idx !== overlayIdx
    );
    setOverlaysArray(newOverlaysArray);
    setIsDirty(true);
  };

  useEffect(() => {
    setFormContent({
      ...formContent,
      overlays: {
        ...formContent.overlays,
        overlaysArray: overlaysArray,
      },
    });
  }, [overlaysArray]);

  const handleSingleFieldValueChange = (
    idx: number,
    field: keyof OverlayType,
    value: OverlayType[keyof OverlayType]
  ) => {
    const newOverlaysArray = [...overlaysArray];
    newOverlaysArray[idx] = { ...newOverlaysArray[idx], [field]: value };
    setOverlaysArray(newOverlaysArray);
    setIsDirty(true);
  };

  return {
    overlaysArray,
    handleDragEnd,
    addOverlay,
    deleteOverlay,
    handleSingleFieldValueChange,
    isDragging,
    setIsDragging,
  };
};

export default useHandleOverlayTab;
