import React, { FC } from 'react';
import ModalProps from '../../types/interfaces/ModalProps';
import ModalTypes from '../../types/enums/modal/ModalTypes';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@tcomponents/ui/alert-dialog';

const QRModal: FC<ModalProps> = ({
  title,
  message,
  type = ModalTypes.QR_CODE,
  cancel = false,
  confirmCallback,
  confirmLabel = 'Done',
  open,
  setOpen,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className={'p-0 max-w-[480px]'}>
        <AlertDialogHeader
          className={
            'flex flex-row justify-between border-b-2 items-center px-8 py-3'
          }
        >
          <AlertDialogTitle className={'font-bold'}>{title}</AlertDialogTitle>
          <AlertDialogAction
            className={'!m-0'}
            key="modal-confirm-button"
            onClick={
              typeof confirmCallback !== 'undefined'
                ? confirmCallback
                : () => setOpen(false)
            }
          >
            {confirmLabel}
          </AlertDialogAction>
        </AlertDialogHeader>
        <AlertDialogDescription className="break-words">
          {message}
        </AlertDialogDescription>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default QRModal;
