import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const chartApi = createApi({
  reducerPath: 'chartApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: [
    'Workflows',
    'DataStores',
    'DashboardConfig',
    'WidgetsData',
    'WidgetChartData',
    'RawDataFromDataStore',
  ],
  endpoints: builder => ({
    getWorkflows: builder.query({
      query: (projectId: number) => {
        return {
          url: '/projects/' + projectId + '/workflows',
          method: 'GET',
        };
      },
      providesTags: ['Workflows'],
    }),
    getDataStores: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/data-stores',
          method: 'GET',
        };
      },
    }),
    getDashboardConfig: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/dashboard',
          method: 'GET',
        };
      },
      providesTags: ['DashboardConfig'],
    }),
    saveDashboardConfig: builder.mutation({
      query: ({ featureId, config }) => {
        return {
          url: '/features/' + featureId + '/dashboard-config',
          method: 'POST',
          body: config,
        };
      },
      invalidatesTags: ['DashboardConfig'],
    }),

    getWidgetsData: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/widgets-data',
          method: 'GET',
        };
      },
      providesTags: ['WidgetsData'],
    }),
    getWidgetChartData: builder.query({
      query: nodeUuid => {
        return {
          url: '/graph/data/' + nodeUuid,
          method: 'GET',
        };
      },
      providesTags: ['WidgetChartData'],
    }),
    getRawDataFromDataStore: builder.query({
      query: nodeUuid => {
        return {
          url: 'graph/data/formatted/' + nodeUuid,
          method: 'GET',
        };
      },
      providesTags: ['RawDataFromDataStore'],
    }),
  }),
});

export const {
  useGetWorkflowsQuery,
  useGetDataStoresQuery,
  useSaveDashboardConfigMutation,
  useGetDashboardConfigQuery,
  useGetWidgetsDataQuery,
  useGetWidgetChartDataQuery,
  useGetRawDataFromDataStoreQuery,
} = chartApi;
