import { useEffect, useState } from 'react';
import {
  MapFormStateType,
  defaultMapFormState,
  mapServerToFormState,
} from '../helpers/formStates';
import { MapFeatureDetails } from '@/types/feature/featureDetails';

export const useHandleFormContent = (featureDetails: MapFeatureDetails) => {
  const [formContent, setFormContent] =
    useState<MapFormStateType>(defaultMapFormState);

  useEffect(() => {
    if (featureDetails) {
      const transformedData = mapServerToFormState(featureDetails);
      setFormContent(transformedData);
    }
  }, [featureDetails]);

  return {
    formContent,
    setFormContent,
  };
};
