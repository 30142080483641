enum Sizes {
  MINI = 'mini',
  TINY = 'tiny',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
  BIG = 'big',
  HUGE = 'huge',
  MASSIVE = 'massive',
}

export default Sizes;
