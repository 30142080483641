import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export enum CustomNodeType {
  Api = 'Api Node',
  Endpoint = 'Endpoint',
  Spreadsheet = 'Spreadsheet Node',
  Borealis = 'Borealis Node',
  Process = 'Process Node',
  Join = 'Join Node',
  GroupBy = 'Group Node',
  DropKeys = 'Drop Keys Node',
  CustomFunction = 'Custom Function Node',
  Input = 'Input Node',
  Storage = 'Storage Node',
  SurveyData = 'Survey Data',
  SurveyVisitor = 'Survey Visitor',
  SurveyInteraction = 'Survey Interaction',
  SurveyProgress = 'Survey Progress',
  Filter = 'Filter Node',
  Location = 'Location Node',
  DateTime = 'Date Time Node',
  Tag = 'Tag Node',
  Sentiment = 'Sentiment Node',
  Summarize = 'Summarize Node',
  Sender = 'Sender Node',
  Receiver = 'Receiver Node',
  InternalGenerator = 'Internal Generator Node',
}

export interface CustomNodeRenderProps {
  type: CustomNodeType;
  icon: IconDefinition;
  color: string;
  title: string;
}

export interface CustomNodeData {
  id: string;
  heading: string;
  type: CustomNodeType;
  meta?: Record<string, any>;
  error_message?: string;
  error_title?: string;
  state?: string;
  isError?: boolean;
  last_successful_run?: string;
  deletable?: boolean;
}

export interface Time {
  hour: number;
  minute: number;
}

type KeyVal = { key: string; val: string };

type EndpointField = {
  key: string;
  validation: string;
  required: boolean;
};

export interface ApiNodeData extends CustomNodeData {
  intervalUnit: ['Hours', 'Minutes'];
  interval: number;
  startingAt: Time;
  timeout: number;
  descriptionTitle: string;
  descriptionUrl: string;
  description: string;
  license: string;
  reviewDate: string;
  requestType: string;
  baseurl: string;
  params: KeyVal[];
  headers: KeyVal[];
  enabled: boolean;
  body: {
    contentType: string;
    content: string;
  };
  responseType: string;
}

export interface EndpointNodeData extends CustomNodeData {
  endpoint: string;
  enabled: boolean;
  allowCustomFields: boolean;
  fields: EndpointField[];
}
export interface SpreadsheetNodeData extends CustomNodeData {
  descriptionTitle: string;
  descriptionUrl: string;
  description: string;
  license: string;
  reviewDate: string;
}

export interface BorealisNodeData extends CustomNodeData {
  engagementId: string;
  enabled: boolean;
  offset: number;
  descriptionTitle: string;
  descriptionUrl: string;
  description: string;
  license: string;
  reviewDate: string;
}

export interface ProcessNodeData extends CustomNodeData {
  argument: string;
  isArgumentEdited: boolean;
  trigger: boolean;
}

export interface JoinNodeData extends CustomNodeData {
  script: string;
}

export interface SenderNodeData extends CustomNodeData {
  namespace: string;
  receivers: { value: string; label: string }[];
  addKeys: {
    key: string;
    value: string;
  }[];
}

export interface ReceiverNodeData extends CustomNodeData {
  namespace: string;
}

export interface InputNodeData extends CustomNodeData {}
export interface StorageNodeData extends CustomNodeData {
  format: string;
  behaviour: string;
  indexFields: string[];
  editable: boolean;
}
export interface DropKeysNodeData extends CustomNodeData {
  keys: string[];
}
export interface GroupNodeData extends CustomNodeData {
  groupByKey: string;
}
export interface CustomFunctionNodeData extends CustomNodeData {
  script: string;
}

export interface SurveyDataNodeData extends CustomNodeData {}
export interface SurveyInteractionNodeData extends CustomNodeData {}
export interface SurveyProgressNodeData extends CustomNodeData {}
export interface SurveyVisitorNodeData extends CustomNodeData {}

export interface SummarizeNodeData extends CustomNodeData {
  source: string;
  destination: string;
  maxWordCount: number;
  instructions: string;
  shouldReprocessRows: boolean;
}

export interface SentimentNodeData extends CustomNodeData {
  source: string;
  destination: string;
  shouldReprocessRows: boolean;
}

export interface LocationNodeData extends CustomNodeData {
  sourceFormat: string;
  source: string;
  destination: string;
}

export interface DateTimeNodeData extends CustomNodeData {
  source: string;
  sourceFormat: string;
  destination: string;
  destinationFormat: string;
}
export interface Filter {
  field: string;
  func: string;
  value: string;
}

export interface FilterNodeData extends CustomNodeData {
  filterMode: string;
  filters: Filter[];
}

export interface TagNodeData extends CustomNodeData {
  source: string;
  destination: string;
  tolerance: number;
  shouldReprocessRows: boolean;
  tags: Array<string>;
}

export interface InternalGeneratorNodeData extends CustomNodeData {
  keys: {
    key: string;
    valueType: string;
    min: number;
    max: number;
  }[];
}

export type CustomNodeTypes =
  | ApiNodeData
  | EndpointNodeData
  | SpreadsheetNodeData
  | BorealisNodeData
  | ProcessNodeData
  | JoinNodeData
  | InputNodeData
  | StorageNodeData
  | DropKeysNodeData
  | FilterNodeData
  | GroupNodeData
  | CustomFunctionNodeData
  | SurveyDataNodeData
  | SurveyInteractionNodeData
  | SurveyProgressNodeData
  | SurveyVisitorNodeData
  | SummarizeNodeData
  | SentimentNodeData
  | LocationNodeData
  | TagNodeData
  | SenderNodeData
  | ReceiverNodeData
  | InternalGeneratorNodeData;
