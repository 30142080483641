export const renderStringWithLinks = (
  inputString: null | string | number | string[]
) => {
  if (inputString === null || inputString === undefined) {
    inputString = '';
  } else if (typeof inputString !== 'string') {
    //that should handle arrays and numbers
    inputString = inputString.toString();
  }

  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = inputString.split(urlRegex);

  const elements = parts.map((part, index) => {
    if (part.match(urlRegex)) {
      const url = part;
      return (
        <a
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="break-all"
        >
          {url}
        </a>
      );
    } else {
      return <span key={index}>{part}</span>;
    }
  });

  return <>{elements}</>;
};
