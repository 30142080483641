// funtion to handle horizontal scrolling on a div element on  wheel scroll event, used in TabbedNavigationBar component
export const handleHorizontalScroll = (e: React.WheelEvent<HTMLDivElement>) => {
  const strength = Math.abs(e.deltaY);
  if (e.deltaY === 0) return;

  const el = e.currentTarget;
  if (
    !(el.scrollLeft === 0 && e.deltaY < 0) &&
    !(
      el.scrollWidth - el.clientWidth - Math.round(el.scrollLeft) === 0 &&
      e.deltaY > 0
    )
  ) {
    e.preventDefault();
  }
  el.scrollTo({
    left: el.scrollLeft + e.deltaY,
    behavior: strength > 70 ? 'auto' : 'smooth',
  });
};
