import React, { useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import TabbedNavigationBar from '@/layout/tab-navigation/TabbedNavigationBar';
import TabbedNavigationBarElement from '@/layout/tab-navigation/TabbedNavigationBarElement';
import { cn } from '@/lib/utils';
import { Button } from '@/tcomponents/ui/button';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { FullscreenContext } from '@/context/FullscreenContext';

export type TFeatureSources = {
  title: string;
  description: string;
  reviewDate: string;
  url: string;
  license: string;
};

export type FeatureSourceBarProps = {
  sources: TFeatureSources[];
  information: string;
  project?: any;
  feature?: any;
};

export default function FeatureSourceBar(props: FeatureSourceBarProps) {
  const { handle: fullScreen } = useContext(FullscreenContext);
  const { sources, information, project, feature } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const getTabbedNavigationElements = () => {
    let elements = [];

    if (typeof sources !== 'undefined' && sources.length > 0) {
      elements.push(
        <TabbedNavigationBarElement name="sources">
          Sources
        </TabbedNavigationBarElement>
      );
    }

    if (typeof information !== 'undefined' && information.length > 0) {
      elements.push(
        <TabbedNavigationBarElement name="information">
          Information
        </TabbedNavigationBarElement>
      );
    }

    return elements;
  };
  const renderTabContent = () => {
    /**
     * TabbedNavigationBarElements are conditionally added to the list therefore
     * the tabIndex value needs to be matched to the index of the navigation elements array
     * in order to render the correct layout
     */
    let elements = getTabbedNavigationElements();

    if (typeof elements[tabIndex]?.props?.name === 'undefined') {
      return <div></div>;
    }

    switch (elements[tabIndex]?.props?.name) {
      case 'sources':
        return (
          <div className="flex flex-col gap-y-4">
            {sources?.map((source, idx) => {
              return (
                <div
                  key={`${source.title}-${idx}`}
                  className="flex flex-col p-4 bg-white border rounded-md shadow-sm"
                >
                  <h4 className="font-bold">{source.title}</h4>
                  <p className="text-sm text-brand">
                    <Link
                      to={source.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-brand hover:text-black"
                    >
                      {source.url}
                    </Link>
                  </p>
                  <p className="text-sm text-gray-500">{source.description}</p>
                  {source.license !== null && source.license !== '' && (
                    <p className="text-xs text-gray-500">
                      <span className="font-bold">License: </span>
                      {source.license}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        );
      case 'information':
        return (
          <ReactMarkdown className="min-w-[100%] markdown min-h-full prose-headings:text-gray-600 prose-headings:mb-[1rem] prose-hr:my-[1rem] prose-blockquote:font-bold p-4 pb-20 prose bg-white rounded-md border shadow-sm">
            {information}
          </ReactMarkdown>
        );
      default:
        return <div></div>;
    }
  };

  const getSourceBarVisibility = () => {
    if (feature?.data?.data?.feature_subtype_fe === 'map') {
      return feature?.data?.data.display.showSources;
    } else {
      return feature?.data?.data.showSources;
    }
  };

  const featureName = feature?.data?.data?.name;
  const featureType = feature?.data?.data?.feature_type;
  const projectName = project?.data?.data?.name;
  const showSourceBar = getSourceBarVisibility();

  return (
    <>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className="fixed inset-0 bg-black z-10 bg-opacity-50 backdrop-blur-xs px-[250px]"
        ></div>
      )}
      {getTabbedNavigationElements().length > 0 && (
        <div
          className={cn(
            'fixed w-[100vw] md:w-[calc(100vw-250px)] z-20 border md:left-[250px] p-1 left-0 transition-all ease-in-out duration-500 ' +
              (isOpen
                ? 'bottom-0 h-[70%] bg-background'
                : 'bottom-0 h-[30px] bg-white'),
            !showSourceBar && 'hidden',
            fullScreen.active && 'hidden'
          )}
        >
          <div className="relative w-full bg-white">
            {!isOpen && (
              <p className="pt-0.5">
                <span className="px-1 font-bold">Source: </span>
                {/* comma separated list of titles of all the sources */}
                {sources?.map(source => source.title).join(', ')}
              </p>
            )}
            {isOpen && (
              <TabbedNavigationBar
                elements={getTabbedNavigationElements()}
                selectedTabIndex={tabIndex}
                onChange={setTabIndex}
              />
            )}

            <Button
              onClick={() => {
                setIsOpen(!isOpen);
                if (isOpen) return;
                ReactGA.event('source_details', {
                  feature_name: featureName,
                  feature_type: featureType,
                  project_name: projectName,
                });
              }}
              variant="ghost"
              className="absolute h-[25px] px-2.5 text-[10px] transform translate-x-1/2 bg-white border-t border-x -top-[29px] right-1/2 md:translate-x-1/2 text-primary rounded-b-none"
            >
              {isOpen ? 'Hide Data Sources' : 'Explore Data Sources'}
              <FontAwesomeIcon
                icon={faCaretUp}
                className={cn(
                  'ml-2  mt-1 transition-all duration-500 py-0',
                  isOpen && 'mb-1.5 rotate-180'
                )}
                size="xl"
              />
            </Button>
          </div>
          <div className=" mx-[90px] mt-8  h-[90%] overflow-auto">
            {renderTabContent()}
          </div>
        </div>
      )}
    </>
  );
}
