import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { DropKeysNodeData } from '../types/node-types';

const DropKeysNode = ({ data, ...rest }: Node<DropKeysNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className=" text-center p-1">
        {data.keys.map((key, index) => (
          <p key={`key_${index}`} className="inline">
            {key}
            {index !== data.keys.length - 1 ? ', ' : ''}
          </p>
        ))}
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default DropKeysNode;
