import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '/node_modules/@fortawesome/fontawesome-pro/css/all.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@mdxeditor/editor/style.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import './index.scss';
import './index.css';
import App from './App';
import { NavigationProvider } from './context/NavigationContext';
import { ModalProvider } from './context/ModalContext';
import { store as persistedConfigStore } from './configureStore';
import { Toaster } from '@/tcomponents/ui/toast/toaster';
import { SaveActionProvider } from '@/context/SaveActionContext';
import { FullscreenProvider } from '@/context/FullscreenContext';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container!);

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

root.render(
  <Provider store={persistedConfigStore}>
    <ModalProvider>
      <NavigationProvider>
        <FullscreenProvider>
          <SaveActionProvider>
            <App />
            <Toaster />
          </SaveActionProvider>
        </FullscreenProvider>
      </NavigationProvider>
    </ModalProvider>
  </Provider>
);
