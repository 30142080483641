import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/tcomponents/ui/tooltip';

import { cn } from '@/lib/utils';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlipProp } from '@fortawesome/fontawesome-svg-core';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 whitespace-nowrap !select-none',
  {
    variants: {
      variant: {
        default: 'bg-primary text-background font-bold hover:bg-primary/90',
        warning: 'bg-warning font-bold text-white hover:bg-warning/90',
        danger:
          'bg-destructive font-bold text-destructive-foreground hover:bg-destructive/90',
        success: 'bg-success font-bold text-white hover:bg-success/90',
        info: 'bg-info font-bold text-black hover:bg-info/90',
        outline:
          'border-2 border-primary font-bold bg-background text-primary hover:bg-primary hover:text-white',
        dark: 'bg-black font-bold text-white hover:bg-black/80',
        ghost: 'hover:bg-accent font-bold hover:text-accent-foreground',
        link: 'text-brand font-bold hover:opacity-60',
      },
      size: {
        default: 'h-10 px-6 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8 text-lg',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  icon?: IconDefinition;
  iconClassName?: string;
  tooltip?: string;
  tooltipWrapperClasses?: string;
  flip?: FlipProp;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      icon,
      iconClassName,
      tooltip,
      tooltipWrapperClasses,
      asChild = false,
      flip = null,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    let childrenArray = props.children;
    let content = '';

    if (!Array.isArray(childrenArray)) {
      childrenArray = [childrenArray];
    }

    // @ts-ignore
    childrenArray.forEach((child: any) => {
      if (typeof child === 'string' && child !== 'button') {
        content += child;
      }
    });

    if (content === '') {
      content = 'button';
    }

    let attribute = content
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '_')
      .replace(/([A-Z])/g, (match: string) => `_${match.toLowerCase()}`);

    let label = content.toString().trim().toLowerCase();

    return tooltip ? (
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger className={tooltipWrapperClasses}>
            <Comp
              className={cn(buttonVariants({ variant, size, className }))}
              ref={ref}
              {...props}
              data-testing={attribute}
              aria-label={label}
            >
              {icon && flip && (
                <FontAwesomeIcon
                  icon={icon}
                  size="1x"
                  className={cn(iconClassName, 'mr-2')}
                  flip={flip}
                />
              )}
              {icon && flip === null && (
                <FontAwesomeIcon
                  icon={icon}
                  size="1x"
                  className={cn(iconClassName, 'mr-2')}
                />
              )}
              {props.children}
            </Comp>
          </TooltipTrigger>
          <TooltipContent className="font-normal " sideOffset={4}>
            {tooltip}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ) : (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        data-testing={attribute}
        aria-label={label}
      >
        {icon && flip && (
          <FontAwesomeIcon
            icon={icon}
            size="1x"
            className={cn(iconClassName, 'mr-2')}
            flip={flip}
          />
        )}
        {icon && flip === null && (
          <FontAwesomeIcon
            icon={icon}
            size="1x"
            className={cn(iconClassName, 'mr-2')}
          />
        )}
        {props.children}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

type IconButtonProps = {
  tooltip?: string;
  disabled?: boolean;
  icon: IconDefinition;
  onClick?: () => void;
  className?: string;
  testingAttribute?: string;
  tabIndex?: number;
};

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  tooltip,
  disabled,
  className,
  onClick,
  testingAttribute,
  tabIndex,
}) => {
  return tooltip ? (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger tabIndex={tabIndex}>
          <button disabled={disabled} tabIndex={tabIndex}>
            <FontAwesomeIcon
              icon={icon}
              onClick={onClick}
              className={cn(
                'cursor-pointer hover:' +
                  (tooltip ? 'opacity-70 ' : '') +
                  className
              )}
              data-testing={testingAttribute}
            />
          </button>
        </TooltipTrigger>
        <TooltipContent className="font-normal" sideOffset={5}>
          {tooltip}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <button disabled={disabled} tabIndex={tabIndex}>
      <FontAwesomeIcon
        icon={icon}
        onClick={onClick}
        className={cn('cursor-pointer hover:opacity-70 ' + className)}
        data-testing={testingAttribute}
      />
    </button>
  );
};

export { IconButton, Button, buttonVariants };
