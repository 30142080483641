import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { Card, CardContent } from '@/tcomponents/ui/card';
import { ContentInfo, GalleryInfo, Info, Steps } from '../Helpers/Types';
import { Label } from '@/tcomponents/ui/label';
import { Input } from '@/tcomponents/ui/input';
import {
  faEllipsisVertical,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Button, IconButton } from '@tcomponents/ui/button';
import { ContentInfoCard } from './ContentInfo';
import { GalleryInfoCard } from './GalleryInfo';

type InfoCardProps = {
  steps: Steps;
  info: Info;
  updateStep: (info: Info) => void;
  handleDeleteStep: (id: string | number) => void;
  handleInfoTypeChange: (val: Info['type'], info: Info) => void;
};

export const InfoCard = (props: InfoCardProps) => {
  const { info, updateStep, handleDeleteStep, handleInfoTypeChange, steps } =
    props;
  return (
    <Card className="relative border-none shadow-none grow">
      <CardContent className="p-6">
        <div>
          {/* Info and Info type */}
          <div className="flex justify-between pr-5 gap-x-[55px] grow">
            <div className="flex flex-col grow gap-y-1 ">
              <Label>Title</Label>
              <Input
                value={info.title}
                onChange={e => {
                  const newInfo = {
                    ...info,
                    title: e.target.value,
                    key: e.target.value,
                  };
                  updateStep(newInfo);
                }}
              />
            </div>
            <div className="flex flex-col gap-y-1 w-[170px]">
              <Label>Type</Label>
              <Select
                defaultValue={info.type}
                value={info.type}
                onValueChange={(val: Info['type']) =>
                  handleInfoTypeChange(val, info as Info)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Type" />
                </SelectTrigger>
                <SelectContent className="w-full">
                  <SelectGroup className={'overflow-y-auto max-h-96 w-full'}>
                    <SelectItem key="content" value="content">
                      Content
                    </SelectItem>
                    <SelectItem key="gallery" value="gallery">
                      Gallery
                    </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* popover to delete question */}
          <div className="absolute top-4 right-4">
            <Popover>
              <PopoverTrigger tabIndex={-1}>
                <IconButton
                  tabIndex={-1}
                  icon={faEllipsisVertical}
                  tooltip="Actions"
                  className="pl-5 py-[3px]"
                />
              </PopoverTrigger>
              <PopoverContent side="left" className="p-0 w-[100px]">
                <PopoverClose>
                  <Button
                    icon={faTrashCan}
                    disabled={steps.length === 1}
                    onClick={() => handleDeleteStep(info.id)}
                    variant="danger"
                    className="font-normal text-[14px] w-full "
                  >
                    Delete
                  </Button>
                </PopoverClose>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        {/*  form content based on the type of the info */}
        <div className="py-4 bg-white">
          {info.type === 'content' && (
            <ContentInfoCard
              info={info as ContentInfo}
              updateStep={updateStep}
            />
          )}
          {info.type === 'gallery' && (
            <GalleryInfoCard
              info={info as GalleryInfo}
              updateStep={updateStep}
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};
