import React, { FC, ReactNode } from 'react';

interface ICenteredContainerProps {
  children: ReactNode;
}
const CenteredContainer: FC<ICenteredContainerProps> = ({ children }) => {
  return (
    <div
      className="ui centered middle aligned grid"
      style={{ height: '100vh' }}
    >
      <div className="column centered-container ui center aligned">
        {children}
      </div>
    </div>
  );
};

export default CenteredContainer;
