import { useSaveGraphMutation } from '@/store/services/projects/graphApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { useEffect } from 'react';

type Props = {
  refetchGraph: () => void;
};

export const useSaveGraph = (props: Props) => {
  const { refetchGraph } = props;
  const [saveGraph, { isSuccess: isSavingSuccess, isError: isSavingError }] =
    useSaveGraphMutation();

  useEffect(() => {
    if (isSavingSuccess) {
      toast({
        duration: 3000,
        title: 'Success!',
        description: 'Graph data saved successfully.',
      });
      refetchGraph();
    }
    if (isSavingError) {
      toast({
        duration: 24 * 60 * 60000,
        className: 'bg-red-500',
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      });
    }
  }, [isSavingSuccess, isSavingError, refetchGraph]);

  return {
    saveGraph,
  };
};
