const listSampleFormat = `[ // array of item objects in order to be displayed
  { // object per item in the chart
    _name: 'Stuff', // the label for the value
    _value: 400 // the value
  },
  {
    _name: 'Stuff',
    _value: 400
  }
]`;

export default listSampleFormat;
