import React, { FC, ReactNode } from 'react';
import Numbers from '../../../types/enums/common/Numbers';
import GridWidths from '../../../types/enums/grid/GridWidths';

interface IColumnProps {
  children?: ReactNode;
  size?: Numbers;
  width?: GridWidths;
}
const Column: FC<IColumnProps> = ({ children, size, width }) => {
  let classesAssembled = (size ? size + ' ' : ' ') + (width ? width : ' ');

  return <div className={'column ' + classesAssembled}>{children}</div>;
};

export default Column;
