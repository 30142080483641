export const defaultDataStyling = [
  '#EB7546',
  '#348FB3',
  '#FFF585',
  '#97DFAF',
  '#386B37',
  '#21A39A',
  '#005F85',
  '#AFBABE',
  '#F19E7D',
  '#71B1CA',
  '#FFF8AA',
  '#B6E9C7',
  '#749773',
  '#64BEB8',
  '#4D8FAA',
  '#C7CFD2',
  '#F7C8B5',
  '#AED2E1',
  '#FFFBCE',
  '#D5F2DF',
  '#B3C7B3',
  '#A6DAD7',
  '#99BFCE',
  '#DFE3E5',
];

// custom styles for react select
export const reactSelectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    background: '#fafafb',
    cursor: 'pointer',
    borderRadius: 3,
    borderColor: '#DEDEDF',
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? '#DEDEDF' : '#DEDEDF',
    },
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    border: '1px solid #DEDEDF',
  }),
};
