import {
  faInfo,
  faChartPie,
  faChartArea,
  faChartColumn,
  faChartSimple,
  faChartBar,
  faListOl,
} from '@fortawesome/free-solid-svg-icons';
import {
  faChartSimpleHorizontal,
  faChartLineUp,
  faTableLayout,
} from '@fortawesome/pro-solid-svg-icons';
import { ChartType } from '../types/chart-types';

export const chartButtonsData = [
  {
    tooltip: 'Statistics',
    chartType: ChartType.Statistics,
    icon: faInfo,
    iconSize: '2xl',
  },
  {
    tooltip: 'Pie Chart',
    chartType: ChartType.Pie,
    icon: faChartPie,
    iconSize: '2xl',
  },
  {
    tooltip: 'Bar Chart',
    chartType: ChartType.SimpleBar,
    icon: faChartSimple,
    iconSize: '2xl',
  },
  {
    tooltip: 'Stacked Bar Chart',
    chartType: ChartType.StackedBar,
    icon: faChartColumn,
    iconSize: '2xl',
  },
  {
    tooltip: 'Horizontal Bar Chart',
    chartType: ChartType.HorizontalBar,
    icon: faChartSimpleHorizontal,
    iconSize: '2xl',
  },
  {
    tooltip: 'Stacked Horizontal Bar Chart',
    chartType: ChartType.StackedHorizontalBar,
    icon: faChartBar,
    iconSize: '2xl',
  },
  {
    tooltip: 'Line Chart',
    chartType: ChartType.Line,
    icon: faChartLineUp,
    iconSize: '2xl',
  },
  {
    tooltip: 'Area Chart',
    chartType: ChartType.Area,
    icon: faChartArea,
    iconSize: '2xl',
  },
  {
    tooltip: 'Rank Chart',
    chartType: ChartType.Rank,
    icon: faListOl,
    iconSize: '2xl',
  },
  {
    tooltip: 'External Website',
    chartType: ChartType.IFrame,
    icon: faTableLayout,
    iconSize: '2xl',
  },
];
