import { combineReducers } from 'redux';
import RequestReducer from './features/requestSlice';
import { authApi } from './services/users/authApi';
import { userApi } from './services/users/userApi';
import { projectApi } from './services/projects/projectApi';
import { featureGroupApi } from './services/projects/featureGroupApi';
import { featureApi } from './services/projects/featureApi';
import { graphApi } from './services/projects/graphApi';
import { chartApi } from './services/projects/chartApi';
import { rankedListApi } from './services/projects/rankedListApi';
import { mapApi } from './services/projects/mapApi';
import { surveyApi } from './services/projects/surveyApi';
import { generalApi } from '@/store/services/generalApi';
import { tableDisplayApi } from '@/store/services/projects/tableDisplayApi';
import { aiToolApi } from './services/aiToolApi';
import { mapManagerApi } from '@/store/services/mapManagerApi';
import { aggregationApi } from '@/store/services/aggregationApi';
import { integrationApi } from './services/integrationApi';
import { roleManagementApi } from './services/roleManagementApi';
import { publicDataApi } from '@/store/services/publicDataApi';
import { receiverNodeApi } from '@/store/services/receiverNodeApi';
import { organisationApi } from './services/organisationApi';
import { userReducer } from './features/users/userSlice';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  requestLoading: RequestReducer,
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [featureGroupApi.reducerPath]: featureGroupApi.reducer,
  [featureApi.reducerPath]: featureApi.reducer,
  [graphApi.reducerPath]: graphApi.reducer,
  [chartApi.reducerPath]: chartApi.reducer,
  [rankedListApi.reducerPath]: rankedListApi.reducer,
  [mapApi.reducerPath]: mapApi.reducer,
  [surveyApi.reducerPath]: surveyApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [tableDisplayApi.reducerPath]: tableDisplayApi.reducer,
  [aiToolApi.reducerPath]: aiToolApi.reducer,
  [mapManagerApi.reducerPath]: mapManagerApi.reducer,
  [aggregationApi.reducerPath]: aggregationApi.reducer,
  [integrationApi.reducerPath]: integrationApi.reducer,
  [roleManagementApi.reducerPath]: roleManagementApi.reducer,
  [publicDataApi.reducerPath]: publicDataApi.reducer,
  [receiverNodeApi.reducerPath]: receiverNodeApi.reducer,
  [organisationApi.reducerPath]: organisationApi.reducer,
});

export default rootReducer;
