import {
  ApiNode,
  BorealisNode,
  CustomFunctionNode,
  CustomNode,
  DropKeysNode,
  EndpointNode,
  GroupByNode,
  InputNode,
  JoinNode,
  ProcessNode,
  SpreadsheetNode,
  StorageNode,
  TagNode,
  DateTimeNode,
  SentimentNode,
  SummarizeNode,
  FilterNode,
  LocationNode,
  SurveyDataNode,
  SurveyInteractionNode,
  SurveyProgressNode,
  SurveyVisitorNode,
  SenderNode,
  ReceiverNode,
  InternalGeneratorNode,
} from '../nodes';

import { CustomNodeType } from '../types';

export const nodeTypes = {
  [CustomNodeType.Api]: ApiNode,
  [CustomNodeType.Endpoint]: EndpointNode,
  [CustomNodeType.Spreadsheet]: SpreadsheetNode,
  [CustomNodeType.Borealis]: BorealisNode,
  [CustomNodeType.Process]: ProcessNode,
  [CustomNodeType.Join]: JoinNode,
  [CustomNodeType.CustomFunction]: CustomFunctionNode,
  [CustomNodeType.DropKeys]: DropKeysNode,
  [CustomNodeType.GroupBy]: GroupByNode,
  [CustomNodeType.Input]: InputNode,
  [CustomNodeType.Storage]: StorageNode,
  [CustomNodeType.SurveyData]: SurveyDataNode,
  [CustomNodeType.SurveyVisitor]: SurveyVisitorNode,
  [CustomNodeType.SurveyInteraction]: SurveyInteractionNode,
  [CustomNodeType.SurveyProgress]: SurveyProgressNode,
  [CustomNodeType.Tag]: TagNode,
  [CustomNodeType.DateTime]: DateTimeNode,
  [CustomNodeType.Sentiment]: SentimentNode,
  [CustomNodeType.Summarize]: SummarizeNode,
  [CustomNodeType.Filter]: FilterNode,
  [CustomNodeType.Location]: LocationNode,
  [CustomNodeType.Sender]: SenderNode,
  [CustomNodeType.Receiver]: ReceiverNode,
  [CustomNodeType.InternalGenerator]: InternalGeneratorNode,
  fallback: CustomNode,
};
