export const PAGES = {
  Project_Management: {
    My_Projects: 'My Projects',
  },
  Services: {
    Print_And_Post: 'Print & Post',
    Address_Lookup_Map: 'Address Lookup Map',
    AI_Wizard: 'AI Wizard',
  },
  Administration: {
    Users: 'Users',
    Roles: 'Roles',
    Organisations: 'Organisations',
    Integrations: 'Integrations',
    Surveys: 'Surveys',
    Maps: 'Maps',
  },
  Monitoring: {
    Workflow_Health: 'Workflow Health',
    Audit_Trail: 'Audit Trail',
    AI_Resources: 'AI Resources',
  },
};
