import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { SurveyVisitorNodeData } from '../types/node-types';

const SurveyVisitorNode = ({ data, ...rest }: Node<SurveyVisitorNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default SurveyVisitorNode;
