import React from 'react';
import { IFrameWidgetOptions } from '../types/chart-types';

type IFrameChartProps = {
  widgetData: IFrameWidgetOptions;
};

export default function IFrameChart(props: IFrameChartProps) {
  const { widgetData } = props;
  return (
    <div className="w-full h-full pl-2 isChart">
      <iframe
        src={widgetData.chartOptions.url}
        width="100%"
        height="100%"
        title="External Website"
      />
    </div>
  );
}
