const rowSampleFormat = `[ // array of rows 
  { // object per row 
    id:125,
    stuff:"thing",
    another:"value"
  },
  { // object per row 
    id:125, 
    stuff:"thing", 
    another:"value"
  }
]`;

export default rowSampleFormat;
