import React, { useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import { AreaWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';
import { getKeys } from '../../utils/helperFunctions';
import { useChartDashboardContext } from '../ChartDashboardProvider';

type AreaChartProps = {
  widgetData: AreaWidgetOptions;
  chartData?: any;
};

export default function SimpleAreaChart(props: AreaChartProps) {
  const { widgetData, chartData } = props;
  const [horizontalBarData, setHorizontalBarData] = useState<any>([]);
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;

  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (finalData?.length === 0) return <NoData />;

  const isFinalDataAnArray = Array.isArray(finalData);
  if (!isFinalDataAnArray) return <NoData />;

  const areaDataKeys = getKeys(finalData);

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${label} :`}</p>
          {payload
            .slice()
            .reverse()
            .map((item: any, index: number) => (
              <div className="flex items-center gap-x-1">
                <span
                  className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
                  style={{ backgroundColor: `${item.fill}` }}
                ></span>
                <p key={`item-${index}`}>{`${item.name} : ${item.value} ${
                  widgetData.chartOptions.unit ?? ''
                }`}</p>
              </div>
            ))}
        </div>
      );
    }

    return null;
  };

  const handleAreaChartHover = (e: any) => {
    const activePayload = e.activePayload;
    const horizontalBarData = activePayload
      ?.slice()
      .reverse()
      .map((item: any) => ({
        _name: item.dataKey,
        _value: item.value,
        fill: item.fill,
      }));
    setHorizontalBarData(horizontalBarData);
  };

  const defaultDataForBarChart = areaDataKeys
    ?.slice()
    .reverse()
    .map((key: string) => ({
      _name: key,
      _value: '-',
    }));

  const truncateYAxisLabel = (tickItem: string) => {
    return tickItem.length > 80 ? tickItem.slice(0, 80) + '...' : tickItem;
  };

  const getLongestLabel = () => {
    let maxLength = 0;
    if (finalData && Array.isArray(finalData)) {
      finalData.forEach((a: { _name: string | any[] }) => {
        let keys = Object.keys(a);
        keys.forEach(key => {
          if (
            key !== '_name' &&
            // @ts-ignore
            parseInt(a[key]).toString().length > maxLength
          ) {
            // @ts-ignore
            maxLength = parseInt(a[key]).toString().length;
          }
        });
      });
    }
    return maxLength * (maxLength / 2.5);
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="flex flex-wrap justify-center gap-x-5 gap-y-2 sm:ml-0">
        {payload.map((item: any, index: number) => (
          <div key={`item-${index}`} className="flex items-center gap-x-1">
            <div
              className="w-10 h-5 rounded-md"
              style={{ backgroundColor: item?.color }}
            />
            <p className="text-sm">{item?.value}</p>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full h-full">
      <div className="relative flex-1">
        <div className="absolute top-0 left-0 w-full h-full ">
          <ResponsiveContainer width="100%" height="100%" className="isChart">
            <AreaChart
              data={finalData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 20,
              }}
              syncId={widgetData.id as string}
              onMouseMove={handleAreaChartHover}
            >
              <CartesianGrid strokeDasharray="1 5" />
              <XAxis
                dataKey="_name"
                label={{ value: widgetData.chartOptions.xAxisLabel, dy: 20 }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                label={{
                  value:
                    widgetData.chartOptions.yAxisLabel &&
                    widgetData.chartOptions.yAxisLabel +
                      (widgetData.chartOptions.unit
                        ? ` (${widgetData.chartOptions.unit})`
                        : ''),
                  angle: -90,
                  dy: 0,
                  dx: -Math.abs(15 + getLongestLabel()),
                }}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{
                  stroke: 'black',
                  strokeWidth: 1,
                  strokeDasharray: '8 8',
                }}
              />
              {widgetData.chartOptions?.showKeys !== false && (
                <Legend
                  wrapperStyle={{
                    paddingTop: 20,
                  }}
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  iconType="rect"
                  content={<CustomLegend />}
                />
              )}
              {areaDataKeys.map((key: string, index: number) => (
                <Area
                  isAnimationActive={false}
                  dot={false}
                  type="monotone"
                  activeDot={{ r: 3 }}
                  key={`area-${index}`}
                  dataKey={key}
                  stackId="a"
                  fill={widgetData.dataStyling?.[index]}
                  stroke={widgetData.dataStyling?.[index]}
                  strokeWidth={2}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      {widgetData.chartOptions?.detailBar && (
        <div className="w-full pt-4">
          <div className="flex justify-between px-5">
            <h6>{widgetData.chartOptions.detailNameLabel}</h6>
            <h6>
              {widgetData.chartOptions.detailValueLabel &&
                widgetData.chartOptions.detailValueLabel +
                  (widgetData.chartOptions.unit
                    ? ` (${widgetData.chartOptions.unit})`
                    : '')}
            </h6>
          </div>
          <ResponsiveContainer width="100%" height={areaDataKeys.length * 33}>
            <BarChart
              layout="vertical"
              data={
                horizontalBarData?.length
                  ? horizontalBarData
                  : defaultDataForBarChart
              }
              margin={{
                top: 10,
                right: 15,
                left: 15,
                bottom: 10,
              }}
            >
              <YAxis
                mirror={true}
                dataKey="_name"
                type="category"
                yAxisId={0}
                axisLine={false}
                tick={{ dy: -15, dx: -5 }}
                tickMargin={0}
                tickFormatter={truncateYAxisLabel}
                width={550}
              />
              <YAxis
                mirror={true}
                yAxisId={1}
                dataKey="_value"
                type="category"
                orientation="right"
                tickLine={false}
                axisLine={false}
                tick={{ dy: -15, dx: 5 }}
                tickMargin={0}
              />

              <Bar
                isAnimationActive={false}
                dataKey="_value"
                barSize={10}
                background={{ fill: '#F7FAFB' }}
                radius={[5, 5, 5, 5]}
              />

              <XAxis xAxisId={0} hide axisLine={false} type="number" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}
