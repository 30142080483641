import { Button, IconButton } from '@tcomponents/ui/button';
import { Info, Question } from '@/pages/survey/Helpers/Types';
import { useSaveActionContext } from '@/context/SaveActionContext';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import {
  faCircleInfo,
  faCirclePlus,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';
import { useSteps } from '../hooks/useSteps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { useCallback, useState } from 'react';
import { cn } from '@/lib/utils';
import {
  Draggable,
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { QuestionCard } from '../components/QuestionCard';
import { InfoCard } from '../components/InfoCard';

const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const StepsTab = () => {
  const { setIsDirty } = useSaveActionContext();
  const [idDragging, setIsDragging] = useState(false);
  const {
    steps,
    setSteps,
    addQuestionStep,
    addInfoStep,
    updateStep,
    handleDeleteStep,
    handleQueTypeChange,
    handleInfoTypeChange,
  } = useSteps();

  const handleDragEnd = useCallback<OnDragEndResponder>(
    result => {
      setIsDragging(false);
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const reOrderedQuestions = reorder(
        steps,
        result.source.index,
        result.destination.index
      );
      setSteps(reOrderedQuestions);
      setIsDirty(true);
    },
    [steps]
  );

  return (
    <div className="w-full">
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {steps.map((step, idx) => (
                <Draggable
                  key={step.id}
                  draggableId={step.id as string}
                  index={idx}
                >
                  {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <div data-id={step.id} className="flex">
                        {/* drag handle */}
                        <div
                          {...provided.dragHandleProps}
                          className={cn(
                            'w-[30px] rounded-l-md bg-gray-200 draggable-handle cursor-move flex items-center justify-center relative ',
                            {
                              'bg-[#F8DC8B]': step.step === 'question',
                              'bg-[#55768A]': step.step === 'info',
                            }
                          )}
                        >
                          <FontAwesomeIcon
                            icon={faGripDotsVertical}
                            className="p-2 text-white text-[20px]"
                          />
                          <FontAwesomeIcon
                            icon={
                              step.step === 'question'
                                ? faCircleQuestion
                                : faCircleInfo
                            }
                            className="p-2 text-white text-[20px] top-0 absolute"
                          />
                        </div>
                        {step.step === 'question' && (
                          <QuestionCard
                            steps={steps}
                            question={step as Question}
                            updateStep={updateStep}
                            handleDeleteStep={handleDeleteStep}
                            handleQueTypeChange={handleQueTypeChange}
                          />
                        )}
                        {step.step === 'info' && (
                          <InfoCard
                            info={step as Info}
                            updateStep={updateStep}
                            handleDeleteStep={handleDeleteStep}
                            handleInfoTypeChange={handleInfoTypeChange}
                            steps={steps}
                          />
                        )}
                      </div>
                      {/* add new question button  */}
                      <div
                        className={cn(
                          'flex items-center w-full py-3 transition-opacity duration-200 ease-in-out gap-x-4',
                          {
                            'opacity-100': !idDragging,
                            'opacity-0': idDragging,
                          }
                        )}
                      >
                        <span className="w-full h-[1px] bg-gray-300 grow -mr-3"></span>
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              icon={faCirclePlus}
                              tooltip="Add Step"
                              className="-mr-5 text-gray-300 rounded-full cursor-pointer hover:text-gray-800"
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            side="left"
                            className="p-0 text-[14px] w-fit min-w-[150px]"
                          >
                            <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                              <Button
                                variant="ghost"
                                className="font-normal text-[12px] text-gray-800 px-3 items-center justify-start min-w-full flex gap-x-2"
                                onClick={() => addQuestionStep(idx + 1)}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleQuestion}
                                  className=" text-[15px] text-gray-600"
                                />
                                Add Question
                              </Button>
                              <Button
                                variant="ghost"
                                className="font-normal text-[12px] text-gray-800 px-3 items-center justify-start min-w-full flex gap-x-2"
                                onClick={() => addInfoStep(idx + 1)}
                              >
                                {' '}
                                <FontAwesomeIcon
                                  icon={faCircleInfo}
                                  className=" text-[15px] text-gray-600"
                                />
                                Add Info
                              </Button>
                            </PopoverClose>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default StepsTab;
