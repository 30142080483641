import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { StorageNodeData } from '../types/node-types';
import { useGetDataFromDataStoreQuery } from '@/store/services/projects/rankedListApi';
import { useCustomGraphContext } from '../CustomGraphProvider';
import { useGetGraphQuery } from '@/store/services/projects/graphApi';
import { useParams } from 'react-router-dom';

const StorageNode = ({ data, ...rest }: Node<StorageNodeData>) => {
  const { featureId } = useParams();

  const { activePipelineNodeId } = useCustomGraphContext();

  const { data: graphDataFromServer } = useGetGraphQuery(Number(featureId), {
    skip: !featureId,
  });

  const subGraphObjectFromServer = graphDataFromServer?.data?.subGraphs;
  const subGraphExist = !!subGraphObjectFromServer[activePipelineNodeId];

  const apiReturnedData = useGetDataFromDataStoreQuery(rest.id as string, {
    skip: !rest.id || !subGraphExist,
    refetchOnMountOrArgChange: true,
  });

  const parsedData =
    apiReturnedData && apiReturnedData.data
      ? JSON.parse(apiReturnedData.data)?.data
      : [];

  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className="p-1 font-bold text-center text-gray-600">
        <p>
          records: {typeof parsedData !== 'undefined' ? parsedData.length : 0}
        </p>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default StorageNode;
