import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { ApiNodeData } from '../types/node-types';

const ApiNode = ({ data, ...rest }: Node<ApiNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className="p-1 text-gray-600">
        <p>
          {' '}
          <span className="font-bold">URL:</span> {data.baseurl}
        </p>
        <p>
          {' '}
          <span className="font-bold">Interval:</span> {data.interval}{' '}
          {data.intervalUnit}
        </p>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default ApiNode;
