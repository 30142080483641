import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/tcomponents/ui/card';
import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import { useMapSettingsContext } from '../MapSettingsProvider';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { cn } from '@/lib/utils';
import { MapFeatureDetails } from '@/types/feature/featureDetails';

const DetailsTab = () => {
  const { formContent, setFormContent, errors, setErrors } =
    useMapSettingsContext();
  const { details } = formContent;
  const { setIsDirty } = useSaveActionContext();

  const handleFeatureDetailsChange = (
    key: keyof typeof formContent.details,
    value: string
  ) => {
    setFormContent({
      ...formContent,
      details: {
        ...formContent.details,
        [key]: value,
      },
    });
    if (key === 'name' && value === '') {
      setErrors({ ...errors, nameError: 'Name is required' });
    } else {
      setErrors({ ...errors, nameError: '' });
    }
    setIsDirty(true);
  };

  const handleDisplaySetupChange = (
    key: keyof typeof formContent.details.displaySetup,
    value: boolean
  ) => {
    setFormContent({
      ...formContent,
      details: {
        ...formContent.details,
        displaySetup: {
          ...formContent.details.displaySetup,
          [key]: value,
        },
      },
    });
    setIsDirty(true);
  };

  type SidebarSection = MapFeatureDetails['sidebarSections'][number];
  type EditableSidebarSectionFields = Pick<
    SidebarSection,
    'title' | 'icon' | 'description'
  >;
  const handleSidebarSectionsChange = (
    index: number,
    fieldKey: keyof EditableSidebarSectionFields,
    value: string
  ) => {
    setFormContent({
      ...formContent,
      details: {
        ...formContent.details,
        sidebarSections: formContent.details.sidebarSections.map(
          (section, i) => {
            if (i === index) {
              return {
                ...section,
                [fieldKey]: value,
              };
            }
            return section;
          }
        ),
      },
    });
    setIsDirty(true);
  };
  return (
    <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
      <Card className=" h-fit">
        <CardHeader>
          <CardTitle>Feature Details</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex flex-col gap-y-2">
              <div className="flex justify-between">
                <Label required>Name</Label>
                <span className="text-red-500 text-[10px]">
                  {errors.nameError}
                </span>
              </div>
              <Input
                value={details.name}
                onChange={e =>
                  handleFeatureDetailsChange('name', e.target.value)
                }
                className={errors.nameError ? 'border-red-500' : ''}
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Header Info</Label>

              <Input
                value={details.headerInfo}
                onChange={e =>
                  handleFeatureDetailsChange('headerInfo', e.target.value)
                }
              />
            </div>
            <div className="flex flex-col gap-y-2">
              <Label>Description</Label>
              <Input
                value={details.description}
                onChange={e =>
                  handleFeatureDetailsChange('description', e.target.value)
                }
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <Label>Icon</Label>
              <div className="flex">
                <i
                  className={
                    details.icon +
                    ' w-10 h-10 border rounded-l-md p-1 flex items-center justify-center bg-gray-500 text-white text-xl'
                  }
                ></i>
                <Input
                  value={details.icon}
                  onChange={e =>
                    handleFeatureDetailsChange('icon', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="flex flex-col gap-y-4">
        <Card className="h-fit">
          <CardHeader>
            <CardTitle>Display Setup</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-row gap-12">
              <div className="flex flex-col gap-y-2">
                <Label className="text-base">Allow Fullscreen</Label>

                <Switch
                  checked={details.displaySetup.allowFullScreen}
                  onCheckedChange={checked =>
                    handleDisplaySetupChange('allowFullScreen', checked)
                  }
                  checkedLabel="On"
                  unCheckedLabel="Off"
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label className="text-base">Show Sources</Label>

                <Switch
                  checked={details.displaySetup.showSource}
                  onCheckedChange={checked =>
                    handleDisplaySetupChange('showSource', checked)
                  }
                  checkedLabel="On"
                  unCheckedLabel="Off"
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label className="text-base">Search Bar</Label>

                <Switch
                  checked={
                    details.displaySetup.searchBar === false ? false : true
                  }
                  onCheckedChange={checked =>
                    handleDisplaySetupChange('searchBar', checked)
                  }
                  checkedLabel="On"
                  unCheckedLabel="Off"
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label className="text-base">Cluster Markers</Label>

                <Switch
                  checked={details.displaySetup.clusterMarkers}
                  onCheckedChange={checked =>
                    handleDisplaySetupChange('clusterMarkers', checked)
                  }
                  checkedLabel="On"
                  unCheckedLabel="Off"
                />
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="h-fit">
          <CardHeader>
            <CardTitle>Sidebar Sections</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col divide-y gap-y-8">
            {details.sidebarSections.map((section, index) => (
              <div
                key={section.key}
                className={cn('flex flex-col pt-5 gap-y-4', {
                  'border-t': index === 0,
                  '!border-b pb-5':
                    index === details.sidebarSections.length - 1,
                })}
              >
                <div className="flex justify-between gap-x-4">
                  <div className="flex flex-col w-1/2 gap-y-2">
                    <Label>{section.titleLabel}</Label>
                    <Input
                      value={section.title}
                      onChange={e =>
                        handleSidebarSectionsChange(
                          index,
                          'title',
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-col w-1/2 gap-y-2">
                    <Label>Icon</Label>
                    <div className="flex items-center">
                      <i
                        className={
                          section.icon +
                          ' w-10 h-10 border rounded-l-md p-1 flex items-center justify-center bg-gray-500 text-white text-xl'
                        }
                      ></i>
                      <Input
                        value={section.icon ?? ''}
                        onChange={e =>
                          handleSidebarSectionsChange(
                            index,
                            'icon',
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-2">
                  <Label>Description</Label>
                  <Input
                    value={section.description ?? ''}
                    onChange={e =>
                      handleSidebarSectionsChange(
                        index,
                        'description',
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
export default DetailsTab;
