import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

type LoadingDialogProps = {
  title: string;
};
export const LoadingDialog = ({ title }: LoadingDialogProps) => {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-full h-screen overflow-hidden bg-gray-700 bg-opacity-50">
      <div className=" w-[250px] h-[150px] rounded-md shadow-sm bg-white text-black flex flex-col items-center justify-center gap-y-8">
        <h2 className="text-[18px] font-bold text-center">{title}</h2>
        <i className="text-[55px] fas fa-spinner fa-spin text-primary"></i>
      </div>
    </div>
  );
};

export const useLoadingDialog = (title: string) => {
  const [loadingDialog, setLoadingDialog] = useState(false);

  useEffect(() => {
    if (loadingDialog) {
      const el = document.createElement('div');
      document.body.appendChild(el);

      ReactDOM.render(<LoadingDialog title={title} />, el);

      return () => {
        ReactDOM.unmountComponentAtNode(el);
        document.body.removeChild(el);
      };
    }
  }, [loadingDialog, title]);

  return { setLoadingDialog };
};
