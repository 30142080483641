import { ChartType } from '../types/chart-types';

export const calculateMinimumHeightOfWidget = (
  chartType: ChartType | undefined
) => {
  if (chartType === ChartType.Statistics) {
    return 5;
  }

  if (chartType === ChartType.StackedBar) {
    return 9;
  }

  if (
    chartType === ChartType.Line ||
    chartType === ChartType.Area ||
    chartType === ChartType.Pie
  ) {
    return 10.5;
  }

  return 12;
};
