import { useContext, useMemo, useState } from 'react';
import { ModalContext } from '@/context/ModalContext';
import {
  useDeleteNodeDataMutation,
  useUpdateDataStoreSchemaMutation,
} from '@/store/services/projects/graphApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { cloneDeep } from 'lodash';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { useLoadingDialog } from '@/tcomponents/custom/LoadingDialog';
import {
  CellComponent,
  ColumnDefinition,
  Formatter,
  Tabulator,
} from 'tabulator-tables';
import { deleteRowIcon, useTabulator } from '../../../../../hooks/useTabulator';
import { Schema } from '../components/TableSettingsView';

type Props = {
  schema?: Schema;
  dataStoreId: string;
  setTableView: React.Dispatch<
    React.SetStateAction<'data-view' | 'settings-view'>
  >;
};

const availableEditors = {
  input: 'Text (single line)',
  textarea: 'Text (multi line)',
  list: 'Choice (single)',
  'list-multi': 'Choice (multi)',
  number: 'Number',
  toggle: 'Boolean',
};
const columnTemplate = {
  order: 0,
  title: '',
  field: '',
  type: 'input',
  editor: 'input',
  personal: false,
};

export const useTableSettingsView = ({
  schema,
  dataStoreId,
  setTableView,
}: Props) => {
  const [enforceSchema, setEnforceSchema] = useState(
    schema?.enforce_schema ?? false
  );
  const [processUpdates, setProcessUpdates] = useState(
    schema?.process_updates ?? false
  );
  const [isSaveButtonDirty, setIsSaveButtonDirty] = useState(false);

  const [updateDataStoreSchema] = useUpdateDataStoreSchemaMutation();
  const [deleteNodeData] = useDeleteNodeDataMutation();

  const { showDialog } = useContext(ModalContext);
  const spinner = useLoadingDialog('Updating Data Store...');

  const showWipeModal = () => {
    showDialog({
      title: 'Confirm data deletion',
      message:
        'This action will erase all data in this store, this CANNOT be undone. Are you sure you want to proceed?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => {
          deleteNodeData(dataStoreId)
            .unwrap()
            .then(() => {
              toast({
                description:
                  'Successfully deleted the data from this data store',
                duration: 5000,
              });
            })
            .catch(() => {
              toast({
                variant: 'destructive',
                title: 'Uh Oh!',
                description: 'Something went wrong.',
                duration: 24 * 60 * 60000,
              });
            });
        },
      },
    });
  };

  const tabulatorConfig: Tabulator['options'] = useMemo(
    () => ({
      data: cloneDeep(schema?.configuration) || [],
      layout: 'fitColumns',
      placeholder: 'No Columns Defined',
      tabEndNewRow: columnTemplate,
      height: '100%',
      columnDefaults: {
        headerSort: false,
      },
      movableRows: true,
      rowHeader: {
        headerSort: false,
        resizable: false,
        minWidth: 30,
        width: 30,
        rowHandle: true,
        formatter: 'handle',
      },
      columns: [
        {
          title: 'Order',
          field: 'order',
          accessor: 'rownum',
          visible: false,
        },
        {
          title: 'Title',
          field: 'title',
          editor: 'input',
          validator: ['required', 'unique'],
          mutateLink: ['field'], //trigger generation of field name if not set
        },
        {
          title: 'Field',
          field: 'field',
          editor: 'input',
          validator: ['unique', 'required'],
          mutator: (value: any, data: { title: any }) => {
            return !value ? data.title : value; //generate field name from title if not set
          },
        },
        {
          title: 'Type',
          field: 'type',
          formatter: 'lookup',
          formatterParams: availableEditors, //map formatter values to user facing values
          editor: 'list',
          editorParams: {
            values: availableEditors,
          },
          mutateLink: ['values'], //trigger updates on other columns when this cell is edited
        },
        {
          title: 'Values',
          field: 'values',
          editor: 'input',
          formatter: (cell: CellComponent) => {
            const data = cell.getData() as { type: string };
            return data.type.startsWith('list') ? cell.getValue() : '-';
          },
          editable: (cell: CellComponent) => {
            const data = cell.getData() as { type: string };
            return data.type.startsWith('list'); //only allow edit on columns that accept value lists
          },
          mutatorData: (value: any[], data: any) => {
            return Array.isArray(value) ? value.join(' | ') : value; //map array data to comma separated list
          },
          accessor: (value: string, data: { type: string }) => {
            return data.type.startsWith('list')
              ? value?.split('|').map(item => item.trim())
              : []; //ensure values list is stored as array
          },
        },
        {
          title: 'Personal',
          field: 'personal',
          width: 100,
          formatter: 'toggle' as Formatter,
          formatterParams: { clickable: true },
          hozAlign: 'center',
        },
        deleteRowIcon,
      ] as ColumnDefinition[],
      debugInvalidOptions: false,
    }),
    [schema, dataStoreId]
  );

  const {
    tableDivRef,
    addNewRow,
    isTableDirty,
    trackerData,
    trackerRef,
    tabulatorRef,
  } = useTabulator({
    config: tabulatorConfig,
    trackFields: true,
  });

  const updateDataStoreSchemaFn = () => {
    if (!trackerData || !dataStoreId) return;
    spinner.setLoadingDialog(true);
    updateDataStoreSchema({
      dataStoreId,
      processUpdates,
      enforceSchema,
      data: trackerData,
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Data Store Schema Updated Successfully',
        });
        trackerRef.current?.reset();
        setTableView('data-view');
      })
      .catch(err => {
        if (
          err?.data?.message === 'The process node is currently being modified'
        ) {
          showDialog({
            title: 'Data Store Update Alert!',
            message:
              'The process node is currently being modified, please try again in a few minutes.',
            type: ModalTypes.DANGER,
            cancel: true,
          });
        } else if (
          err?.data?.message === 'The process node has previously been updated'
        ) {
          showDialog({
            title: 'Data Loss Alert!',
            message:
              'Data in the store has changed since the last update, updating the data now may possibly  result in data loss, do you want to proceed?',
            type: ModalTypes.DANGER,
            cancel: true,
            ok: {
              cb: updateDataStoreSchemaFn,
            },
          });
        } else {
          toast({
            title: 'Oh no!',
            description: 'Failed to update Data Store',
            variant: 'destructive',
          });
        }
      })
      .finally(() => {
        spinner.setLoadingDialog(false);
      });
  };

  return {
    tabulatorRef,
    tableDivRef,
    addNewRow,
    updateDataStoreSchemaFn,
    isTableDirty,
    isSaveButtonDirty,
    setIsSaveButtonDirty,
    enforceSchema,
    processUpdates,
    setEnforceSchema,
    setProcessUpdates,
    showWipeModal,
  };
};
