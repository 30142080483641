import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { HorizontalBarWidgetOptions } from '../../types/chart-types';
import { defaultColors, useColorPicker } from '../../hooks/useColorPicker';
import { SketchPicker } from 'react-color';
import { cn } from '@/lib/utils';
import { useChartDashboardContext } from '../../ChartDashboardProvider';
type HorizontalBarChartOptionsProps = {
  widgetData: HorizontalBarWidgetOptions;
};

export default function HorizontalBarChartOptions(
  props: HorizontalBarChartOptionsProps
) {
  const { widgetData } = props;
  const { singleWidgetData, updateWidgetData, isReadyToShow } =
    useChartDashboardContext();

  const {
    showColorPicker,
    selectedColorIndex,
    colors,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
  } = useColorPicker({
    initialColors: [...widgetData.dataStyling, ...defaultColors] ?? [],
    updateWidgetData,
  });
  const handleChartOptionsChange = (key: string, value: string) => {
    updateWidgetData({
      chartOptions: {
        ...widgetData.chartOptions,
        [key]: value,
      },
    });
  };

  const isDataAnArray = Array.isArray(singleWidgetData);

  return (
    <div>
      <div className="flex flex-col p-3 gap-y-4">
        <h4>Chart Options</h4>
        <div>
          <Label>X-axis Label</Label>
          <Input
            onChange={e =>
              handleChartOptionsChange('xAxisLabel', e.target.value)
            }
            value={widgetData.chartOptions.xAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>X-axis Label (negative) </Label>
          <Input
            onChange={e =>
              handleChartOptionsChange('xAxisLabelNegative', e.target.value)
            }
            value={widgetData.chartOptions.xAxisLabelNegative ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Y-axis Label</Label>
          <Input
            onChange={e =>
              handleChartOptionsChange('yAxisLabel', e.target.value)
            }
            value={widgetData.chartOptions.yAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Value Label</Label>
          <Input
            onChange={e =>
              handleChartOptionsChange('valueLabel', e.target.value)
            }
            value={widgetData.chartOptions.valueLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Unit</Label>
          <Input
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  unit: e.target.value,
                },
              })
            }
            value={widgetData.chartOptions.unit ?? ''}
            placeholder="Enter Unit"
          />
        </div>
      </div>
      <div className="flex flex-col divide-y">
        <h4 className="p-3">Chart Styling</h4>
        {isReadyToShow &&
          isDataAnArray &&
          singleWidgetData.length > 0 &&
          singleWidgetData.map((item: any, index: number, arr) => (
            <div
              className={cn('flex items-center justify-between p-2', {
                'bg-gray-100': index % 2 !== 0,
                '!border-b': index === arr.length - 1,
              })}
              key={`color-${index}`}
            >
              <span>{item._name}</span>
              <span
                onClick={() => handleColorClick(index)}
                className={`w-8 h-8 rounded-md cursor-pointer`}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
            </div>
          ))}
        {showColorPicker && (
          <div className="absolute z-10">
            <div
              className="fixed inset-0 bg-white opacity-20"
              onClick={handleColorPickerClose}
            ></div>
            <div className="inset-0">
              <SketchPicker
                width="200px"
                color={colors[selectedColorIndex]}
                onChange={handleColorChange}
                presetColors={[...defaultColors, 'transparent']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
