import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { WidgetLayout } from './types/chart-types';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { useChartDashboardContext } from './ChartDashboardProvider';
import { getChartComponent } from './lib/getChartComponent';
import { Widget } from './Widget';
import { useContext } from 'react';
import { NavigationContext } from '@/context/NavigationContext';
import useAuth from '@/hooks/UseAuth';
import { featurePermissions } from '@/stringConsts/permissionConsts';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function WidgetContainer() {
  const { isPublicView } = useContext(NavigationContext);
  const { widgets, layouts, setLayouts, widgetsDataFromServer } =
    useChartDashboardContext();
  const { setIsDirty } = useSaveActionContext();

  const { hasPermission } = useAuth();

  const handleLayoutChange = (
    newLayout: WidgetLayout[],
    allLayouts: Layouts
  ) => {
    setLayouts(allLayouts);
  };

  const breakpoints = { lg: 800, md: 500, xxs: 0 };
  const cols = { lg: 2, md: 1, sm: 1, xs: 1, xxs: 1 };

  return (
    <>
      <ResponsiveGridLayout
        onDrag={() => setIsDirty(true)}
        onResize={() => setIsDirty(true)}
        className={`my-4 h-full`}
        isDroppable={true}
        onLayoutChange={handleLayoutChange}
        layouts={layouts}
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={10}
        width={1200}
        margin={[20, 20]}
        containerPadding={[1, 1]}
        isDraggable={
          hasPermission(featurePermissions.general.settings_access) &&
          !isPublicView
        }
        isResizable={
          hasPermission(featurePermissions.general.settings_access) &&
          !isPublicView
        }
        // resolves issue with tooltip hidden behind the container
        useCSSTransforms={false}
      >
        {layouts.lg &&
          widgets.map((widgetData, index) => {
            const chartData = widgetsDataFromServer[widgetData.id];
            let chartComponent = getChartComponent(widgetData, chartData);
            return (
              <div className="bg-white border shadow-sm" key={widgetData.id}>
                <Widget
                  widgetData={widgetData}
                  isPublicView={isPublicView}
                  index={index}
                  chartComponent={chartComponent}
                />
              </div>
            );
          })}
      </ResponsiveGridLayout>
    </>
  );
}
