import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { PieWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type SimplePieChartProps = {
  widgetData: PieWidgetOptions;
  chartData?: any;
};
const SimplePieChart = (props: SimplePieChartProps) => {
  const { widgetData, chartData } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;
  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (finalData?.length === 0) return <NoData />;

  const isFinalDataAnArray = Array.isArray(finalData);
  if (!isFinalDataAnArray) return <NoData />;

  const dataWithColors = finalData?.map((data: any, index: number) => ({
    ...data,
    fill: widgetData.dataStyling?.[index % widgetData.dataStyling.length],
  }));

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${payload[0]?.payload?._name} :`}</p>
          <div className="flex items-center gap-x-1">
            <span
              className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
              style={{ backgroundColor: `${payload[0]?.payload?.fill}` }}
            ></span>
            <p>
              {payload[0].value + ' '} {widgetData.chartOptions.unit ?? ''}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };
  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <div className="flex flex-col flex-wrap justify-center h-full gap-y-2 gap-x-4 pr-[10px]">
        {payload.map((item: any, index: number) => (
          <div key={`item-${index}`} className="flex items-center gap-x-2">
            <div
              className="min-w-[38px] max-w-[38px] min-h-[18px] max-h-[18px] rounded-md"
              style={{ backgroundColor: item.color }}
            />
            <p className="text-sm max-w-[180px]">{item?.payload?._name}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%" className="isChart">
      <PieChart width={800} height={800}>
        <Pie
          dataKey="_value"
          isAnimationActive={false}
          data={dataWithColors}
          outerRadius={'55%'}
          cx={'45%'}
          label={data => `${(data.percent * 100).toFixed(1)}%`}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{
            width: '30%',
            minHeight: 200,
            height: '70%',
            overflowY: '-moz-hidden-unscrollable',
            overflowX: 'auto',
            right: '5%',
            padding: '20px 10px',
          }}
          layout="vertical"
          verticalAlign="middle"
          iconType="rect"
          align="right"
          content={<CustomLegend />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SimplePieChart;
