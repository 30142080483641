import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@/tcomponents/ui/button';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useCustomGraphContext } from '../../CustomGraphProvider';

export default function CustomFunctionFormFields() {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const handleKeyChange = (val: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.script = val;
    updateNodeData(dataCopy);
  };

  const { script } = activeNode.data;

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div
        className={
          'flex flex-col divide-y-[1px] gap-2 border p-2 text-sm rounded-md bg-white m-2' +
          (isExpanded ? ' w-full' : '')
        }
      >
        <h5 className="ml-1 font-bold">Script</h5>

        <div className="flex flex-col p-1">
          <p className="font-bold">
            function {'('} <span className="text-red-300">$data</span> {')'}{' '}
            {'{'}
          </p>
          <div className="relative">
            <textarea
              className={
                'border p-1 text-sm rounded-sm w-full self-center min-h-[360px] bg-gray-600 text-white text'
              }
              placeholder="Script"
              value={script}
              onChange={e => handleKeyChange(e.target.value)}
            />
            <Link
              className="absolute z-50 text-info top-1 right-1 hover:text-info/80"
              to={
                'https://crowdcontrolhq.atlassian.net/wiki/spaces/IUD/pages/46563353/Custom+Functions'
              }
              target="_blank"
            >
              <IconButton
                className="hover:text-info"
                icon={faCircleInfo}
                tooltip="View Documentation"
              />
            </Link>
          </div>
          <p className="font-bold">{'}'}</p>
        </div>
      </div>
    </div>
  );
}
