import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const receiverNodeApi = createApi({
  reducerPath: 'receiverNodeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  endpoints: builder => ({
    getValidReceiverNodes: builder.query<
      { data: { namespace: string; label: string; value: string }[] },
      void
    >({
      query: () => {
        return {
          url: '/transfer-nodes/receiver',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetValidReceiverNodesQuery } = receiverNodeApi;
