import React, { useCallback } from 'react';
import ReactFlow, { Background, BackgroundVariant, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

import GraphSidebar from './GraphSidebar';

import ConfigurationForm from './ConfigurationForm';
import { CustomNodeType } from './types/node-types';
import CanvasBreadCrumb from './CanvasBreadCrumb';
import { useParams } from 'react-router';
import CanvasNodeErrors from '@/pages/dashboard/projects/graph/CanvasNodeErrors';
import { useCustomGraphContext } from './CustomGraphProvider';
import { nodeTypes } from './lib/nodeTypes';
import { useGetGraph } from './hooks/useGetGraph';
import { useSaveGraph } from './hooks/useSaveGraph';

const deleteKeyCodeArray = ['Delete', 'Backspace'];

const proOptions = { hideAttribution: true };

const Graph = () => {
  const { featureId } = useParams();
  const { refetchGraph } = useGetGraph();
  const { saveGraph } = useSaveGraph({ refetchGraph });

  const {
    nodes,
    onNodesChange,
    edges,
    onEdgesChange,
    pipelineData,
    activeNode,
    setActiveNodeId,
    fullWidthSettingsView,
    setFullWidthSettingsView,
    subgraphActive,
    setSubgraphActive,
    peek,
    activePipelineNodeId,
    reactFlowWrapper,
    onConnect,
    onDragOver,
    onDrop,
    setReactFlowInstance,
    toggleNewSubGraph,
    handleNodeClick,
    deleteProcessNode,
    handleEdgeClick,
    onPanClickForEdges,
  } = useCustomGraphContext();

  const handleGraphSave = useCallback(() => {
    if (featureId) {
      const mainGraphNodes = subgraphActive ? peek().nodes : nodes;
      const mainGraphEdges = subgraphActive ? peek().edges : edges;
      const mainGraphSubgraph = subgraphActive
        ? {
            ...pipelineData,
            [peek().activeNodeId]: {
              nodes,
              edges,
            },
          }
        : pipelineData;

      saveGraph({
        featureId: Number(featureId),
        values: {
          nodes: mainGraphNodes,
          edges: mainGraphEdges,
          subGraphs: mainGraphSubgraph,
        },
      });

      toggleNewSubGraph(false);
      setSubgraphActive(false);
    }
  }, [featureId, nodes, edges, pipelineData]);

  const mainGraphNodes = subgraphActive ? peek().nodes : nodes;
  const activePipelineNode = mainGraphNodes?.find(
    n => n.id === activePipelineNodeId
  );

  return (
    <div className="flex gap-[25px] h-full mb-[15px]">
      <GraphSidebar isSubGraphActive={subgraphActive} />
      <div className="flex flex-col border shadow grow">
        <CanvasBreadCrumb
          handleGraphSave={handleGraphSave}
          subgraphActive={subgraphActive}
          toggleNewSubGraph={value => {
            toggleNewSubGraph(value);
            setFullWidthSettingsView(false);
          }}
          activePipelineNode={activePipelineNode}
        />
        <div className="flex items-center grow">
          <div
            className="flex flex-col h-full bg-white grow"
            ref={reactFlowWrapper}
          >
            {!fullWidthSettingsView && <CanvasNodeErrors nodes={nodes} />}
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitView
              // @ts-ignore
              nodeTypes={nodeTypes}
              onNodeClick={(e, node) => handleNodeClick(node.id)}
              onNodeDoubleClick={(e, node) => {
                if (node.type === CustomNodeType.Process) {
                  handleNodeClick(node.id, 'double');
                  toggleNewSubGraph(true);
                } else {
                  setFullWidthSettingsView(!fullWidthSettingsView);
                }
              }}
              onNodesDelete={deleteProcessNode}
              onPaneClick={() => {
                onPanClickForEdges();
                setActiveNodeId('');
              }}
              // onKeyDown={onKeyDown}
              onEdgeClick={(e, edge) => handleEdgeClick(edge.id)}
              deleteKeyCode={deleteKeyCodeArray}
              proOptions={proOptions}
            >
              <Background
                color="#f1f1f1"
                variant={BackgroundVariant['Lines']}
              />

              <Controls />
            </ReactFlow>
          </div>
          <div
            className={
              'h-full overflow-y-auto bg-[#FAFAFB] min-w-[250px] relative' +
              (fullWidthSettingsView ? ' w-full' : ' w-[250px] xl:w-[300px]')
            }
          >
            {activeNode ? (
              <ConfigurationForm
                isSubGraphActive={subgraphActive}
                toggleNewSubGraph={toggleNewSubGraph}
                nodeErrors={{
                  title: activeNode.error_title,
                  message: activeNode.error_message,
                }}
                toggleExpanded={{
                  callback: () =>
                    setFullWidthSettingsView(!fullWidthSettingsView),
                }}
                subGraphParentNode={activePipelineNode}
              />
            ) : (
              <div className="flex items-center justify-center h-full p-5 text-2xl font-bold text-center text-gray-400 break-words ">
                <h3>Select a node to view setup</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Graph;
