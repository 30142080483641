import React from 'react';
import MapSettingsProvider from './MapSettingsProvider';
import MapSettings from './MapSettings';

interface Props {
  children?: React.ReactNode;
}

const MapContainer = (props: Props) => {
  return (
    <MapSettingsProvider>
      <MapSettings />
    </MapSettingsProvider>
  );
};

export default MapContainer;
