import React from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { Input } from '@tcomponents/ui/input';
import { DateTimeNodeData } from '@/pages/dashboard/projects/graph/types';
import Icon from '@components/common/Icon';

const DateTimeFormFields: React.FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { source, destination, sourceFormat, destinationFormat } =
    activeNode.data as DateTimeNodeData;

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Source</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Source Field</label>
            <Input
              value={source}
              onChange={e =>
                handleSingleInputFieldChange('source', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold flex flex-row">
              Source Format
              <div title="Explore datetime formats">
                <Icon
                  className="ml-2 text-info cursor-pointer"
                  icon="fas fa-circle-info"
                  onClick={() =>
                    window.open(
                      'https://crowdcontrolhq.atlassian.net/wiki/x/AYCcB',
                      '_blank'
                    )
                  }
                />
              </div>
            </label>
            <Input
              value={sourceFormat}
              onChange={e =>
                handleSingleInputFieldChange('sourceFormat', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
        </div>
      </div>
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Destination</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Destination Field</label>
            <Input
              value={destination}
              onChange={e =>
                handleSingleInputFieldChange('destination', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold flex flex-row">
              Destination Format
              <div title="Explore datetime formats">
                <Icon
                  className="ml-2 text-info cursor-pointer"
                  icon="fas fa-circle-info"
                  onClick={() =>
                    window.open(
                      'https://crowdcontrolhq.atlassian.net/wiki/x/AYCcB',
                      '_blank'
                    )
                  }
                />
              </div>
            </label>
            <Input
              placeholder={'DD/MM/YYYY HH:mm:ss'}
              value={destinationFormat}
              onChange={e =>
                handleSingleInputFieldChange(
                  'destinationFormat',
                  e.target.value
                )
              }
              defaultValue="DD/MM/YYYY HH:mm:ss"
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateTimeFormFields;
