import React, { useMemo } from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { CustomNodeData, ProcessNodeData } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { useCustomGraphContext } from '../CustomGraphProvider';

const ProcessNode = ({ data, ...rest }: Node<ProcessNodeData>) => {
  const { pipelineData } = useCustomGraphContext();

  const childNodeNames = useMemo(() => {
    if (!(data.id in pipelineData)) return [];
    const { nodes: childNodes } = pipelineData[data.id];
    return childNodes.map(node => (node as Node<CustomNodeData>).data.heading);
  }, [pipelineData]);

  const removeTitles: string[] = [
    'Input Node',
    'Storage Node',
    'Input',
    'Storage',
  ];

  const filteredNodes: string[] = childNodeNames.filter((name: string) => {
    return !removeTitles.includes(name);
  });

  const hasRemainingNodes: boolean = filteredNodes.length >= 1;

  return (
    <CustomNode data={data} nodeProps={rest}>
      {hasRemainingNodes && (
        <div className="p-1 text-center">
          {filteredNodes.map((name: string) => (
            <p key={name}>{name}</p>
          ))}
        </div>
      )}
      <div className="font-bold p-1.5 text-white overflow-hidden rounded-b-md flex gap-x-2 items-center bg-[#61A04D] ">
        <FontAwesomeIcon icon={faDatabase} className="text-[14px] text-white" />
        <p>Storage</p>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default ProcessNode;
