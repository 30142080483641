import React, { FC } from 'react';
import TabulatorTable from '../../../components/common/Table';

interface IWorkflowLogsProps {
  id: string | number;
}

const List: FC<IWorkflowLogsProps> = ({ id }) => {
  const tableConfig = () => {
    return {
      ajaxURL: `${process.env.REACT_APP_API_BASE_URL}/features/${id}/logs`,
      resizableRows: true,
      rowHeight: 60,
      columns: [
        {
          title: '',
          field: 'log_level_colour',
          formatter: 'color',
          minWidth: '20px',
          width: '20px',
          maxWidth: '20px',
          headerSort: false,
          resizable: false,
          tooltip: function (e: any, cell: any) {
            let rowData = cell.getRow().getData();
            let el = document.createElement('div');
            el.style.backgroundColor = rowData?.log_level_colour;

            el.innerText = rowData?.log_level
              .toLowerCase()
              .split(' ')
              .map(function (word: string) {
                return word.replace(word[0], word[0].toUpperCase());
              })
              .join(' ');

            return el;
          },
        },
        { title: 'Title', field: 'title' },
        { title: 'Node', field: 'node_title' },
        {
          title: 'Data',
          field: 'data',
          sorter: 'string',
          formatter: 'textarea',
        },
        {
          title: 'Timestamp',
          sorter: 'datetime',
          sorterParams: {
            format: 'dd/MM/yyyy HH:mm:ss',
          },
          field: 'created_at',
          width: 180,
        },
      ],
      tableFilters: [
        {
          type: 'refresh',
          placeholder: 'Refresh',
        },
        {
          type: 'input',
          placeholder: 'Search',
          filterName: 'input',
          filterOn: [
            { field: 'title', type: 'like' },
            { field: 'node_title', type: 'like' },
            { field: 'data', type: 'like' },
          ],
        },
      ],
      initialSort: [{ column: 'created_at', dir: 'desc' }],
    };
  };

  return (
    <div className="flex flex-col h-full max-w-[99%]">
      <TabulatorTable id={'workflow-log'} config={tableConfig()} />
    </div>
  );
};

export default List;
