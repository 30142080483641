import React, { useContext, useEffect, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  LayersControl,
  ZoomControl,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import MapSideBar from './components/MapSideBar';
import Markers from './components/Markers';
import { createCustomClusterIcon } from './components/CustomCluster';
import { config } from './config';
import FeatureSourceBar, {
  FeatureSourceBarProps,
} from '../common/FeatureSourceBar';
import { useMapConfig } from './hooks/useMapConfig';
import { useMapData } from './hooks/useMapData';
import { useQueAndAnsVisibility } from './hooks/useQueAndAnsVisibility';
import { useCreateMarkers } from './hooks/useCreateMarkers';
import { ChangeView } from './components/ChangeView';
import OverlayRender from './components/OverlayRender';
import { useHandleOverlayVisibility } from './hooks/useHandleOverlayVisibility';
import { BoundaryRenderer } from './components/BoundaryRenderer';
import { useHandleBoundaryVisibility } from './hooks/useHandleBoundaryVisibility';
import { MapSearchBar } from './components/MapSearchBar';
import { FullscreenContext } from '@/context/FullscreenContext';
import { IMapConfigResponse, IMapDataResponse } from './types/types';
import { MapEvents } from './components/MapEvents';
import { FeatureCollection } from 'geojson';

interface IMapViewProps {
  id?: string | number;
  setLastUpdatedForMap?: React.Dispatch<React.SetStateAction<string>>;
  project?: any;
  feature?: any;
  isPublicView?: boolean;
  mapConfigFromProps?: IMapConfigResponse;
  mapDataFromProps?: IMapDataResponse;
  mapShapesResponseFromProps?: any;
  aggregatedDataFromProps?: any;
}

export default function MapView(props: IMapViewProps) {
  const {
    id,
    project,
    feature,
    setLastUpdatedForMap,
    isPublicView,
    mapConfigFromProps,
    mapDataFromProps,
    mapShapesResponseFromProps,
    aggregatedDataFromProps,
  } = props;

  const [currentZoom, setCurrentZoom] = useState(0);
  const [enabledBoundaryShapeFileData, setEnabledBoundaryShapeFileData] =
    useState<FeatureCollection | undefined>(undefined);
  const { handle } = useContext(FullscreenContext);

  const isFullscreen = handle.active;

  const {
    mapConfigResponse: mapConfigFromApi,
    selectedQuestionsMeta: selectedQuestionsMetaFromApi,
    includeValueField: includeValueFieldFromApi,
  } = useMapConfig(id as string, isPublicView);

  const mapConfigResponse = mapConfigFromProps || mapConfigFromApi;

  const selectedQuestionsMeta = mapConfigFromProps
    ? mapConfigFromProps.data.selectedQuestionsMeta
    : selectedQuestionsMetaFromApi;

  const includeValueField = mapConfigFromProps
    ? mapConfigFromProps.data.detailView.includeValueField
    : includeValueFieldFromApi;

  let cluster = mapConfigResponse?.data?.cluster ?? true;
  const overlays = mapConfigResponse?.data?.overlays;
  const boundaries = mapConfigResponse?.data?.boundaries;
  const shouldShowSearchBar =
    mapConfigResponse?.data?.display?.searchBar !== false && !isFullscreen;

  const {
    overlayVisibility,
    toggleOverlayVisibility,
    overlaysLoading,
    setOverlaysLoadingState,
  } = useHandleOverlayVisibility(overlays);

  const {
    boundariesVisibility,
    toggleBoundaryVisibility,
    boundariesLoading,
    setBoundariesLoadingState,
    visibleBoundaryConfigData,
  } = useHandleBoundaryVisibility(boundaries);

  const {
    mapDataResponse,
    information,
    sources,
    postcodesWithCoords,
    initialCoordsForMap,
  } = useMapData(
    id as string,
    mapConfigResponse,
    isPublicView,
    mapDataFromProps
  );

  const {
    questionsAndAnswersVisibility,
    visibleQuestion,
    toggleQuestionAndAnswersVisibility,
  } = useQueAndAnsVisibility(selectedQuestionsMeta);

  const { markersData } = useCreateMarkers(
    mapConfigResponse,
    mapDataResponse,
    includeValueField,
    selectedQuestionsMeta,
    questionsAndAnswersVisibility,
    visibleQuestion,
    postcodesWithCoords
  );
  // we want to zoom into the initial boundary if there are no markers
  const haveMarkers = !!selectedQuestionsMeta?.length;
  // When the zoom level is equal or greater than the “Visibility Zoom Level” then the markers are displayed
  const visibilityZoomLevel =
    mapConfigResponse?.data?.layout?.visibilityZoomLevel ?? 0;
  const shouldShowMarkers = currentZoom >= Number(visibilityZoomLevel);

  // effect to set last updated for map in the parent component
  useEffect(() => {
    setLastUpdatedForMap &&
      setLastUpdatedForMap(mapDataResponse?.last_updated || '');
  }, [mapDataResponse?.last_updated, setLastUpdatedForMap]);
  return (
    <>
      <div className="flex w-full h-full overflow-x-hidden">
        <MapContainer {...config.settings} className="z-10 w-full h-full">
          <MapEvents setCurrentZoom={setCurrentZoom} />
          <ChangeView coordinates={initialCoordsForMap} />
          <LayersControl position="topleft">
            {config.layers.map((layer, index) => {
              return (
                <LayersControl.BaseLayer
                  key={index}
                  checked={index === 0 ? true : false}
                  name={layer.name}
                >
                  <TileLayer attribution={layer.attribution} url={layer.url} />
                </LayersControl.BaseLayer>
              );
            })}
          </LayersControl>
          <ZoomControl position="topright" zoomInText="+" zoomOutText="-" />
          {shouldShowMarkers &&
            (cluster !== false ? (
              <MarkerClusterGroup
                chunkedLoading
                iconCreateFunction={createCustomClusterIcon}
                maxClusterRadius={10}
              >
                <Markers data={markersData} />
              </MarkerClusterGroup>
            ) : (
              <Markers data={markersData} />
            ))}
          {overlays?.map(overlay => (
            <OverlayRender
              key={overlay.id}
              overlay={overlay}
              overlayVisibility={overlayVisibility}
              setOverlaysLoadingState={setOverlaysLoadingState}
              isPublicView={isPublicView}
              mapShapesResponseFromProps={mapShapesResponseFromProps}
            />
          ))}
          {boundaries?.map(boundary => (
            <BoundaryRenderer
              key={boundary.id}
              boundary={boundary}
              boundariesVisibility={boundariesVisibility}
              setBoundariesLoadingState={setBoundariesLoadingState}
              isPublicView={isPublicView}
              mapShapesResponseFromProps={mapShapesResponseFromProps}
              aggregatedDataFromProps={aggregatedDataFromProps}
              haveMarkers={haveMarkers}
              setEnabledBoundaryShapeFileData={setEnabledBoundaryShapeFileData}
            />
          ))}
          {shouldShowSearchBar && mapConfigResponse && (
            <MapSearchBar
              enabledBoundaryShapeFileData={enabledBoundaryShapeFileData}
              visibleBoundaryConfigData={visibleBoundaryConfigData}
            />
          )}
        </MapContainer>
        {!isPublicView && mapConfigResponse && (
          <MapSideBar
            mapConfigResponse={mapConfigResponse}
            allQuestions={selectedQuestionsMeta || []}
            questionsAndAnswersVisibility={questionsAndAnswersVisibility}
            toggleQuestionAndAnswersVisibility={
              toggleQuestionAndAnswersVisibility
            }
            overlayVisibility={overlayVisibility}
            toggleOverlayVisibility={toggleOverlayVisibility}
            boundariesVisibility={boundariesVisibility}
            toggleBoundaryVisibility={toggleBoundaryVisibility}
            overlaysLoading={overlaysLoading}
            boundariesLoading={boundariesLoading}
          />
        )}
      </div>
      <FeatureSourceBar
        information={information as string}
        sources={sources as FeatureSourceBarProps['sources']}
        project={project}
        feature={feature}
      />
    </>
  );
}
