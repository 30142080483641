import React from 'react';
import { ReactFlowProvider } from 'reactflow';
import Graph from './Graph';
import CustomGraphProvider from './CustomGraphProvider';

interface Props {
  children?: React.ReactNode;
}

export default function GraphContainer(props: Props) {
  return (
    <ReactFlowProvider>
      <CustomGraphProvider>
        <Graph />
      </CustomGraphProvider>
    </ReactFlowProvider>
  );
}
