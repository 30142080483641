import React, { useCallback, useMemo } from 'react';
import { JoinNodeData, ProcessNodeData } from '../../types/node-types';
import { getIncomers, Node, useEdges, useNodes, useReactFlow } from 'reactflow';
import { Input } from '@/tcomponents/ui/input';
import { Switch } from '@/tcomponents/ui/switch';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { IconButton } from '@/tcomponents/ui/button';
import { useCustomGraphContext } from '../../CustomGraphProvider';

function determineArgumentValue(item: Node<unknown>) {
  const node = item as Node<ProcessNodeData>;
  return {
    ...node,
    data: {
      ...node.data,
      argument: node.data.isArgumentEdited
        ? node.data.argument
        : node.data.heading,
    },
  };
}

export function JoinNodeFormFields() {
  const {
    updateNodeData: stateUpdater,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();
  const { setNodes } = useReactFlow();
  let nodes = useNodes();
  const edges = useEdges();

  const incomingNodes = useMemo(() => {
    const self = nodes.find(node => node.id === activeNode.id);
    if (!self) return [];
    return getIncomers(self, nodes, edges).map(determineArgumentValue);
  }, [nodes, edges, activeNode.id]);

  const updateNodeData = useCallback(
    (data: ProcessNodeData) => {
      const copy = [...nodes];
      const node = copy.find(n => n.id === data.id) as Node<ProcessNodeData>;
      if (!node) {
        return;
      }

      // Reset the isEdited flag to default the variable name back to the node name
      if (data.argument.length === 0) {
        data.isArgumentEdited = false;
      }

      node.data = {
        ...node.data,
        ...data,
      };
      setNodes(copy);
    },
    [nodes, setNodes]
  );
  const sanitizedInputValue = (val: string) => {
    const value = val
      ?.replace(/[^A-Za-z0-9\-_.\s]/g, '') // Remove non-alphanumeric characters and underscores
      ?.replace(/^[0-9$]/, '') // Remove characters starting with a number or $
      // Need to match the camel case logic where hyphen, underscore, period and space are case changing chars
      ?.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

    // Lowercase first character
    return value?.charAt(0).toLowerCase() + value?.slice(1);
  };

  const connectedNodesArguments = useMemo(() => {
    return incomingNodes.map(node => {
      const processNode = node as Node<ProcessNodeData>;
      return sanitizedInputValue(
        processNode.data.argument || processNode.data.heading
      );
    });
  }, [incomingNodes]);

  const handleKeyChange = (val: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.script = val;
    stateUpdater(dataCopy);
  };

  const { script } = activeNode.data as JoinNodeData;

  return (
    <div className={'flex' + (isExpanded ? ' flex-wrap' : ' flex-col')}>
      {incomingNodes.map(node => {
        const processNode = node as Node<ProcessNodeData>;
        return (
          <div
            key={processNode.id}
            className={isExpanded ? ' w-1/2' : ' w-full'}
          >
            <div
              className={
                'flex flex-col divide-y-[1px] gap-y-3 border p-4 text-sm rounded-md bg-white m-2'
              }
            >
              <h6 className="font-bold">{processNode.data.heading}</h6>
              <div className="flex flex-col pt-2 gap-y-3">
                <div className="flex flex-col gap-y-1">
                  <p>Argument</p>
                  <Input
                    onChange={e => {
                      const newData = {
                        ...processNode.data,
                        argument: sanitizedInputValue(e.target.value),
                        isArgumentEdited: true,
                      };
                      processNode.data = newData;
                      updateNodeData(newData);
                    }}
                    key={processNode.id}
                    value={sanitizedInputValue(processNode.data.argument)}
                    placeholder='camelCase (Example: "myArgument")'
                  />
                </div>
                <div className="flex items-center gap-x-4">
                  <p>Trigger</p>
                  <div className="flex items-center gap-x-2">
                    <Switch
                      checked={processNode.data.trigger}
                      onClick={() => {
                        const newData = {
                          ...processNode.data,
                          trigger: !processNode.data.trigger,
                        };
                        processNode.data = newData;
                        updateNodeData(newData);
                      }}
                    />
                    <p>{processNode.data.trigger ? 'On' : 'Off'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="w-full p-2">
        <div className="flex flex-col divide-y-[1px] gap-2 border p-2 text-sm rounded-md bg-white w-full">
          <h5 className="ml-1 font-bold">Script</h5>

          <div className="flex flex-col p-1">
            <p className="font-bold">
              function {'('}{' '}
              <span className="text-red-300">
                {connectedNodesArguments.map(arg => '$' + arg).join(', ')}
              </span>{' '}
              {')'} {'{'}
            </p>
            <div className="relative">
              <textarea
                className="p-1 text-sm rounded-sm w-full min-h-[360px] bg-gray-600 text-white text"
                placeholder="Script"
                value={script}
                onChange={e => handleKeyChange(e.target.value)}
              />
              <Link
                className="absolute z-50 text-info top-1 right-1 hover:text-info/80"
                to={
                  'https://crowdcontrolhq.atlassian.net/wiki/spaces/IUD/pages/46596109/Join+Functions'
                }
                target="_blank"
              >
                <IconButton
                  className="hover:text-info"
                  icon={faCircleInfo}
                  tooltip="View Documentation"
                />
              </Link>
            </div>
            <p className="font-bold">{'}'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
