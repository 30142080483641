import React, { useContext, useEffect } from 'react';
import { BorealisNodeData } from '../../types/node-types';
import { toast } from '@tcomponents/ui/toast/use-toast';
import { Input } from '@tcomponents/ui/input';
import { Label } from '@tcomponents/ui/label';
import { Switch } from '@tcomponents/ui/switch';
import { Textarea } from '@tcomponents/ui/textarea';
import { Button } from '@tcomponents/ui/button';
import { SaveActionContext } from '@/context/SaveActionContext';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import {
  useGetGraphQuery,
  useLazyTriggerNodeQuery,
  useResetNodeOffsetMutation,
} from '@/store/services/projects/graphApi';
import { useParams } from 'react-router';

const BorealisNodeFormFields = () => {
  const { setIsDirty } = useContext(SaveActionContext);
  const { showDialog } = useContext(ModalContext);

  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  const [triggerNode] = useLazyTriggerNodeQuery();
  const triggerNodeFn = async () => {
    setIsDirty(false);
    triggerNode(activeNode.id)
      .then((response: any) => {
        if (response.isSuccess) {
          toast({
            duration: 3000,
            title: 'Success!',
            description: response.data.success,
          });
        }
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong',
          description: 'Unable to trigger node.',
        });
      });
  };

  const {
    engagementId,
    enabled,
    offset,
    descriptionTitle,
    descriptionUrl,
    description,
    license,
    reviewDate,
  } = activeNode.data as BorealisNodeData;

  const [resetNodeOffset, { data, isSuccess, isError }] =
    useResetNodeOffsetMutation(activeNode.id);

  const { featureId } = useParams();
  const { refetch } = useGetGraphQuery(Number(featureId), {
    skip: !featureId,
  });

  const showOffsetResetModal = () => {
    showDialog({
      title: 'Confirm offset reset',
      message:
        'Are you sure you want to reset the offset back to 0, this cannot be undone?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => callresetNodeOffsetRequest(activeNode.id),
      },
    });
  };

  const callresetNodeOffsetRequest = async (id: string) => {
    await resetNodeOffset(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        duration: 3000,
        title: 'Success!',
        description: data.success,
      });
      refetch();
    }
    if (isError) {
      toast({
        duration: 24 * 60 * 60000,
        className: 'bg-red-500',
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
      });
    }
  }, [isSuccess, isError, refetch]);

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      {/* configuration block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Configuration</h5>
            <button
              className="px-3 py-1 text-sm font-bold text-white bg-yellow-500 rounded-sm"
              onClick={triggerNodeFn}
              data-testing="trigger"
              aria-label="trigger"
            >
              Trigger
            </button>
          </div>
          <div className="flex flex-col py-2 gap-y-2">
            <div className={'flex flex-row'}>
              <div className="flex flex-col flex-1 py-2 gap-y-2">
                <label className="text-xs font-bold">
                  Engagement ID{' '}
                  <i
                    className={'fas fa-brackets-curly text-warning-error fa-xl'}
                  />
                </label>
                <Input
                  value={engagementId}
                  onChange={e =>
                    handleSingleInputFieldChange('engagementId', e.target.value)
                  }
                  className={
                    'flex flex-1 bg-white grow' +
                    (isExpanded ? 'flex-none mr-3' : 'mr-0 w-[220px]')
                  }
                />
              </div>
              <div className="flex flex-col flex-1 py-2 gap-y-2"></div>
            </div>
            <div className={'flex flex-row'}>
              <div className="flex flex-col flex-1 py-2 gap-y-2">
                <div className="flex flex-col gap-y-2">
                  <label className="text-xs font-bold">Enabled</label>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={enabled}
                      onCheckedChange={val =>
                        handleSingleInputFieldChange('enabled', val)
                      }
                      id="isEnabled"
                    />
                    <Label htmlFor="isEnabled">{enabled ? 'Yes' : 'No'}</Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sync block */}
      <div
        className={
          'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
          (isExpanded ? ' w-1/2' : ' w-full')
        }
      >
        <div className="flex items-center justify-between">
          <h5 className="font-bold ml-1 text-[15px]">Sync</h5>
          <Button
            variant="danger"
            size="sm"
            onClick={showOffsetResetModal}
            icon={faRotateLeft}
          >
            Reset
          </Button>
        </div>
        <div className="flex flex-col py-2 gap-y-2">
          <div className={'flex flex-row'}>
            <div className="flex flex-col flex-1 py-2 gap-y-2">
              <label className="text-xs font-bold">Offset</label>
              <Input
                value={offset}
                className={
                  'flex flex-1 bg-white grow' +
                  (isExpanded ? 'flex-none mr-3' : 'mr-0 w-[220px]')
                }
                type="number"
                readOnly={true}
              />
            </div>
            <div className="flex flex-col flex-1 py-2 gap-y-2"></div>
          </div>
        </div>
      </div>
      {/* Source Details block */}
      <div
        className={
          'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
          (isExpanded ? ' w-1/2' : ' w-full')
        }
      >
        <h5 className="pb-1 font-bold">Source Details</h5>
        <div className="flex flex-col py-2 gap-y-4">
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Title</label>
            <Input
              value={descriptionTitle}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionTitle',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">URL</label>
            <Input
              value={descriptionUrl}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionUrl',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Description</label>
            <Textarea
              value={description}
              onChange={e =>
                handleSingleInputFieldChange(
                  'description',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">License</label>
            <Input
              value={license}
              onChange={e =>
                handleSingleInputFieldChange('license', e.target.value)
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Review Date</label>
            <Input
              value={reviewDate}
              onChange={e =>
                handleSingleInputFieldChange('reviewDate', e.target.value)
              }
              className="flex flex-1 bg-white"
              type="date"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BorealisNodeFormFields;
