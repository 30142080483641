import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '@/store/utils/createErrorHandler';

export const rankedListApi = createApi({
  reducerPath: 'rankedListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Ranked-list'],
  endpoints: builder => ({
    getRankedListConfig: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/ranked-list',
          method: 'GET',
        };
      },
    }),
    getRankedListData: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/ranked-list-data',
          method: 'GET',
        };
      },
    }),
    getDataFromDataStore: builder.query({
      query: (nodeUuid: string) => {
        return {
          url: '/graph/data/' + nodeUuid,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
      // @ts-ignore
      onQueryStarted: createErrorHandler(
        'There was a problem loading data from data store'
      ),
    }),
  }),
});

export const {
  useGetRankedListConfigQuery,
  useGetRankedListDataQuery,
  useGetDataFromDataStoreQuery,
} = rankedListApi;
