import { useMemo } from 'react';
import { Button, IconButton } from '@tcomponents/ui/button';
import { Card, CardContent } from '@/tcomponents/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import {
  faCirclePlus,
  faEllipsisVertical,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';
import { Input } from '@/tcomponents/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { Label } from '@/tcomponents/ui/label';
import { cn } from '@/lib/utils';
import { Draggable } from 'react-beautiful-dnd';
import { Switch } from '@/tcomponents/ui/switch';
import ReactSelect from 'react-select';
import { useMapSettingsContext } from '../MapSettingsProvider';
import {
  BoundaryType,
  boundaryTypes,
  layerTypes,
} from '../hooks/useHandleBoundaryTab';
import ColorPicker from '@/tcomponents/custom/ColorPicker';
import { useParams } from 'react-router-dom';
import {
  useGetDataStoresQuery,
  useGetRawDataFromDataStoreQuery,
  useGetWorkflowsQuery,
} from '@/store/services/projects/chartApi';
import { getKeys } from '../../utils/helperFunctions';
import { useAggregateListDataQuery } from '@/store/services/aggregationApi';
import { useEffect } from 'react';

type Props = {
  boundary: BoundaryType;
  idx: number;
  handleSingleFieldValueChange: (
    idx: number,
    field: keyof BoundaryType,
    value: string | boolean | { value: string; label: string }[]
  ) => void;
  handleBoundaryTypeChange: (
    idx: number,
    type: keyof typeof boundaryTypes
  ) => void;
  handleDataStoreChange: (
    idx: number,
    value: string,
    filteredDataStores: { value: string; text: string; format: string }[]
  ) => void;
  deleteBoundary: (idx: number) => void;
  addBoundary: (idx: number) => void;
  isDragging: boolean;
  handleThemingOnKeysFromOrValuesFromChange: (
    idx: number,
    uniqueStringsArray: string[]
  ) => void;
  handleThemingColorChange: (
    idx: number,
    themingIdx: number,
    color: string
  ) => void;
};

export const Boundary = (props: Props) => {
  const { allShapesResponse } = useMapSettingsContext();
  const {
    boundary,
    idx,
    handleSingleFieldValueChange,
    handleBoundaryTypeChange,
    handleDataStoreChange,
    deleteBoundary,
    addBoundary,
    isDragging,
    handleThemingOnKeysFromOrValuesFromChange,
    handleThemingColorChange,
  } = props;
  const boundarySystemShapeFiles = allShapesResponse?.data?.filter(
    shape => shape.type === layerTypes.Boundary
  );

  const selectedShapeFileId = Number(boundary?.shapeFile);
  const selectedShapeFile = boundarySystemShapeFiles?.find(
    shape => shape.id === selectedShapeFileId
  );

  const reactSelectOptions = selectedShapeFile?.properties?.map(
    (property: string) => ({
      value: property,
      label: property,
    })
  );
  const isSimpleBoundaryType = boundary.type === boundaryTypes.Simple;
  const isHeatmapBoundaryType = boundary.type === boundaryTypes.Heatmap;
  const isCategoryBoundaryType = boundary.type === boundaryTypes.Category;
  const isLookupCalculation = boundary.calculation === 'lookup' ?? false;

  const { projectId } = useParams<{ projectId: string }>();

  const workflows = useGetWorkflowsQuery(parseInt(projectId as string));
  const dataStores = useGetDataStoresQuery(parseInt(boundary.workflow), {
    skip: !boundary.workflow,
  });
  const filteredDataStores = dataStores?.data?.data?.filter(
    (dataStore: { format: string }) =>
      dataStore.format === 'list' || dataStore.format === 'row'
  );
  const selectedDataStore = filteredDataStores?.find(
    (dataStore: { value: string }) => dataStore.value === boundary.dataStore
  );
  const selectedDataStoreType = selectedDataStore?.format;
  const isRowTypeDataStore = selectedDataStoreType === 'row';

  const { data: dataFromDataStoreResponse } = useGetRawDataFromDataStoreQuery(
    boundary.dataStore,
    {
      skip: !boundary.dataStore,
    }
  );
  const dataFromDataStore = dataFromDataStoreResponse?.data;
  const dataKeys = getKeys(dataFromDataStore);

  const { data } = useAggregateListDataQuery(
    {
      data: {
        values_from: Array.isArray(boundary.valuesFrom)
          ? boundary.valuesFrom
          : boundary.valuesFrom?.split(','),
        group_by: '',
        keys_from: boundary.keysFrom ?? '',
        calculation: boundary.calculation ?? 'lookup',
        data_store_id: boundary.dataStore,
        sort_by: 'asc',
      },
      endpoint: 'list',
    },
    {
      skip: !boundary.keysFrom || !boundary.valuesFrom,
    }
  );
  const uniqueKeysArray = useMemo(
    () =>
      Array.from(
        new Set(
          data?.data?.map(
            (item: { _name: string; _value: string }) => item._value
          ) as string[]
        )
      ),
    [data]
  );
  useEffect(() => {
    if (isCategoryBoundaryType && uniqueKeysArray?.length) {
      handleThemingOnKeysFromOrValuesFromChange(idx, uniqueKeysArray);
    }
  }, [uniqueKeysArray]);

  return (
    <Draggable key={idx} draggableId={idx.toString()} index={idx}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div data-id={boundary.id} className="relative flex">
            {/* drag handle */}
            <div
              {...provided.dragHandleProps}
              className="w-[30px] rounded-l-md bg-gray-200 draggable-handle cursor-move flex items-center justify-center "
            >
              <FontAwesomeIcon
                icon={faGripDotsVertical}
                className="p-2 text-white text-[20px]"
              />
            </div>
            <Card className="relative border-none shadow-none grow">
              <CardContent className="p-6">
                <div>
                  {/* title, startEnabled and color */}
                  <div className="flex justify-between pr-5 gap-x-[30px] grow">
                    <div className="flex flex-col grow gap-y-1 ">
                      <Label>Title</Label>
                      <Input
                        value={boundary.title}
                        onChange={e =>
                          handleSingleFieldValueChange(
                            idx,
                            'title',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex items-center px-4 gap-x-8">
                      <div className="flex flex-col gap-y-3">
                        <Label>Start Enabled</Label>
                        <Switch
                          checkedLabel="On"
                          unCheckedLabel="Off"
                          checked={boundary.startEnabled}
                          onCheckedChange={checked => {
                            handleSingleFieldValueChange(
                              idx,
                              'startEnabled',
                              checked
                            );
                          }}
                        />
                      </div>
                      {isSimpleBoundaryType && (
                        <div className="flex flex-col gap-y-1">
                          <Label>Colour</Label>
                          <ColorPicker
                            value={boundary.color}
                            onChange={color =>
                              handleSingleFieldValueChange(idx, 'color', color)
                            }
                          />
                        </div>
                      )}
                      <div className=" w-[170px]">
                        <Label> Type</Label>
                        <Select
                          value={boundary.type}
                          onValueChange={(
                            value: keyof typeof boundaryTypes
                          ) => {
                            handleBoundaryTypeChange(idx, value);
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Type" />
                          </SelectTrigger>
                          <SelectContent className="w-full">
                            <SelectGroup
                              className={'overflow-y-auto max-h-96 w-full'}
                            >
                              <SelectItem
                                key={boundaryTypes.Simple}
                                value={boundaryTypes.Simple}
                              >
                                Simple
                              </SelectItem>
                              <SelectItem
                                key={boundaryTypes.Heatmap}
                                value={boundaryTypes.Heatmap}
                              >
                                Heatmap
                              </SelectItem>
                              <SelectItem
                                key={boundaryTypes.Category}
                                value={boundaryTypes.Category}
                              >
                                Category
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                  {/* popover to delete ovelay */}
                  <div className="absolute top-4 right-4">
                    <Popover>
                      <PopoverTrigger tabIndex={-1}>
                        <IconButton
                          tabIndex={-1}
                          icon={faEllipsisVertical}
                          tooltip="Actions"
                          className="pl-5 py-[3px]"
                        />
                      </PopoverTrigger>
                      <PopoverContent side="left" className="p-0 w-[100px]">
                        <PopoverClose>
                          <Button
                            icon={faTrashCan}
                            variant="danger"
                            className="font-normal text-[14px] w-full "
                            onClick={() => deleteBoundary(idx)}
                          >
                            Delete
                          </Button>
                        </PopoverClose>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div className="flex flex-wrap py-4 bg-white gap-x-8">
                  <div className="w-1/6">
                    <Label> Shape Source</Label>
                    <Select
                      value={boundary.shapeSource}
                      onValueChange={value => {
                        handleSingleFieldValueChange(idx, 'shapeSource', value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select File" />
                      </SelectTrigger>
                      <SelectContent className="w-full">
                        <SelectGroup
                          className={'overflow-y-auto max-h-96 w-full'}
                        >
                          <SelectItem key={'System'} value={'System'}>
                            System
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-1/6">
                    <Label> Shape File</Label>
                    <Select
                      value={boundary.shapeFile}
                      onValueChange={value => {
                        if (boundary.shapeSource === 'System') {
                          handleSingleFieldValueChange(idx, 'shapeFile', value);
                        }
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select File" />
                      </SelectTrigger>
                      <SelectContent className="w-full">
                        <SelectGroup
                          className={'overflow-y-auto max-h-96 w-full'}
                        >
                          {boundarySystemShapeFiles
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map(shape => (
                              <SelectItem
                                key={shape.id}
                                value={String(shape.id)}
                              >
                                {shape.name}
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  {(isHeatmapBoundaryType || isCategoryBoundaryType) && (
                    <div className="w-1/6">
                      <Label> Key Property</Label>
                      <Select
                        value={boundary.keyProperty ?? ''}
                        onValueChange={value => {
                          handleSingleFieldValueChange(
                            idx,
                            'keyProperty',
                            value
                          );
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Key Property" />
                        </SelectTrigger>
                        <SelectContent className="w-full">
                          <SelectGroup
                            className={'overflow-y-auto max-h-96 w-full'}
                          >
                            {selectedShapeFile?.properties?.map((prop, idx) => (
                              <SelectItem key={idx} value={prop}>
                                {prop}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                  <div className="flex items-center grow gap-x-8">
                    <div className="w-1/2">
                      <Label> Hover Properties</Label>
                      <ReactSelect
                        isMulti
                        options={reactSelectOptions}
                        value={boundary.hoverProperties.filter(
                          item => item.value
                        )}
                        onChange={value => {
                          handleSingleFieldValueChange(
                            idx,
                            'hoverProperties',
                            value as [{ value: string; label: string }]
                          );
                        }}
                      />
                    </div>
                    <div className="w-1/2">
                      <Label>Search Property</Label>
                      <Select
                        value={boundary.searchProperty ?? ''}
                        onValueChange={value => {
                          handleSingleFieldValueChange(
                            idx,
                            'searchProperty',
                            value
                          );
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select Search Property" />
                        </SelectTrigger>
                        <SelectContent className="w-full">
                          <SelectGroup
                            className={'overflow-y-auto max-h-96 w-full'}
                          >
                            {selectedShapeFile?.properties?.map((prop, idx) => (
                              <SelectItem key={idx} value={prop}>
                                {prop}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
                {isHeatmapBoundaryType && (
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-wrap py-2 bg-white border-t gap-x-8">
                      <div className="w-[23.4%]">
                        <Label>Workflow</Label>
                        <Select
                          value={boundary.workflow ?? ''}
                          onValueChange={value => {
                            handleSingleFieldValueChange(
                              idx,
                              'workflow',
                              value
                            );
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Workflow" />
                          </SelectTrigger>
                          <SelectContent className="w-full">
                            <SelectGroup
                              className={'overflow-y-auto max-h-96 w-full'}
                            >
                              <SelectLabel>Workflows</SelectLabel>
                              {workflows?.data?.data.map(
                                (
                                  workflow: { value: string; text: string },
                                  index: number
                                ) => (
                                  <SelectItem
                                    key={`workflow-${index} - ${workflow.value}`}
                                    value={workflow.value}
                                  >
                                    {' '}
                                    {workflow.text}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="w-[23.4%]">
                        <Label>Data Store</Label>
                        <Select
                          disabled={!boundary.workflow}
                          value={boundary.dataStore ?? ''}
                          onValueChange={value => {
                            handleDataStoreChange(
                              idx,
                              value,
                              filteredDataStores
                            );
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue
                              placeholder={
                                boundary.dataStore
                                  ? 'Select a Data Store'
                                  : 'Choose a workflow to select a Data Store'
                              }
                            />
                          </SelectTrigger>
                          <SelectContent className="w-full">
                            <SelectGroup
                              className={'overflow-y-auto max-h-96 w-full'}
                            >
                              <SelectLabel>Data Store</SelectLabel>
                              {filteredDataStores?.map(
                                (
                                  dataStore: { value: string; text: string },
                                  index: number
                                ) => (
                                  <SelectItem
                                    key={`dataStore-${index} - ${dataStore.value}`}
                                    value={dataStore.value}
                                  >
                                    {' '}
                                    {dataStore.text}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      {isRowTypeDataStore && (
                        <>
                          <div className="w-[23.4%]">
                            <Label>Calculation</Label>
                            <Select
                              value={boundary.calculation ?? ''}
                              onValueChange={value => {
                                handleSingleFieldValueChange(
                                  idx,
                                  'calculation',
                                  value
                                );
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select Calculation" />
                              </SelectTrigger>
                              <SelectContent className="w-full">
                                <SelectGroup
                                  className={'overflow-y-auto max-h-96 w-full'}
                                >
                                  <SelectItem key={'Count'} value={'count'}>
                                    Count
                                  </SelectItem>
                                  <SelectItem key={'Lookup'} value={'lookup'}>
                                    Lookup
                                  </SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="w-[23.4%]">
                            <Label>Values From</Label>
                            <Select
                              value={boundary.valuesFrom ?? ''}
                              onValueChange={value => {
                                handleSingleFieldValueChange(
                                  idx,
                                  'valuesFrom',
                                  value
                                );
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select Values From" />
                              </SelectTrigger>
                              <SelectContent className="w-full">
                                <SelectGroup
                                  className={'overflow-y-auto max-h-96 w-full'}
                                >
                                  {dataKeys.map((dataKey, index) => (
                                    <SelectItem
                                      key={`dataKey-${index} - ${dataKey}`}
                                      value={dataKey}
                                    >
                                      {dataKey}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="flex items-center gap-x-8">
                      {isRowTypeDataStore && isLookupCalculation && (
                        <div className="w-[23.4%]">
                          <Label>Keys From</Label>
                          <Select
                            value={boundary.keysFrom ?? ''}
                            onValueChange={value => {
                              handleSingleFieldValueChange(
                                idx,
                                'keysFrom',
                                value
                              );
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select Keys From" />
                            </SelectTrigger>
                            <SelectContent className="w-full">
                              <SelectGroup
                                className={'overflow-y-auto max-h-96 w-full'}
                              >
                                {dataKeys.map((dataKey, index) => (
                                  <SelectItem
                                    key={`dataKey-${index} - ${dataKey}`}
                                    value={dataKey}
                                  >
                                    {dataKey}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                      <div className="flex flex-col w-1/5 gap-y-1 ">
                        <Label>Units</Label>
                        <Input
                          value={boundary.units ?? ''}
                          onChange={e =>
                            handleSingleFieldValueChange(
                              idx,
                              'units',
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-y-1">
                        <Label>Min Colour</Label>
                        <ColorPicker
                          value={boundary.minColor ?? '#265F81'}
                          onChange={color =>
                            handleSingleFieldValueChange(idx, 'minColor', color)
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-y-1">
                        <Label>Max Colour</Label>
                        <ColorPicker
                          value={boundary.maxColor ?? '#DB2828'}
                          onChange={color =>
                            handleSingleFieldValueChange(idx, 'maxColor', color)
                          }
                        />
                      </div>
                    </div>
                    {isRowTypeDataStore && isLookupCalculation && (
                      <div className="grow">
                        <Label>Hover Properties</Label>
                        <ReactSelect
                          isMulti
                          options={dataKeys.map((dataKey, index) => ({
                            label: dataKey,
                            value: dataKey,
                          }))}
                          value={boundary.dataStoreHoverKeys?.filter(
                            item => item.value
                          )}
                          onChange={value => {
                            handleSingleFieldValueChange(
                              idx,
                              'dataStoreHoverKeys',
                              value as [{ value: string; label: string }]
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {isCategoryBoundaryType && (
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-wrap py-2 bg-white border-t gap-x-8">
                      <div className="w-[23.4%]">
                        <Label>Workflow</Label>
                        <Select
                          value={boundary.workflow ?? ''}
                          onValueChange={value => {
                            handleSingleFieldValueChange(
                              idx,
                              'workflow',
                              value
                            );
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select Workflow" />
                          </SelectTrigger>
                          <SelectContent className="w-full">
                            <SelectGroup
                              className={'overflow-y-auto max-h-96 w-full'}
                            >
                              <SelectLabel>Workflows</SelectLabel>
                              {workflows?.data?.data.map(
                                (
                                  workflow: { value: string; text: string },
                                  index: number
                                ) => (
                                  <SelectItem
                                    key={`workflow-${index} - ${workflow.value}`}
                                    value={workflow.value}
                                  >
                                    {' '}
                                    {workflow.text}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="w-[23.4%]">
                        <Label>Data Store</Label>
                        <Select
                          disabled={!boundary.workflow}
                          value={boundary.dataStore ?? ''}
                          onValueChange={value => {
                            handleDataStoreChange(
                              idx,
                              value,
                              filteredDataStores
                            );
                          }}
                        >
                          <SelectTrigger>
                            <SelectValue
                              placeholder={
                                boundary.dataStore
                                  ? 'Select a Data Store'
                                  : 'Choose a workflow to select a Data Store'
                              }
                            />
                          </SelectTrigger>
                          <SelectContent className="w-full">
                            <SelectGroup
                              className={'overflow-y-auto max-h-96 w-full'}
                            >
                              <SelectLabel>Data Store</SelectLabel>
                              {filteredDataStores?.map(
                                (
                                  dataStore: { value: string; text: string },
                                  index: number
                                ) => (
                                  <SelectItem
                                    key={`dataStore-${index} - ${dataStore.value}`}
                                    value={dataStore.value}
                                  >
                                    {' '}
                                    {dataStore.text}
                                  </SelectItem>
                                )
                              )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      {isRowTypeDataStore && (
                        <>
                          <div className="w-[23.4%]">
                            <Label>Keys From</Label>
                            <Select
                              value={boundary.keysFrom ?? ''}
                              onValueChange={value => {
                                handleSingleFieldValueChange(
                                  idx,
                                  'keysFrom',
                                  value
                                );
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select Keys From" />
                              </SelectTrigger>
                              <SelectContent className="w-full">
                                <SelectGroup
                                  className={'overflow-y-auto max-h-96 w-full'}
                                >
                                  {dataKeys.map((dataKey, index) => (
                                    <SelectItem
                                      key={`dataKey-${index} - ${dataKey}`}
                                      value={dataKey}
                                    >
                                      {dataKey}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="w-[23.4%]">
                            <Label>Values From</Label>
                            <Select
                              value={boundary.valuesFrom ?? ''}
                              onValueChange={value => {
                                handleSingleFieldValueChange(
                                  idx,
                                  'valuesFrom',
                                  value
                                );
                              }}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select Values From" />
                              </SelectTrigger>
                              <SelectContent className="w-full">
                                <SelectGroup
                                  className={'overflow-y-auto max-h-96 w-full'}
                                >
                                  {dataKeys.map((dataKey, index) => (
                                    <SelectItem
                                      key={`dataKey-${index} - ${dataKey}`}
                                      value={dataKey}
                                    >
                                      {dataKey}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="grow">
                            <Label>Hover Properties</Label>
                            <ReactSelect
                              isMulti
                              options={dataKeys.map((dataKey, index) => ({
                                label: dataKey,
                                value: dataKey,
                              }))}
                              value={boundary.dataStoreHoverKeys?.filter(
                                item => item.value
                              )}
                              onChange={value => {
                                handleSingleFieldValueChange(
                                  idx,
                                  'dataStoreHoverKeys',
                                  value as [{ value: string; label: string }]
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* Theming section for category boundary type */}
                    <div className="flex flex-col mt-1 gap-y-2">
                      <Label>Theming</Label>
                      <div className="flex flex-col border-t divide-y">
                        {boundary.theming.map((theme, themingIndex) => (
                          <div
                            key={theme.key}
                            className={cn(
                              'flex items-center justify-between p-2',
                              {
                                'bg-gray-100': themingIndex % 2 !== 0,
                                '!border-b':
                                  themingIndex === boundary.theming.length - 1,
                              }
                            )}
                          >
                            <p>{theme.key}</p>
                            <ColorPicker
                              value={theme.color}
                              onChange={color => {
                                handleThemingColorChange(
                                  idx,
                                  themingIndex,
                                  color
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
          {/* add new boundary button  */}
          <div
            className={cn(
              'flex items-center w-full py-3 transition-opacity duration-200 ease-in-out gap-x-4',
              {
                'opacity-100': !isDragging,
                'opacity-0': isDragging,
              }
            )}
          >
            <span className="w-full h-[1px] bg-gray-300 grow -mr-3"></span>
            <IconButton
              onClick={() => addBoundary(idx + 1)}
              icon={faCirclePlus}
              tooltip="Add Boundary"
              className="-mr-5 text-gray-300 rounded-full cursor-pointer hover:text-gray-800"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};
