import React, { useContext } from 'react';
import { BaseWidgetOptions, ChartType } from './types/chart-types';
import { useDownloadAsPNG } from './hooks/useDownloadAsPNG';
import { useParams } from 'react-router-dom';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { FullscreenContext } from '@/context/FullscreenContext';
import { Button, IconButton } from '@/tcomponents/ui/button';
import {
  faCamera,
  faCopy,
  faEllipsisVertical,
  faGear,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Icon from '@/components/common/Icon';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { useChartDashboardContext } from './ChartDashboardProvider';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { cn } from '@/lib/utils';
import { ModalContext } from '@/context/ModalContext';
import { DashboardFeatureDetails } from '@/types/feature/featureDetails';
import useAuth from '@/hooks/UseAuth';
import { featurePermissions } from '@/stringConsts/permissionConsts';

type PropTypes = {
  widgetData: BaseWidgetOptions;
  isPublicView?: boolean;
  index: number;
  chartComponent: JSX.Element | null;
};
export const Widget = (props: PropTypes) => {
  const { widgetData, isPublicView, index, chartComponent } = props;
  const { featureId } = useParams();
  const { handle } = useContext(FullscreenContext);
  const { showDialog } = useContext(ModalContext);

  const {
    setSelectedWidgetId,
    setDialogOpen,
    deleteWidget,
    layouts,
    cloneWidget,
  } = useChartDashboardContext();

  const { hasPermission } = useAuth();

  const feature = useGetFeatureQuery(Number(featureId), {
    skip: !featureId || featureId === 'new',
  });

  const PNGImageFileNamePrefix = (
    feature?.data?.data as DashboardFeatureDetails
  )?.name;

  const {
    elementsRef,
    showDownloadingIcon,
    setShowDownloadingIcon,
    downloadAsPng,
  } = useDownloadAsPNG(PNGImageFileNamePrefix);

  const handleDeleteWidget = (widgetId: string) => {
    showDialog({
      title: 'Delete Widget',
      message: 'Are you sure you want to proceed?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => {
          deleteWidget(widgetId);
        },
      },
    });
  };
  return (
    <div className="flex flex-col w-full h-full p-3">
      {/* widget top section */}
      <div className="flex justify-between w-full ">
        <h6>{widgetData.title}</h6>
        {!handle.active && (
          <div className="flex items-center text-primary">
            {widgetData.chartType !== ChartType.IFrame && isPublicView && (
              <>
                {showDownloadingIcon !== widgetData.id && (
                  <IconButton
                    onClick={function () {
                      setShowDownloadingIcon(widgetData.id);
                      downloadAsPng(index);
                    }}
                    icon={faCamera}
                    tooltip="Download Chart PNG"
                    className={
                      'hidden md:block ' +
                      (hasPermission(
                        featurePermissions.general.settings_access
                      ) && !isPublicView
                        ? 'mr-4'
                        : '')
                    }
                  />
                )}
                {showDownloadingIcon === widgetData.id && (
                  <Icon icon={'fas fa-spinner fa-spin'} />
                )}
              </>
            )}
            {hasPermission(featurePermissions.general.settings_access) &&
              !isPublicView && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={faEllipsisVertical}
                      tooltip="Actions"
                      className="pl-5 py-[3px]"
                    />
                  </PopoverTrigger>
                  <PopoverContent side="right" className="p-0 w-[175px]">
                    <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                      <Button
                        icon={faGear}
                        onClick={() => {
                          setSelectedWidgetId(widgetData.id);
                          setDialogOpen(true);
                        }}
                        variant="ghost"
                        className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      >
                        Edit
                      </Button>
                      <Button
                        icon={faCopy}
                        onClick={() => cloneWidget(widgetData.id)}
                        variant="ghost"
                        className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      >
                        Clone
                      </Button>
                      <Button
                        icon={faTrashCan}
                        onClick={() => handleDeleteWidget(widgetData.id)}
                        variant="ghost"
                        className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      >
                        Delete
                      </Button>
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              )}
          </div>
        )}
      </div>
      {widgetData.chartType !== ChartType.Statistics && (
        <p>{widgetData.description}</p>
      )}
      {/* widget chart section */}
      <div
        ref={el => (elementsRef.current[index] = el as HTMLDivElement)}
        className={cn('w-full h-full')}
        data-title={widgetData.title}
        data-grid={layouts.lg[index]}
      >
        {chartComponent}
      </div>
    </div>
  );
};
