import React from 'react';
import { useChartDashboardContext } from '../ChartDashboardProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartMixed } from '@fortawesome/pro-solid-svg-icons';
import {
  AreaWidgetOptions,
  ChartType,
  HorizontalBarWidgetOptions,
  IFrameWidgetOptions,
  LineWidgetOptions,
  PieWidgetOptions,
  RankWidgetOptions,
  SimpleBarWidgetOptions,
  StackedBarWidgetOptions,
  StackedHorizontalBarWidgetOptions,
  StatisticsWidgetOptions,
} from '../types/chart-types';
import StatisticsChart from '../charts/StatisticsChart';
import SimplePieChart from '../charts/PieChart';
import StackedBarChart from '../charts/StackedBarChart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import SimpleLineChart from '../charts/LineChart';
import SimpleAreaChart from '../charts/AreaChart';
import IFrameChart from '../charts/IFrameChart';
import SimpleBarChart from '../charts/SimpleBarChart';
import StackedHorizontalBarChart from '../charts/StackedHorizontalBarChart';
import RankChart from '../charts/RankChart';

export const WidgetChartPreview = () => {
  const { isReadyToShow, widgetData } = useChartDashboardContext();
  return (
    <div className="flex items-center justify-center col-span-2 p-4 bg-[#E5E0E0] bg-cover">
      {!isReadyToShow && (
        <div className="w-40 text-center text-primary opacity-60">
          <FontAwesomeIcon icon={faChartMixed} size="5x" />
          <h5>Not ready yet!</h5>
          <p>Choose your chart and link some data to preview</p>
        </div>
      )}
      {widgetData.chartType === ChartType.Statistics && isReadyToShow && (
        <div className="w-full bg-white border shadow-md h-fit">
          <h6 className="p-2 ml-2">{widgetData.title}</h6>
          <p className="px-2 pb-2 ml-2">{widgetData.description}</p>
          <StatisticsChart widgetData={widgetData as StatisticsWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.Pie && isReadyToShow && (
        <div className="flex flex-col w-full h-[50%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <SimplePieChart widgetData={widgetData as PieWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.StackedBar && isReadyToShow && (
        <div className="flex flex-col w-full h-[80%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <StackedBarChart widgetData={widgetData as StackedBarWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.HorizontalBar && isReadyToShow && (
        <div className="flex flex-col gap-y-2 w-full h-[60%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <HorizontalBarChart
            widgetData={widgetData as HorizontalBarWidgetOptions}
          />
        </div>
      )}
      {widgetData.chartType === ChartType.Line && isReadyToShow && (
        <div className="flex flex-col w-full h-[80%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <SimpleLineChart widgetData={widgetData as LineWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.Area && isReadyToShow && (
        <div className="flex flex-col w-full h-[80%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <SimpleAreaChart widgetData={widgetData as AreaWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.IFrame && isReadyToShow && (
        <div className="flex flex-col w-full gap-y-2 h-[80%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <IFrameChart widgetData={widgetData as IFrameWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.SimpleBar && isReadyToShow && (
        <div className="flex flex-col w-full h-[70%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <SimpleBarChart widgetData={widgetData as SimpleBarWidgetOptions} />
        </div>
      )}
      {widgetData.chartType === ChartType.StackedHorizontalBar &&
        isReadyToShow && (
          <div className="flex flex-col gap-y-2 w-full h-[60%] p-5 bg-white border shadow-md">
            <h6>{widgetData.title}</h6>
            <p>{widgetData.description}</p>
            <StackedHorizontalBarChart
              widgetData={widgetData as StackedHorizontalBarWidgetOptions}
            />
          </div>
        )}
      {widgetData.chartType === ChartType.Rank && isReadyToShow && (
        <div className="flex flex-col gap-y-2 w-full h-[60%] p-5 bg-white border shadow-md">
          <h6>{widgetData.title}</h6>
          <p>{widgetData.description}</p>
          <RankChart widgetData={widgetData as RankWidgetOptions} />
        </div>
      )}
    </div>
  );
};
