import React, { useState, useEffect } from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { SenderNodeData } from '@/pages/dashboard/projects/graph/types';
import { Input } from '@tcomponents/ui/input';
import makeAnimated from 'react-select/animated';
import { reactSelectStyles } from '@/pages/dashboard/features/forms/RankedList/consts';
import ReactSelect, { MultiValue } from 'react-select';
import { useGetValidReceiverNodesQuery } from '@/store/services/receiverNodeApi';
import { cloneDeep } from 'lodash';
import { buttonVariants, IconButton } from '@tcomponents/ui/button';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { cn } from '@/lib/utils';

const SenderFormFields: React.FC = () => {
  const [filteredNodes, setFilteredNodes] = useState<
    { label: string; value: string }[]
  >([]);

  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { namespace, receivers, addKeys } = activeNode.data as SenderNodeData;

  const { data } = useGetValidReceiverNodesQuery();

  useEffect(() => {
    if (typeof data === 'undefined') {
      return;
    }

    let filterResult = [];

    filterResult = data.data
      .filter(
        item =>
          item.namespace ===
          (typeof namespace === 'undefined' || namespace === ''
            ? null
            : namespace)
      )
      .map(item => ({ value: item.value, label: item.label }));

    setFilteredNodes(filterResult);
  }, [namespace, data]);

  const handleSingleInputFieldChange = (
    field: string,
    val:
      | string
      | number
      | boolean
      | MultiValue<{ label: string; value: string }>
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  const handleKeyChange = (idx: number, key: string, value: string) => {
    let dataCopy = cloneDeep({ ...activeNode.data });
    dataCopy.addKeys[idx] = { key, value };
    updateNodeData(dataCopy);
  };

  const handleKeyDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.addKeys = dataCopy.addKeys.filter(
      (_: any, i: number) => i !== idx
    );
    updateNodeData(dataCopy);
  };

  const handleAddKey = () => {
    const dataCopy = { ...activeNode.data };
    let keysToSpread =
      typeof activeNode.data.addKeys !== 'undefined'
        ? activeNode.data.addKeys
        : [];
    dataCopy.addKeys = [...keysToSpread, { key: '', value: '' }];

    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Connection</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Namespace (optional)</label>
            <Input
              value={namespace}
              onChange={e =>
                handleSingleInputFieldChange('namespace', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Receivers</label>
            <ReactSelect
              isClearable={true}
              className="w-full"
              value={Array.isArray(receivers) ? receivers : []}
              isMulti
              options={filteredNodes}
              onChange={value =>
                handleSingleInputFieldChange('receivers', value)
              }
              placeholder="Select Receivers"
              components={{
                ...makeAnimated(),
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              styles={reactSelectStyles}
            />
          </div>
        </div>
      </div>

      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md ' +
            (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <h5 className="font-bold ">Add Keys</h5>
          {typeof addKeys !== 'undefined' && addKeys.length >= 1 && (
            <div className="flex pt-[5px] justify-between font-bold">
              <div className="">Key</div>
              <div
                className={
                  isExpanded
                    ? 'pr-[20px] text-center grow'
                    : 'pr-20 text-center grow'
                }
              >
                Value{' '}
                <i
                  className={'fas fa-brackets-curly text-warning-error fa-xl'}
                />
              </div>
            </div>
          )}
          {typeof addKeys !== 'undefined' &&
            addKeys.length >= 1 &&
            addKeys.map((field, index) => (
              <div
                key={`field_${index}`}
                className="flex items-center justify-between gap-x-2 pt-[5px]"
              >
                <input
                  className={
                    'border p-1 text-sm rounded-sm ' +
                    (isExpanded ? ' w-1/2 mr-3 h-10' : 'w-[60px] h-10')
                  }
                  type="text"
                  placeholder="Key"
                  value={field.key}
                  onChange={e =>
                    handleKeyChange(index, e.target.value, field.value)
                  }
                />

                <input
                  className={
                    'border p-1 text-sm rounded-sm ' +
                    (isExpanded ? ' w-1/2 mr-3 h-10' : 'w-[120px] h-10')
                  }
                  type="text"
                  placeholder="Value"
                  value={field.value}
                  onChange={e =>
                    handleKeyChange(index, field.key, e.target.value)
                  }
                />

                <IconButton
                  icon={faXmark}
                  onClick={() => handleKeyDeletion(index)}
                  className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm "
                />
              </div>
            ))}

          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddKey}
            data-testing="add_field"
            aria-label="add field"
          >
            + Add Key
          </button>
        </div>
      </div>
    </div>
  );
};

export default SenderFormFields;
