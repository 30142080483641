import React, { useEffect } from 'react';
import FeatureSourceBar from '../common/FeatureSourceBar';
import TabulatorTable from '@components/common/Table';
import { cn } from '@/lib/utils';
import { useGetTableDataQuery } from '@/store/services/projects/tableDisplayApi';

interface ITableDisplayProps {
  id: string | number;
  setLastUpdatedForTable?: React.Dispatch<React.SetStateAction<string>>;
  project?: any;
  feature?: any;
}

export default function TableDisplay(props: ITableDisplayProps) {
  const { id, setLastUpdatedForTable, project, feature } = props;

  const tableData = useGetTableDataQuery(parseInt(id as string), {
    skip: !id,
    refetchOnFocus: true,
  });

  const information = tableData.data?.information;
  const sources = tableData.data?.sources;

  // effect to set last updated for ranked list in the parent component
  useEffect(() => {
    setLastUpdatedForTable &&
      setLastUpdatedForTable(tableData.data?.last_updated);
  }, [tableData.data?.last_updated, setLastUpdatedForTable]);

  const tabulatorConfig = () => {
    return {
      position: 'absolute',
      layout: 'fitData',
      autoColumns: 'full',
      data: tableData.data?.data,
      layoutColumnsOnNewData: true,
      resizableRows: true,
      placeholder: 'No Data Available',
      placeholderHeaderFilter: 'No Matching Data Found',
      nestedFieldSeparator: '||',
      autoColumnsDefinitions: function (
        definitions: { title: string; field: string }[]
      ) {
        definitions.forEach((column: any) => {
          if (column.field === '__id') {
            column.visible = false;
          }

          column.title = column.title.replace(/\\U\+002E/, '.');

          feature.data.data.hidden_fields?.forEach((field: any) => {
            if (column.title === field.label) {
              column.visible = false;
            }
          });

          if (column.field === 'timestamp') {
            column.formatter = 'datetime';
            column.formatterParams = {
              inputFormat: 'yyyy-MM-dd HH:mm:ss',
              outputFormat: 'yyyy/MM/dd HH:mm:ss',
            };
            column.sorter = 'datetime';
            column.sorterParams = {
              format: 'yyyy-MM-dd HH:mm:ss',
            };
          }
        });

        return definitions;
      },
      // ],
      columnDefaults: {
        maxWidth: 500,
        headerFilter: 'input',
        formatter: 'textarea',
        sorter: 'alphanum',
        headerTooltip: true,
        mutator: function (value: any[]) {
          return Array.isArray(value) ? value.join(', ') : value;
        },
      },
      ajaxConfig: null,
    };
  };

  return (
    <div
      className={cn(
        'w-full relative',
        (typeof sources !== 'undefined' && sources.length > 0) ||
          (typeof information !== 'undefined' && information.length > 0)
          ? 'h-[91%]'
          : 'h-[98%]'
      )}
    >
      <div className="hidden md:flex md:flex-col">
        <TabulatorTable id={'user-list'} config={tabulatorConfig()} />
      </div>
      <FeatureSourceBar
        project={project}
        feature={feature}
        sources={sources}
        information={information}
      />
    </div>
  );
}
