import { useSelector } from 'react-redux';

export default function useAuth() {
  const { user } = useSelector((state: any) => state);
  const userPermissions = user?.userData?.permissions ?? [];
  const hasPermission = (permission: string) => {
    return userPermissions.indexOf(permission) !== -1;
  };

  const hasAnyPermission = (permissions: string[]) => {
    return permissions.some(permission => userPermissions.includes(permission));
  };
  const hasAllPermissions = (permissions: string[]) => {
    return permissions.every(permission =>
      userPermissions.includes(permission)
    );
  };

  const loggedInUser = () => user.userData;

  return {
    userPermissions,
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    loggedInUser,
  };
}
