import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '../../utils/createErrorHandler';
import ProjectResponseInterface, {
  ProjectsByOrganisation,
} from '@/types/interfaces/api-response/ProjectResponseInterface';
import { IFavouriteResponse } from '@/layout/sidebar/Sidebar';

export const projectApi = createApi({
  reducerPath: 'projectApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Projects', 'Project', 'ProjectList', 'ProjectTemplates'],
  endpoints: builder => ({
    getAllProjects: builder.query<ProjectsByOrganisation, number>({
      query: id => {
        return {
          url: '/projects',
          method: 'GET',
        };
      },
      providesTags: ['Projects'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading projects'
      ),
    }),
    getProjectTemplates: builder.query<
      { data: ProjectResponseInterface[] },
      number
    >({
      query: id => {
        return {
          url: '/projects/available-templates',
          method: 'GET',
        };
      },
      providesTags: ['ProjectTemplates'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading project Templates'
      ),
    }),
    getProjectSettings: builder.query<
      { data: ProjectResponseInterface },
      number
    >({
      query: (id: number) => {
        return {
          url: '/projects/' + id,
          method: 'GET',
        };
      },
      providesTags: ['Project'],
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading project settings'
      ),
    }),
    storeProject: builder.mutation({
      query: (values: object) => {
        return {
          url: '/projects',
          method: 'POST',
          body: values,
          formData: true,
        };
      },
      invalidatesTags: ['Projects', 'ProjectList', 'Project'],
    }),
    fetchProjectDetails: builder.query({
      query: (id: number) => {
        return {
          url: '/projects/' + id,
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project details'
      ),
      keepUnusedDataFor: 1,
      providesTags: ['Project'],
    }),
    getProjectUsersForSelector: builder.query({
      query: (projectId: number) => {
        return {
          url: '/projects/' + projectId + '/users-for-selector',
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project users'
      ),
    }),
    getAvailableProjectUsersForSelector: builder.query({
      query: (projectId: number) => {
        return {
          url: '/projects/' + projectId + '/available-users-for-selector',
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading project users'
      ),
    }),
    assignUserToProject: builder.mutation({
      query: ({ projectId, userId }) => {
        return {
          url: '/projects/' + projectId + '/user/' + userId,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Project'],
    }),
    assignUsersToProject: builder.mutation({
      query: ({ projectId, users }) => {
        return {
          url: '/projects/' + projectId + '/assign-multiple-users',
          method: 'PUT',
          body: { users: users },
        };
      },
      invalidatesTags: ['Project'],
    }),
    removeUserFromProject: builder.mutation({
      query: ({ projectId, userId }) => {
        return {
          url: '/projects/' + projectId + '/user/' + userId,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Project'],
    }),
    deleteProject: builder.mutation({
      query: (id: any) => {
        return {
          url: '/projects/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ProjectList'],
    }),
    toggleProject: builder.mutation({
      query: (projectId: string | number) => {
        return {
          url: '/projects/' + projectId + '/toggleTemplate',
          method: 'GET',
        };
      },
      invalidatesTags: ['Project'],
    }),
    duplicateProject: builder.mutation({
      query: (data: object) => {
        return {
          url: '/projects/duplicate',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Projects'],
    }),
    archiveData: builder.mutation({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/archive-data',
          method: 'GET',
        };
      },
    }),
    favourite: builder.mutation<IFavouriteResponse, string>({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/favourites',
          method: 'PUT',
        };
      },
      invalidatesTags: ['Projects', 'Project'],
    }),
    unFavourite: builder.mutation<IFavouriteResponse, string>({
      query: (id: string) => {
        return {
          url: '/projects/' + id + '/favourites',
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Projects', 'Project'],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProjectTemplatesQuery,
  useGetProjectSettingsQuery,
  useStoreProjectMutation,
  useFetchProjectDetailsQuery,
  useGetProjectUsersForSelectorQuery,
  useGetAvailableProjectUsersForSelectorQuery,
  useAssignUserToProjectMutation,
  useAssignUsersToProjectMutation,
  useRemoveUserFromProjectMutation,
  useDeleteProjectMutation,
  useToggleProjectMutation,
  useDuplicateProjectMutation,
  useArchiveDataMutation,
  useFavouriteMutation,
  useUnFavouriteMutation,
} = projectApi;
