import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { NumberQuestion, Question } from '../Helpers/Types';

interface NumberQueProps {
  question: NumberQuestion;
  updateQuestion: (data: Question) => void;
}
export const NumberQue = (props: NumberQueProps) => {
  const { question: questionData, updateQuestion } = props;
  return (
    <div className="flex gap-x-8">
      <div>
        <Label>Default</Label>
        <Input
          className="w-[100px]"
          type="number"
          min={0}
          value={questionData.default}
          onChange={e => {
            updateQuestion({
              ...questionData,
              default: parseInt(e.target.value),
            });
          }}
        />
      </div>
      <div>
        <Label>Min</Label>
        <Input
          className="w-[100px]"
          type="number"
          min={0}
          value={questionData.min}
          onChange={e => {
            updateQuestion({
              ...questionData,
              min: parseInt(e.target.value),
            });
          }}
        />
      </div>
      <div>
        <Label>Max</Label>
        <Input
          className="w-[100px]"
          type="number"
          min={0}
          value={questionData.max}
          onChange={e => {
            updateQuestion({
              ...questionData,
              max: parseInt(e.target.value),
            });
          }}
        />
      </div>
      <div>
        <Label>Increment</Label>
        <Input
          className="w-[100px]"
          type="number"
          min={0}
          defaultValue={1}
          value={questionData.increment}
          onChange={e => {
            updateQuestion({
              ...questionData,
              increment: parseInt(e.target.value),
            });
          }}
        />
      </div>
    </div>
  );
};
