import { useState } from 'react';
import { WidgetOptions } from '../types/chart-types';

type UseColorPickerProps = {
  initialColors: string[];
  updateWidgetData?: (data: Partial<WidgetOptions>) => void;
};

type UseColorPickerResult = {
  showColorPicker: boolean;
  selectedColorIndex: number;
  colors: string[];
  handleColorClick: (index: number) => void;
  handleColorChange: (color: any) => void;
  handleColorPickerClose: () => void;
  setColors: (colors: string[]) => void;
  setShowColorPicker: (showColorPicker: boolean) => void;
};

export const defaultColors = [
  '#EB7546',
  '#348FB3',
  '#FFF585',
  '#97DFAF',
  '#386B37',
  '#21A39A',
  '#005F85',
  '#AFBABE',
  '#F19E7D',
  '#71B1CA',
  '#FFF8AA',
  '#B6E9C7',
  '#749773',
  '#64BEB8',
  '#4D8FAA',
  '#C7CFD2',
  '#F7C8B5',
  '#AED2E1',
  '#FFFBCE',
  '#D5F2DF',
  '#B3C7B3',
  '#A6DAD7',
  '#99BFCE',
  '#DFE3E5',
];

export function useColorPicker({
  initialColors,
  updateWidgetData,
}: UseColorPickerProps): UseColorPickerResult {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColorIndex, setSelectedColorIndex] = useState(-1);
  const [colors, setColors] = useState<string[]>(initialColors);

  const handleColorClick = (index: number) => {
    setSelectedColorIndex(index);
    setShowColorPicker(true);
  };

  const handleColorChange = (color: any) => {
    const newColors = [...colors];
    newColors[selectedColorIndex] = color.hex;
    setColors(newColors);
    updateWidgetData &&
      updateWidgetData({
        dataStyling: newColors,
      });
  };

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
    setSelectedColorIndex(-1);
  };

  return {
    showColorPicker,
    selectedColorIndex,
    colors,
    setColors,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
    setShowColorPicker,
  };
}
