import React, { FC, RefObject, useEffect, useMemo, useRef } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@tcomponents/ui/form';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import { cn } from '@/lib/utils';
import { Button } from '@tcomponents/ui/button';
import { useColorPicker } from '../../../projects/chartDashboard/hooks/useColorPicker';
import { SketchPicker } from 'react-color';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  useGetDataStoresQuery,
  useGetWorkflowsQuery,
} from '@/store/services/projects/chartApi';
import { useParams } from 'react-router-dom';
import { getKeys } from '../../../projects/utils/helperFunctions';
import useRankedListForm, { ApiSubmitHandler } from './hooks/useRankedListForm';
import { defaultDataStyling, reactSelectStyles } from './consts';
import { useGetDataFromDataStoreQuery } from '@/store/services/projects/rankedListApi';
import { validateDataStylingKeys } from './utils';
import { Switch } from '@tcomponents/ui/switch';
import { RankedListFeatureDetails } from '@/types/feature/featureDetails';

interface IRankedListFormProps {
  onSubmitHandler: ApiSubmitHandler;
  data: RankedListFeatureDetails | undefined;
  setIsDirty: (isDirty: boolean) => void;
  setRefSubmitButton: (submitButtonRef: RefObject<HTMLButtonElement>) => void;
}

const EmptyDataStore = () => {
  return (
    <h3 className="flex flex-col items-center justify-center h-full text-gray-400 gap-y-1">
      Empty Data Store
    </h3>
  );
};

const RankedListForm: FC<IRankedListFormProps> = props => {
  const refSubmitButton = useRef<HTMLButtonElement>(null);
  const { projectId } = useParams();
  const {
    showColorPicker,
    selectedColorIndex,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
  } = useColorPicker({
    initialColors: defaultDataStyling,
  });
  const { form, handleSubmit } = useRankedListForm(
    props.onSubmitHandler,
    props.data
  );
  const {
    reset,
    control,
    formState: { errors, isDirty },
    getValues,
  } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ranking_bar',
  });
  const addRankingBar = () => {
    append([
      {
        value: '',
        label: '',
      },
    ]);
  };
  const selectedWorkflow = form.getValues('workflow');
  const selectedDataStore = form.getValues('data_store');

  const workflows = useGetWorkflowsQuery(parseInt(projectId as string), {
    skip: !projectId,
  });
  const workflowData = workflows.data?.data;
  const dataStores = useGetDataStoresQuery(
    parseInt(selectedWorkflow as string),
    { skip: !selectedWorkflow }
  );

  const filteredListGroupedDataStores = dataStores.data?.data.filter(
    (dataStore: any) => dataStore.format === 'list-grouped'
  );

  const apiReturnedData = useGetDataFromDataStoreQuery(
    selectedDataStore as string,
    {
      skip: !selectedDataStore,
      refetchOnMountOrArgChange: true,
    }
  );

  const parsedData = useMemo(
    () =>
      apiReturnedData && apiReturnedData.data
        ? JSON.parse(apiReturnedData.data)?.data
        : undefined,

    [apiReturnedData.data]
  );
  // All data keys with underscore
  const allParsedDataKeys = getKeys(parsedData, true).sort();
  // All data keys that don't have underscore
  const parsedDataKeys = useMemo(
    () => getKeys(parsedData).sort(),
    [parsedData]
  );

  const dataForReactSelect = parsedDataKeys?.map((item: string) => ({
    value: item,
    label: item,
  }));

  useEffect(() => {
    if (props.data) {
      const rankingBarsOptions = props.data.ranking_bars.map((item: any) => {
        return item.map((i: any) => ({
          value: i ?? '',
          label: i ?? '',
        }));
      });

      reset({
        name: props.data.name,
        header_info: props.data.header_info ?? '',
        description: props.data.description ?? '',
        icon: props.data.icon,
        column_label: props.data.layout.column_label ?? '',
        initial_sort: props.data.layout.initial_sort ?? '_name',
        sort_direction: props.data.layout.sort_direction ?? 'asc',
        workflow: props.data.data_source.workflow ?? '',
        data_store: props.data.data_source.data_store_id ?? '',
        ranking_bar: rankingBarsOptions ?? [[]],
        showSources: props.data.showSources ?? false,
        data_styling: Array.isArray(props.data.data_styling)
          ? {}
          : props.data.data_styling,
      });
    }
  }, [props.data]);

  // if all keys have been loaded, we check if keys in data_styling is still valid
  useEffect(() => {
    if (parsedData && props.data && props.data.data_styling) {
      const newDataStyling = validateDataStylingKeys(
        props.data.data_styling,
        parsedDataKeys
      );
      form.setValue('data_styling', newDataStyling, { shouldDirty: true });
    }
  }, [parsedData]);

  const onWorkflowChange = (val: string) => {
    reset(
      {
        ...getValues(),
        column_label: '',
        initial_sort: '_name',
        ranking_bar: [[]],
        data_styling: null,
        data_store: '',
        workflow: val,
      },
      {
        keepDefaultValues: true,
      }
    );
  };

  const onDataSourceChange = (val: string) => {
    reset(
      {
        ...getValues(),
        column_label: '',
        initial_sort: '_name',
        ranking_bar: [[]],
        data_styling: null,
        data_store: val,
      },
      {
        keepDefaultValues: true,
      }
    );
  };

  // sets default data styling based on the keys of the data
  const dataStyling = form.getValues('data_styling');

  useEffect(() => {
    if (
      !dataStyling &&
      parsedDataKeys.length > 0 &&
      apiReturnedData.isSuccess &&
      !apiReturnedData.isFetching
    ) {
      const newDataStyling = parsedDataKeys?.reduce(
        (acc: Record<string, string>, curr: string, index: number) => {
          acc[curr] = defaultDataStyling[index];
          return acc;
        },
        {}
      );
      form.setValue('data_styling', newDataStyling);
    }
  }, [parsedDataKeys, apiReturnedData.isSuccess, apiReturnedData.isFetching]);

  useEffect(() => {
    props.setRefSubmitButton(refSubmitButton);
  }, []);

  useEffect(() => {
    props.setIsDirty(isDirty);
  }, [isDirty]);

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col h-full">
        <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
          <div className="flex flex-col w-full space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Feature Details</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel required>Name</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.name} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="header_info"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel>Header Info</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.header_info} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel>Description</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Textarea {...field} error={errors.description} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="icon"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel required>Icon</FormLabel>
                          <FormMessage />
                        </div>
                        <div className="flex">
                          <i
                            className={
                              field.value +
                              ' w-10 h-10 border rounded-l-md p-1 flex items-center justify-center bg-gray-500 text-white text-xl'
                            }
                          ></i>
                          <FormControl>
                            <Input
                              {...field}
                              error={errors.icon}
                              className="rounded-l-none "
                            />
                          </FormControl>
                        </div>
                      </FormItem>
                    )}
                  />

                  <button hidden={true} ref={refSubmitButton} type={'submit'} />
                </div>
              </CardContent>
            </Card>
            {selectedWorkflow &&
              selectedDataStore &&
              parsedData?.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>Layout</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      <FormField
                        control={form.control}
                        name="column_label"
                        render={({ field }) => (
                          <FormItem>
                            <div className="flex items-center justify-between">
                              <FormLabel>Name Column Label</FormLabel>
                              <FormMessage />
                            </div>
                            <FormControl>
                              <Input
                                {...field}
                                error={errors.column_label}
                                placeholder="Enter label"
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />

                      <div className="flex justify-between space-x-4">
                        <FormField
                          control={form.control}
                          name="initial_sort"
                          render={({ field }) => (
                            <FormItem className="w-1/2">
                              <FormLabel>Initial Sort</FormLabel>
                              <Select onValueChange={field.onChange} {...field}>
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select Columns" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectGroup
                                    className={'overflow-y-auto max-h-96'}
                                  >
                                    <SelectLabel>Workflows</SelectLabel>

                                    {allParsedDataKeys.map(
                                      // @ts-ignore
                                      (item, index) => (
                                        <SelectItem
                                          key={`initial_sort-${index} - ${item}`}
                                          value={item}
                                        >
                                          {' '}
                                          {item}
                                        </SelectItem>
                                      )
                                    )}
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="sort_direction"
                          render={({ field }) => (
                            <FormItem className="w-1/2">
                              <FormLabel>Sort Direction</FormLabel>
                              <Select onValueChange={field.onChange} {...field}>
                                <FormControl>
                                  <SelectTrigger>
                                    <SelectValue placeholder="Select Direction" />
                                  </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                  <SelectItem value="asc">Ascending</SelectItem>
                                  <SelectItem value="desc">
                                    Descending
                                  </SelectItem>
                                </SelectContent>
                              </Select>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )}

            {selectedWorkflow &&
              selectedDataStore &&
              parsedData?.length > 0 && (
                <Card className="relative">
                  <CardHeader>
                    <CardTitle>Styling</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Controller
                      name="data_styling"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <div className="divide-y" ref={ref}>
                          {value &&
                            Object.keys(value).map((key, index, keys) => (
                              <div
                                className={cn(
                                  'relative flex items-center justify-between p-2 ml-1',
                                  {
                                    'bg-gray-100': index % 2 !== 0,
                                    'border-t': index === 0,
                                    '!border-b': index === keys.length - 1,
                                  }
                                )}
                                key={`color-${index}`}
                              >
                                <span>{key}</span>
                                <span
                                  onClick={() => handleColorClick(index)}
                                  className={`w-8 h-8 rounded-md cursor-pointer`}
                                  style={{ backgroundColor: value[key] }}
                                ></span>
                              </div>
                            ))}
                          {showColorPicker && (
                            <>
                              <div
                                className="fixed inset-0 bg-white opacity-20"
                                onClick={handleColorPickerClose}
                              ></div>

                              <SketchPicker
                                className="absolute z-20 left-1/2 bottom-[10px]"
                                disableAlpha={true}
                                presetColors={[
                                  ...defaultDataStyling,
                                  'transparent',
                                ]}
                                width="200px"
                                color={
                                  Object.values(dataStyling ?? {})[
                                    selectedColorIndex
                                  ]
                                }
                                onChange={color => {
                                  const newDataStyling = {
                                    ...value,
                                    [Object.keys(dataStyling ?? {})[
                                      selectedColorIndex
                                    ]]: color.hex,
                                  };
                                  onChange(newDataStyling);
                                  handleColorChange(color);
                                }}
                              />
                            </>
                          )}
                        </div>
                      )}
                    />
                  </CardContent>
                </Card>
              )}
          </div>
          <div className="flex flex-col w-full space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>List Setup</CardTitle>
              </CardHeader>
              <CardContent>
                <FormField
                  control={form.control}
                  name="showSources"
                  render={({ field }) => (
                    <FormItem className="flex flex-col w-1/2">
                      <FormLabel className="text-base">Show Sources</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          // @ts-ignore
                          onCheckedChange={field.onChange}
                          checkedLabel="Yes"
                          unCheckedLabel="No"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Data Source</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="workflow"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Workflow</FormLabel>
                        <Select
                          {...field}
                          value={field.value?.toString()}
                          onValueChange={val => {
                            // callback is triggered somehow when data is loaded where val still refers to the default value ''.
                            // so to prevent updating the state with that value, we check if val is truthy
                            // the same goes for the data store select below
                            if (val) {
                              onWorkflowChange(val);
                              field.onChange(val);
                            }
                          }}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Choose Workflow" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectGroup className={'overflow-y-auto max-h-96'}>
                              <SelectLabel>Workflows</SelectLabel>
                              {workflowData &&
                                workflowData.map(
                                  // @ts-ignore
                                  workflow => (
                                    <SelectItem
                                      key={workflow.value}
                                      value={workflow.value.toString()}
                                    >
                                      {workflow.text}
                                    </SelectItem>
                                  )
                                )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="data_store"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Data Store</FormLabel>
                        <Select
                          {...field}
                          value={field.value?.toString()}
                          onValueChange={val => {
                            if (val) {
                              onDataSourceChange(val);
                              field.onChange(val);
                            }
                          }}
                          disabled={!selectedWorkflow}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue
                                placeholder={
                                  selectedWorkflow
                                    ? 'Choose Datastore'
                                    : 'Select workflow to choose data store'
                                }
                              />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectGroup className={'overflow-y-auto max-h-96'}>
                              <SelectLabel>Data Stores</SelectLabel>
                              {filteredListGroupedDataStores &&
                                filteredListGroupedDataStores.map(
                                  // @ts-ignore
                                  (dataStore, index) => (
                                    <SelectItem
                                      key={`dataStore-${index} - ${dataStore.value}`}
                                      value={dataStore.value}
                                    >
                                      {dataStore.text}
                                    </SelectItem>
                                  )
                                )}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
            {selectedWorkflow &&
              selectedDataStore &&
              parsedData?.length > 0 && (
                <Card>
                  <CardHeader>
                    <CardTitle>Ranking Bars</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-2 divide-y">
                      {fields.map((item, index) => {
                        return (
                          <div
                            key={item.id}
                            className={cn(
                              'flex justify-between pt-2 w-full gap-x-4',
                              {
                                'border-t': index === 0,
                                '!border-b pb-2': index === fields.length - 1,
                              }
                            )}
                          >
                            <Controller
                              name={`ranking_bar.${index}`}
                              control={control}
                              render={({ field }) => (
                                <ReactSelect
                                  isClearable={true}
                                  className="w-full"
                                  key={index}
                                  {...field}
                                  ref={field.ref}
                                  value={
                                    Array.isArray(field.value)
                                      ? field.value
                                      : []
                                  }
                                  isMulti
                                  options={dataForReactSelect}
                                  placeholder="Select options..."
                                  components={{
                                    ...makeAnimated(),
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    ClearIndicator: () => null,
                                  }}
                                  styles={reactSelectStyles}
                                />
                              )}
                            />
                            <Button
                              className="text-xl"
                              variant="danger"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              X
                            </Button>
                          </div>
                        );
                      })}
                      <Button
                        type="button"
                        variant="info"
                        className="w-full text-white"
                        onClick={addRankingBar}
                      >
                        + New Ranking Bar
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}
          </div>
        </div>
        {parsedData?.length === 0 && <EmptyDataStore />}
      </form>
    </Form>
  );
};

export default RankedListForm;
