import { useGetDashboardConfigQuery } from '@/store/services/projects/chartApi';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

type propTypes = {
  setColumnTitles: Function;
  setWidgets: Function;
  setLayouts: Function;
};

export const useGetDashboardConfig = (props: propTypes) => {
  const { setColumnTitles, setWidgets, setLayouts } = props;
  const { featureId: id, featureType } = useParams();
  const {
    data: dashboardConfig,
    isError,
    isSuccess,
    isFetching,
    isLoading,
  } = useGetDashboardConfigQuery(parseInt(id as string), {
    skip:
      !id || id === 'new' || featureType?.toLocaleLowerCase() !== 'dashboard',
  });

  useEffect(() => {
    if (isError || isFetching || isLoading) {
      setWidgets([]);
      setColumnTitles({ left: '', right: '' });
      setLayouts({ lg: [] });
    }
    if (isSuccess) {
      setColumnTitles({
        left: dashboardConfig.data.columnTitles.left,
        right: dashboardConfig.data.columnTitles.right,
      });

      setWidgets(dashboardConfig.data.widgets);
      setLayouts({ lg: dashboardConfig.data.layout });
    }
  }, [isError, isSuccess, isFetching, id]);

  return {
    isDashboardConfigFetching: isFetching,
  };
};
