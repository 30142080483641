import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { FilterNodeData } from '../types/node-types';

const FilterNode = ({ data, ...rest }: Node<FilterNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className="p-1 text-gray-600">
        <div className="flex flex-row gap-1">
          <p className="text-xs font-bold">Mode: </p>
          <p className="text-xs truncate">
            {data.hasOwnProperty('filterMode') && data.filterMode !== ''
              ? data.filterMode
              : 'AND'}
          </p>
        </div>
        {data?.filters?.length > 0 && (
          <div className="flex flex-col">
            <p className="text-xs font-bold">Filters: </p>
            <div>
              <ul className="list-disc pl-2 ml-1">
                {data.filters.map((filter, index) => (
                  <li className="ellipsis" key={index}>
                    {filter.field} {filter.func} {filter.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default FilterNode;
