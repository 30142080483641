import { useGetMapDataQuery } from '@/store/services/projects/mapApi';
import { useEffect, useMemo, useState } from 'react';
import { IMapConfigResponse, IMapDataResponse } from '../types/types';
import { useLoadingDialog } from '@/tcomponents/custom/LoadingDialog';
import { fetchNearestPostcodes } from '../utils/postcode';
import { LatLngExpression } from 'leaflet';

export const useMapData = (
  id: string | number,
  mapConfigResponse: IMapConfigResponse | undefined,
  isPublicView: boolean = false,
  mapDataFromProps?: IMapDataResponse
) => {
  const [postcodesWithCoordsFromNewApi, setPostcodesWithCoordsFromNewApi] =
    useState<Record<string, [number, number]> | null>(null);

  const { setLoadingDialog } = useLoadingDialog('Loading map data...');

  const { data } = useGetMapDataQuery(parseInt(id as string), {
    skip: !id || isPublicView,
    refetchOnFocus: true,
  });
  const mapDataResponse = mapDataFromProps || data;
  const information = mapDataResponse?.information;
  const sources = mapDataResponse?.sources;

  const locationField = mapConfigResponse?.data.layout.locationField;
  const locationFormat = mapConfigResponse?.data.layout.format;
  const hasSelectedQuestions =
    mapConfigResponse?.data?.selectedQuestionsMeta?.length;

  const allPostcodes: string[] = useMemo(() => {
    if (!mapDataResponse || !locationField || !hasSelectedQuestions) return [];
    return mapDataResponse.data
      .map(item => item[locationField as string])
      .filter(Boolean);
  }, [mapDataResponse, locationField, hasSelectedQuestions]);

  useEffect(() => {
    if (!mapDataResponse || !mapConfigResponse) return;
    if (locationFormat !== 'postcode') return;
    if (!allPostcodes.length) return;
    const fetchData = async () => {
      setLoadingDialog(true);
      const data = await fetchNearestPostcodes(allPostcodes);
      setPostcodesWithCoordsFromNewApi(data);
      setLoadingDialog(false);
    };

    fetchData();
  }, [allPostcodes]);

  const initialCoordsForMap: LatLngExpression[] | [] = useMemo(() => {
    if (!mapDataResponse || !locationField || !hasSelectedQuestions) return [];
    if (locationFormat === 'postcode' && postcodesWithCoordsFromNewApi) {
      return Object.values(postcodesWithCoordsFromNewApi);
    }
    if (locationFormat === 'lat/long' && mapDataResponse) {
      return mapDataResponse.data.map(item => item[locationField as string]);
    }
    return [];
  }, [
    postcodesWithCoordsFromNewApi,
    mapDataResponse,
    locationFormat,
    locationField,
    hasSelectedQuestions,
  ]);

  return {
    mapDataResponse: mapDataResponse,
    information,
    sources,
    postcodesWithCoords: postcodesWithCoordsFromNewApi,
    initialCoordsForMap,
  };
};
