import { MapFeatureDetails } from '@/types/feature/featureDetails';

export const defaultMapFormState = {
  details: {
    tabType: 'details',
    name: '',
    headerInfo: '',
    description: '',
    icon: 'fas fa-map-location-dot',
    displaySetup: {
      allowFullScreen: false,
      showSource: false,
      clusterMarkers: true,
      searchBar: true,
    },
    sidebarSections: [
      {
        key: 'markersSection',
        titleLabel: 'Markers Title',
        title: 'Markers',
        icon: 'fas fa-location-dot',
        description: '',
      },
      {
        key: 'overlaysSection',
        titleLabel: 'Overlays Title',
        title: 'Overlays',
        icon: 'fas fa-bridge-water',
        description: '',
      },
      {
        key: 'boundariesSection',
        titleLabel: 'Boundaries Title',
        title: 'Boundaries',
        icon: 'fas fa-street-view',
        description: '',
      },
    ],
  },
  markers: {
    tabType: 'markers',
    layout: {
      format: 'postcode',
      locationField: '',
      fieldsOfInterest: [{ value: '', label: '' }],
      visibilityZoomLevel: '',
    },
    data_source: {
      workflow: '',
      data_store: '',
    },
    detailView: {
      includeValueField: true,
      displayFieldsOfInterest: [
        {
          value: '',
          label: '',
        },
      ],
    },
    selectedQuestionsMeta: [] as MapFeatureDetails['selectedQuestionsMeta'],
  },
  overlays: {
    tabType: 'overlays',
    overlaysArray: [] as MapFeatureDetails['overlays'],
  },
  boundaries: {
    tabType: 'boundaries',
    boundariesArray: [] as MapFeatureDetails['boundaries'],
  },
};
export type MapFormStateType = typeof defaultMapFormState;

export const mapServerToFormState = (serverData: MapFeatureDetails) => {
  return {
    details: {
      tabType: 'details',
      name: serverData.name,
      headerInfo: serverData.header_info,
      description: serverData.description,
      icon: serverData.icon,
      displaySetup: {
        showSource: serverData.display.showSources,
        allowFullScreen: serverData.display.allowFullScreen,
        searchBar: serverData.display.searchBar,
        // here this cluster property should be the part of the display object
        clusterMarkers: serverData.cluster,
      },
      sidebarSections:
        // if the property is not present in the server data, then use the default value
        serverData.sidebarSections ??
        defaultMapFormState.details.sidebarSections,
    },
    markers: {
      tabType: 'markers',
      layout: {
        format: serverData.layout.format,
        locationField: serverData.layout.locationField,
        fieldsOfInterest: serverData.layout.fieldsOfInterest,
        visibilityZoomLevel: serverData.layout.visibilityZoomLevel,
      },
      data_source: {
        workflow: serverData.data_source.workflow,
        data_store: serverData.data_source.data_store,
      },
      detailView: {
        includeValueField: serverData.detailView.includeValueField,
        displayFieldsOfInterest: serverData.detailView.displayFieldsOfInterest,
      },
      selectedQuestionsMeta: serverData.selectedQuestionsMeta,
    },
    overlays: {
      tabType: 'overlays',
      overlaysArray: serverData.overlays ?? [],
    },
    boundaries: {
      tabType: 'boundaries',
      boundariesArray: serverData.boundaries ?? [],
    },
  };
};
export const mapFormStateToServer = (formState: MapFormStateType) => {
  return {
    name: formState.details.name,
    description: formState.details.description,
    header_info: formState.details.headerInfo,
    icon: formState.details.icon,
    config: {
      data_source: {
        data_store: formState.markers.data_source.data_store,
        workflow: formState.markers.data_source.workflow,
      },
      display: {
        allowFullScreen: formState.details.displaySetup.allowFullScreen,
        showSources: formState.details.displaySetup.showSource,
        searchBar: formState.details.displaySetup.searchBar,
      },
      layout: {
        format: formState.markers.layout.format,
        locationField: formState.markers.layout.locationField,
        fieldsOfInterest: formState.markers.layout.fieldsOfInterest,
        visibilityZoomLevel: formState.markers.layout.visibilityZoomLevel,
      },
      detailView: {
        includeValueField: formState.markers.detailView.includeValueField,
        displayFieldsOfInterest:
          formState.markers.detailView.displayFieldsOfInterest,
      },
      selectedQuestionsMeta: formState.markers.selectedQuestionsMeta,
      cluster: formState.details.displaySetup.clusterMarkers,
      sidebarSections: formState.details.sidebarSections,
      overlays: formState.overlays.overlaysArray,
      boundaries: formState.boundaries.boundariesArray,
    },
  };
};
