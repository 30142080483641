import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  FullScreen,
  FullScreenHandle,
  useFullScreenHandle,
} from 'react-full-screen';
import { toast } from '@tcomponents/ui/toast/use-toast';

export interface IFullscreenContext {
  handle: FullScreenHandle;
  enterFullscreen: () => () => Promise<void>;
}
const defaultFullscreenContext: IFullscreenContext = {
  handle: {
    active: false,
    enter: () => Promise.resolve(),
    exit: () => Promise.resolve(),
    node: { current: null },
  },
  enterFullscreen: () => () => Promise.resolve(),
};

interface IFullscreenContextProps {
  children?: ReactNode;
}

export const FullscreenContext = createContext<IFullscreenContext>(
  defaultFullscreenContext
);
export const FullscreenProvider = (props: IFullscreenContextProps) => {
  const { children } = props;

  const handle = useFullScreenHandle();
  const enterFullscreen = () => {
    return handle.enter;
  };

  let idleMouseTimer: string | number | NodeJS.Timeout | undefined;
  let forceMouseHide = useRef(false);

  const handleMouseInactivity = useCallback((evt: any) => {
    if (forceMouseHide.current) {
      return;
    }

    document.body.style.cursor = '';

    clearTimeout(idleMouseTimer);

    idleMouseTimer = setTimeout(() => {
      document.body.style.cursor = 'none';

      forceMouseHide.current = true;

      setTimeout(() => {
        forceMouseHide.current = false;
      }, 200);
    }, 3000);
  }, []);

  useEffect(() => {
    if (handle.active) {
      toast({
        description: 'Press the escape key at any time to exit fullscreen mode',
        duration: 5000,
      });

      // Your wrapper here
      document.body.addEventListener('mousemove', handleMouseInactivity, false);
    } else {
      document.body.removeEventListener(
        'mousemove',
        handleMouseInactivity,
        false
      );
      clearTimeout(idleMouseTimer);
      document.body.style.cursor = '';

      // Sometimes there's a race condition between clearing the timer and the body cursor
      setTimeout(() => {
        document.body.style.cursor = '';
      }, 3000);
    }
  }, [handle, handleMouseInactivity, idleMouseTimer]);

  return (
    <FullscreenContext.Provider value={{ handle, enterFullscreen }}>
      <FullScreen handle={handle}>{children}</FullScreen>
    </FullscreenContext.Provider>
  );
};

export const FullscreenConsumer = FullscreenContext.Consumer;
