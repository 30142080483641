import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '@/store/utils/createErrorHandler';

export const tableDisplayApi = createApi({
  reducerPath: 'tableDisplayApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Table-Display'],
  endpoints: builder => ({
    getTableConfig: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/table',
          method: 'GET',
        };
      },
    }),
    getTableData: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/table-data',
          method: 'GET',
        };
      },
    }),
    getDataFromDataStore: builder.query({
      query: (nodeUuid: string) => {
        return {
          url: '/graph/data/' + nodeUuid,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
      // @ts-ignore
      onQueryStarted: createErrorHandler(
        'There was a problem loading data from data store'
      ),
    }),
  }),
});

export const {
  useGetTableConfigQuery,
  useGetTableDataQuery,
  useGetDataFromDataStoreQuery,
} = tableDisplayApi;
