import React, { createContext, ReactNode, useContext, useState } from 'react';

export interface ISaveActionContext {
  setIsDirty: (isDirty: boolean) => void;
  isDirty: boolean;
  setSaveAction: (saveAction: any) => void;
  saveAction: any;
}

const defaultContextValues: ISaveActionContext = {
  setIsDirty: () => {},
  isDirty: false,
  setSaveAction: () => {},
  saveAction: {},
};

interface ISaveActionContextProps {
  children?: ReactNode;
}

export const SaveActionContext =
  createContext<ISaveActionContext>(defaultContextValues);
export const SaveActionProvider = (props: ISaveActionContextProps) => {
  const { children } = props;
  const [saveAction, setSaveAction] = useState({ callback: () => {} });
  const [isDirty, setIsDirty] = useState(false);

  return (
    <SaveActionContext.Provider
      value={{ setSaveAction, saveAction, isDirty, setIsDirty }}
    >
      {children}
    </SaveActionContext.Provider>
  );
};

export const SaveActionConsumer = SaveActionContext.Consumer;

export const useSaveActionContext = () => useContext(SaveActionContext)!;
