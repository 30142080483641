import React, { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { organisationPermissions } from '@/stringConsts/permissionConsts';

// Integrations
const OrganisationsList = lazy(
  () => import('@/pages/dashboard/organisations/OrganisationsList')
);
const NewOrganisation = lazy(
  () => import('@/pages/dashboard/organisations/NewOrganisation')
);
const OrganisationManager = lazy(
  () => import('@/pages/dashboard/organisations/OrganisationManager')
);

export const organisationsRoutes = [
  {
    element: (
      <ProtectedRoute permissions={[organisationPermissions.general.read]} />
    ),
    children: [{ path: 'organisations', element: <OrganisationsList /> }],
  },
  {
    element: (
      <ProtectedRoute permissions={[organisationPermissions.general.create]} />
    ),
    children: [{ path: 'organisations/new', element: <NewOrganisation /> }],
  },
  {
    element: (
      <ProtectedRoute permissions={[organisationPermissions.general.edit]} />
    ),
    children: [{ path: 'organisations/:id', element: <OrganisationManager /> }],
  },
];
