import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { string, array, object, record, TypeOf, boolean } from 'zod';

const valueSchema = string();
const labelSchema = string();

const rankingBarSchema = array(
  array(
    object({
      value: valueSchema.optional(),
      label: labelSchema.optional(),
    })
  )
);

const formSchema = object({
  name: string().min(1, 'Name is required'),
  description: string(),
  header_info: string(),
  icon: string().min(1, 'Icon is required'),
  column_label: string(),
  initial_sort: string(),
  sort_direction: string(),
  workflow: string(),
  data_store: string(),
  ranking_bar: rankingBarSchema,
  data_styling: record(string()).nullable(),
  showSources: boolean(),
});

export type FormInput = TypeOf<typeof formSchema>;

export type ApiSubmitHandler = (
  data: ReturnType<typeof transformSubmitData>
) => void;

export default function useRankedListForm(
  onSubmit: ApiSubmitHandler,
  values: any
) {
  const form = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      header_info: '',
      icon: 'fas fa-list-ol',
      column_label: '',
      initial_sort: '_name',
      sort_direction: 'asc',
      workflow: '',
      data_store: '',
      ranking_bar: [[{ label: '', value: '' }]],
      data_styling: {},
      showSources: false,
    },
  });

  return {
    form,
    handleSubmit: form.handleSubmit((data: FormInput) =>
      onSubmit(transformSubmitData(data))
    ),
  };
}

function transformSubmitData(formInput: FormInput) {
  // transforming ranking bar data to match the format of the api, array of arrays
  const rankingBarsData =
    formInput.ranking_bar &&
    formInput.ranking_bar.map((item: any) => {
      return item.map((i: any) => i.value);
    });
  const obj = {
    name: formInput.name,
    description: formInput.description,
    header_info: formInput.header_info,
    icon: formInput.icon,
    config: {
      layout: {
        column_label: formInput.column_label,
        initial_sort: formInput.initial_sort,
        sort_direction: formInput.sort_direction,
      },
      showSources: formInput.showSources,
      data_styling: formInput.data_styling,
      data_source: {
        workflow: formInput.workflow,
        data_store_id: formInput.data_store,
      },
      ranking_bars: rankingBarsData,
    },
  };
  return obj;
}
