import React, { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { SketchPicker } from 'react-color';

type ColorPickerProps = {
  value: string;
  onChange: (color: string) => void;
  colourPickerClassNames?: string;
};
export const defaultColors = [
  '#EB7546',
  '#348FB3',
  '#FFF585',
  '#97DFAF',
  '#386B37',
  '#21A39A',
  '#005F85',
  '#AFBABE',
  '#F19E7D',
  '#71B1CA',
  '#FFF8AA',
  '#B6E9C7',
  '#749773',
  '#64BEB8',
  '#4D8FAA',
  '#C7CFD2',
  '#F7C8B5',
  '#AED2E1',
  '#FFFBCE',
  '#D5F2DF',
  '#B3C7B3',
  '#A6DAD7',
  '#99BFCE',
  '#DFE3E5',
];
const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  colourPickerClassNames,
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(value);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: { hex: string }) => {
    setColor(color.hex);
    onChange(color.hex);
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <div className="relative">
      <div
        onClick={handleClick}
        className="w-8 h-8 rounded-md bg-primary"
        style={{ backgroundColor: color }}
      />
      {displayColorPicker ? (
        <>
          <div
            className="fixed inset-0 bg-white opacity-20"
            onClick={handleClose}
          />
          <div
            className={cn(
              'absolute z-10 left-[-240px] top-[-50px] ',
              colourPickerClassNames
            )}
          >
            <SketchPicker
              color={color}
              onChange={handleChange}
              presetColors={[...defaultColors, 'transparent']}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ColorPicker;
