import React, { FC, ReactNode } from 'react';
import Numbers from '../../../types/enums/common/Numbers';
import GridWidths from '../../../types/enums/grid/GridWidths';
import GridModifiers from '../../../types/enums/grid/GridModifiers';

interface IGridProps {
  children: ReactNode;
  size?: Numbers;
  width?: GridWidths;
  modifiers?: Array<GridModifiers>;
  className?: string;
}

const Grid: FC<IGridProps> = ({
  children,
  size,
  width,
  modifiers = [],
  className = '',
}) => {
  const mods = modifiers?.join(' ');
  let classesAssembled =
    (size ? size + ' column ' : ' ') +
    (width ? width + ' ' : ' ') +
    className +
    ' ' +
    mods;

  return <div className={'ui grid ' + classesAssembled}>{children}</div>;
};

export default Grid;
