import React, { FC, ReactNode } from 'react';

interface IPageActionsProps {
  children: ReactNode;
}

const PageActions: FC<IPageActionsProps> = ({ children }) => {
  return (
    <div className="flex">
      <div
        className="hidden md:flex"
        style={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        {children}
      </div>
    </div>
  );
};

export default PageActions;
