import React, { FC, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { NavigationContext } from '../../context/NavigationContext';
import Icon from '../../components/common/Icon';
import {
  TooltipContent,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
} from '@tcomponents/ui/tooltip';
import { cn } from '@/lib/utils';

interface ISidebarMenuItemProps {
  name: string;
  link?: string;
  target?: string;
  callback?: React.RefCallback<any>;
  children?: React.ReactNode;
  icon?: string;
  header?: boolean;
  dimmed?: boolean;
  setIsMobileSidebarOpen?: (isOpen: boolean) => void;
  id?: number | null;
}

const SidebarMenuItem: FC<ISidebarMenuItemProps> = ({
  name,
  link,
  icon = '',
  target = '',
  id = null,
  dimmed: privateFeature = false,
}) => {
  const { activePage } = useContext(NavigationContext);
  const { featureId } = useParams();

  return (
    <li>
      {privateFeature ? (
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                className={cn(
                  'text-sidebar-icon hover:bg-gray-700 rounded-sm hover:text-white px-2 py-3 flex items-center gap-x-1',
                  privateFeature && 'opacity-60',
                  ((id === null && activePage === name) ||
                    (featureId && id && parseInt(featureId) === id)) &&
                    'bg-gray-700'
                )}
                to={link ? link : '#'}
              >
                <Icon icon={icon} className="text-[17.5px]" />

                <span className="text-white">{name}</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p>Private Feature</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <Link
          className={cn(
            'text-sidebar-icon hover:bg-gray-700 rounded-sm hover:text-white px-2 py-3 flex items-center gap-x-1',
            ((id === null && activePage === name) ||
              (featureId && id && parseInt(featureId) === id)) &&
              'bg-gray-700'
          )}
          to={link ? link : '#'}
          rel={target ? 'noopener noreferrer' : ''}
          target={target}
        >
          <Icon icon={icon} className="text-[17.5px]" />
          <span className="text-white">{name}</span>
        </Link>
      )}
    </li>
  );
};

export default SidebarMenuItem;
