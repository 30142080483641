import React, { FC, RefObject, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { object, string, TypeOf } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@tcomponents/ui/form';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import { SurveyFeatureDetails } from '@/types/feature/featureDetails';

export type FormInput = TypeOf<typeof formSchema>;

interface IWorkflowFormProps {
  onSubmitHandler: SubmitHandler<FormInput>;
  data: SurveyFeatureDetails | undefined;
  setIsDirty: (isDirty: boolean) => void;
  setRefSubmitButton: (submitButtonRef: RefObject<HTMLButtonElement>) => void;
}

const formSchema = object({
  name: string().min(1, 'Name is required'),
  description: string().optional(),
  icon: string().min(1, 'Icon is required'),
});
const SurveyForm: FC<IWorkflowFormProps> = props => {
  const form = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      icon: 'fas fa-clipboard-list-check',
    },
  });
  const {
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;
  const refSubmitButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    props.setRefSubmitButton(refSubmitButton);
  }, [props]);

  useEffect(() => {
    if (typeof props.data !== 'undefined') {
      let obj = {
        ...props.data,
        ...{ description: props.data.description ?? '' },
      };
      reset(obj);
    }
  }, [props.data, reset]);

  useEffect(() => {
    props.setIsDirty(isDirty);
  }, [isDirty, props]);

  return (
    <div className="grid grid-cols-1">
      <Card>
        <CardHeader>
          <CardTitle>Feature Details</CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              className="space-y-4"
              onSubmit={handleSubmit(props.onSubmitHandler)}
            >
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex items-center justify-between">
                      <FormLabel required>Name</FormLabel>
                      <FormMessage />
                    </div>
                    <FormControl>
                      <Input {...field} error={errors.name} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex justify-between">
                      <FormLabel>Description</FormLabel>
                      <FormMessage />
                    </div>
                    <FormControl>
                      <Textarea {...field} error={errors.description} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="icon"
                render={({ field }) => (
                  <FormItem>
                    <div className="flex justify-between">
                      <FormLabel required>Icon</FormLabel>
                      <FormMessage />
                    </div>
                    <div className="flex">
                      <i
                        className={
                          field.value +
                          ' w-10 h-10 border rounded-l-md p-1 flex items-center justify-center bg-gray-500 text-white text-xl'
                        }
                      ></i>
                      <FormControl>
                        <Input
                          {...field}
                          error={errors.icon}
                          className="rounded-l-none "
                        />
                      </FormControl>
                    </div>
                  </FormItem>
                )}
              />
              <button hidden={true} ref={refSubmitButton} type={'submit'} />
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SurveyForm;
