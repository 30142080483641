import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { LocationNodeData } from '../types/node-types';

const LocationNode = ({ data, ...rest }: Node<LocationNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div className="p-1 text-gray-600">
        <div className="flex flex-row gap-1">
          <p className="text-xs font-bold">Source: </p>
          <p className="text-xs truncate">{data.source}</p>
        </div>
        <div className="flex flex-row gap-1">
          <p className="text-xs font-bold">Dest: </p>
          <p className="text-xs truncate">{data.destination}</p>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default LocationNode;
