import { useEffect, useState } from 'react';

import { cn } from '@/lib/utils';
import { Button } from '@/tcomponents/ui/button';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/tcomponents/ui/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@/tcomponents/ui/switch';
import {
  IMapConfigResponse,
  IQuestionAndAnswerVisibility,
} from '../types/types';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { Badge } from '@/tcomponents/ui/badge';
import { boundaryTypes } from '../hooks/useHandleBoundaryTab';
import { defaultMapFormState } from '../helpers/formStates';

type ToggleQuestionAndAnswersVisibility = (
  question: string,
  answer?: string
) => void;

type MapSideBarProps = {
  allQuestions: MapFeatureDetails['selectedQuestionsMeta'];
  questionsAndAnswersVisibility: IQuestionAndAnswerVisibility;
  toggleQuestionAndAnswersVisibility: ToggleQuestionAndAnswersVisibility;
  toggleOverlayVisibility: (id: string) => void;
  mapConfigResponse?: IMapConfigResponse;
  overlayVisibility: Record<string, boolean>;
  boundariesVisibility: Record<string, boolean>;
  toggleBoundaryVisibility: (id: string) => void;
  overlaysLoading: Record<string, boolean>;
  boundariesLoading: Record<string, boolean>;
};

export default function MapSideBar(props: MapSideBarProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedSidebarSection, setSelectedSidebarSection] =
    useState<MapFeatureDetails['sidebarSections'][number]['key']>(
      'markersSection'
    );
  const [openItem, setOpenItem] = useState<string>('item-0');
  const {
    allQuestions,
    questionsAndAnswersVisibility,
    toggleQuestionAndAnswersVisibility,
    mapConfigResponse,
    toggleOverlayVisibility,
    overlayVisibility,
    boundariesVisibility,
    toggleBoundaryVisibility,
    overlaysLoading,
    boundariesLoading,
  } = props;
  const sidebarSections = mapConfigResponse?.data?.sidebarSections;
  const selectedSection = sidebarSections?.find(
    section => section.key === selectedSidebarSection
  );
  const overlays = mapConfigResponse?.data?.overlays ?? [];
  const boundaries = mapConfigResponse?.data?.boundaries ?? [];
  const shouldSidebarRender =
    allQuestions.length > 0 || overlays.length > 0 || boundaries.length > 0;

  // we will check how many sections have data, because we only want to show the sidebar sections if more than one section has data
  const doMoreThanOneSectionHaveData =
    (allQuestions.length > 0 ? 1 : 0) +
      (overlays.length > 0 ? 1 : 0) +
      (boundaries.length > 0 ? 1 : 0) >
    1;

  const MarkersSection = () => {
    return (
      <div>
        <div className="p-3 border-y">
          <h4>{selectedSection?.title ?? 'Markers'}</h4>
          <p className="font-bold text-gray-400">
            {selectedSection?.description ?? ''}
          </p>
        </div>
        <Accordion
          type="single"
          collapsible
          className="w-full bg-white"
          defaultValue={openItem}
          value={openItem}
        >
          {allQuestions?.map((item, idx) => (
            <AccordionItem key={idx} value={`item-${idx}`}>
              {allQuestions.length > 1 && (
                <div
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => {
                    toggleQuestionAndAnswersVisibility(item.question);
                    setOpenItem(
                      openItem === `item-${idx}` ? '' : `item-${idx}`
                    );
                  }}
                >
                  <div className="flex items-center gap-x-2">
                    <AccordionTrigger className="text-2xl text-gray-400" />
                    <h6>{item.question}</h6>
                  </div>
                  <Switch
                    className="mr-2"
                    checked={
                      questionsAndAnswersVisibility[item.question]?.visible
                    }
                  />
                </div>
              )}
              {item.answers
                ?.filter((answerData, idx) => answerData.answer)
                ?.map((answerData, idx) => (
                  <AccordionContent
                    key={idx}
                    className={
                      'border-t cursor-pointer ' +
                      (idx % 2 !== 0 && 'bg-gray-50')
                    }
                    onClick={() =>
                      toggleQuestionAndAnswersVisibility(
                        item.question,
                        answerData.answer
                      )
                    }
                  >
                    <div className="flex items-center justify-between px-2 pt-3">
                      <div className="flex items-center gap-x-2">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          color={answerData.color}
                          size="xl"
                        />
                        <p className="pr-4">{answerData.answer}</p>
                      </div>
                      <Switch
                        disabled={
                          !questionsAndAnswersVisibility[item.question]?.visible
                        }
                        checked={
                          questionsAndAnswersVisibility[item.question]?.answers[
                            answerData.answer
                          ]
                        }
                      />
                    </div>
                  </AccordionContent>
                ))}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  };
  const BoundariesSection = () => {
    return (
      <div>
        <div className="p-3 border-y">
          <h4>{selectedSection?.title ?? 'Boundaries'}</h4>
          <p className="font-bold text-gray-400">
            {selectedSection?.description ?? ''}
          </p>
        </div>
        <div className="flex flex-col w-full divide-y gap-y-4">
          {boundaries?.map((boundary, idx) => (
            <div
              key={boundary.id}
              className={cn('flex items-center px-3 pt-3 w-full', {
                '!border-b pb-3': idx === boundaries.length - 1,
              })}
            >
              <div className="flex justify-between w-full gap-x-2">
                <div className="flex items-center gap-x-3">
                  <span
                    className="flex items-center justify-center min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] border border-gray-400"
                    style={{
                      background:
                        boundary.type === boundaryTypes.Heatmap
                          ? `linear-gradient(${boundary.maxColor}, ${boundary.minColor})`
                          : boundary.type === boundaryTypes.Simple
                          ? boundary.color
                          : boundary.type === boundaryTypes.Category
                          ? `repeating-linear-gradient(180deg, ${boundary.theming
                              .map(
                                (theme, index) =>
                                  `${theme.color} ${index * 4}px, ${
                                    theme.color
                                  } ${(index + 1) * 4}px`
                              )
                              .join(',')})`
                          : '',
                    }}
                  >
                    {boundariesLoading[boundary.id] && (
                      <i className="text-[18px] font-extrabold text-white fas fa-spinner fa-spin"></i>
                    )}
                  </span>
                  <div>
                    <h6>{boundary.title}</h6>
                    {boundary.type === boundaryTypes.Heatmap && (
                      <Badge className="-ml-[2px]" variant="destructive">
                        Heatmap
                      </Badge>
                    )}
                    {boundary.type === boundaryTypes.Category && (
                      <Badge
                        className="-ml-[2px] bg-brand text-white"
                        variant="outline"
                      >
                        Category
                      </Badge>
                    )}
                  </div>
                </div>
                <Switch
                  checked={boundariesVisibility[boundary.id]}
                  onCheckedChange={() => {
                    toggleBoundaryVisibility(boundary.id);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const OverlaysSection = () => {
    return (
      <div>
        <div className="p-3 border-y">
          <h4>{selectedSection?.title ?? 'Overlays'}</h4>
          <p className="font-bold text-gray-400">
            {selectedSection?.description ?? ''}
          </p>
        </div>
        <div className="flex flex-col w-full divide-y gap-y-4">
          {overlays?.map((overlay, idx) => (
            <div
              key={overlay.id}
              className={cn(
                'flex items-center justify-between px-3 pt-3 w-full',
                {
                  '!border-b pb-3': idx === overlays.length - 1,
                }
              )}
            >
              <div className="flex justify-between w-full gap-x-2">
                <div className="flex items-center gap-x-3">
                  <span
                    className="flex items-center justify-center min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] border border-gray-400"
                    style={{ backgroundColor: overlay.color }}
                  >
                    {overlaysLoading[overlay.id] && (
                      <i className="text-[18px] font-extrabold text-white fas fa-spinner fa-spin"></i>
                    )}
                  </span>
                  <h6>{overlay.title}</h6>
                </div>
              </div>
              <Switch
                checked={overlayVisibility[overlay.id]}
                onCheckedChange={() => toggleOverlayVisibility(overlay.id)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  const renderSidebarSection = (
    key: MapFeatureDetails['sidebarSections'][number]['key']
  ) => {
    switch (key) {
      case 'markersSection':
        return <MarkersSection />;
      case 'boundariesSection':
        return <BoundariesSection />;
      case 'overlaysSection':
        return <OverlaysSection />;
      default:
        return null;
    }
  };

  useEffect(() => {
    allQuestions.length
      ? setSelectedSidebarSection('markersSection')
      : overlays.length
      ? setSelectedSidebarSection('overlaysSection')
      : boundaries.length && setSelectedSidebarSection('boundariesSection');
  }, [allQuestions.length, overlays.length, boundaries.length]);

  return (
    <>
      {shouldSidebarRender && (
        <div
          className={cn(
            'relative h-full transition-all bg-white duration-500  w-[0px] z-20 flex',
            isSidebarOpen && 'w-[500px]'
          )}
        >
          <Button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            variant={'ghost'}
            className="absolute left-0 transform -rotate-90 -translate-y-1/2 -ml-[70px] top-1/2 bg-white text-primary h-[25px] px-2.5 text-[12px] rounded-b-none"
          >
            <FontAwesomeIcon
              icon={faCaretUp}
              className={cn(
                'mr-2 mt-1 transition-all duration-1000 py-0',
                isSidebarOpen && 'mb-1.5 -rotate-180'
              )}
              size="xl"
            />
            Map Controls
          </Button>
          {/* Map Sections Control */}
          {doMoreThanOneSectionHaveData && (
            <div className=" w-[65px] h-full bg-[#F1F1F1] border-x border-gray-300  flex flex-col">
              {(
                sidebarSections ?? defaultMapFormState.details.sidebarSections
              )?.map(section => (
                <div
                  className={cn(
                    'px-1 py-2 border-b border-white text-primary hover:text-white hover:bg-primary',
                    {
                      'bg-primary text-white':
                        selectedSidebarSection === section.key,
                      hidden:
                        (section.key === 'markersSection' &&
                          !allQuestions.length) ||
                        (section.key === 'boundariesSection' &&
                          !boundaries.length) ||
                        (section.key === 'overlaysSection' && !overlays.length),
                    }
                  )}
                  key={section.key}
                  onClick={() =>
                    setSelectedSidebarSection(
                      section.key as
                        | 'markersSection'
                        | 'boundariesSection'
                        | 'overlaysSection'
                    )
                  }
                >
                  <i
                    key={section.key}
                    className={
                      section.icon +
                      ' flex items-center justify-center text-3xl'
                    }
                  ></i>
                  <p className=" text-[10px] text-center">{section.title}</p>
                </div>
              ))}
            </div>
          )}
          <div className="grow">
            <div
              className={cn(
                'opacity-0 transition-opacity delay-0 duration-0 overflow-y-auto max-h-full',
                isSidebarOpen && 'opacity-100 delay-500 duration-500'
              )}
            >
              {renderSidebarSection(selectedSidebarSection)}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
