import React, { lazy } from 'react';

const Login = lazy(() => import('../../pages/authentication/Login'));
const Logout = lazy(() => import('../../pages/authentication/Logout'));
const ForgotPassword = lazy(
  () => import('../../pages/authentication/ForgotPassword')
);
const ResetPassword = lazy(
  () => import('../../pages/authentication/ResetPassword')
);
const NotFound = lazy(() => import('../../pages/NotFound'));
const Unauthorized = lazy(() => import('../../pages/Unauthorized'));
const ServerError = lazy(() => import('../../pages/ServerError'));
const EndUserAgreement = lazy(() => import('../../pages/EndUserAgreement'));
const InvalidToken = lazy(
  () => import('../../pages/authentication/ExpiredToken')
);
const PublicDataView = lazy(
  () => import('../../pages/publicData/publicDataView')
);

export const unauthenticatedRoutes = [
  { path: 'login', element: <Login /> },
  { path: 'forgot-password', element: <ForgotPassword /> },
  { path: 'reset-password', element: <ResetPassword /> },
  { path: 'end-user-agreement', element: <EndUserAgreement /> },
  { path: 'invalid-token', element: <InvalidToken /> },
  { path: 'unauthorized', element: <Unauthorized /> },
  { path: 'server-error', element: <ServerError /> },
  { path: 'logout', element: <Logout /> },
  { path: '*', element: <NotFound /> },
  { path: '/public/:type/:uuid', element: <PublicDataView /> },
];
