import { useGetFeatureGroupQuery } from '@/store/services/projects/featureGroupApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import FeatureGroupInterface from '@/types/interfaces/api-response/FeatureGroupInterface';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useGetFeatureGroup = () => {
  let { featureGroupId, projectId } = useParams();

  const [featureGroupDetails, setFeatureGroupDetails] =
    useState<FeatureGroupInterface>();

  const navigate = useNavigate();

  const featureGroup = useGetFeatureGroupQuery(
    {
      id: Number(featureGroupId),
      projectId: Number(projectId),
    },
    {
      skip: !featureGroupId || !projectId,
    }
  );

  // useEffect for fetching feature group details
  useEffect(() => {
    if (featureGroupId && featureGroup.isSuccess) {
      setFeatureGroupDetails(featureGroup.data.data);
    }
    if (featureGroupId && featureGroup.isError) {
      if (
        isApiErrorResponse(featureGroup.error) &&
        featureGroup.error.status !== 403
      ) {
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: 'There was a problem loading feature group details',
          duration: 24 * 60 * 60000,
        });

        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [featureGroupId, featureGroup]);

  return {
    featureGroupDetails,
  };
};
