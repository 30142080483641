import { ChartType } from '../types/chart-types';

export const calculateHeightOfWidget = (chartType: ChartType | undefined) => {
  if (chartType === ChartType.Statistics) {
    return 5;
  }

  if (chartType === ChartType.StackedBar) {
    return 9;
  }

  if (
    chartType === ChartType.Line ||
    chartType === ChartType.Area ||
    chartType === ChartType.Pie
  ) {
    return 10.5;
  }

  if (chartType === ChartType.IFrame) {
    return 18;
  }

  return 12;
};
