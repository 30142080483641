import React, { useEffect, useState } from 'react';
import { EndpointNodeData } from '../../types/node-types';
import { Label } from '@tcomponents/ui/label';
import { Switch } from '@tcomponents/ui/switch';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import { cn } from '@/lib/utils';
import { buttonVariants, IconButton } from '@tcomponents/ui/button';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import { useParams } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { cloneDeep } from 'lodash';

const EndpointNodeFormFields = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const params = useParams();

  useEffect(() => {
    if (!activeNode.data.hasOwnProperty('endpoint')) {
      const dataCopy = { ...activeNode.data };

      dataCopy.endpoint = '';
      updateNodeData(dataCopy);
    }
  }, [activeNode]);

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);

    if (field === 'endpoint') {
      const data = JSON.parse(examplePayload);

      data.endpoint = val;

      setExamplePayload(prettifyJson(data));
    }
  };

  const handlePreviewParams = () => {
    const data = JSON.parse(examplePayload);

    data.data = {};

    let fieldValue: any[] = [];

    activeNode.data.fields.forEach(
      (
        field: { key: string; required: boolean; validation: string },
        idx: number
      ) => {
        switch (field.validation) {
          case 'string':
            fieldValue[idx] = 'Any String';
            break;
          case 'number':
            fieldValue[idx] = 123;
            break;
          case 'alphanumeric':
            fieldValue[idx] = 'abc123';
            break;
          case 'boolean':
            fieldValue[idx] = true;
            break;
          default:
            fieldValue[idx] = 'Anything';
        }

        data.data[field.key] = fieldValue[idx];
      }
    );

    setExamplePayload(prettifyJson(data));
  };

  const handleParamChange = (
    idx: number,
    key: string,
    validation: string,
    required: boolean
  ) => {
    let dataCopy = cloneDeep({ ...activeNode.data });
    dataCopy.fields[idx] = { key, validation, required };
    updateNodeData(dataCopy);
    handlePreviewParams();
  };

  const handleParamDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.fields = dataCopy.fields.filter((_: any, i: number) => i !== idx);
    updateNodeData(dataCopy);
    handlePreviewParams();
  };

  const handleAddParam = () => {
    const dataCopy = { ...activeNode.data };
    dataCopy.fields = [
      ...activeNode.data.fields,
      { key: '', validation: 'any', enabled: false },
    ];
    updateNodeData(dataCopy);
  };

  const { enabled, endpoint, fields, allowCustomFields } =
    activeNode.data as EndpointNodeData;

  const initialState = {
    feature_id: parseInt(params.featureId ?? ''),
    endpoint: `${endpoint ?? ''}`,
    data: {},
  };

  useEffect(() => {
    handlePreviewParams();
  }, []);

  const prettifyJson = (data: object) => JSON.stringify(data, null, '\t');

  const [examplePayload, setExamplePayload] = useState(
    prettifyJson(initialState)
  );

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      {/* Request block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Request Details</h5>
          </div>
          <div className="flex flex-col py-2 gap-y-2">
            <label className="text-xs font-bold">Endpoint</label>
            <div>{process.env.REACT_APP_API_SUBDOMAIN_URL + '/workflow'}</div>

            <div className={'flex flex-col'}>
              <label className="mb-2 text-xs font-bold">Body Content</label>
              <div className="flex items-center space-x-2">
                <div className="bg-[#ECECEC] w-full p-2">
                  <pre className="whitespace-pre-wrap">{examplePayload}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Configuration block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md ' +
            (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between mb-1">
            <h5 className="font-bold">Configuration</h5>
          </div>

          <label className="pt-2 text-xs font-bold">Endpoint</label>

          <div className={'flex flex-row border-none'}>
            <input
              className="flex-1 p-1 border rounded-sm"
              type="text"
              value={endpoint}
              onChange={e =>
                handleSingleInputFieldChange('endpoint', e.target.value)
              }
            />
          </div>
          <div className={'flex flex-row  border-none'}>
            <div className="flex flex-col flex-1 py-2 gap-y-2">
              <div className="flex flex-col gap-y-2">
                <label className="text-xs font-bold">Allow Custom Fields</label>
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={allowCustomFields}
                    onCheckedChange={val =>
                      handleSingleInputFieldChange('allowCustomFields', val)
                    }
                    id="allowsCustomFields"
                  />
                  <Label htmlFor="isEnabled">
                    {allowCustomFields ? 'Yes' : 'No'}
                  </Label>
                </div>
              </div>
            </div>
          </div>

          <div className={'flex flex-row border-none'}>
            <div className="flex flex-col flex-1 py-2 gap-y-2">
              <div className="flex flex-col gap-y-2">
                <label className="text-xs font-bold">Enabled</label>
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={enabled}
                    onCheckedChange={val =>
                      handleSingleInputFieldChange('enabled', val)
                    }
                    id="isEnabled"
                  />
                  <Label htmlFor="isEnabled">{enabled ? 'Yes' : 'No'}</Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* fields block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md ' +
            (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <h5 className="font-bold ">Fields</h5>
          {fields.length >= 1 && (
            <div className="flex pt-[5px] justify-between font-bold">
              <div className="">Key</div>
              <div
                className={
                  isExpanded
                    ? 'pr-[105px] text-center grow'
                    : 'pr-12 text-center grow'
                }
              >
                Type
              </div>
              <div className="mr-5 ">Required</div>
            </div>
          )}
          {fields.map((field, index) => (
            <div
              key={`field_${index}`}
              className="flex items-center justify-between gap-x-2 pt-[5px]"
            >
              <input
                className={
                  'border p-1 text-sm rounded-sm ' +
                  (isExpanded ? ' w-1/2 mr-3 h-10' : 'w-[60px] h-10')
                }
                type="text"
                placeholder="Key"
                value={field.key}
                onChange={e =>
                  handleParamChange(
                    index,
                    e.target.value,
                    field.validation,
                    field.required
                  )
                }
              />

              <Select
                defaultValue={'any'}
                value={field.validation}
                onValueChange={val =>
                  handleParamChange(index, field.key, val, field.required)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder={'Any'} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectItem value="any">Any</SelectItem>
                    <SelectItem value="number">Number</SelectItem>
                    <SelectItem value="boolean">Boolean</SelectItem>
                    <SelectItem value="alphanumeric">Alpha Numeric</SelectItem>
                    <SelectItem value="string">String</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <div className="flex items-center gap-x-1">
                <Switch
                  checked={field.required}
                  onCheckedChange={val =>
                    handleParamChange(index, field.key, field.validation, val)
                  }
                  id="isEnabled"
                />

                <IconButton
                  icon={faXmark}
                  onClick={() => handleParamDeletion(index)}
                  className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm "
                />
              </div>
            </div>
          ))}
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddParam}
            data-testing="add_field"
            aria-label="add field"
          >
            + Add Field
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndpointNodeFormFields;
