import ProtectedRoute from '@/routes/ProtectedRoute';
import { projectPermissions } from '@/stringConsts/permissionConsts';
import React, { lazy } from 'react';

const ProjectsList = lazy(() => import('../../pages/dashboard/projects/List'));
const ProjectSettings = lazy(
  () => import('../../pages/dashboard/projects/Settings')
);
const ProjectView = lazy(() => import('../../pages/dashboard/projects/View'));

export const projectRoutes = [
  { path: '/', element: <ProjectsList /> },
  // Projects
  { path: 'projects', element: <ProjectsList /> },
  { path: 'projects/:id', element: <ProjectView /> },
  { path: 'projects/:id/settings', element: <ProjectSettings /> },
  {
    element: (
      <ProtectedRoute permissions={[projectPermissions.general.create_new]} />
    ),
    children: [{ path: 'projects/new', element: <ProjectSettings /> }],
  },
];
