import { LatLngExpression } from 'leaflet';

// Initial Map config settings
const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const config = {
  settings: {
    center: [54.8, -3.0] as LatLngExpression,
    zoom: 6,
    scrollWheelZoom: true,
    zoomControl: false,
    attributionControl: false,
  },
  layers: [
    {
      name: 'Simple',
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      url: `https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png`,
    },
    {
      name: 'Transport',
      attribution:
        '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a>OpenStreetMap</a> contributors',
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    },
    {
      name: 'Satellite',
      attribution:
        '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a>',
      url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`,
    },
  ],
  defaultMarkerColor: '#265F81',
};
