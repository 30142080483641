import TailwindShowcase from '@/pages/unused/TailwindShowcase';
import React, { lazy } from 'react';

const PlanningApplicationList = lazy(
  () => import('../../pages/dashboard/planning-application/List')
);

const AddressLookupMap = lazy(
  () => import('../../pages/services/AddressLookupMap')
);
export const servicesRoutes = [
  { path: 'address-lookup-map', element: <AddressLookupMap /> },
  { path: 'tailwind-components', element: <TailwindShowcase /> },
  { path: 'planning-application', element: <PlanningApplicationList /> },
];
