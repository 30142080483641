import { cn } from '@/lib/utils';
import { Button } from '@/tcomponents/ui/button';
import { Input } from '@/tcomponents/ui/input';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

type AcceptedImageType =
  | 'image/jpg'
  | 'image/png'
  | 'image/gif'
  | 'image/jpeg'
  | 'image/webp';

const DEFAULT_ACCEPTED_IMAGE_TYPES = [
  'image/jpg',
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/webp',
];

type Props = {
  image: string | null | undefined;
  handleImageUpload: (
    e: React.ChangeEvent<HTMLInputElement>,
    file: Blob | string,
    index?: number
  ) => void;
  handleImageRemove: (index?: number) => void;
  isImageAnIcon?: boolean;
  className?: string;
  index?: number;
  maxFileSize?: number;
  acceptedImageTypes?: AcceptedImageType[];
  rules?: string;
};
const CustomImageField = (props: Props) => {
  const {
    image,
    handleImageUpload,
    isImageAnIcon,
    handleImageRemove,
    className,
    index,
    maxFileSize,
    acceptedImageTypes = DEFAULT_ACCEPTED_IMAGE_TYPES,
    rules,
  } = props;
  const fileFormats = acceptedImageTypes.map(type =>
    type.split('/')[1].toUpperCase()
  );
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const fileSizeLimit = isImageAnIcon
    ? 1024 * 1024
    : maxFileSize
    ? 1024 * 1024 * maxFileSize
    : 1024 * 1024 * 8;

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files.length) {
      //1Mb in bytes = 1024 * 1024
      if (
        event.target.files.length > 0 &&
        event.target?.files[0].size <= fileSizeLimit
      ) {
        handleImageUpload(event, event.target?.files[0], index);
      } else {
        toast({
          title: 'Uh oh! File Too Big',
          variant: 'destructive',
          description: `The image should be ${`${maxFileSize ?? 8}Mb`} or less`,
          duration: 24 * 60 * 60000,
        });
      }
    }
  };

  return (
    <div className="flex items-center">
      {image && (
        <div
          className={cn(
            'relative border rounded-md bg-background max-h-[400px]',
            isImageAnIcon ? 'w-[110px] h-[110px]' : 'w-full',
            className
          )}
        >
          <button
            type="button"
            className="absolute top-0 right-1 cursor-pointer text-[14px] font-bold"
            onClick={() => {
              index ? handleImageRemove(index) : handleImageRemove();
            }}
            title={isImageAnIcon ? 'Remove Icon' : 'Remove Image'}
          >
            ×
          </button>
          <div className={cn('flex justify-center items-center h-full')}>
            <img
              src={image ?? ''}
              alt={isImageAnIcon ? 'Favicon' : 'Image'}
              className={cn(
                'max-h-[200px] max-w-full w-full h-full object-contain'
              )}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col ml-3 h-[120px] w-[120px] justify-center items-start">
        <Button
          type="button"
          icon={faUpload}
          className="button-upload"
          onClick={() => hiddenInputRef.current?.click()}
        >
          Upload
        </Button>
        {isImageAnIcon ? (
          <>
            <span className="font-bold text-[#B1B1B1DE]">
              {rules ?? '64px by 64px'}
            </span>
            <span className="font-bold text-[#B1B1B1DE]">1Mb max</span>
            <span className="font-bold text-[#B1B1B1DE]">
              {fileFormats?.join(', ')}
            </span>
          </>
        ) : (
          <>
            <span className="font-bold text-[#B1B1B1DE]">{`${(
              fileSizeLimit /
              (1024 * 1024)
            ).toFixed(0)}Mb max`}</span>
            <span className="font-bold text-[#B1B1B1DE]">
              {fileFormats?.join(', ')}
            </span>
          </>
        )}
        <Input
          type="file"
          className="items-center justify-center hidden cursor-pointer"
          accept={acceptedImageTypes.join(', ')}
          multiple={false}
          ref={hiddenInputRef}
          onChange={handleImageChange}
        />
      </div>
    </div>
  );
};

export default CustomImageField;
