import { useSaveActionContext } from '@/context/SaveActionContext';
import { useState } from 'react';

type TReturnTypes = {
  columnTitles: {
    left: string;
    right: string;
  };
  setColumnTitles: React.Dispatch<
    React.SetStateAction<{
      left: string;
      right: string;
    }>
  >;
  handleColumnTitlesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const useColumnTitles = (): TReturnTypes => {
  const { setIsDirty } = useSaveActionContext();

  const [columnTitles, setColumnTitles] = useState({
    left: '',
    right: '',
  });

  const handleColumnTitlesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColumnTitles(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setIsDirty(true);
  };

  return {
    columnTitles,
    setColumnTitles,
    handleColumnTitlesChange,
  };
};
