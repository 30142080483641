import {
  useGetDashboardConfigQuery,
  useGetWidgetsDataQuery,
} from '@/store/services/projects/chartApi';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { DashboardFeatureDetails } from '@/types/feature/featureDetails';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useGetWidgetsData() {
  const { featureId: id, featureType } = useParams();
  const [widgetsDataFromServer, setWidgetsDataFromServer] = useState<
    Record<string, any[]>
  >({});
  const feature = useGetFeatureQuery(Number(id), {
    skip:
      id === 'new' || !id || featureType?.toLocaleLowerCase() !== 'dashboard',
  });
  const { data: dashboardConfig } = useGetDashboardConfigQuery(
    parseInt(id as string),
    {
      skip:
        id === 'new' || !id || featureType?.toLocaleLowerCase() !== 'dashboard',
    }
  );

  const refreshInterval = (feature.data?.data as DashboardFeatureDetails)
    ?.refresh;

  const widgetsData = useGetWidgetsDataQuery(Number(id), {
    skip:
      id === 'new' ||
      !id ||
      dashboardConfig?.data?.widgets.length === 0 ||
      !dashboardConfig?.data ||
      featureType?.toLocaleLowerCase() !== 'dashboard',
    pollingInterval: refreshInterval && refreshInterval * 60000,
  });

  const { isLoading, isError } = widgetsData;

  useEffect(() => {
    if (widgetsData.isSuccess) {
      const widgetsDataFromServer = widgetsData.data?.data;
      setWidgetsDataFromServer(widgetsDataFromServer);
    }
  }, [widgetsData.isSuccess, widgetsData.data?.data]);

  const lastUpdated = widgetsData?.data?.last_updated;
  const sources = widgetsData?.data?.sources;
  const information = widgetsData?.data?.information;

  return {
    lastUpdated,
    sources,
    information,
    widgetsDataFromServer,
    setWidgetsDataFromServer,
    isWidgetsDataLoading: isLoading,
    isWidgetsDataError: isError,
  };
}
