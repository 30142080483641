import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store';
import { authApi } from './store/services/users/authApi';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { userApi } from './store/services/users/userApi';
import { projectApi } from './store/services/projects/projectApi';
import { featureGroupApi } from './store/services/projects/featureGroupApi';
import { featureApi } from './store/services/projects/featureApi';
import { graphApi } from './store/services/projects/graphApi';
import { chartApi } from './store/services/projects/chartApi';
import { rankedListApi } from './store/services/projects/rankedListApi';
import { mapApi } from './store/services/projects/mapApi';
import { surveyApi } from './store/services/projects/surveyApi';
import { generalApi } from '@/store/services/generalApi';
import { tableDisplayApi } from '@/store/services/projects/tableDisplayApi';
import { aiToolApi } from './store/services/aiToolApi';
import { mapManagerApi } from '@/store/services/mapManagerApi';
import { aggregationApi } from '@/store/services/aggregationApi';
import { integrationApi } from './store/services/integrationApi';
import { roleManagementApi } from './store/services/roleManagementApi';
import { publicDataApi } from '@/store/services/publicDataApi';
import { receiverNodeApi } from '@/store/services/receiverNodeApi';
import { organisationApi } from './store/services/organisationApi';

export const store = configureStore({
  reducer: rootReducer,
  // devTools: devToolsEnhancer(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      userApi.middleware,
      projectApi.middleware,
      featureGroupApi.middleware,
      featureApi.middleware,
      graphApi.middleware,
      chartApi.middleware,
      rankedListApi.middleware,
      mapApi.middleware,
      surveyApi.middleware,
      generalApi.middleware,
      tableDisplayApi.middleware,
      aiToolApi.middleware,
      mapManagerApi.middleware,
      aggregationApi.middleware,
      integrationApi.middleware,
      roleManagementApi.middleware,
      publicDataApi.middleware,
      receiverNodeApi.middleware,
      organisationApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
