import { useGetGraphQuery } from '@/store/services/projects/graphApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomGraphContext } from '../CustomGraphProvider';
import { useContext, useEffect } from 'react';
import { SaveActionContext } from '@/context/SaveActionContext';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';

export const useGetGraph = () => {
  const { featureId } = useParams();

  const { setNodes, setEdges, setPipelineData } = useCustomGraphContext();
  const { setIsDirty } = useContext(SaveActionContext);
  const navigate = useNavigate();
  const {
    data,
    isSuccess,
    isError,
    refetch: refetchGraph,
    error,
  } = useGetGraphQuery(Number(featureId), {
    skip: !featureId,
  });

  useEffect(() => {
    if (isSuccess) {
      const { nodes, edges, subGraphs } = data.data;
      setNodes(
        // @ts-ignore
        nodes.map(node => {
          if (node.state === 'Error') {
            return {
              ...node,
              data: {
                ...node.data,
                error_title: node.error_title,
                error_message: node.error_message,
                state: node.state,
                isError: true,
              },
            };
          }
          return node;
        })
      );

      let mappedNodes = [];
      let dupeSubGraphs = {};
      for (let uuid in subGraphs) {
        if (subGraphs.hasOwnProperty(uuid)) {
          // @ts-ignore
          mappedNodes = subGraphs[uuid].nodes.map(node => {
            if (node.state === 'Error') {
              return {
                ...node,
                data: {
                  ...node.data,
                  error_title: node.error_title,
                  error_message: node.error_message,
                  state: node.state,
                  isError: true,
                },
              };
            }
            return node;
          });

          // @ts-ignore
          dupeSubGraphs[uuid] = {
            nodes: mappedNodes,
            edges: subGraphs[uuid].edges,
          };
        }
      }

      setIsDirty(true);

      setEdges(edges);
      setPipelineData(dupeSubGraphs);
    }
    if (isError) {
      if (isApiErrorResponse(error) && error.status !== 403) {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'There was a problem with your request.',
        });
        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [isSuccess, data, isError]);

  return {
    refetchGraph,
  };
};
