import { useSaveActionContext } from '@/context/SaveActionContext';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { useEffect, useState } from 'react';
import { useSurveyContext } from '../SurveyProvider';
import { useUploadSurveyAssetMutation } from '@/store/services/projects/surveyApi';
import { get, set } from 'lodash';
import { v4 as uuid } from 'uuid';

export enum ImageTypes {
  browser_icon = 'browser_icon',
  social_share = 'social_share',
  welcome_image = 'welcome_image',
  submit_image = 'submit_image',
}

const imagePaths: Record<string, string> = {
  [ImageTypes.browser_icon]: 'setup.browser_tab.icon',
  [ImageTypes.social_share]: 'setup.social_share.image',
  [ImageTypes.welcome_image]: 'welcome.page_content.image',
  [ImageTypes.submit_image]: 'submit.page_content.image',
};
export const useUploadImage = (imageType: ImageTypes) => {
  const [image, setImage] = useState<string>('');
  const { setIsDirty } = useSaveActionContext();
  const { surveyConfigId, formContent, setFormContent } = useSurveyContext();

  const [uploadSurveyAsset] = useUploadSurveyAssetMutation();
  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: Blob | string
  ) => {
    e.preventDefault();
    let formBody = new FormData();
    formBody.append('id', surveyConfigId?.toString() ?? '');
    formBody.append('type', `${imageType}-${uuid()}`);
    formBody.append('asset', file);

    uploadSurveyAsset(formBody)
      .unwrap()
      .then(response => {
        toast({
          duration: 3000,
          description: 'Uploaded the image',
        });
        setImage(response.path);
        setIsDirty(true);
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh!',
          description: 'Could not upload the image',
        });
        setImage('');
      });
  };

  const handleImageRemove = () => {
    const path = imagePaths[imageType];
    if (path) {
      const obj = { ...formContent };
      set(obj, path, '');
      setFormContent(obj);
      setImage('');
      setIsDirty(true);
    }
    setImage('');
    setIsDirty(true);
  };
  // use effect to update the form content when the image changes
  useEffect(() => {
    if (!image) return;
    const path = imagePaths[imageType];
    if (path) {
      const obj = { ...formContent };
      set(obj, path, image);
      setFormContent(obj);
    }
  }, [image]);

  // use effect to set the image when the component mounts
  useEffect(() => {
    const path = imagePaths[imageType];
    if (path) {
      const initialImage: string = get(formContent, path) ?? '';
      setImage(initialImage);
    }
  }, [formContent, imageType, surveyConfigId]);

  return { image, handleImageUpload, handleImageRemove };
};
