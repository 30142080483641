import { useGetProjectAndFeatureDetails } from '@/hooks/useGetProjectAndFeatureDetails';
import { createContext, useContext, useMemo, useState } from 'react';
import { useHandleFormContent } from './hooks/useHandleFormContent';
import { MapFormStateType, defaultMapFormState } from './helpers/formStates';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { useGetAllShapesQuery } from '@/store/services/mapManagerApi';
import { LayerFromServer } from './types/shape';

interface MapSettingsProviderProps {
  children: React.ReactNode;
}

const defaultContextValue = {
  projectDetails: null,
  featureDetails: null,
  pageTitle: '',
  pageSubTitle: '',
  formContent: defaultMapFormState,
  setFormContent: () => {},
  errors: { nameError: '' },
  setErrors: () => {},
  allShapesResponse: { data: [] as LayerFromServer[] },
};

interface IMapSettingsContext {
  projectDetails: any;
  featureDetails: any;
  pageTitle: string;
  pageSubTitle: string;
  formContent: MapFormStateType;
  setFormContent: React.Dispatch<React.SetStateAction<MapFormStateType>>;
  errors: Record<string, string>;
  setErrors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  allShapesResponse?: { data: LayerFromServer[] };
}

const MapSettingsContext =
  createContext<IMapSettingsContext>(defaultContextValue);

const MapSettingsProvider = (props: MapSettingsProviderProps) => {
  const [errors, setErrors] = useState<Record<string, string>>({
    nameError: '',
  });
  const { projectDetails, pageTitle, pageSubTitle, featureDetails } =
    useGetProjectAndFeatureDetails();

  const { formContent, setFormContent } = useHandleFormContent(
    featureDetails as MapFeatureDetails
  );
  const { data: allShapesResponse } = useGetAllShapesQuery();
  const context = useMemo(() => {
    return {
      projectDetails,
      featureDetails,
      pageTitle,
      pageSubTitle,
      formContent,
      setFormContent,
      errors,
      setErrors,
      allShapesResponse,
    };
  }, [
    projectDetails,
    featureDetails,
    pageTitle,
    pageSubTitle,
    formContent,
    setFormContent,
    errors,
    allShapesResponse,
  ]);

  return (
    <MapSettingsContext.Provider value={context}>
      {props.children}
    </MapSettingsContext.Provider>
  );
};
export const useMapSettingsContext = () => {
  const context = useContext(MapSettingsContext);
  if (!context) {
    throw new Error(
      'useMapSettingsContext must be used within a SurveyProvider'
    );
  }
  return context;
};
export default MapSettingsProvider;
