import React from 'react';
import { DropKeysNodeData } from '../../types/node-types';
import { useCustomGraphContext } from '../../CustomGraphProvider';

export default function DropKeysFormFields() {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const handleAddKey = () => {
    const dataCopy = { ...activeNode.data };
    dataCopy.keys.push('');
    updateNodeData(dataCopy);
  };

  const handleKeyChange = (idx: number, key: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.keys[idx] = key;
    updateNodeData(dataCopy);
  };

  const handleKeyDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data } as DropKeysNodeData;
    dataCopy.keys = dataCopy.keys.filter((_, i) => i !== idx);
    updateNodeData(dataCopy);
  };

  const { keys } = activeNode.data as DropKeysNodeData;

  return (
    <div
      className={
        'flex flex-col divide-y-[1px] gap-2 border p-2 shadow-md m-2 bg-white rounded-md' +
        (isExpanded ? ' w-1/2' : '')
      }
    >
      <h5 className="ml-2 font-bold">Keys to Drop</h5>
      {keys.map((key, index) => (
        <div
          key={`param_${index}`}
          className="flex items-center justify-between p-1"
        >
          <input
            className="border p-2 mt-1.5 rounded-sm w-[200px]"
            type="text"
            placeholder="Key"
            value={key}
            onChange={e => handleKeyChange(index, e.target.value)}
          />
          <button
            className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm"
            onClick={() => handleKeyDeletion(index)}
          >
            X
          </button>
        </div>
      ))}
      <button
        className="p-2 font-bold text-center text-gray-600 bg-gray-200 rounded-sm cursor-pointer hover:bg-gray-300"
        onClick={handleAddKey}
      >
        + Add Key
      </button>
    </div>
  );
}
