import { NavigationContext } from '@/context/NavigationContext';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { FeatureDetails } from '@/types/feature/featureDetails';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useGetFeature = (isDeleteSuccess?: boolean) => {
  const { featureId } = useParams();
  const { setActivePage } = useContext(NavigationContext)!;

  const [featureDetails, setFeatureDetails] = useState<FeatureDetails>();

  const [pageTitle, setPageTitle] = useState(
    featureId !== 'new' ? 'Loading...' : 'New Feature'
  );

  const navigate = useNavigate();
  const {
    data,
    isSuccess,
    isError,
    error,
    refetch: featureRefetch,
  } = useGetFeatureQuery(Number(featureId), {
    skip: featureId === 'new' || isDeleteSuccess || !featureId,
  });

  // useEffect for fetching feature details
  useEffect(() => {
    if (featureId !== 'new' && isSuccess && data?.data) {
      setPageTitle(data.data.name ?? '');
      setActivePage(data.data.name);
      setFeatureDetails(data.data);
    }

    if (featureId !== 'new' && isError && !isDeleteSuccess) {
      if (isApiErrorResponse(error) && error.status !== 403) {
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: 'There was a problem loading feature details',
          duration: 24 * 60 * 60000,
        });
        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [featureId, isSuccess, isError, isDeleteSuccess, data]);

  return {
    featureDetails,
    pageTitle,
    featureRefetch,
  };
};
