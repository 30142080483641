import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AITemplate, AITemplatesResponse } from '@/pages/aiTool/types';
export const aiToolApi = createApi({
  reducerPath: 'aiToolApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['aiTool'],
  endpoints: builder => ({
    getAITemplates: builder.query<AITemplatesResponse, void>({
      query: () => {
        return {
          url: '/ai/templates',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
      providesTags: ['aiTool'],
    }),
    createAITemplate: builder.mutation({
      query: (data: Omit<AITemplate, 'id'>) => {
        return {
          url: '/ai/templates',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['aiTool'],
    }),

    getAITemplate: builder.query<{ data: AITemplate }, number>({
      query: id => {
        return {
          url: `/ai/templates/${id}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
    }),
    updateAITemplate: builder.mutation({
      query: (data: AITemplate) => {
        return {
          url: `/ai/templates/${data.id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['aiTool'],
    }),
    deleteAITemplate: builder.mutation({
      query: (id: number) => {
        return {
          url: `/ai/templates/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['aiTool'],
    }),
    getAITemplateCompletion: builder.mutation({
      query: ({
        id,
        data,
      }: {
        id: number;
        data: { subject: string; details: string[] };
      }) => {
        return {
          url: `/ai/template-completion/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAITemplatesQuery,
  useCreateAITemplateMutation,
  useGetAITemplateQuery,
  useUpdateAITemplateMutation,
  useDeleteAITemplateMutation,
  useGetAITemplateCompletionMutation,
} = aiToolApi;
