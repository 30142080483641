import { BaseWidgetOptions } from '../types/chart-types';

export const defaultWidgetState: BaseWidgetOptions = {
  chartType: undefined,
  description: '',
  id: '',
  title: '',
  dataSource: {
    workflow: '',
    dataStore: '',
  },
  dataStyling: [
    '#EB7546',
    '#348FB3',
    '#FFF585',
    '#97DFAF',
    '#386B37',
    '#21A39A',
    '#005F85',
    '#AFBABE',
    '#F19E7D',
    '#71B1CA',
    '#FFF8AA',
    '#B6E9C7',
    '#749773',
    '#64BEB8',
    '#4D8FAA',
    '#C7CFD2',
    '#F7C8B5',
    '#AED2E1',
    '#FFFBCE',
    '#D5F2DF',
    '#B3C7B3',
    '#A6DAD7',
    '#99BFCE',
    '#DFE3E5',
  ],
  chartOptions: {},
};
