import { Button, IconButton } from '@tcomponents/ui/button';
import { Card, CardContent } from '@/tcomponents/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import {
  faCirclePlus,
  faEllipsisVertical,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';
import { Input } from '@/tcomponents/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { Label } from '@/tcomponents/ui/label';
import { cn } from '@/lib/utils';
import { Draggable } from 'react-beautiful-dnd';
import { Switch } from '@/tcomponents/ui/switch';
import ReactSelect from 'react-select';
import { OverlayType } from '../hooks/useHandleOverlayTab';
import { useMapSettingsContext } from '../MapSettingsProvider';
import ColorPicker from '@/tcomponents/custom/ColorPicker';
import { layerTypes } from '../hooks/useHandleBoundaryTab';

type Props = {
  overlay: OverlayType;
  idx: number;
  handleSingleFieldValueChange: (
    idx: number,
    field: keyof OverlayType,
    value: OverlayType[keyof OverlayType]
  ) => void;
  deleteOverlay: (idx: number) => void;
  addOverlay: (idx: number) => void;
  isDragging: boolean;
};

export const Overlay = (props: Props) => {
  const { allShapesResponse } = useMapSettingsContext();
  const {
    overlay,
    idx,
    handleSingleFieldValueChange,
    deleteOverlay,
    addOverlay,
    isDragging,
  } = props;

  const overlaySystemShapeFiles = allShapesResponse?.data?.filter(
    shape => shape.type === layerTypes.Overlay
  );

  const selectedShapeFileId = Number(overlay?.shapeFile);
  const selectedShapeFile = overlaySystemShapeFiles?.find(
    shape => shape.id === selectedShapeFileId
  );

  const reactSelectOptions = selectedShapeFile?.properties?.map(
    (property: string) => ({
      value: property,
      label: property,
    })
  );

  return (
    <Draggable key={idx} draggableId={idx.toString()} index={idx}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div data-id={overlay.id} className="relative flex">
            {/* drag handle */}
            <div
              {...provided.dragHandleProps}
              className="w-[30px] rounded-l-md bg-gray-200 draggable-handle cursor-move flex items-center justify-center "
            >
              <FontAwesomeIcon
                icon={faGripDotsVertical}
                className="p-2 text-white text-[20px]"
              />
            </div>
            <Card className="relative border-none shadow-none grow">
              <CardContent className="p-6">
                <div>
                  {/* title, startEnabled and color */}
                  <div className="flex justify-between pr-5 gap-x-[55px] grow">
                    <div className="flex flex-col grow gap-y-1 ">
                      <Label>Title</Label>
                      <Input
                        value={overlay.title}
                        onChange={e =>
                          handleSingleFieldValueChange(
                            idx,
                            'title',
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="flex px-4 gap-x-10">
                      <div className="flex flex-col gap-y-3">
                        <Label>Start Enabled</Label>
                        <Switch
                          checkedLabel="On"
                          unCheckedLabel="Off"
                          checked={overlay.startEnabled}
                          onCheckedChange={checked => {
                            handleSingleFieldValueChange(
                              idx,
                              'startEnabled',
                              checked
                            );
                          }}
                        />
                      </div>
                      <div className="flex flex-col gap-y-1">
                        <Label>Colour</Label>
                        <ColorPicker
                          value={overlay.color}
                          onChange={color =>
                            handleSingleFieldValueChange(idx, 'color', color)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* popover to delete ovelay */}
                  <div className="absolute top-4 right-4">
                    <Popover>
                      <PopoverTrigger tabIndex={-1}>
                        <IconButton
                          tabIndex={-1}
                          icon={faEllipsisVertical}
                          tooltip="Actions"
                          className="pl-5 py-[3px]"
                        />
                      </PopoverTrigger>
                      <PopoverContent side="left" className="p-0 w-[100px]">
                        <PopoverClose>
                          <Button
                            icon={faTrashCan}
                            variant="danger"
                            className="font-normal text-[14px] w-full "
                            onClick={() => deleteOverlay(idx)}
                          >
                            Delete
                          </Button>
                        </PopoverClose>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
                <div className="flex flex-wrap py-4 bg-white gap-x-8">
                  <div className="w-1/6">
                    <Label> Shape Source</Label>
                    <Select
                      value={overlay.shapeSource}
                      onValueChange={value => {
                        handleSingleFieldValueChange(idx, 'shapeSource', value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select File" />
                      </SelectTrigger>
                      <SelectContent className="w-full">
                        <SelectGroup
                          className={'overflow-y-auto max-h-96 w-full'}
                        >
                          <SelectItem key={'System'} value={'System'}>
                            System
                          </SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-1/6">
                    <Label> Shape File</Label>

                    <Select
                      value={overlay.shapeFile}
                      onValueChange={value => {
                        if (overlay.shapeSource === 'System') {
                          handleSingleFieldValueChange(idx, 'shapeFile', value);
                        }
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select File" />
                      </SelectTrigger>
                      <SelectContent className="w-full">
                        <SelectGroup
                          className={'overflow-y-auto max-h-96 w-full'}
                        >
                          {overlaySystemShapeFiles
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map(shape => (
                              <SelectItem
                                key={shape.id}
                                value={String(shape.id)}
                              >
                                {shape.name}
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="grow">
                    <Label> Hover Properties</Label>
                    <ReactSelect
                      isMulti
                      options={reactSelectOptions}
                      value={overlay.hoverProperties.filter(item => item.value)}
                      onChange={value => {
                        handleSingleFieldValueChange(
                          idx,
                          'hoverProperties',
                          value as [{ value: string; label: string }]
                        );
                      }}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          {/* add new overlay button  */}
          <div
            className={cn(
              'flex items-center w-full py-3 transition-opacity duration-200 ease-in-out gap-x-4',
              {
                'opacity-100': !isDragging,
                'opacity-0': isDragging,
              }
            )}
          >
            <span className="w-full h-[1px] bg-gray-300 grow -mr-3"></span>
            <IconButton
              onClick={() => addOverlay(idx + 1)}
              icon={faCirclePlus}
              tooltip="Add Overlay"
              className="-mr-5 text-gray-300 rounded-full cursor-pointer hover:text-gray-800"
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};
