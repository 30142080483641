import { cn } from '@/lib/utils';
import { IconButton } from '@/tcomponents/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import {
  IconDefinition,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

type PropTypes = {
  children: React.ReactNode;
  icon?: IconDefinition;
  tooltip?: string;
  buttonClassName?: string;
  popoverSide?: 'top' | 'right' | 'bottom' | 'left';
  popoverAlign?: 'start' | 'center' | 'end';
  popoverClassName?: string;
};

export const InfoPopover = (props: PropTypes) => {
  const {
    children,
    icon = faCircleInfo,
    tooltip = 'Click For Example',
    popoverSide = 'bottom',
    popoverAlign = 'start',
    buttonClassName,
    popoverClassName,
  } = props;
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          className={cn('hover:text-info text-info', buttonClassName)}
          icon={icon}
          tooltip={tooltip}
        />
      </PopoverTrigger>
      <PopoverContent
        side={popoverSide}
        align={popoverAlign}
        className={cn('w-fit', popoverClassName)}
      >
        {children}
      </PopoverContent>
    </Popover>
  );
};
