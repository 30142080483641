import { useContext, useMemo } from 'react';
import { useUpdateDataStoreMutation } from '@/store/services/projects/graphApi';
import { ModalContext } from '@/context/ModalContext';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { cloneDeep } from 'lodash';
import { useLoadingDialog } from '@/tcomponents/custom/LoadingDialog';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { Tabulator } from 'tabulator-tables';
import { deleteRowIcon, useTabulator } from '../../../../../hooks/useTabulator';
import { buildColumnDefinitions } from '../lib/buildColumnDefinitions';
import { Schema } from '../components/TableSettingsView';

type Props = {
  data: Record<string, any>[];
  schema?: Schema;
  dataStoreId: string;
};

export const useTableDataView = ({ data, schema, dataStoreId }: Props) => {
  const { showDialog } = useContext(ModalContext);
  const spinner = useLoadingDialog('Updating Data Store...');
  const [updateDataStore] = useUpdateDataStoreMutation();

  const tabulatorConfig: Tabulator['options'] = useMemo(
    () => ({
      data: cloneDeep(data) || [],
      layout: 'fitData',
      placeholder: 'Empty Data Store',
      height: '100%',
      autoColumns: schema?.configuration.length ? false : 'full',
      columns: buildColumnDefinitions(cloneDeep(schema?.configuration) || []),
      progressiveLoadScrollMargin: 10,
      autoColumnsDefinitions: function (definitions) {
        if (!definitions) return [];
        // hiding __id column
        const modifiedDefinitions = definitions.map(definition => {
          if (definition.field === '__id') {
            return { ...definition, visible: false };
          }
          return definition;
        });
        // adding delete row icon
        modifiedDefinitions.push(deleteRowIcon);
        return modifiedDefinitions;
      },
      columnDefaults: schema?.configuration.length
        ? {
            headerFilter: true,
            headerSort: false,
            maxWidth: 500,
            headerTooltip: true,
          }
        : {
            headerFilter: 'input',
            editor: 'input',
            maxWidth: 500,
            headerTooltip: true,
          },
      placeholderHeaderFilter: 'No Matching Data',
      nestedFieldSeparator: '||',
      tabEndNewRow: {},
      debugInvalidOptions: false,
    }),
    [schema, dataStoreId, data]
  );

  const {
    tableDivRef,
    tabulatorRef,
    trackerRef,
    addNewRow,
    importSpreadsheet,
    trackerData,
    isTableDirty,
  } = useTabulator({
    config: tabulatorConfig,
    trackFields: false,
  });

  const updateDataStoreFn = (lastUpdated: string, bypass: boolean = false) => {
    if (!trackerData || !dataStoreId) return;
    spinner.setLoadingDialog(true);
    updateDataStore({
      dataStoreId,
      data: trackerData,
      lastUpdated,
      bypass,
    })
      .unwrap()
      .then(() => {
        toast({
          description: 'Data Store Updated Successfully',
        });
        trackerRef.current?.reset();
      })
      .catch(err => {
        if (
          err?.data?.message === 'The process node is currently being modified'
        ) {
          showDialog({
            title: 'Data Store Update Alert!',
            message:
              'The process node is currently being modified, please try again in a few minutes.',
            type: ModalTypes.DANGER,
            cancel: true,
          });
        } else if (
          err?.data?.message === 'The process node has previously been updated'
        ) {
          showDialog({
            title: 'Data Loss Alert!',
            message:
              'Data in the store has changed since the last update, updating the data now may possibly  result in data loss, do you want to proceed?',
            type: ModalTypes.DANGER,
            cancel: true,
            ok: {
              cb: () => updateDataStoreFn(lastUpdated, true),
            },
          });
        } else {
          toast({
            title: 'Oh no!',
            description: 'Failed to update Data Store',
            variant: 'destructive',
          });
        }
      })
      .finally(() => {
        spinner.setLoadingDialog(false);
      });
  };

  return {
    tabulatorRef,
    tableDivRef,
    addNewRow,
    importSpreadsheet,
    updateDataStoreFn,
    isTableDirty,
  };
};
