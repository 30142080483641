import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import TabulatorTable from '../../../../components/common/Table';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { faAdd, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { PopoverClose } from '@radix-ui/react-popover';
import { Button } from '@/tcomponents/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAuth from '@/hooks/UseAuth';
import { featurePermissions } from '@/stringConsts/permissionConsts';

interface IFeatureGroupFeaturesProps {
  id: number | string;
  project_id?: number | string;
}

const FeatureGroupFeatures: FC<IFeatureGroupFeaturesProps> = ({
  id,
  project_id,
}) => {
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const goToFeatureDetails = (e: any, row: any) => {
    navigate(
      `/projects/${project_id}/feature-groups/${id}/${row.getData().id}/${
        row.getData()?.feature_subtype_fe
      }`
    );
  };

  const tableConfig = () => {
    return {
      ajaxURL: `${process.env.REACT_APP_API_BASE_URL}/feature-groups/${id}/features?project_id=${project_id}`,
      columns: [
        { title: 'Title', field: 'name' },
        { title: 'Feature', field: 'feature_subtype' },
        { title: 'Type', field: 'feature_type' },
        { title: 'Description', field: 'description' },
        {
          title: 'Public',
          field: 'public',
          formatter: 'tickCross',
          width: 100,
          hozAlign: 'center',
          sorter: 'boolean',
        },
      ],
      events: {
        rowClick: goToFeatureDetails,
      },
      tableFilters: [
        {
          type: 'input',
          placeholder: 'Search',
          filterName: 'input',
          filterOn: [
            { field: 'name', type: 'like' },
            { field: 'feature_subtype', type: 'like' },
            { field: 'feature_type', type: 'like' },
            { field: 'description', type: 'like' },
          ],
        },
      ],
    };
  };

  return (
    <>
      <TabulatorTable
        id={'feature-group-features'}
        actions={
          hasPermission(featurePermissions.general.create) && (
            <Popover>
              <PopoverTrigger>
                <Button
                  icon={faAdd}
                  className="w-[150px]"
                  data-testing="feature-add-button"
                  disabled={!hasPermission(featurePermissions.general.create)}
                >
                  Add Feature
                  <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                side="bottom"
                className="p-0 w-[150px] text-[14px]"
              >
                <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                  <div className="w-full text-left">
                    <h5 className=" bg-[#EBEBEB] p-2 w-full cursor-default">
                      Processing
                    </h5>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/workflow`
                        )
                      }
                      key={'workflow'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      data-testing="feature-add-workflow-button"
                    >
                      Workflow
                    </Button>
                  </div>
                  <div className="w-full text-left">
                    <h5 className=" bg-[#EBEBEB] p-2 w-full cursor-default">
                      Display
                    </h5>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/dashboard`
                        )
                      }
                      key={'dashboard'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      data-testing="feature-add-dashboard-button"
                    >
                      Dashboard
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/ranked-list`
                        )
                      }
                      key={'ranked-list'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      data-testing="feature-add-ranked-list-button"
                    >
                      Ranked List
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/table`
                        )
                      }
                      key={'table'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                    >
                      Table
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/map`
                        )
                      }
                      key={'map'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      data-testing="feature-add-map-button"
                    >
                      Map
                    </Button>
                  </div>
                  <div className="w-full text-left">
                    <h5 className=" bg-[#EBEBEB] p-2 w-full cursor-default">
                      Tool
                    </h5>
                    <Button
                      onClick={() =>
                        navigate(
                          `/projects/${project_id}/feature-groups/${id}/new/survey`
                        )
                      }
                      key={'survey'}
                      variant="ghost"
                      className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                      data-testing="feature-add-survey-button"
                    >
                      Survey
                    </Button>
                  </div>
                </PopoverClose>
              </PopoverContent>
            </Popover>
          )
        }
        config={tableConfig()}
      />
    </>
  );
};

export default FeatureGroupFeatures;
