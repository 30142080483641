import React, { FC, useEffect, useRef, useState } from 'react';
import { Input as TInput } from '@/tcomponents/ui/input';
import Icon from '../../Icon';

interface IDropdownProps {
  placeHolder: string;
  onChange: (callback: any) => void;
  style?: Object;
}

const Input: FC<IDropdownProps> = ({ placeHolder, onChange, style }) => {
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue('');
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  // @ts-ignore
  const onSearch = e => {
    setSearchValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="flex items-center" style={style}>
      <TInput
        type="text"
        placeholder={placeHolder}
        onChange={onSearch}
        value={searchValue}
        ref={searchRef}
        className="bg-white rounded-full"
      />
      <Icon icon="fas fa-search -ml-8"></Icon>
    </div>
  );
};

export default Input;
