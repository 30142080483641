import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createErrorHandler } from '../../utils/createErrorHandler';
import { UserDetailsInterface } from '@/types/interfaces/api-response/UserDetailsInterface';
import { roleManagementApi } from '../roleManagementApi';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['User'],
  endpoints: builder => ({
    getUserDetails: builder.query<{ data: UserDetailsInterface }, number>({
      query: (id: number) => {
        return {
          url: '/users/' + id,
          method: 'GET',
        };
      },
      onQueryStarted: createErrorHandler(
        'There was a problem loading user details'
      ),
      providesTags: ['User'],
    }),
    getEnabledUsersForSelector: builder.query<
      { data: { label: string; value: number }[] },
      void
    >({
      query: () => {
        return {
          url: '/users/available-users-for-selector',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
    }),
    updateUserDetails: builder.mutation({
      query: ({ id, name, email, organisations, enabled, roles }) => {
        return {
          url: '/users/' + id,
          method: 'PUT',
          body: { email, name, organisations, enabled, roles },
        };
      },
      invalidatesTags: ['User'],
      // we need to invalidate the roleManagementApi tags- rolesList and roleDetails after updating the user details
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          roleManagementApi.util.invalidateTags(['rolesList', 'roleDetails'])
        );
      },
    }),
    deleteUser: builder.mutation({
      query: (id: number) => {
        return {
          url: '/users/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),
    updateStatus: builder.mutation({
      query: (values: { id: string | number; status: boolean }) => {
        return {
          url: 'users/' + values.id + '/update-status',
          method: 'POST',
          body: {
            enabled: values.status,
          },
        };
      },
      invalidatesTags: ['User'],
    }),
    unlockUser: builder.mutation({
      query: (id: string | number) => {
        return {
          url: 'users/' + id + '/unlock',
          method: 'GET',
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useDeleteUserMutation,
  useUpdateStatusMutation,
  useUnlockUserMutation,
  useGetEnabledUsersForSelectorQuery,
} = userApi;
