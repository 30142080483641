import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { array, boolean, object, string, TypeOf } from 'zod';

const formSchema = object({
  name: string().min(1, 'Name is required'),
  description: string(),
  header_info: string(),
  icon: string().min(1, 'Icon is required'),
  workflow: string(),
  data_store: string(),
  showSources: boolean(),
  hidden_fields: array(
    object({
      value: string().optional(),
      label: string().optional(),
    })
  ),
});

export type FormInput = TypeOf<typeof formSchema>;

export type ApiSubmitHandler = (
  data: ReturnType<typeof transformSubmitData>
) => void;

export default function useTableForm(onSubmit: ApiSubmitHandler) {
  const form = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      header_info: '',
      icon: 'fas fa-table',
      workflow: '',
      data_store: '',
      showSources: false,
      hidden_fields: [],
    },
  });

  return {
    form,
    handleSubmit: form.handleSubmit((data: FormInput) =>
      onSubmit(transformSubmitData(data))
    ),
  };
}

function transformSubmitData(formInput: FormInput) {
  return {
    name: formInput.name,
    description: formInput.description,
    header_info: formInput.header_info,
    icon: formInput.icon,
    config: {
      showSources: formInput.showSources,
      data_source: {
        workflow: formInput.workflow,
        data_store_id: formInput.data_store,
      },
      hidden_fields: formInput.hidden_fields,
    },
  };
}
