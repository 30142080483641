import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  IMapConfigResponse,
  IMapDataResponse,
} from '@/pages/dashboard/projects/maps/types/types';

export const mapApi = createApi({
  reducerPath: 'mapApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['map'],
  endpoints: builder => ({
    getMapConfig: builder.query<IMapConfigResponse, number>({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/map',
          method: 'GET',
        };
      },
    }),
    getMapData: builder.query<IMapDataResponse, number>({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/map-data',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
    }),
  }),
});

export const { useGetMapConfigQuery, useGetMapDataQuery } = mapApi;
