import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { FC } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProtectedRouteProps {
  permissions: Array<string>;
}

/**
 * When a user signs in, the user's details including permissions and JWT token are stored in redux.
 * When this user requests access to a protected route, this component will access the redux store and retrieve the user’s permissions
 * The user’s permissions will then be cross-checked with an array containing the required permissions for that route
 * If the user’s permissions matches the required permission, access is granted, else access will be denied
 *
 * @param allowedRoles
 * @constructor
 */
const ProtectedRoute: FC<IProtectedRouteProps> = ({ permissions }) => {
  const { user } = useSelector((state: any) => state);
  const location = useLocation();

  return permissions.find(permission =>
    user?.userData?.permissions.includes(permission)
  ) ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
