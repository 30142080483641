const groupedListSampleFormat = `[ // array of group objects in order to be displayed
  { // object per item in the chart
    _name: 'Fossil Fuels', // the label for the group
    Gas: 400, // key value for item in group, key is the label for the item (keys starting with an underscore will be ignored)
    Coal: 1230
  },
  {
    _name: 'Renewables',
    Solar: 152,
    Wind: 652,
    Hydroelectric: 1400
  }
]`;

export default groupedListSampleFormat;
