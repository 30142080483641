import { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { mapLayersPermissions } from '@/stringConsts/permissionConsts';

const MapManager = lazy(() => import('../../pages/dashboard/maps/MapManager'));
const NewMapShape = lazy(
  () => import('../../pages/dashboard/maps/NewMapShape')
);
const ViewMapShape = lazy(
  () => import('../../pages/dashboard/maps/ViewMapShape')
);

export const mapShapeRoutes = [
  {
    element: (
      <ProtectedRoute permissions={[mapLayersPermissions.general.read]} />
    ),
    children: [
      {
        path: 'map-manager',
        element: <MapManager />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute permissions={[mapLayersPermissions.general.create]} />
    ),
    children: [
      {
        path: 'map-manager/new',
        element: <NewMapShape />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute permissions={[mapLayersPermissions.general.edit]} />
    ),
    children: [
      {
        path: 'map-manager/:id',
        element: <ViewMapShape />,
      },
    ],
  },
];
