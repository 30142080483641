import React, { FC } from 'react';
import ModalProps from '../../types/interfaces/ModalProps';
import Icon from './Icon';
import ModalTypes from '../../types/enums/modal/ModalTypes';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@tcomponents/ui/alert-dialog';
import { cn } from '@/lib/utils';
import ActionInterface from '@/types/interfaces/ActionInterface';

interface ModalPropsExtended extends ModalProps {
  customActions?: ActionInterface[];
}

const Modal: FC<ModalPropsExtended> = ({
  title,
  message,
  type = ModalTypes.DEFAULT,
  cancel = true,
  confirmCallback,
  confirmLabel = 'OK',
  open,
  setOpen,
  customActions,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle
            className={cn(
              'font-bold',
              type === ModalTypes.DANGER ? 'text-danger-error' : '',
              type === ModalTypes.WARNING ? 'text-warning' : ''
            )}
          >
            {type === ModalTypes.WARNING && (
              <Icon icon={'fas fa-triangle-exclamation'} className="mr-1" />
            )}
            {type === ModalTypes.DANGER && (
              <Icon icon={'fas fa-circle-exclamation'} className="mr-1" />
            )}
            {type === ModalTypes.SUCCESS && (
              <Icon icon={'fas fa-circle-check'} />
            )}
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription className="break-words">
            {message}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          {cancel && <AlertDialogCancel>Cancel</AlertDialogCancel>}
          {customActions?.map((action, index) => (
            <AlertDialogAction
              key={index}
              onClick={action.cb}
              className={action?.buttonClasses?.join(' ')}
            >
              {action.label}
            </AlertDialogAction>
          ))}
          {confirmCallback && (
            <AlertDialogAction
              key="modal-confirm-button"
              onClick={
                typeof confirmCallback !== 'undefined'
                  ? confirmCallback
                  : () => setOpen(false)
              }
            >
              {confirmLabel}
            </AlertDialogAction>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Modal;
