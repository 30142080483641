import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetRankedListConfigQuery,
  useGetRankedListDataQuery,
} from '@/store/services/projects/rankedListApi';
import HorizontalStackedBarChart, {
  HorizontalBarChartData,
} from './HorizontalStackedBarChart';
import {
  ColumnDef,
  SortDirection,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/tcomponents/ui/table';
import { Button } from '@/tcomponents/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import LoadingData from '../chartDashboard/common/LoadingData';
import ErrorData from '../chartDashboard/common/ErrorData';
import NoData from '../chartDashboard/common/NoData';
import { sum } from 'lodash';
import { getKeys } from '../utils/helperFunctions';
import FeatureSourceBar from '../common/FeatureSourceBar';

interface IRankedListDisplayProps {
  id: string | number;
  setLastUpdatedForRankedList?: React.Dispatch<React.SetStateAction<string>>;
  project?: any;
  feature?: any;
}

export interface IRankedListConfig {
  data_styling: Record<string, string>;
  layout: {
    column_label: string;
    initial_sort: string;
    sort_direction: SortDirection;
  };
  ranking_bars: [string[]];
  max_values: number[];
}

type ColumnFactoryProps = {
  config?: IRankedListConfig;
  columnKeys: string[];
};

const makeColumns = (
  props: ColumnFactoryProps
): ColumnDef<HorizontalBarChartData>[] => {
  if (!props.config) return [];

  const dataKeyColumns: ColumnDef<HorizontalBarChartData>[] =
    props.columnKeys.map(columnKey => {
      return {
        id: columnKey,
        accessorKey: `keys.${columnKey}`,
        header: ({ column }) => {
          return (
            <div className="sticky flex items-center gap-x-1">
              <span
                className="w-[14px] h-[7px] rounded-sm"
                style={{
                  backgroundColor: props.config?.data_styling[columnKey],
                }}
              ></span>
              <Button
                tooltip={
                  column.getIsSorted() === 'desc'
                    ? `Sort ${columnKey} - Ascending`
                    : `Sort ${columnKey} - Descending`
                }
                variant="ghost"
                className="flex items-center p-0 text-sm font-bold text-black gap-x-1 hover:text-brand"
                onClick={() => column.toggleSorting()}
              >
                {columnKey}
                <FontAwesomeIcon
                  icon={
                    column.getIsSorted() === 'asc' ? faCaretUp : faCaretDown
                  }
                  className={
                    column.getIsSorted() ? 'opacity-100' : 'opacity-50'
                  }
                />
              </Button>
            </div>
          );
        },
        cell: ({ row }) => {
          return <TableCell>{row.original.keys[columnKey]}</TableCell>;
        },
      };
    });

  return [
    {
      id: '_name',
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <>
            <div className="sticky flex items-center gap-x-1 ">
              <Button
                tooltip={
                  column.getIsSorted() === 'asc'
                    ? `Sort ${props.config?.layout.column_label} - Descending`
                    : `Sort ${props.config?.layout.column_label} - Ascending`
                }
                variant="ghost"
                className="flex items-center p-0 text-sm font-bold text-black gap-x-1 hover:text-brand"
                onClick={() => column.toggleSorting()}
              >
                {props.config?.layout.column_label}
                <FontAwesomeIcon
                  className={
                    column.getIsSorted() ? 'opacity-100' : 'opacity-50'
                  }
                  icon={
                    column.getIsSorted() === 'asc' ? faCaretUp : faCaretDown
                  }
                />
              </Button>
            </div>
          </>
        );
      },
      cell: ({ row }) => {
        return (
          <TableCell>
            <div className="text-sm font-bold">{row.original.name}</div>
            <HorizontalStackedBarChart
              data={[row.original]}
              config={props.config}
            />
          </TableCell>
        );
      },
    },

    ...dataKeyColumns,
  ];
};

type RankedListData = {
  [key: string]: number;
};

const emptyArray: HorizontalBarChartData[] = [];

export default function RankedListDisplay(props: IRankedListDisplayProps) {
  const { id, setLastUpdatedForRankedList, project, feature } = props;
  const [sorting, setSorting] = useState<SortingState>([]);

  const rankedListConfig = useGetRankedListConfigQuery(parseInt(id as string), {
    skip: !id,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const rankedListData = useGetRankedListDataQuery(parseInt(id as string), {
    skip: !id,
    refetchOnFocus: true,
  });
  const information = rankedListData.data?.information;
  const sources = rankedListData.data?.sources;

  const columnKeys = getKeys(rankedListData.data?.data);

  const formattedData: HorizontalBarChartData[] = useMemo(
    () =>
      rankedListData.data?.data.map((item: RankedListData) => {
        return {
          name: item._name,
          keys: Object.fromEntries(
            Object.entries(item).filter(([key, val]) => key !== '_name')
          ),
        };
      }) || emptyArray,
    [rankedListData.data]
  );

  const calculateMaxValues = (): number[] => {
    const rankingBars = (rankedListConfig.data?.data as IRankedListConfig)
      ?.ranking_bars;
    if (!rankingBars) return [];
    return rankingBars.map(ranking => {
      const maxValuesForRanking = ranking.map(key => {
        return formattedData.reduce(
          (max, item) => Math.max(item.keys[key], max),
          0
        );
      });
      return sum(maxValuesForRanking);
    });
  };

  const columns = useMemo(() => {
    if (!rankedListConfig.data?.data || !rankedListData.data?.data) return [];
    return makeColumns({
      config: {
        ...rankedListConfig.data?.data,
        max_values: calculateMaxValues(),
      },
      columnKeys,
    });
  }, [rankedListConfig.data?.data, rankedListData.data?.data]);

  useEffect(() => {
    if (rankedListConfig.data?.data && sorting.length === 0) {
      const { initial_sort, sort_direction } =
        rankedListConfig.data.data.layout;
      setSorting(
        initial_sort
          ? [
              {
                id: initial_sort,
                desc: sort_direction === 'desc',
              },
            ]
          : []
      );
    }
  }, [rankedListConfig]);

  // effect to set last updated for ranked list in the parent component
  useEffect(() => {
    setLastUpdatedForRankedList &&
      setLastUpdatedForRankedList(rankedListData.data?.last_updated);
  }, [rankedListData.data?.last_updated, setLastUpdatedForRankedList]);

  const table = useReactTable({
    data: formattedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableSorting: true,
    enableSortingRemoval: false,
  });

  const rows = table.getSortedRowModel().rows;

  if (rankedListData.isLoading) return <LoadingData />;
  if (rankedListData.isError) return <ErrorData />;
  if (rankedListData?.data?.data?.length === 0) return <NoData />;

  return (
    <div>
      <Table containerClassName="pb-20">
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow
              className="text-sm font-normal text-left text-gray-500"
              key={headerGroup.id}
            >
              {headerGroup.headers.map(header => {
                return (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody className="bg-white border-0">
          {rows.length ? (
            rows.map(row => {
              return (
                <TableRow
                  key={row.original.name}
                  className="border border-border  hover:bg-muted/50"
                >
                  {row
                    .getVisibleCells()
                    .map(cell =>
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                </TableRow>
              );
            })
          ) : (
            <TableRow className="hover:bg-muted/50">
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <FeatureSourceBar
        project={project}
        feature={feature}
        sources={sources}
        information={information}
      />
    </div>
  );
}
