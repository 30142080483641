import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import { RankWidgetOptions } from '../../types/chart-types';
import { useChartDashboardContext } from '../../ChartDashboardProvider';
import { Input } from '@/tcomponents/ui/input';
type RankChartOptionsProps = {
  widgetData: RankWidgetOptions;
};

export default function RankChartOptions(props: RankChartOptionsProps) {
  const { widgetData } = props;
  const { updateWidgetData } = useChartDashboardContext();

  return (
    <div>
      <div className="flex flex-col p-3 gap-y-4">
        <h4>Chart Options</h4>
        <div>
          <Label> Rank Label</Label>
          <Input
            name="rankLabel"
            value={widgetData.chartOptions?.rankLabel ?? 'Rank'}
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  rankLabel: e.target.value,
                },
              })
            }
            className="mt-1"
          />
        </div>
        <div>
          <Label> Option Label</Label>
          <Input
            name="optionLabel"
            value={widgetData.chartOptions?.optionLabel ?? 'Option'}
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  optionLabel: e.target.value,
                },
              })
            }
            className="mt-1"
          />
        </div>
        <div>
          <Label> Show Values</Label>
          <Switch
            checked={widgetData.chartOptions?.showValues ?? false}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: { ...widgetData.chartOptions, showValues: val },
              })
            }
            className="mt-1"
          />
        </div>
      </div>
    </div>
  );
}
