import React from 'react';
import { Button } from '@/tcomponents/ui/button';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { useChartDashboardContext } from '../ChartDashboardProvider';

export const WidgetBuilderTopbar = () => {
  const { setDialogOpen, handleSaveButtonClick, setSelectedWidgetId } =
    useChartDashboardContext();
  return (
    <div className="flex items-center justify-between px-5 py-4 border-b">
      <h4>Widget Builder</h4>
      <div className="flex items-center">
        <Button
          variant="outline"
          className="mr-2"
          onClick={() => {
            setDialogOpen(false);
            setSelectedWidgetId(null);
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleSaveButtonClick} icon={faSave}>
          Save
        </Button>
      </div>
    </div>
  );
};
