import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { OverlayData } from './OverlayRender';
import { BoundaryData } from './BoundaryRenderer';

type Props = {
  data: OverlayData | undefined | BoundaryData;
  fileType: 'Shape' | 'GeoJSON';
  layer: MapFeatureDetails['overlays'][0] | MapFeatureDetails['boundaries'][0];
  layerType?: 'overlay' | 'boundary';
};
export const ShapeFileWithDataProp = (props: Props) => {
  const { data, fileType, layer, layerType } = props;

  const map = useMap();

  useEffect(() => {
    if (!data || !map) return;

    const geo = L.geoJson([], {
      onEachFeature: function popUp(f, l) {
        if (f.properties) {
          // Get the hoverProperties from the overlay and convert to lower case, we had to do this because in overlay properties are in different case
          const hoverProperties = layer.hoverProperties
            .filter(prop => prop.label !== null && prop.value !== null)
            .map(prop => prop.value.toLowerCase());
          // Filter the properties based on hoverProperties
          if (hoverProperties.length > 0) {
            // Create a new object with lower case keys
            const lowerCaseProperties = Object.fromEntries(
              Object.entries(f.properties).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            );
            const out = hoverProperties
              ?.filter((key: string) => key in lowerCaseProperties)
              ?.map(
                (key: string) =>
                  `<strong>${
                    key.charAt(0).toUpperCase() + key.slice(1)
                  }</strong> <br />
                 ${lowerCaseProperties[key]} <br />`
              );

            l.bindPopup(out.join('<br />'));
          }
        }
      },
      style: () => ({
        color: layer.color ?? '#3388ff',
        weight: 1,
        fillOpacity: 0.1,
      }),
    }).addTo(map);

    const loadFile = async () => {
      try {
        geo.addData(data);
        if (layerType === 'boundary') {
          geo.bringToBack();
        }
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to load ${fileType} file: ${layer.title}`,
        });
      }
    };

    loadFile();

    return () => {
      map.removeLayer(geo);
    };
  }, [data, fileType, map, layer]);

  return null;
};
