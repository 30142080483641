import { Question } from './Types';
import { ChoiceQue } from '../components/ChoiceQuestion';
import { ParagraphQue } from '../components/ParagraphQuestion';
import { NumberQue } from '../components/NumberQuestion';
import { OrderQue } from '../components/OrderQuestion';
import { FormQue } from '../components/FormQuestion';

export const getQuestionComponent = (
  question: Question,
  updateQuestion: (questionData: Question) => void
) => {
  switch (question.type) {
    case 'choice':
      return <ChoiceQue question={question} updateQuestion={updateQuestion} />;
    case 'paragraph':
      return (
        <ParagraphQue question={question} updateQuestion={updateQuestion} />
      );
    case 'number':
      return <NumberQue question={question} updateQuestion={updateQuestion} />;
    case 'order':
      return <OrderQue question={question} updateQuestion={updateQuestion} />;
    case 'form':
      return <FormQue question={question} updateQuestion={updateQuestion} />;
  }
};
