import React, { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import SuspenseSpinner from '../components/common/loading-spinner';
import AuthenticatedRoutes from './AuthenticatedRoutes';

import { aiWizardRoutes } from '@/routes/routeGroups/aiWizardRoutes';
import { mapShapeRoutes } from '@/routes/routeGroups/mapShapesRoutes';
import { userManagementRoutes } from '@/routes/routeGroups/userManagementRoutes';
import { surveyPackageRoutes } from '@/routes/routeGroups/surveyPackageRoutes';
import { projectRoutes } from '@/routes/routeGroups/projectRoutes';
import { featuresRoutes } from '@/routes/routeGroups/featuresRoutes';
import { servicesRoutes } from '@/routes/routeGroups/servicesRoutes';
import { roleManagementRoutes } from './routeGroups/roleManagementRoutes';
import { unauthenticatedRoutes } from './routeGroups/unauthenticatedRoutes';
import { integrationsRoutes } from './routeGroups/integrationsRoutes';
import { organisationsRoutes } from './routeGroups/organisationsRoutes';

const router = createBrowserRouter([
  ...unauthenticatedRoutes,

  {
    element: <AuthenticatedRoutes />,
    children: [
      ...aiWizardRoutes,
      ...mapShapeRoutes,
      ...userManagementRoutes,
      ...surveyPackageRoutes,
      ...roleManagementRoutes,
      ...integrationsRoutes,
      ...organisationsRoutes,
      ...projectRoutes,
      ...featuresRoutes,
      ...servicesRoutes,
    ],
  },
]);

export const routes = () => {
  return (
    <Suspense fallback={<SuspenseSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
