import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';
import { HorizontalBarWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type HorizontalBarChartProps = {
  widgetData: HorizontalBarWidgetOptions;
  renderedFromDashboard?: boolean;
  chartData?: any;
};

export default function HorizontalBarChart(props: HorizontalBarChartProps) {
  const { widgetData, renderedFromDashboard, chartData } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();

  const finalData = !chartData ? singleWidgetData : chartData;
  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (chartData?.length === 0) return <NoData />;

  const isFinalDataAnArray = Array.isArray(finalData);
  if (!isFinalDataAnArray) return <NoData />;

  const dataWithColors = finalData?.map((data: any, index: number) => ({
    ...data,
    pv: widgetData.chartOptions.xAxisLabelNegative
      ? data._value
      : Math.max(0, data._value),
    fill: widgetData.dataStyling?.[index % widgetData.dataStyling.length],
  }));

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="p-2 text-white bg-gray-700 rounded-md">
          <p className="text-xs font-bold">{`${label} :`}</p>
          {payload.map((item: any, index: number) => (
            <div className="flex items-center gap-x-1">
              <span
                className="w-[10px] h-[10px] border rounded-[3px] border-[#707070]"
                style={{ backgroundColor: `${payload[0]?.payload?.fill}` }}
              ></span>
              <p key={`item-${index}`}>{`${item.value} ${
                widgetData.chartOptions.unit ?? ''
              }`}</p>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const maxAbsValue = dataWithColors
    ? Math.max(...dataWithColors.map((item: any) => Math.abs(item.pv)))
    : 0;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex justify-between mx-[15px] gap-x-10">
        <div className="flex justify-between w-1/2">
          <h6>{widgetData.chartOptions.yAxisLabel}</h6>
          <h6>{widgetData.chartOptions.xAxisLabelNegative}</h6>
        </div>
        <div className="flex justify-between w-1/2">
          <h6>{widgetData.chartOptions.xAxisLabel}</h6>
          <h6>
            {widgetData.chartOptions.valueLabel &&
              widgetData.chartOptions.valueLabel +
                (widgetData.chartOptions.unit
                  ? ` (${widgetData.chartOptions.unit})`
                  : '')}
          </h6>
        </div>
      </div>
      <div
        className={`flex-1 w-full h-full mt-2 mb-12 overflow-x-hidden overflow-y-auto ${
          renderedFromDashboard ? '' : 'max-h-[350px]'
        }`}
      >
        <ResponsiveContainer
          minHeight={dataWithColors?.length * 40}
          className="isChart"
        >
          <BarChart
            layout="vertical"
            data={dataWithColors}
            margin={{
              top: 10,
              right: 15,
              left: 15,
              bottom: 10,
            }}
            barCategoryGap={'30%'}
          >
            <YAxis
              mirror={true}
              dataKey="_name"
              type="category"
              yAxisId={0}
              axisLine={false}
              tick={({ x, y, payload }) => {
                return (
                  <text
                    x={x}
                    y={y}
                    dy={`${-30 / dataWithColors.length}%`}
                    dx={-5}
                    textAnchor="start"
                    fill="#666"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {payload.value?.length > 50
                      ? payload.value.substring(
                          0,
                          payload.value.lastIndexOf(' ', 50)
                        ) + '...'
                      : payload.value}
                  </text>
                );
              }}
              tickMargin={0}
            />
            <YAxis
              mirror={true}
              yAxisId={1}
              dataKey="_value"
              type="category"
              orientation="right"
              tickLine={false}
              axisLine={false}
              tick={({ x, y, payload }) => (
                <text
                  x={x}
                  y={y}
                  dy={`${-30 / dataWithColors.length}%`}
                  dx={5}
                  textAnchor="end"
                  fill="#666"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {payload.value}
                </text>
              )}
              tickMargin={0}
            />

            <Bar
              isAnimationActive={false}
              dataKey="_value"
              // barSize={10}
              background={{ fill: '#F7FAFB' }}
              radius={[5, 5, 5, 5]}
            />

            <XAxis
              domain={
                widgetData.chartOptions.xAxisLabelNegative
                  ? [-maxAbsValue, maxAbsValue]
                  : [0, maxAbsValue]
              }
              xAxisId={0}
              hide
              axisLine={false}
              type="number"
            />
            <Tooltip
              cursor={{ fill: 'transparent' }}
              content={<CustomTooltip />}
            />
            {widgetData.chartOptions.xAxisLabelNegative && (
              <ReferenceLine
                x={0}
                stroke="#CCDEE2"
                strokeDasharray="3 3"
                isFront={true}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
