import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../configureStore';
import { AuthUser } from '@/store/services/users/authApi';

interface AuthUserState {
  userData: AuthUser | null;
}

const initState: AuthUserState = {
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    clear: state => {
      state.userData = null;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const userReducer = userSlice.reducer;
export const { clear, setUserData } = userSlice.actions;
export const selectAuth = (state: RootState) => state.user;

export default userSlice.reducer;
