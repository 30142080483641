import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RoleData } from '@/pages/dashboard/roles/types';
export const roleManagementApi = createApi({
  reducerPath: 'roleManagementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['rolesList', 'roleDetails'],

  endpoints: builder => ({
    getRolesList: builder.query<{ data: RoleData['details'][] }, void>({
      query: () => ({
        url: '/roles',
        method: 'GET',
      }),
      providesTags: ['rolesList'],
      keepUnusedDataFor: 0.01,
    }),
    createRole: builder.mutation<{ id: number }, RoleData['details']>({
      query: data => ({
        url: '/roles',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['rolesList'],
    }),
    getRoleDetails: builder.query<RoleData, string>({
      query: id => ({
        url: `/roles/${id}`,
        method: 'GET',
      }),
      providesTags: ['roleDetails'],
    }),
    updateRole: builder.mutation<
      void,
      {
        details: RoleData['details'];
        permissions: { key: string; active: boolean }[];
      }
    >({
      query: data => ({
        url: `/roles/${data.details.id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['rolesList', 'roleDetails'],
    }),
    deleteRole: builder.mutation<void, string>({
      query: id => ({
        url: `/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['rolesList', 'roleDetails'],
    }),
  }),
});

export const {
  useGetRolesListQuery,
  useCreateRoleMutation,
  useGetRoleDetailsQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleManagementApi;
