import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { GroupNodeData } from '../types/node-types';

const GroupNode = ({ data, ...rest }: Node<GroupNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <div>
        <p style={{ margin: '0px', textAlign: 'center' }}>Custom</p>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          height: '8px',
          width: '8px',
        }}
      />
    </CustomNode>
  );
};
export default GroupNode;
