import React, { useContext } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';

import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import Icon from '@components/common/Icon';
import { iPackageList } from '@/pages/survey/Helpers/Types';
import { useSurveyContext } from '../SurveyProvider';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { Label } from '@/tcomponents/ui/label';
import CustomImageField from '../components/CustomImageField';
import { ImageTypes, useUploadImage } from '../hooks/useUploadImage';
import { faUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { faQrcode } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@tcomponents/ui/button';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import QRCode from 'react-qr-code';
import { InfoPopover } from '@/tcomponents/custom/InfoPopover';
import socialShareInfoImage from '@/assets/images/surveyInfoImages/social_share.png';
import browserTabInfoImage from '@/assets/images/surveyInfoImages/browse_tab.png';
import { SketchPicker } from 'react-color';
import {
  defaultColors,
  useColorPicker,
} from '@/pages/dashboard/projects/chartDashboard/hooks/useColorPicker';

interface iProps {
  packageList: iPackageList[];
  setUrl: Function;
  subdomainIsOk: boolean;
  subdomainIsChecking: boolean;
  hasEverChecked: boolean;
}

const SetupTab: React.FC<iProps> = ({
  packageList,
  setUrl,
  subdomainIsOk,
  subdomainIsChecking,
  hasEverChecked,
}) => {
  const { setIsDirty } = useSaveActionContext();
  const { formContent, setFormContent } = useSurveyContext();
  const { showDialog, hideDialog } = useContext(ModalContext);

  const {
    image: browserIcon,
    handleImageUpload: handleUploadBrowserIcon,
    handleImageRemove: handleRemoveBrowserIcon,
  } = useUploadImage(ImageTypes.browser_icon);
  const {
    image: socialImage,
    handleImageUpload: handleUploadSocialImage,
    handleImageRemove: handleRemoveSocialImage,
  } = useUploadImage(ImageTypes.social_share);

  const shouldShowTick = () => {
    const isValidFromApi = subdomainIsOk && !subdomainIsChecking;
    const isEmptyField =
      formContent.setup.website_setup.website_url === '' ||
      formContent.setup.website_setup.website_url === null;

    if (isEmptyField) {
      return false;
    }

    if (!hasEverChecked && !isEmptyField) {
      return true;
    }

    if (hasEverChecked && isValidFromApi) {
      return true;
    }

    if (hasEverChecked && !isValidFromApi) {
      return false;
    }

    return false;
  };

  const visitButtonsDisabled = (): boolean => {
    return !shouldShowTick() || formContent.visibility === 'Draft';
  };

  const surveyUrl: string = `https://${formContent.setup.website_setup.website_url}${process.env.REACT_APP_SURVEY_DOMAIN}/`;
  const showQRModal = (): void => {
    showDialog({
      title: 'QR Code',
      message: (
        <>
          <div className={'p-10 flex flex-col items-center space-y-[30px]'}>
            <div
              style={{
                height: 'auto',
                margin: '0 auto',
                maxWidth: 220,
                width: '100%',
              }}
            >
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={surveyUrl}
                viewBox={`0 0 256 256`}
              />
            </div>

            <p className={'text-[20px] text-black'}>{surveyUrl}</p>
          </div>
        </>
      ),
      type: ModalTypes.QR_CODE,
      cancel: false,
      ok: {
        label: 'Done',
        cb: () => hideDialog(),
      },
    });
  };

  const { showColorPicker, handleColorPickerClose, setShowColorPicker } =
    useColorPicker({
      initialColors: defaultColors,
    });

  return (
    <div className="grid grid-cols-2 space-x-2">
      <div className="space-y-2 row">
        {/* Website setup */}
        <Card>
          <CardHeader>
            <CardTitle>Website Setup</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col gap-y-5">
            {/* URL section */}
            <div className="flex flex-col gap-y-2">
              <div className="flex items-center gap-x-4">
                <Label>URL</Label>
                <span className="font-bold text-red-600 text-[10px] mb-0.5">
                  Must be unique across all projects
                </span>
              </div>
              <div className="relative flex flex-wrap items-center space-x-2">
                <div className="w-1/4">
                  <div className="absolute inline top-2 left-2">
                    {shouldShowTick() && (
                      <Icon
                        icon="fas fa-check-circle fa-l"
                        className="text-brand"
                      ></Icon>
                    )}
                    {!shouldShowTick() && (
                      <Icon
                        icon="fas fa-xmark-circle fa-l"
                        className="text-danger-error"
                      ></Icon>
                    )}
                    {subdomainIsChecking && (
                      <Icon
                        icon="fas fa-spinner fa-spin fa-l"
                        className="text-project-empty"
                      ></Icon>
                    )}
                  </div>
                  <Input
                    className="pl-10 text-right"
                    placeholder="Subdomain"
                    pattern="^[a-z0-9\-]+$"
                    value={formContent.setup.website_setup.website_url}
                    disabled={formContent.visibility !== 'Draft'}
                    autoComplete="off"
                    data-lpignore="true"
                    onChange={e => {
                      setUrl(e.target.value);
                      const obj = { ...formContent };
                      obj.setup.website_setup.website_url = e.target.value;
                      setFormContent(obj);
                      setIsDirty(true);
                    }}
                  />
                </div>
                <p className="text-xl">{process.env.REACT_APP_SURVEY_DOMAIN}</p>
                <Button
                  icon={faUpRightFromSquare}
                  className="bg-primary"
                  disabled={visitButtonsDisabled()}
                  tooltip={
                    visitButtonsDisabled()
                      ? 'Survey must have valid URL and not be in Draft state to be visible online'
                      : ''
                  }
                  onClick={() => window.open(surveyUrl, '_blank')}
                >
                  Visit
                </Button>
                <Button
                  icon={faQrcode}
                  className="bg-primary"
                  disabled={visitButtonsDisabled()}
                  tooltip={
                    visitButtonsDisabled()
                      ? 'Survey must have valid URL and not be in Draft state to be visible online'
                      : ''
                  }
                  onClick={showQRModal}
                >
                  QR
                </Button>
              </div>
            </div>
            {/* package section */}
            <div className="flex flex-col gap-y-2">
              <Label>Package</Label>
              <Select
                value={formContent.setup.website_setup.package}
                onValueChange={value => {
                  const obj = { ...formContent };
                  obj.setup.website_setup.package = value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Your Package" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    {packageList.map((item, index) => (
                      <SelectItem
                        key={`initial_sort-${index} - ${item}`}
                        value={item.value}
                      >
                        {' '}
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            {/* preview password section */}
            <div className="flex flex-col gap-y-2">
              <Label>Preview Password</Label>
              <Input
                value={formContent.setup.website_setup.preview_password}
                onChange={e => {
                  const obj = { ...formContent };
                  obj.setup.website_setup.preview_password = e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>

            <div className="flex flex-col gap-y-2">
              <Label>Theme Colour</Label>
              <>
                <span className="flex items-center border rounded-md w-fit">
                  <span
                    onClick={() => setShowColorPicker(true)}
                    className={`w-[35px] inline-block h-[30px] rounded-md cursor-pointer border m-1`}
                    style={{
                      backgroundColor:
                        formContent.setup.website_setup.theme_color,
                    }}
                  ></span>
                </span>
                {showColorPicker && (
                  <div className="z-10">
                    <div
                      className="fixed inset-0 bg-white opacity-20"
                      onClick={handleColorPickerClose}
                    ></div>
                    <div className="inset-0">
                      <SketchPicker
                        disableAlpha={true}
                        presetColors={[...defaultColors, 'transparent']}
                        width="200px"
                        color={formContent.setup.website_setup.theme_color}
                        onChange={color => {
                          const obj = { ...formContent };
                          obj.setup.website_setup.theme_color = color.hex;
                          setFormContent(obj);
                          setIsDirty(true);
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            </div>
          </CardContent>
        </Card>
        {/* social share section */}
        <Card>
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Social Share</CardTitle>
              <InfoPopover>
                <div>
                  <h6 className="mb-1">Example Social Share</h6>
                  <img alt="Example Social Share" src={socialShareInfoImage} />
                </div>
              </InfoPopover>
            </div>
            <h6 className="text-gray-400 ">
              How should the link look when you share it on social media
            </h6>
          </CardHeader>
          <CardContent>
            <div className="space-y-5">
              {/* social share tab title section */}
              <div className="flex flex-col gap-y-2">
                <Label>Title</Label>
                <Input
                  value={formContent.setup.social_share.title}
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.setup.social_share.title = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              {/* social share description section */}
              <div className="flex flex-col gap-y-2">
                <Label>Description</Label>
                <Textarea
                  value={formContent.setup.social_share.description}
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.setup.social_share.description = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              <div className="flex flex-col gap-y-2">
                <Label>Image</Label>
                <CustomImageField
                  image={socialImage}
                  handleImageUpload={handleUploadSocialImage}
                  handleImageRemove={handleRemoveSocialImage}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {/* Social share section */}
      <div className="space-y-2 row">
        {/* Browser tab section */}
        <Card>
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Browser Tab</CardTitle>
              <InfoPopover>
                <div>
                  <h6 className="mb-1">Example Browser Tab</h6>
                  <img alt="Example Browser Tab" src={browserTabInfoImage} />
                </div>
              </InfoPopover>
            </div>
            <h6 className="text-gray-400 ">
              How should the page tab look in the web browser
            </h6>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {/* browser title section */}
              <div className="flex flex-col gap-y-2">
                <Label>Title</Label>
                <Input
                  value={formContent.setup.browser_tab.title}
                  onChange={e => {
                    const obj = { ...formContent };
                    obj.setup.browser_tab.title = e.target.value;
                    setFormContent(obj);
                    setIsDirty(true);
                  }}
                />
              </div>
              {/* browser icon section */}
              <div className="flex flex-col gap-y-2">
                <Label>Icon</Label>
                <CustomImageField
                  image={browserIcon}
                  handleImageUpload={handleUploadBrowserIcon}
                  handleImageRemove={handleRemoveBrowserIcon}
                  isImageAnIcon={true}
                  acceptedImageTypes={[
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'image/webp',
                  ]}
                />
              </div>
            </div>
          </CardContent>
        </Card>
        {/* borealis integration section */}
        <Card>
          <CardHeader>
            <div className="flex gap-x-3">
              <CardTitle>Borealis Integration</CardTitle>
            </div>
            <h6 className="text-gray-400 ">
              Optional integration for sending survey data to Borealis
            </h6>
          </CardHeader>
          <CardContent>
            {/* borealis integration section */}
            <div className="flex flex-col gap-y-2">
              <Label>
                Engagement Plan ID{' '}
                <i
                  className={'fas fa-brackets-curly text-warning-error fa-xl'}
                />
              </Label>
              <Input
                value={
                  formContent.setup.borealis_integration.engagement_plan_id
                }
                onChange={e => {
                  const obj = { ...formContent };
                  obj.setup.borealis_integration.engagement_plan_id =
                    e.target.value;
                  setFormContent(obj);
                  setIsDirty(true);
                }}
              />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SetupTab;
