import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { useState, useEffect } from 'react';

export const useHandleOverlayVisibility = (
  overlays: MapFeatureDetails['overlays'] | undefined
) => {
  const [overlayVisibility, setOverlayVisibility] = useState<
    Record<string, boolean>
  >({});
  const [overlaysLoading, setOverlaysLoading] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (overlays) {
      const visibilityStates: Record<string, boolean> = {};
      const loadingStates: Record<string, boolean> = {};
      overlays.forEach(overlay => {
        visibilityStates[overlay.id] = overlay.startEnabled ?? false;
        loadingStates[overlay.id] = false;
      });
      setOverlayVisibility(visibilityStates);
      setOverlaysLoading(loadingStates);
    }
  }, [overlays]);

  const toggleOverlayVisibility = (id: string) => {
    setOverlayVisibility(prevVisibility => ({
      ...prevVisibility,
      [id]: !prevVisibility[id],
    }));
  };

  const setOverlaysLoadingState = ({
    id,
    loading,
  }: {
    id: string;
    loading: boolean;
  }) => {
    setOverlaysLoading(prevLoading => ({
      ...prevLoading,
      [id]: loading,
    }));
  };

  return {
    overlayVisibility,
    toggleOverlayVisibility,
    overlaysLoading,
    setOverlaysLoadingState,
  };
};
