import { useEffect, useState } from 'react';
import {
  BaseWidgetOptions,
  ChartType,
  WidgetOptions,
} from '../types/chart-types';
import { v4 as uniqueId } from 'uuid';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { Layouts } from 'react-grid-layout';
import { useGetWidgetsData } from './useGetWidgetsData';
import { defaultWidgetState } from '../lib/defaultWidgetState';
import { calculateHeightOfWidget } from '../lib/calculateHeightOfWidget';
import { calculateMinimumHeightOfWidget } from '../lib/calculateMinHeightOfWidget';
import { useGetRawDataFromDataStore } from './useGetRawDataFromDataStore';
import { useAggregatedChartData } from './useGetAggregatedChartData';

interface UseWidgetsReturn {
  layouts: Layouts;
  widgets: BaseWidgetOptions[];
  addOrEditWidget: (widgetData: BaseWidgetOptions) => void;
  deleteWidget: (widgetId: string) => void;
  setLayouts: (layouts: Layouts) => void;
  setWidgets: (widgets: BaseWidgetOptions[]) => void;
  updateWidgetData: (data: Partial<WidgetOptions>) => void;
  handleSaveButtonClick: () => void;
  widgetData: WidgetOptions;
  widgetsDataFromServer: Record<string, any[]>;
  isWidgetsDataError: boolean;
  isWidgetsDataLoading: boolean;
  isSingleWidgetDataError: boolean;
  isSingleWidgetDataLoading: boolean;
  rawDataFromDataStore: any[];
  singleWidgetData: any[];
  cloneWidget: (widgetId: string) => void;
}

type PropTypes = {
  selectedWidgetId: string | null;
  setSelectedWidgetId: (id: string | null) => void;
  setDialogOpen: (open: boolean) => void;
  dialogOpen: boolean;
};

export default function useWidgets(props: PropTypes): UseWidgetsReturn {
  const { selectedWidgetId, setDialogOpen, dialogOpen, setSelectedWidgetId } =
    props;
  const [layouts, setLayouts] = useState<Layouts>({});
  const [widgets, setWidgets] = useState<BaseWidgetOptions[]>([]);
  const { setIsDirty } = useSaveActionContext();
  const [widgetData, setWidgetData] =
    useState<WidgetOptions>(defaultWidgetState);

  /** custom hook to get widgets data for all widgets from one endpoint */
  const {
    widgetsDataFromServer,
    setWidgetsDataFromServer,
    isWidgetsDataLoading,
    isWidgetsDataError,
  } = useGetWidgetsData();

  // ** custom hook to get raw data for single widget, so that we can fill the form options */
  const { rawDataFromDataStore } = useGetRawDataFromDataStore(widgetData);

  // ** custom hook to get aggregated data for single widget, it takes two arguments- widget data and rawDataFromDataStore which we fetch to fill the left sidebar form items, if there is no aggregation data possible for the single widget in widget builder then it will return the rawDataFromDataStore */
  const {
    singleWidgetData,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
  } = useAggregatedChartData(rawDataFromDataStore, widgetData);

  const updateWidgetLayouts = (
    widgetData: BaseWidgetOptions,
    newId: string
  ) => {
    const layoutCopy = { ...layouts };
    const lgLastWidgetLayout = layoutCopy.lg[layoutCopy.lg.length - 1];
    const lastWidget = lgLastWidgetLayout;
    let newX = 0;
    let newY = 0;
    if (lastWidget) {
      newX = lastWidget.x === 0 ? 1 : 0;
      newY = lastWidget.y;
    }

    const newLgWidgetLayout = [
      ...layoutCopy.lg,
      {
        i: newId,
        x: newX,
        y: newY,
        w: 1,
        h: calculateHeightOfWidget(widgetData.chartType),
        maxH: widgetData.chartType === ChartType.Statistics ? 5 : 40,
        minH: calculateMinimumHeightOfWidget(widgetData.chartType),
        static: false,
        moved: false,
      },
    ];

    const newLayouts = {
      ...layouts,
      lg: newLgWidgetLayout,
      md: newLgWidgetLayout,
    };

    return newLayouts;
  };

  const addOrEditWidget = (widgetData: BaseWidgetOptions) => {
    if (widgetData.id) {
      const newWidgets = widgets.map(widget =>
        widget.id === widgetData.id ? widgetData : widget
      );
      setWidgetsDataFromServer({
        ...widgetsDataFromServer,
        [widgetData.id]: singleWidgetData,
      });
      setWidgets(newWidgets);
      setIsDirty(true);
      return;
    }
    const newId = uniqueId();
    const newWidget = {
      ...widgetData,
      id: newId,
    };

    const newLayouts = updateWidgetLayouts(widgetData, newId);

    setWidgetsDataFromServer({
      ...widgetsDataFromServer,
      [newId]: singleWidgetData,
    });

    setWidgets([...widgets, newWidget]);
    setIsDirty(true);
    setLayouts(newLayouts);
  };

  const deleteWidget = (widgetId: string) => {
    const newWidgets = widgets.filter(widget => widget.id !== widgetId);
    const newLayouts = Object.fromEntries(
      Object.entries(layouts).map(([key, value]) => {
        return [key, value.filter((widget: any) => widget.i !== widgetId)];
      })
    );
    setWidgets(newWidgets);
    setLayouts(newLayouts);
    setIsDirty(true);
  };

  const updateWidgetData = (data: Partial<WidgetOptions>) => {
    setWidgetData({ ...widgetData, ...data });
  };

  const cloneWidget = (widgetId: string) => {
    const widgetData = widgets.find(widget => widget.id === widgetId);
    const widgetDataFromServer = widgetsDataFromServer[widgetId];
    if (widgetData) {
      const newId = uniqueId();
      const newWidget = {
        ...widgetData,
        id: newId,
        title: `${widgetData.title} (copy)`,
      };

      const newLayouts = updateWidgetLayouts(widgetData, newId);

      setWidgetsDataFromServer({
        ...widgetsDataFromServer,
        [newId]: widgetDataFromServer,
      });

      setWidgets([...widgets, newWidget]);
      setIsDirty(true);
      setLayouts(newLayouts);
      setSelectedWidgetId(newId);
      setDialogOpen(true);
    }
  };

  const handleSaveButtonClick = () => {
    if (widgetData.chartType) {
      addOrEditWidget(widgetData);
      setDialogOpen(false);
    }
    setWidgetData(defaultWidgetState);
  };

  useEffect(() => {
    if (!selectedWidgetId || !dialogOpen) {
      setWidgetData(defaultWidgetState);
      return;
    }
    const widgetData = widgets.find(widget => widget.id === selectedWidgetId);
    if (widgetData) {
      setWidgetData(widgetData);
    }
  }, [selectedWidgetId, widgets, dialogOpen]);

  return {
    layouts,
    widgets,
    addOrEditWidget,
    deleteWidget,
    setLayouts,
    setWidgets,
    updateWidgetData,
    handleSaveButtonClick,
    widgetData,
    widgetsDataFromServer,
    isWidgetsDataError,
    isWidgetsDataLoading,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    rawDataFromDataStore,
    singleWidgetData,
    cloneWidget,
  };
}
