import React from 'react';
import { CustomNode } from '.';
import { Handle, Position, Node } from 'reactflow';
import { CustomFunctionNodeData } from '../types/node-types';

const CustomFunctionNode = ({
  data,
  ...rest
}: Node<CustomFunctionNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <Handle
        type="target"
        position={Position.Top}
        id="b"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default CustomFunctionNode;
