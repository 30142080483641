import React from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { Input } from '@tcomponents/ui/input';
import { SummarizeNodeData } from '@/pages/dashboard/projects/graph/types';
import { Textarea } from '@tcomponents/ui/textarea';
import { Switch } from '@tcomponents/ui/switch';
import { Label } from '@tcomponents/ui/label';

const SummarizeFormFields: React.FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const {
    source,
    destination,
    maxWordCount,
    instructions,
    shouldReprocessRows,
  } = activeNode.data as SummarizeNodeData;

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Fields</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Source Field</label>
            <Input
              value={source}
              onChange={e =>
                handleSingleInputFieldChange('source', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Destination Field</label>
            <Input
              value={destination}
              onChange={e =>
                handleSingleInputFieldChange('destination', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className={'flex flex-row'}>
            <div className="flex flex-col flex-1 py-2 gap-y-2">
              <div className="flex flex-col gap-y-2">
                <label className="text-xs font-bold">
                  Reprocess Existing Values
                </label>
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={shouldReprocessRows}
                    onCheckedChange={val =>
                      handleSingleInputFieldChange('shouldReprocessRows', val)
                    }
                    id="shouldReprocessRows"
                  />
                  <Label htmlFor="shouldReprocessRows">
                    {shouldReprocessRows ? 'Yes' : 'No'}
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Configuration</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Max Word Count</label>
            <Input
              defaultValue={200}
              value={maxWordCount}
              onChange={e =>
                handleSingleInputFieldChange('maxWordCount', e.target.value)
              }
              type="number"
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Instructions</label>
            <Textarea
              value={instructions}
              onChange={e =>
                handleSingleInputFieldChange('instructions', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummarizeFormFields;
