import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { LineWidgetOptions } from '../../types/chart-types';
import { defaultColors, useColorPicker } from '../../hooks/useColorPicker';
import { SketchPicker } from 'react-color';
import { Switch } from '@/tcomponents/ui/switch';
import { getKeys } from '../../../utils/helperFunctions';
import { cn } from '@/lib/utils';
import { useChartDashboardContext } from '../../ChartDashboardProvider';
type LineChartOptionsProps = {
  widgetData: LineWidgetOptions;
};

export default function LineChartOptions(props: LineChartOptionsProps) {
  const { widgetData } = props;
  const { singleWidgetData, updateWidgetData, isReadyToShow } =
    useChartDashboardContext();

  const {
    showColorPicker,
    selectedColorIndex,
    colors,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
  } = useColorPicker({
    initialColors: [...widgetData.dataStyling, ...defaultColors] ?? [],
    updateWidgetData,
  });

  const dataStylingKeys = getKeys(singleWidgetData);
  const isDataAnArray = Array.isArray(singleWidgetData);

  const handleChartOptionsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateWidgetData({
      chartOptions: {
        ...widgetData.chartOptions,
        [name]: value,
      },
    });
  };
  return (
    <div>
      <div className="flex flex-col p-3 gap-y-4">
        <h4>Chart Options</h4>
        <div>
          <Label>X-axis Label</Label>
          <Input
            name="xAxisLabel"
            onChange={handleChartOptionsChange}
            value={widgetData.chartOptions.xAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Y-axis Label</Label>
          <Input
            name="yAxisLabel"
            onChange={handleChartOptionsChange}
            value={widgetData.chartOptions.yAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Unit</Label>
          <Input
            name="unit"
            onChange={handleChartOptionsChange}
            value={widgetData.chartOptions.unit ?? ''}
            placeholder="Enter Unit"
          />
        </div>
        <div>
          <Label> Show Key</Label>
          <Switch
            checked={widgetData.chartOptions?.showKeys ?? true}
            defaultChecked={true}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  showKeys: val,
                },
              })
            }
            className="mt-1"
            checkedLabel="On"
            unCheckedLabel="Off"
          />
        </div>
        <div className="flex flex-col">
          <Label> Auto Baseline Adjust</Label>
          <Switch
            checked={widgetData.chartOptions?.autoBaselineAdjust ?? false}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  autoBaselineAdjust: val,
                },
              })
            }
            className="mt-1"
            checkedLabel="On"
            unCheckedLabel="Off"
          />
        </div>
        <div className="flex flex-col">
          <Label> Detail Bars</Label>
          <Switch
            checked={widgetData.chartOptions?.detailBar ?? false}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  detailBar: val,
                },
              })
            }
            className="mt-1"
            checkedLabel="On"
            unCheckedLabel="Off"
          />
        </div>
        {widgetData.chartOptions?.detailBar && (
          <>
            <div>
              <Label>Detail Name Label</Label>
              <Input
                name="detailNameLabel"
                onChange={handleChartOptionsChange}
                value={widgetData.chartOptions.detailNameLabel ?? ''}
                placeholder="Enter Label"
              />
            </div>
            <div>
              <Label>Detail Value Label</Label>
              <Input
                name="detailValueLabel"
                onChange={handleChartOptionsChange}
                value={widgetData.chartOptions.detailValueLabel ?? ''}
                placeholder="Enter Label"
              />
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col divide-y">
        <h4 className="p-3">Chart Styling</h4>
        {isReadyToShow &&
          isDataAnArray &&
          dataStylingKeys.length > 0 &&
          dataStylingKeys.map((key, index, arr) => (
            <div
              className={cn('flex items-center justify-between p-2', {
                'bg-gray-100': index % 2 !== 0,
                '!border-b': index === arr.length - 1,
              })}
              key={`color-${index}`}
            >
              <span>{key}</span>
              <span
                onClick={() => handleColorClick(index)}
                className={`w-8 h-8 rounded-md cursor-pointer`}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
            </div>
          ))}
        {showColorPicker && (
          <div className="absolute z-10">
            <div
              className="fixed inset-0 bg-white opacity-20"
              onClick={handleColorPickerClose}
            ></div>
            <div className="inset-0">
              <SketchPicker
                width="200px"
                color={colors[selectedColorIndex]}
                onChange={handleColorChange}
                presetColors={[...defaultColors, 'transparent']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
