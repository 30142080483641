import { defaultDataStyling } from './consts';

export function validateDataStylingKeys(
  dataStyling: Record<string, string>,
  newKeys: string[]
) {
  const keySet = new Set(newKeys);
  const newDataStyling = Object.fromEntries(
    Object.entries(dataStyling).filter(([key]) => {
      if (keySet.has(key)) {
        keySet.delete(key);
        return true;
      }
      return false;
    })
  );
  // keyset -> only contains those keys that were not in the data styling object
  return {
    ...newDataStyling,
    ...Object.fromEntries(
      Array.from(keySet).map(key => [
        key,
        defaultDataStyling[
          Math.floor(Math.random() * defaultDataStyling.length)
        ],
      ])
    ),
  };
}
