import React, { useState, useEffect, useContext } from 'react';
import AuthenticatedLayout from '@/layout/AuthenticatedLayout';
import { Button } from '@tcomponents/ui/button';
import { faEye, faSave } from '@fortawesome/pro-solid-svg-icons';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@tcomponents/ui/popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCircleExclamation,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import { Alert, AlertDescription, AlertTitle } from '@tcomponents/ui/alert';
import SidebarTypes from '@/types/enums/common/SidebarTypes';
import { useNavigate, useParams } from 'react-router-dom';
import LinkInterface from '@/types/interfaces/LinkInterface';
import { toast } from '@tcomponents/ui/toast/use-toast';
import { PopoverClose } from '@radix-ui/react-popover';
import TabbedNavigationBarElement from '@/layout/tab-navigation/TabbedNavigationBarElement';
import SetupTab from '@/pages/survey/ConfigurationTabs/SetupTab';
import WelcomeTab from '@/pages/survey/ConfigurationTabs/WelcomeTab';
import StepsTab from '@/pages/survey/ConfigurationTabs/StepsTab';
import SubmitTab from '@/pages/survey/ConfigurationTabs/SubmitTab';
import ThanksTab from '@/pages/survey/ConfigurationTabs/ThanksTab';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import { mapFormStateToConfiguration } from '@/pages/survey/Helpers/FormStates';
import { Prompt } from '@/utils/use-prompt';
import { iPackageList } from './Helpers/Types';
import { useSurveyContext } from './SurveyProvider';
import { useSaveActionContext } from '@/context/SaveActionContext';
import {
  useAssignSurveyStateMutation,
  useGetSurveyPackageListQuery,
  useSaveSurveyConfigurationMutation,
} from '@/store/services/projects/surveyApi';
import useAuth from '@/hooks/UseAuth';
import { useURLCheck } from '@/pages/survey/hooks/useURLCheck';
import {
  featurePermissions,
  surveyPermissions,
} from '@/stringConsts/permissionConsts';
import { WorkflowView } from '../dashboard/projects/graph/components/WorkflowView';
import { useGetRowTypeDataStoresQuery } from '@/store/services/projects/graphApi';
import TabbedNavigationBar from '@/layout/tab-navigation/TabbedNavigationBar';

const FeatureSurveyConfiguration: React.FC = () => {
  let { featureId, featureGroupId, projectId, featureType } = useParams();

  const [tabIndex, setTabIndex] = useState(0);
  const [dataTabIndex, setDataTabIndex] = useState(0);
  const { setIsDirty, isDirty } = useSaveActionContext();

  const { showDialog } = useContext(ModalContext);
  const navigate = useNavigate();
  const [packageList, setPackageList] = useState<Array<iPackageList>>([]);

  const {
    projectDetails,
    pageTitle,
    pageSubTitle,
    formContent,
    surveyConfigId,
    surveyFeatureData,
  } = useSurveyContext();

  const {
    data: surveyListData,
    isSuccess: isSurveyListFetchSuccess,
    isError: isSurveyListFetchError,
  } = useGetSurveyPackageListQuery();

  const { data: rowTypeDataStoresArray } = useGetRowTypeDataStoresQuery(
    Number(featureId),
    {
      skip: !featureId,
    }
  );

  const selectedRowTypeDataStore = rowTypeDataStoresArray?.[dataTabIndex];

  const dataTabNavigationElements =
    rowTypeDataStoresArray?.map(rowTypeDataStore => {
      return (
        <TabbedNavigationBarElement key={rowTypeDataStore.uuid}>
          {rowTypeDataStore.title}
        </TabbedNavigationBarElement>
      );
    }) ?? [];
  useEffect(() => {
    if (!surveyListData) return;
    if (isSurveyListFetchSuccess) {
      setPackageList(surveyListData);
    }
    if (isSurveyListFetchError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem fetching the survey package list',
        duration: 24 * 60 * 60000,
      });
    }
  }, [isSurveyListFetchSuccess, isSurveyListFetchError, surveyListData]);

  const [saveSurveyConfiguration, { isError, isSuccess }] =
    useSaveSurveyConfigurationMutation();

  const onSubmitHandler = () => {
    const mappedForm = mapFormStateToConfiguration(
      formContent,
      Number(surveyConfigId)
    );
    saveSurveyConfiguration(mappedForm);
  };
  useEffect(() => {
    if (isSuccess) {
      setIsDirty(false);
      toast({
        title: 'Success!',
        description: 'Successfully saved the survey',
        duration: 5000,
      });
    }
    if (isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem saving the survey',
        duration: 24 * 60 * 60000,
      });
    }
  }, [isSuccess, isError]);
  const canSave = (): boolean => {
    const isDirtyAndIsOk = isDirty && isSubdomainOk();
    const isEmpty =
      formContent.setup.website_setup.website_url === '' ||
      formContent.setup.website_setup.website_url === null;
    const isDraftAndIsEmpty = formContent.visibility === 'Draft' && isEmpty;

    return isDirtyAndIsOk || (isDirty && isDraftAndIsEmpty);
  };

  Prompt({
    message: 'Are you sure you want to leave without saving?',
    when: isDirty,
  });

  const [
    assignSurveyState,
    { isSuccess: isAssigningSurveySuccess, isError: isAssigningSurveyError },
  ] = useAssignSurveyStateMutation();

  useEffect(() => {
    if (isAssigningSurveySuccess) {
      toast({
        title: 'Success!',
        description: 'Successfully set the survey state',
        duration: 5000,
      });
    }

    if (isAssigningSurveyError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem setting the survey state',
        duration: 24 * 60 * 60000,
      });
    }
  }, [isAssigningSurveySuccess, isAssigningSurveyError]);

  const callSurveyStateApi = (state: string) => {
    formContent.visibility = state;
    assignSurveyState({ featureId: featureId as string, state: state });
  };

  const { hasPermission } = useAuth();

  const handleSurveyStateChange = (
    state: 'Draft' | 'Preview' | 'Live' | 'Closed'
  ) => {
    let message;
    switch (state) {
      case 'Draft':
        message = 'Take the survey offline';
        break;
      case 'Preview':
        message =
          'Preview the survey before it goes live, users will need to enter password';
        break;
      case 'Live':
        message = 'This will make the survey live for the public';
        break;
      case 'Closed':
        message = 'Close the survey and show the thanks message';
        break;
      default:
        message = 'This will modify the survey state';
        break;
    }

    showDialog({
      title: `Put Survey Into ${state} State?`,
      message: message,
      type: ModalTypes.DEFAULT,
      cancel: true,
      ok: {
        cb: () => callSurveyStateApi(state),
      },
    });
  };

  const breadcrumbElements: Array<LinkInterface> =
    featureId !== 'new'
      ? [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
        ]
      : [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
        ];

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <SetupTab
            packageList={packageList}
            setUrl={setUrl}
            subdomainIsOk={subdomainIsOk}
            subdomainIsChecking={subdomainIsChecking}
            hasEverChecked={hasEverChecked}
          />
        );
      case 1:
        return <WelcomeTab />;
      case 2:
        return <StepsTab />;
      case 3:
        return <SubmitTab />;
      case 4:
        return <ThanksTab />;
      case 5:
        return (
          <div className="flex flex-col h-full">
            <>
              <TabbedNavigationBar
                elements={dataTabNavigationElements || []}
                selectedTabIndex={dataTabIndex}
                onChange={index => setDataTabIndex(index)}
                tabsContainerClassName="px-0 md:px-0 2xl:px-0 rounded-y-md bg-background gap-x-1 border-none ml-[10px]"
                tabsTriggerClassName="bg-white px-6 rounded-t-md h-full border-x border-t data-[state=active]:border-t-border data-[state=active]:border-x-border"
              />
              <WorkflowView
                selectedRowTypeDataStore={selectedRowTypeDataStore}
                featureName={surveyFeatureData?.name}
              />
            </>
          </div>
        );
      default:
        return (
          <SetupTab
            packageList={packageList}
            setUrl={setUrl}
            subdomainIsOk={subdomainIsOk}
            subdomainIsChecking={subdomainIsChecking}
            hasEverChecked={hasEverChecked}
          />
        );
    }
  };

  const { setUrl, subdomainIsOk, subdomainIsChecking, hasEverChecked } =
    useURLCheck();

  const isSubdomainOk = () => {
    const isValidFromApi = subdomainIsOk && !subdomainIsChecking;
    const isEmptyField =
      formContent.setup.website_setup.website_url === '' ||
      formContent.setup.website_setup.website_url === null;

    if (
      !isEmptyField &&
      !subdomainIsOk &&
      !subdomainIsChecking &&
      !hasEverChecked
    ) {
      return true;
    }

    if (isEmptyField) {
      return false;
    }

    if (!hasEverChecked && !isEmptyField) {
      return true;
    }

    if (hasEverChecked && isValidFromApi) {
      return true;
    }

    if (hasEverChecked && !isValidFromApi) {
      return false;
    }

    return true;
  };
  const getTabbedNavigationBarElements = () => {
    return [
      <TabbedNavigationBarElement>Setup</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement>Welcome</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement>Steps</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement>Submit</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement>Thanks</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement>Data</TabbedNavigationBarElement>,
    ];
  };

  // Reset tab index to 0 (setupTab) when featureId changes
  useEffect(() => {
    setTabIndex(0);
    setIsDirty(false);
  }, [featureId]);

  return (
    <AuthenticatedLayout
      fullscreen={featureType?.toLowerCase() === 'map'}
      breadcrumbElements={breadcrumbElements}
      logo={
        typeof projectDetails?.logo_url !== 'undefined'
          ? projectDetails.logo_url
          : process.env.REACT_APP_DEFAULT_PROJECT_LOGO
      }
      icon=""
      title={pageTitle}
      subTitle={pageSubTitle}
      actions={
        <div className="flex items-center gap-x-8">
          <div style={{ display: 'inherit', alignItems: 'center' }}>
            {!isSubdomainOk() && (
              <Button icon={faEye} className="bg-[#FBBD08]" disabled={true}>
                {formContent.visibility}
                <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </Button>
            )}
            {isSubdomainOk() &&
              hasPermission(surveyPermissions.general.edit) && (
                <Popover>
                  <PopoverTrigger disabled={canSave()}>
                    <Button
                      icon={faEye}
                      className="bg-[#FBBD08]"
                      disabled={canSave()}
                      tooltip={
                        canSave()
                          ? 'You cannot change the state of the survey until changes are saved'
                          : ''
                      }
                    >
                      {formContent.visibility}
                      <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    side="bottom"
                    className="p-0 text-[14px] w-fit min-w-[150px]"
                  >
                    <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                      <Button
                        onClick={() => handleSurveyStateChange('Draft')}
                        key={'empty-project'}
                        variant="ghost"
                        className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full"
                      >
                        Draft
                      </Button>

                      <Button
                        onClick={() => handleSurveyStateChange('Preview')}
                        key={'empty-project'}
                        variant="ghost"
                        className="font-normal text-[14px] text-gray-800 items-center justify-start px-4 min-w-full"
                      >
                        Preview
                      </Button>

                      <Button
                        onClick={() => handleSurveyStateChange('Live')}
                        key={'empty-project'}
                        variant="ghost"
                        className="font-normal text-[14px] text-gray-800 items-center justify-start px-4 min-w-full"
                        disabled={
                          !hasPermission(surveyPermissions.general.approve)
                        }
                        tooltip={
                          !hasPermission(surveyPermissions.general.approve)
                            ? 'Manager approval required to make survey live'
                            : ''
                        }
                      >
                        Live
                      </Button>

                      <Button
                        onClick={() => handleSurveyStateChange('Closed')}
                        key={'empty-project'}
                        variant="ghost"
                        className="font-normal text-[14px] text-gray-800 items-center justify-start px-4 min-w-full"
                      >
                        Closed
                      </Button>
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              )}
          </div>
          {hasPermission(featurePermissions.general.settings_access) && (
            <Button
              icon={faSitemap}
              flip="vertical"
              onClick={() => {
                navigate(
                  `/projects/${projectId}/feature-groups/${featureGroupId}/${featureId}/survey`
                );
              }}
            >
              Workflow
            </Button>
          )}
          <Button
            icon={faSave}
            flip="vertical"
            onClick={onSubmitHandler}
            disabled={
              !canSave() || !hasPermission(surveyPermissions.general.edit)
            }
          >
            Save
          </Button>
        </div>
      }
      notifications={
        formContent.visibility === 'Live' ? (
          <Alert className="flex items-center p-3 rounded-none bg-warning-background">
            <div className="flex items-center justify-center py-[5px] px-[30px] md:px-[80px]">
              <div className="mr-2">
                <FontAwesomeIcon
                  className="w-[34px] h-[34px] mr-1 text-warning-error"
                  icon={faCircleExclamation}
                  size="lg"
                />
              </div>
              <div>
                <AlertTitle className="m-0 text-lg font-bold text-[18px] text-warning-text">
                  Live Survey
                </AlertTitle>
                <AlertDescription className="overflow-hidden text-base text-warning-text max-h-10 text-ellipsis">
                  This survey has been published and is live on the web, changes
                  to this survey will be reflected on the live site.
                </AlertDescription>
              </div>
            </div>
          </Alert>
        ) : null
      }
      sidebarData={
        projectDetails
          ? {
              type: SidebarTypes.PROJECT,
              data: projectDetails,
              backButtonCallback: () => navigate('/projects'),
            }
          : {
              type: SidebarTypes.PROJECT,
              data: {},
              backButtonCallback: () => navigate('/projects'),
            }
      }
      tabIndex={tabIndex}
      selectedTabIndexChanged={setTabIndex}
      tabNavigationElements={getTabbedNavigationBarElements()}
    >
      {renderTab()}
    </AuthenticatedLayout>
  );
};

export default FeatureSurveyConfiguration;
