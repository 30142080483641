import React, { createContext, ReactNode, useState } from 'react';
import ActionInterface from '../types/interfaces/ActionInterface';
import ModalTypes from '../types/enums/modal/ModalTypes';
import Modal from '../components/common/Modal';
import QRModal from '@components/common/QRModal';

interface IModalContext {
  showDialog: (config: DialogConfig) => void;
  hideDialog: () => void;
}

interface IModalContextProps {
  children?: ReactNode;
}

export const ModalContext = createContext<IModalContext>({
  showDialog: () => {},
  hideDialog: () => {},
});

interface DialogConfig {
  title: string;
  message: string | ReactNode;
  ok?: ActionInterface;
  cancel: boolean;
  type?: ModalTypes;
  customActions?: ActionInterface[];
}

export const ModalProvider = (props: IModalContextProps) => {
  const [open, setOpen] = useState(false);
  const { children } = props;
  const [dialogConfig, setDialogConfig] = useState<DialogConfig>({
    title: '',
    cancel: true,
    message: '',
    ok: {
      label: '',
      cb: () => {},
      buttonClasses: [''],
    },
    type: ModalTypes.DEFAULT,
    customActions: [],
  });

  const modalContext = React.useMemo(
    () => ({
      showDialog: (config: DialogConfig) => {
        setDialogConfig(config);
        setOpen(true);
      },
      hideDialog: () => {
        setOpen(false);
      },
    }),
    []
  );

  return (
    <ModalContext.Provider value={modalContext}>
      {dialogConfig && dialogConfig.type !== 'qr_code' && (
        <Modal
          title={dialogConfig.title}
          type={dialogConfig.type}
          message={dialogConfig.message}
          open={open}
          setOpen={setOpen}
          cancel={dialogConfig.cancel}
          confirmCallback={dialogConfig.ok?.cb}
          customActions={dialogConfig.customActions}
          confirmLabel={dialogConfig.ok?.label}
        />
      )}
      {dialogConfig && dialogConfig.type === 'qr_code' && (
        <QRModal
          title={dialogConfig.title}
          type={dialogConfig.type}
          message={dialogConfig.message}
          open={open}
          setOpen={setOpen}
          cancel={dialogConfig.cancel}
          confirmCallback={dialogConfig.ok?.cb}
          confirmLabel={dialogConfig.ok?.label}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
};
