import React, { ReactNode } from 'react';
import { CustomNodeData } from '../types/node-types';
import { Node } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '@/lib/utils';
import { faLock } from '@fortawesome/free-solid-svg-icons';

type NodeProps = Omit<Node, 'data'>;
export interface CustomNodeProps {
  data: Pick<CustomNodeData, 'meta' | 'heading' | 'isError' | 'deletable'>;
  children: ReactNode;
  nodeProps: NodeProps;
}

const CustomNode = (props: CustomNodeProps) => {
  const { nodeProps } = props;
  const selected = nodeProps?.selected;
  const roundedBorderNodeTypes = [
    'Join Node',
    'Input Node',
    'Custom Function Node',
  ];

  return (
    <div
      className={cn(
        'text-[8px] bg-white border border-[#AAAAAA] rounded-md shadow-md min-w-[110px] max-w-[120px] break-words',
        selected && 'outline outline-2 outline-[#45A1FB] shadow-none',
        props.data.isError &&
          'outline outline-2 outline-destructive shadow-md shadow-destructive',
        props.data.isError &&
          selected &&
          'outline outline-2 outline-[#45A1FB] shadow-none'
      )}
    >
      <div
        style={{
          backgroundColor: props.data.meta?.bgColor,
        }}
        className={cn(
          'font-bold p-1.5 text-white overflow-hidden flex gap-x-2 items-center relative',
          roundedBorderNodeTypes.includes(nodeProps.type as string)
            ? 'rounded-md'
            : 'rounded-t-md'
        )}
      >
        <FontAwesomeIcon
          icon={props.data.meta?.icon}
          className="text-[14px] text-white"
        />
        <p>{props.data.heading}</p>
        {props.data?.deletable === false && (
          <FontAwesomeIcon
            icon={faLock}
            className="absolute text-white top-1 right-1"
          />
        )}
      </div>
      {props.children}
    </div>
  );
};

export default CustomNode;
