import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { StackedHorizontalBarWidgetOptions } from '../../types/chart-types';
import { defaultColors, useColorPicker } from '../../hooks/useColorPicker';
import { SketchPicker } from 'react-color';
import { getKeys } from '../../../utils/helperFunctions';
import { cn } from '@/lib/utils';
import { Switch } from '@/tcomponents/ui/switch';
import { useChartDashboardContext } from '../../ChartDashboardProvider';

type StackedHorizontalBarChartOptionsProps = {
  widgetData: StackedHorizontalBarWidgetOptions;
};

export default function StackedHorizontalBarChartOptions(
  props: StackedHorizontalBarChartOptionsProps
) {
  const { widgetData } = props;
  const { singleWidgetData, updateWidgetData, isReadyToShow } =
    useChartDashboardContext();

  const {
    showColorPicker,
    selectedColorIndex,
    colors,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
  } = useColorPicker({
    initialColors: [...widgetData.dataStyling, ...defaultColors] ?? [],
    updateWidgetData,
  });

  const dataStylingKeys = getKeys(singleWidgetData);
  const isDataAnArray = Array.isArray(singleWidgetData);

  return (
    <div>
      <div className="flex flex-col p-3 gap-y-4">
        <h4>Chart Options</h4>
        <div>
          <Label>X-axis Label</Label>
          <Input
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  xAxisLabel: e.target.value,
                },
              })
            }
            value={widgetData.chartOptions.xAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Y-axis Label</Label>
          <Input
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  yAxisLabel: e.target.value,
                },
              })
            }
            value={widgetData.chartOptions.yAxisLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Detail Name Label</Label>
          <Input
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  detailNameLabel: e.target.value,
                },
              })
            }
            value={widgetData.chartOptions.detailNameLabel ?? ''}
            placeholder="Enter Label"
          />
        </div>
        <div>
          <Label>Unit</Label>
          <Input
            onChange={e =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  unit: e.target.value,
                },
              })
            }
            value={widgetData.chartOptions.unit ?? ''}
            placeholder="Enter Unit"
          />
        </div>
        <div>
          <Label>Show Key</Label>
          <Switch
            checkedLabel="On"
            unCheckedLabel="Off"
            checked={widgetData.chartOptions?.showKeys === false ? false : true}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: {
                  ...widgetData.chartOptions,
                  showKeys: val,
                },
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col divide-y">
        <h4 className="p-3">Chart Styling</h4>
        {isReadyToShow &&
          isDataAnArray &&
          dataStylingKeys.length > 0 &&
          dataStylingKeys.map((item, index, arr) => (
            <div
              className={cn('flex items-center justify-between p-2', {
                'bg-gray-100': index % 2 !== 0,
                '!border-b': index === arr.length - 1,
              })}
              key={`color-${index}`}
            >
              <span>{item}</span>
              <span
                onClick={() => handleColorClick(index)}
                className={`w-8 h-8 rounded-md cursor-pointer`}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
            </div>
          ))}
        {showColorPicker && (
          <div className="absolute z-10">
            <div
              className="fixed inset-0 bg-white opacity-20"
              onClick={handleColorPickerClose}
            ></div>
            <div className="inset-0">
              <SketchPicker
                width="200px"
                color={colors[selectedColorIndex]}
                onChange={handleColorChange}
                presetColors={[...defaultColors, 'transparent']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
