import { FC } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';

interface MapEventsProps {
  setCurrentZoom: React.Dispatch<React.SetStateAction<number>>;
}

export const MapEvents: FC<MapEventsProps> = ({ setCurrentZoom }) => {
  const map = useMap();
  useMapEvents({
    // zoom event (when zoom animation ended)
    zoomend() {
      const zoom = map.getZoom(); // get current Zoom of map
      setCurrentZoom(zoom);
    },
  });
  return null;
};
