import React from 'react';
import { CustomNode } from '.';
import { Handle, Node, Position } from 'reactflow';
import { InputNodeData } from '../types/node-types';

const InputNode = ({ data, ...rest }: Node<InputNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};
export default InputNode;
