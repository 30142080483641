import React, { FC, Fragment, useContext } from 'react';
import LinkInterface from '../../types/interfaces/LinkInterface';
import { Link } from 'react-router-dom';
import { FullscreenContext } from '@/context/FullscreenContext';

interface IBreadcrumbProps {
  elements: Array<LinkInterface>;
}

const Breadcrumb: FC<IBreadcrumbProps> = ({ elements }) => {
  const { handle } = useContext(FullscreenContext);

  const getElementLink = (link: string) => {
    if (handle.active) {
      return '';
    }

    return link;
  };

  return (
    <nav className="hidden md:flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-1 text-base">
        {elements.map((element, index) => (
          <Fragment key={'breadcrumb-' + index}>
            <li>
              <Link
                className="text-brand hover:text-black"
                to={getElementLink(element.link)}
              >
                {element.displayName}
              </Link>
            </li>
            {index !== elements.length - 1 && (
              <div className="font-semibold"> /</div>
            )}
          </Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
