import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function LoadingData() {
  return (
    <div className="flex flex-col items-center justify-center h-full text-gray-400 gap-y-1">
      <FontAwesomeIcon icon={faCircleNotch} size="2xl" spin />
      <h6>Loading Data</h6>
    </div>
  );
}
