import React from 'react';
import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { Textarea } from '@/tcomponents/ui/textarea';
import { ChartType } from '../types/chart-types';
import { chartButtonsData } from '../lib/widgetBuilderChartButtons';
import { Button } from '@/tcomponents/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useWidgetBuilderSetupOptions } from '@/pages/dashboard/projects/chartDashboard/chartDialog/hooks/useWidgetBuilderSetupOptions';

export const WidgetBuilderSetup = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const {
    widgetData,
    updateWidgetData,
    workflows,
    filteredDataStoresByChartType,
    calculateAggType,
    multiselectData,
    firstDataRow,
    fieldsToShow,
  } = useWidgetBuilderSetupOptions(projectId);

  return (
    <>
      <div className="flex flex-col max-h-[75vh] col-span-1 py-4 px-5 overflow-auto gap-y-10">
        <div className="flex flex-col gap-y-4">
          <h4>Widget Setup</h4>
          {fieldsToShow.title.active && (
            <div>
              <Label>Title</Label>
              <Input
                onChange={e => updateWidgetData({ title: e.target.value })}
                value={widgetData.title}
                placeholder="Enter widget title"
                className="mt-1"
                required
              />
            </div>
          )}

          {fieldsToShow.description.active && (
            <div>
              <Label>Description</Label>
              <Textarea
                onChange={e =>
                  updateWidgetData({ description: e.target.value })
                }
                value={widgetData.description}
                placeholder="Enter widget description"
                className="mt-1 break-words break-all resize-none"
                required
              />
            </div>
          )}

          {fieldsToShow.chartType.active && (
            <div>
              <Label>Chart Type</Label>
              <div className="flex flex-wrap gap-4 mt-1">
                {chartButtonsData.map(
                  ({ tooltip, chartType, icon, iconSize }) => (
                    <Button
                      key={chartType}
                      tooltip={tooltip}
                      onClick={() => updateWidgetData({ chartType })}
                      variant="outline"
                      size="icon"
                      className={`w-14 h-14 border-border ${
                        widgetData.chartType === chartType
                          ? 'bg-primary border-primary text-white'
                          : ''
                      }`}
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        size={iconSize as SizeProp}
                      />
                    </Button>
                  )
                )}
              </div>
            </div>
          )}
        </div>

        {/* Data Source Block */}
        {widgetData.chartType !== ChartType.IFrame && (
          <div className="flex flex-col col-span-1 gap-y-4">
            <h4>Data Source</h4>
            {fieldsToShow.workflow.active && (
              <div>
                <Label>Workflow</Label>
                <Select
                  disabled={!widgetData.chartType}
                  value={widgetData.dataSource?.workflow}
                  onValueChange={val =>
                    updateWidgetData({
                      dataSource: {
                        workflow: val,
                        dataStore: widgetData.dataSource?.dataStore ?? '',
                      },
                    })
                  }
                >
                  <SelectTrigger className="mt-1">
                    <SelectValue
                      placeholder={
                        widgetData.chartType
                          ? 'Select a workflow'
                          : 'Choose a chart type to select a workflow'
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      <SelectLabel>Workflows</SelectLabel>

                      {workflows &&
                        workflows.data &&
                        workflows.data.data.map(
                          // @ts-ignore
                          (workflow, index) => (
                            <SelectItem
                              key={`workflow-${index} - ${workflow.value}`}
                              value={workflow.value}
                            >
                              {' '}
                              {workflow.text}
                            </SelectItem>
                          )
                        )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}

            {fieldsToShow.datastore.active && (
              <div>
                <Label>Data Store</Label>
                <Select
                  disabled={
                    !widgetData.chartType || !widgetData.dataSource?.workflow
                  }
                  value={widgetData.dataSource?.dataStore}
                  onValueChange={val =>
                    updateWidgetData({
                      dataSource: {
                        workflow: widgetData.dataSource?.workflow ?? '',
                        dataStore: val,
                      },
                      //@ts-ignore
                      rowFormat: {},
                    })
                  }
                >
                  <SelectTrigger className="mt-1">
                    <SelectValue
                      placeholder={
                        widgetData.dataSource?.workflow
                          ? 'Select a datastore'
                          : 'Choose a workflow to select a datastore'
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      <SelectLabel>Data Store</SelectLabel>
                      {filteredDataStoresByChartType &&
                        filteredDataStoresByChartType.map(
                          // @ts-ignore
                          (dataStore, index) => (
                            <SelectItem
                              key={`dataStore-${index} - ${dataStore.value}`}
                              value={dataStore.value}
                            >
                              {' '}
                              {dataStore.text}
                            </SelectItem>
                          )
                        )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}

            {fieldsToShow.groupBy.active && (
              <div>
                <Label>Group By</Label>
                <Select
                  value={widgetData?.rowFormat?.agg_group_by}
                  onValueChange={val =>
                    updateWidgetData({
                      // @ts-ignore
                      rowFormat: {
                        ...widgetData?.rowFormat,
                        agg_group_by: val,
                        agg_type: 'group',
                        agg_sort_by: val,
                      },
                    })
                  }
                >
                  <SelectTrigger className="mt-1">
                    <SelectValue
                      placeholder={
                        Object.keys(firstDataRow).length > 0
                          ? 'Select a column'
                          : 'Choose a datastore to select a column'
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      <SelectLabel>Group By</SelectLabel>
                      {Object.keys(firstDataRow).length > 0 &&
                        Object.keys(firstDataRow).map((key, index) => (
                          <SelectItem
                            key={`group_by-${index} - ${key}`}
                            value={key}
                          >
                            {' '}
                            {key}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}

            {fieldsToShow.calculation.active &&
              fieldsToShow.calculation.options === 'list' &&
              widgetData?.dataSource?.dataStore && (
                <div>
                  <Label>Calculation</Label>
                  <Select
                    value={widgetData?.rowFormat?.agg_list_calculation}
                    defaultValue={'count'}
                    onValueChange={val => {
                      updateWidgetData({
                        // @ts-ignore
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          agg_list_calculation: val,
                        },
                      });
                    }}
                  >
                    <SelectTrigger className="mt-1">
                      <SelectValue placeholder="Select Calculation" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectLabel>Calculation</SelectLabel>
                        <SelectItem key={`calculation-count`} value="count">
                          {' '}
                          {'count'}
                        </SelectItem>
                        <SelectItem key={`calculation-average`} value="average">
                          {' '}
                          {'average'}
                        </SelectItem>
                        <SelectItem key={`calculation-sum`} value="sum">
                          {' '}
                          {'sum'}
                        </SelectItem>
                        <SelectItem key={`calculation-order`} value="order">
                          {' '}
                          {'order'}
                        </SelectItem>
                        <SelectItem key={`calculation-values`} value="lookup">
                          {' '}
                          {'lookup'}
                        </SelectItem>
                        {widgetData?.chartType === ChartType.Statistics && (
                          <SelectItem key={`calculation-values`} value="values">
                            {' '}
                            {'values'}
                          </SelectItem>
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}

            {fieldsToShow.calculation.active &&
              fieldsToShow.calculation.options === 'group' &&
              widgetData?.dataSource?.dataStore && (
                <div>
                  <Label>Calculation</Label>
                  <Select
                    value={widgetData?.rowFormat?.agg_calculation}
                    defaultValue="count"
                    onValueChange={val => {
                      updateWidgetData({
                        // @ts-ignore
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          agg_calculation: val,
                        },
                      });
                    }}
                  >
                    <SelectTrigger className="mt-1">
                      <SelectValue placeholder="Select Calculation" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectLabel>Calculation</SelectLabel>
                        <SelectItem key={`calculation-count`} value="count">
                          {' '}
                          {'count'}
                        </SelectItem>
                        <SelectItem key={`calculation-average`} value="average">
                          {' '}
                          {'average'}
                        </SelectItem>
                        <SelectItem key={`calculation-sum`} value="sum">
                          {' '}
                          {'sum'}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}

            {fieldsToShow.keysFrom.active && (
              <div>
                <Label>Keys From</Label>
                <Select
                  value={widgetData?.rowFormat?.agg_keys_from}
                  onValueChange={val =>
                    updateWidgetData({
                      /* @ts-ignore */
                      rowFormat: {
                        ...widgetData?.rowFormat,
                        agg_keys_from: val,
                        agg_type: calculateAggType(),
                      },
                    })
                  }
                >
                  <SelectTrigger className="mt-1">
                    <SelectValue
                      placeholder={
                        Object.keys(firstDataRow).length > 0
                          ? 'Select a column'
                          : 'Choose a datastore to select a column'
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      <SelectLabel>Values From</SelectLabel>
                      {Object.keys(firstDataRow).length > 0 &&
                        Object.keys(firstDataRow).map((key, index) => (
                          <SelectItem
                            key={`values_from-${index} - ${key}`}
                            value={key}
                          >
                            {' '}
                            {key}
                          </SelectItem>
                        ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}

            {fieldsToShow.valuesFrom.active &&
              fieldsToShow.valuesFrom.options.isSingleSelect &&
              !fieldsToShow.valuesFrom.options.isMultiSelect &&
              widgetData?.dataSource?.dataStore && (
                <div>
                  <Label>Values From</Label>
                  <Select
                    value={
                      Array.isArray(widgetData?.rowFormat?.agg_values_from)
                        ? widgetData?.rowFormat?.agg_values_from[0] ?? ''
                        : widgetData?.rowFormat?.agg_values_from
                    }
                    onValueChange={val =>
                      updateWidgetData({
                        /* @ts-ignore */
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          agg_values_from: [val],
                          agg_type: calculateAggType(),
                        },
                      })
                    }
                  >
                    <SelectTrigger className="mt-1">
                      <SelectValue
                        placeholder={
                          Object.keys(firstDataRow).length > 0
                            ? 'Select a column'
                            : 'Choose a datastore to select a column'
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectLabel>Values From</SelectLabel>
                        {Object.keys(firstDataRow).length > 0 &&
                          Object.keys(firstDataRow).map((key, index) => (
                            <SelectItem
                              key={`values_from-${index} - ${key}`}
                              value={key}
                            >
                              {' '}
                              {key}
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}

            {fieldsToShow.valuesFrom.active &&
              widgetData?.dataSource?.dataStore &&
              !fieldsToShow.valuesFrom.options.isSingleSelect &&
              fieldsToShow.valuesFrom.options.isMultiSelect && (
                <div>
                  <Label>Values From</Label>
                  <ReactSelect
                    menuPlacement={'top'}
                    isMulti
                    options={multiselectData}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={() => {
                      if (!widgetData?.rowFormat?.agg_values_from) {
                        return '';
                      } else {
                        return Array.isArray(
                          widgetData?.rowFormat?.agg_values_from
                        )
                          ? widgetData?.rowFormat?.agg_values_from.map(
                              (item: string) => {
                                return {
                                  label: item,
                                  value: item,
                                };
                              }
                            )
                          : (widgetData?.rowFormat?.agg_values_from ?? '')
                              .split(',')
                              .map((item: string) => {
                                return {
                                  label: item,
                                  value: item,
                                };
                              });
                      }
                    }}
                    onChange={val =>
                      updateWidgetData({
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          // @ts-ignore
                          agg_values_from: val.map(v => v.value),
                          agg_type: calculateAggType(),
                        },
                      })
                    }
                  />
                </div>
              )}

            {fieldsToShow.sortBy.active && (
              <div className={'flex flex-row'}>
                <div className={'flex flex-col gap-y-1 w-[55%] mr-3'}>
                  <Label>Sort By</Label>
                  <Select
                    value={widgetData?.rowFormat?.agg_sort_by}
                    onValueChange={val =>
                      updateWidgetData({
                        // @ts-ignore
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          agg_sort_by: val,
                        },
                      })
                    }
                  >
                    <SelectTrigger className="mt-1">
                      <SelectValue
                        placeholder={
                          Object.keys(firstDataRow).length > 0
                            ? 'Select a column'
                            : 'Choose a datastore to select a column'
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectLabel>Sort By</SelectLabel>
                        <SelectItem
                          value={'(no-sort)'}
                          key={'sort_by-no-value'}
                        >
                          {' (No Sort)'}
                        </SelectItem>
                        {Object.keys(firstDataRow).length > 0 &&
                          Object.keys(firstDataRow).map((key, index) => (
                            <SelectItem
                              key={`sort_by-${index} - ${key}`}
                              value={key}
                            >
                              {' '}
                              {key}
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex flex-col w-[40%]  gap-y-1">
                  <Label>Direction</Label>
                  <Select
                    value={widgetData?.rowFormat?.agg_sort_direction}
                    defaultValue={'asc'}
                    onValueChange={val =>
                      updateWidgetData({
                        // @ts-ignore
                        rowFormat: {
                          ...widgetData?.rowFormat,
                          agg_sort_direction: val,
                        },
                      })
                    }
                  >
                    <SelectTrigger className="mt-1">
                      <SelectValue placeholder="Select sort direction" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectLabel>Direction</SelectLabel>
                        <SelectItem key={`sort_direction-asc`} value="asc">
                          {' '}
                          {'ascending'}
                        </SelectItem>
                        <SelectItem key={`sort_direction-desc`} value="desc">
                          {' '}
                          {'descending'}
                        </SelectItem>
                        {/*<SelectItem*/}
                        {/*  key={`sort_direction-desc`}*/}
                        {/*  value="(no-sort)"*/}
                        {/*>*/}
                        {/*  {' '}*/}
                        {/*  {'no sort'}*/}
                        {/*</SelectItem>*/}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetBuilderSetup;
