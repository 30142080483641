import { useParams } from 'react-router-dom';
import { WidgetOptions } from '../types/chart-types';
import { useGetRawDataFromDataStoreQuery } from '@/store/services/projects/chartApi';

export const useGetRawDataFromDataStore = (widgetData: WidgetOptions) => {
  const { featureType } = useParams();
  const dataStoreId = widgetData.dataSource?.dataStore;
  const { data } = useGetRawDataFromDataStoreQuery(dataStoreId, {
    skip:
      !dataStoreId ||
      !widgetData ||
      featureType?.toLocaleLowerCase() !== 'dashboard',
  });

  const rawDataFromDataStore = data?.data ?? [];
  return {
    rawDataFromDataStore,
  };
};
