import { useCallback, useRef, useState } from 'react';
import { toPng } from 'html-to-image';

export const useDownloadAsPNG = (PNGfileNamePrefix: string) => {
  const elementsRef = useRef<HTMLDivElement[]>([]);
  const [showDownloadingIcon, setShowDownloadingIcon] = useState('');

  const downloadAsPng = useCallback(
    (index: number) => {
      if (elementsRef.current[index] === null) {
        return;
      }

      const element = elementsRef.current[index].querySelector(
        '.isChart'
      ) as HTMLDivElement;

      const options: any = {
        cacheBust: true,
        backgroundColor: 'transparent',
      };

      if (element !== null) {
        options.height = element.offsetHeight;
      }

      toPng(element, options)
        .then(dataUrl => {
          const widgetTitle =
            elementsRef.current[index].attributes.getNamedItem(
              'data-title'
            )?.value;
          const filename =
            PNGfileNamePrefix +
            (typeof widgetTitle !== 'undefined' ? ` - ${widgetTitle}` : '') +
            '.png';
          const link = document.createElement('a');
          link.download = filename;
          link.href = dataUrl;
          link.click();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setShowDownloadingIcon('');
        });
    },

    [elementsRef, PNGfileNamePrefix]
  );
  return {
    elementsRef,
    showDownloadingIcon,
    setShowDownloadingIcon,
    downloadAsPng,
  };
};
