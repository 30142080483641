import React, { FC } from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { InternalGeneratorNodeData } from '@/pages/dashboard/projects/graph/types';
import { cn } from '@/lib/utils';
import { Input } from '@tcomponents/ui/input';
import { buttonVariants } from '@tcomponents/ui/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';

const InternalGeneratorNodeFormFields: FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { keys = [] } = activeNode.data as InternalGeneratorNodeData;

  const handleKeyChange = (
    idx: number,
    key: string,
    valueType: string,
    min: number,
    max: number
  ) => {
    const dataCopy = { ...activeNode.data };
    let keysCopy = [...dataCopy.keys];

    keysCopy[idx] = {
      key: key,
      valueType: valueType,
      max: max,
      min: min,
    };

    dataCopy.keys = keysCopy;

    updateNodeData(dataCopy);
  };

  const handleKeyDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    let keysCopy = [...dataCopy.keys];

    dataCopy.keys = keysCopy.filter((_: any, i: number) => i !== idx);

    updateNodeData(dataCopy);
  };

  const handleAddKey = () => {
    const dataCopy = { ...activeNode.data };
    let keysCopy = [...dataCopy.keys];

    keysCopy = [...keysCopy, { key: '', valueType: 'name', min: 0, max: 10 }];

    dataCopy.keys = keysCopy;

    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={'w-full'}>
        <div className="grid generator-node-grid gap-x-4 gap-y-2 p-4 bg-white border rounded-md">
          <h5 className="col-span-3 font-bold">Generate Data</h5>
          <hr className="col-span-3" />
          <p className="text-xs font-bold">Key</p>
          <p className="text-xs font-bold">Value Type</p>

          <hr className="col-span-3" />

          {keys.map((param, index) => (
            <>
              <Input
                className={'border p-1 text-sm rounded-sm'}
                value={param.key}
                onChange={e =>
                  handleKeyChange(
                    index,
                    e.target.value,
                    param.valueType,
                    param.min,
                    param.max
                  )
                }
              />

              <Select
                value={param.valueType}
                onValueChange={val =>
                  handleKeyChange(index, param.key, val, param.min, param.max)
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder={'Select A Type Of Data'} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectItem value="id">Id</SelectItem>
                    <SelectItem value="name">Name</SelectItem>
                    <SelectItem value="firstName">First Name</SelectItem>
                    <SelectItem value="lastName">Last Name</SelectItem>
                    <SelectItem value="number">Number</SelectItem>
                    <SelectItem value="email">Email Address</SelectItem>
                    <SelectItem value="postcode">Postcode</SelectItem>
                    <SelectItem value="sentence">Sentence</SelectItem>
                    <SelectItem value="yesNo">Yes / No / Not Sure</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>

              <button
                className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm max-w-[36px] h-full"
                onClick={() => handleKeyDeletion(index)}
              >
                X
              </button>

              {param.valueType === 'number' && (
                <div className="p-1 col-span-3">
                  <div className="inline-block w-1/3 mr-4">
                    <div className="flex flex-col">
                      <label className={'font-bold text-xs'}>Min</label>

                      <Input
                        className={'border p-1 text-sm rounded-sm'}
                        placeholder="min"
                        type="number"
                        value={param.min ?? ''}
                        onChange={e => {
                          handleKeyChange(
                            index,
                            param.key,
                            param.valueType,
                            parseInt(e.target.value),
                            param.max
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="inline-block w-1/3">
                    <div className="flex flex-col">
                      <label className={'font-bold text-xs'}>Max</label>
                      <Input
                        className={'border p-1 text-sm rounded-sm'}
                        placeholder="max"
                        type="number"
                        value={param.max ?? ''}
                        onChange={e => {
                          handleKeyChange(
                            index,
                            param.key,
                            param.valueType,
                            param.min,
                            parseInt(e.target.value)
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <hr className="col-span-3" />
            </>
          ))}

          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer col-span-3'
            }
            onClick={handleAddKey}
            data-testing="new_param"
            aria-label="new param"
          >
            + New Data
          </button>
        </div>
      </div>
    </div>
  );
};

export default InternalGeneratorNodeFormFields;
