import React from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { ReceiverNodeData } from '@/pages/dashboard/projects/graph/types';
import { Input } from '@tcomponents/ui/input';

const ReceiverFormFields: React.FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { namespace } = activeNode.data as ReceiverNodeData;

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Connection</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Namespace (optional)</label>
            <Input
              value={namespace}
              onChange={e =>
                handleSingleInputFieldChange('namespace', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceiverFormFields;
