import { IconButton } from '@tcomponents/ui/button';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { cn } from '@/lib/utils';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Overlay } from './Overlay';
import useHandleOverlayTab from '../hooks/useHandleOverlayTab';

const OverlaysTab = () => {
  const {
    overlaysArray,
    handleDragEnd,
    addOverlay,
    deleteOverlay,
    handleSingleFieldValueChange,
    isDragging,
    setIsDragging,
  } = useHandleOverlayTab();

  return (
    <div className="w-full">
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable droppableId="list">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="relative"
            >
              {overlaysArray.map((overlay, idx) => (
                <Overlay
                  key={overlay.id}
                  overlay={overlay}
                  idx={idx}
                  handleSingleFieldValueChange={handleSingleFieldValueChange}
                  deleteOverlay={deleteOverlay}
                  addOverlay={addOverlay}
                  isDragging={isDragging}
                />
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {overlaysArray.length === 0 && (
        <div
          className={cn(
            'flex items-center w-full py-3 transition-opacity duration-200 ease-in-out gap-x-4'
          )}
        >
          <span className="w-full h-[1px] bg-gray-300 grow -mr-3"></span>
          <IconButton
            onClick={addOverlay}
            icon={faCirclePlus}
            tooltip="Add Overlay"
            className="-mr-5 text-gray-300 rounded-full cursor-pointer hover:text-gray-800"
          />
        </div>
      )}
    </div>
  );
};

export default OverlaysTab;
