import React, { createContext, ReactNode, useContext, useState } from 'react';

interface INavigationContext {
  activePage: string;
  setActivePage: (selected: string) => void;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  isPublicView: boolean;
  setIsPublicView: (isPublicView: boolean) => void;
}
interface INavigationContextProps {
  children?: ReactNode;
}
export const NavigationContext = createContext<INavigationContext>({
  setActivePage: () => {},
  activePage: '',
  setCollapsed: () => {},
  collapsed: false,
  isPublicView: false,
  setIsPublicView: () => {},
});

export const NavigationProvider = (props: INavigationContextProps) => {
  const { children } = props;
  const [activePage, setActivePage] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [isPublicView, setIsPublicView] = useState<boolean>(false);

  const navigationContext = React.useMemo(
    () => ({
      setActivePage,
      activePage,
      setCollapsed,
      collapsed,
      isPublicView,
      setIsPublicView,
    }),
    [activePage, collapsed, isPublicView]
  );

  return (
    <NavigationContext.Provider value={navigationContext}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationConsumer = NavigationContext.Consumer;
