import React, { FC, ReactNode } from 'react';

interface IPageNotificationsProps {
  children: ReactNode;
}

const PageNotifications: FC<IPageNotificationsProps> = ({ children }) => {
  return (
    <div className="flex flex-col items-center border-b">
      <>{children}</>
    </div>
  );
};

export default PageNotifications;
