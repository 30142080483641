import { useMemo } from 'react';

type DataItem = {
  _name: string;
  _value: number;
  [key: string]: any; // Allows for additional properties
};

const useSortAndRankData = (data: DataItem[]) => {
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => a._value - b._value);
  }, [data]);

  const dataWithRanks = useMemo(() => {
    let rank = 1;
    return sortedData.map((item, index, array) => {
      if (index > 0 && item._value === array[index - 1]._value) {
        // Same value as previous, same rank
        return { ...item, rank };
      } else {
        // Different value, update rank based on index
        rank = index + 1;
        return { ...item, rank };
      }
    });
  }, [sortedData]);

  return dataWithRanks;
};

export default useSortAndRankData;
