import React from 'react';
import { StatisticsWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';

import { useChartDashboardContext } from '../ChartDashboardProvider';

type StatisticsChartProps = {
  widgetData: StatisticsWidgetOptions;
  chartData?: any;
};

export default function StatisticsChart(props: StatisticsChartProps) {
  const { widgetData, chartData } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();
  const finalData = !chartData ? singleWidgetData : chartData;
  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (finalData?.length === 0) return <NoData />;
  const isFinalDataAnArray = Array.isArray(finalData);
  if (!isFinalDataAnArray) return <NoData />;

  return (
    <div className="flex items-center justify-center w-full bg-white isChart">
      {finalData?.map((item: any, index: number) => (
        <div
          className="flex items-center justify-around w-full h-full"
          key={`stats-${index}`}
        >
          <div className="flex flex-col items-center justify-between h-full gap-y-4 grow">
            <div className="flex items-center text-center gap-x-1">
              {widgetData.chartOptions.showKeys && (
                <span
                  className="min-w-[10px] h-[5px] bg-black rounded-md"
                  style={{ backgroundColor: widgetData.dataStyling[index] }}
                ></span>
              )}
              <span className=" text-[14px]">{item._name}</span>
            </div>
            <h5 className="text-[30px]">{item._value}</h5>
          </div>
          {index < finalData.length - 1 && (
            <span className="min-w-[2px] min-h-[80px] bg-border"></span>
          )}
        </div>
      ))}
    </div>
  );
}
