export const defaultChartDashboardContextValues = {
  handleSaveDashboard: () => {},
  selectedWidgetId: null,
  setSelectedWidgetId: () => {},
  hasSourcesBar: false,
  columnTitles: {
    left: '',
    right: '',
  },
  handleColumnTitlesChange: () => {},
  widgets: [],
  addOrEditWidget: () => {},
  cloneWidget: () => {},
  layouts: {},
  setLayouts: () => {},
  deleteWidget: () => {},
  sources: [],
  information: '',
  dialogOpen: false,
  setDialogOpen: () => {},
  widgetData: {},
  updateWidgetData: () => {},
  handleSaveButtonClick: () => {},
  isReadyToShow: false,
  setIsReadyToShow: () => {},
  widgetsDataFromServer: {},
  isWidgetsDataError: false,
  isWidgetsDataLoading: false,
  isSingleWidgetDataError: false,
  isSingleWidgetDataLoading: false,
  rawDataFromDataStore: [],
  singleWidgetData: [],
  isDashboardConfigFetching: false,
};
