// function to get the keys of the data, if second argument is passed true then it will return all keys including keys with underscore
export const getKeys = (
  data?: Record<string, string | number>[],
  includeUnderscoreKeys = false
) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }
  let keys = Array.from(new Set(data.map(item => Object.keys(item)).flat()));
  return includeUnderscoreKeys ? keys : keys.filter(key => !key.includes('_'));
};

// function to format the date & time in dd/mm/yy hh:mm format
export const formatDateAndTime = (date: string) => {
  const lastUpdatedDate = new Date(date);

  const day = String(lastUpdatedDate.getDate()).padStart(2, '0');
  const month = String(lastUpdatedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const year = String(lastUpdatedDate.getFullYear()).slice(-2); // Get the last two digits of the year
  const hours = String(lastUpdatedDate.getHours()).padStart(2, '0');
  const minutes = String(lastUpdatedDate.getMinutes()).padStart(2, '0');
  const formattedLastUpdated = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedLastUpdated;
};
