import { useEffect, useMemo, useState } from 'react';
import { IQuestionAndAnswerVisibility } from '../types/types';
import { MapFeatureDetails } from '@/types/feature/featureDetails';

export const useQueAndAnsVisibility = (
  selectedQuestionsMeta?: MapFeatureDetails['selectedQuestionsMeta']
) => {
  const [questionsAndAnswersVisibility, setQuestionsAndAnswersVisibility] =
    useState<IQuestionAndAnswerVisibility>({});

  const visibleQuestion = useMemo(
    () =>
      (Object.entries(questionsAndAnswersVisibility).find(
        ([key, val]) => val.visible
      ) || [])[0],
    [questionsAndAnswersVisibility]
  );

  const toggleQuestionAndAnswersVisibility = (
    question: string,
    answer?: string
  ) => {
    const { visible, answers } = questionsAndAnswersVisibility[question];
    if (answer) {
      const updatedAnswers = {
        ...answers,
        [answer]: !answers[answer],
      };
      const updatedQuestionsAndAnswersVisibility = {
        ...questionsAndAnswersVisibility,
        [question]: {
          visible,
          answers: updatedAnswers,
        },
      };
      setQuestionsAndAnswersVisibility(updatedQuestionsAndAnswersVisibility);
      return;
    }
    const updatedQuestionsAndAnswersVisibility = Object.fromEntries(
      Object.entries(questionsAndAnswersVisibility).map(
        ([queKey, { visible, answers }]) => [
          queKey,
          {
            visible: queKey === question ? !visible : false,
            answers: Object.fromEntries(
              Object.entries(answers).map(([ansKey, val]) => [
                ansKey,
                queKey === question ? !visible : false,
              ])
            ),
          },
        ]
      )
    );
    setQuestionsAndAnswersVisibility(updatedQuestionsAndAnswersVisibility);
  };

  // useEffect to set initial questionsAndAnswersVisibility
  useEffect(() => {
    if (selectedQuestionsMeta) {
      const initialQuestionsAndAnswersVisibility = Object.fromEntries(
        selectedQuestionsMeta.map((question, idx) => [
          question.question,
          {
            visible: idx === 0 ? true : false,
            answers: Object.fromEntries(
              (question.answers || []).map(answer => [
                answer.answer,
                idx === 0 ? true : false,
              ])
            ),
          },
        ])
      );
      setQuestionsAndAnswersVisibility(initialQuestionsAndAnswersVisibility);
    }
  }, [selectedQuestionsMeta]);

  return {
    questionsAndAnswersVisibility,
    visibleQuestion,
    toggleQuestionAndAnswersVisibility,
  };
};
