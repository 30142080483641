import { NavigationContext } from '@/context/NavigationContext';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { useGetProjectSettingsQuery } from '@/store/services/projects/projectApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import ProjectResponseInterface from '@/types/interfaces/api-response/ProjectResponseInterface';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const useGetProjectAndFeatureDetails = () => {
  const { projectId, featureId, featureType } = useParams();
  const navigate = useNavigate();

  const [projectDetails, setProjectDetails] =
    useState<ProjectResponseInterface>();

  const [pageTitle, setPageTitle] = useState(
    featureId !== 'new' ? 'Loading...' : 'New Feature'
  );
  const [pageSubTitle, setPageSubTitle] = useState('');

  const navigation = useContext(NavigationContext);
  const project = useGetProjectSettingsQuery(Number(projectId), {
    skip: !projectId,
  });

  const { data, isSuccess, isError } = useGetFeatureQuery(Number(featureId), {
    skip: featureId === 'new',
  });

  // useEffect for fetching project details
  useEffect(() => {
    if (projectId && project.isSuccess) {
      setProjectDetails(project.data.data);
    }
    if (projectId && project.isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem loading project details',
        duration: 24 * 60 * 60000,
      });

      navigate('/not-found');
    }
  }, [projectId, project]);

  useEffect(() => {
    if (featureId !== 'new' && isSuccess) {
      setPageTitle(data?.data?.name ?? '');
      setPageSubTitle(data?.data?.header_info ?? '');
      if (navigation !== null) {
        navigation.setActivePage(data?.data?.name ?? '');
      }

      if (
        typeof data?.data?.name !== 'undefined' &&
        typeof projectDetails?.name !== 'undefined'
      ) {
        ReactGA.event('feature_view', {
          feature_name: data?.data?.name,
          feature_type: featureType,
          project_name: projectDetails?.name,
        });
      }
    }
    if (featureId !== 'new' && isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem loading feature details',
        duration: 24 * 60 * 60000,
      });

      navigate('/not-found');
    }
  }, [featureId, data, isSuccess, isError, projectDetails, featureType]);

  return {
    projectDetails,
    projectRefetch: project.refetch,
    pageTitle,
    pageSubTitle,
    featureDetails: data?.data,
  };
};
