import { FeatureCollection, Feature } from 'geojson';

export const shapeFileFeatureKeysToLowerCase = (
  shapeFileData?: FeatureCollection
) => {
  if (!shapeFileData) return undefined;
  if (shapeFileData?.features) {
    shapeFileData.features = shapeFileData.features.map((feature: Feature) => {
      if (feature.properties) {
        feature.properties = Object.fromEntries(
          Object.entries(feature.properties)
            .filter(([key, value]) => key && value)
            .map(([key, value]) => [key.toLowerCase(), value])
        );
      }
      return feature;
    });
  }
  return shapeFileData;
};
