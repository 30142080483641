import { Layouts } from 'react-grid-layout';
import { TFeatureSources } from '../../common/FeatureSourceBar';

export enum ChartType {
  Statistics = 'Statistics',
  Bar = 'Bar',
  StackedBar = 'StackedBar',
  Pie = 'Pie',
  HorizontalBar = 'HorizontalBar',
  Line = 'Line',
  Area = 'Area',
  IFrame = 'IFrame',
  SimpleBar = 'SimpleBar',
  StackedHorizontalBar = 'StackedHorizontalBar',
  Rank = 'Rank',
}

export interface WidgetLayout {
  x: number;
  y: number;
  w: number;
  h: number;
  i: string;
  minW?: number;
  minH?: number;
  maxW?: number;
  maxH?: number;
  moved?: boolean;
  static?: boolean;
}

export interface BaseWidgetOptions {
  id: string;
  title: string;
  description: string;
  chartType?: ChartType;
  dataSource?: {
    workflow: string;
    dataStore: string;
  };
  dataStyling: string[];
  rowFormat?: {
    agg_type: string;
    agg_values_from: string | string[];
    agg_group_by?: string;
    agg_calculation?: string;
    agg_sort_by?: string;
    agg_sort_direction?: string;
    agg_list_calculation?: string;
    agg_keys_from?: string;
  };
  chartOptions: {};
}

export type WidgetOptions =
  | BaseWidgetOptions
  | StatisticsWidgetOptions
  | StackedBarWidgetOptions
  | PieWidgetOptions
  | HorizontalBarWidgetOptions
  | LineWidgetOptions
  | AreaWidgetOptions
  | IFrameWidgetOptions
  | RankWidgetOptions;

export interface StatisticsWidgetOptions extends BaseWidgetOptions {
  chartOptions: {
    showKeys: boolean;
  };
}

export interface PieWidgetOptions extends BaseWidgetOptions {
  chartOptions: {
    unit: string;
  };
}

export interface StackedBarWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    stackedBars: boolean;
    groupLabelsVertical: boolean;
    unit: string;
    showKeys: boolean;
  };
}
export interface HorizontalBarWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    xAxisLabelNegative: string;
    valueLabel: string;
    unit: string;
  };
}

export interface LineWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    detailBar: boolean;
    detailNameLabel: string;
    detailValueLabel: string;
    unit: string;
    showKeys: boolean;
    autoBaselineAdjust: boolean;
  };
}

export interface AreaWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    detailBar: boolean;
    detailNameLabel: string;
    detailValueLabel: string;
    unit: string;
    showKeys: boolean;
  };
}

export interface IFrameWidgetOptions extends BaseWidgetOptions {
  chartOptions: {
    url: string;
  };
}

export interface SimpleBarWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    unit: string;
    verticalLabels: boolean;
  };
}

export interface StackedHorizontalBarWidgetOptions extends BaseWidgetOptions {
  chartOptions: ChartAxisOptions & {
    detailNameLabel: string;
    unit: string;
    showKeys: boolean;
  };
}

export interface RankWidgetOptions extends BaseWidgetOptions {
  chartOptions: {
    rankLabel: string;
    optionLabel: string;
    showValues: boolean;
  };
}

type ChartAxisOptions = {
  xAxisLabel: string;
  yAxisLabel: string;
};

export interface IChartDashboardContext {
  handleSaveDashboard: () => void;
  selectedWidgetId: string | null;
  setSelectedWidgetId: (id: string | null) => void;
  hasSourcesBar: boolean;
  columnTitles: {
    left: string;
    right: string;
  };
  handleColumnTitlesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  widgets: BaseWidgetOptions[];
  addOrEditWidget: (widget: BaseWidgetOptions) => void;
  cloneWidget: (widgetId: string) => void;
  layouts: Layouts;
  setLayouts: (layouts: Layouts) => void;
  deleteWidget: (widgetId: string) => void;
  sources: TFeatureSources[];
  information: string;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  widgetData: Partial<WidgetOptions>;
  updateWidgetData: (data: Partial<WidgetOptions>) => void;
  handleSaveButtonClick: () => void;
  isReadyToShow: boolean;
  setIsReadyToShow: (isReady: boolean) => void;
  widgetsDataFromServer: Record<string, any[]>;
  isWidgetsDataError: boolean;
  isWidgetsDataLoading: boolean;
  isSingleWidgetDataError: boolean;
  isSingleWidgetDataLoading: boolean;
  rawDataFromDataStore: any[];
  singleWidgetData: any[];
  isDashboardConfigFetching: boolean;
}
