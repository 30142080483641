import { v4 as uniqueId } from 'uuid';
import { CustomNodeType } from '../types';
import { faDatabase, faDownload } from '@fortawesome/free-solid-svg-icons';

export function createDefaultSubGraph() {
  const idForInputNode = uniqueId();
  const idForStorageNode = uniqueId();

  const defaultSubGraphNodes = [
    {
      id: idForInputNode,
      height: 42,
      width: 110,
      type: CustomNodeType.Input,
      position: { x: 200, y: 150 },
      data: {
        heading: 'Input',
        type: CustomNodeType.Input,
        meta: {
          bgColor: 'gray',
          icon: faDownload,
        },
      },
      deletable: false,
    },
    {
      id: idForStorageNode,
      height: 42,
      width: 110,
      type: CustomNodeType.Storage,
      position: { x: 200, y: 350 },
      data: {
        heading: 'Storage',
        type: CustomNodeType.Storage,
        format: 'unformatted',
        behaviour: 'replace',
        indexField: 'id',
        meta: {
          bgColor: 'gray',
          icon: faDatabase,
        },
      },
      deletable: false,
    },
  ];
  const defaultSubGraphEdges = [
    {
      id: `${idForInputNode}-${idForStorageNode}`,
      source: idForInputNode,
      target: idForStorageNode,
      animated: true,
      style: { stroke: '#4EA9FF', strokeWidth: 2 },
      sourceHandle: 'a',
      targetHandle: 'a',
    },
  ];
  return { nodes: defaultSubGraphNodes, edges: defaultSubGraphEdges };
}
