import { useGetMapConfigQuery } from '@/store/services/projects/mapApi';

export const useMapConfig = (
  id: string | number,
  isPublicView: boolean = false
) => {
  const { data: mapConfigResponse } = useGetMapConfigQuery(
    parseInt(id as string),
    {
      skip: !id || isPublicView,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const includeValueField =
    mapConfigResponse?.data.detailView.includeValueField;
  const selectedQuestionsMeta = mapConfigResponse?.data.selectedQuestionsMeta;

  return { mapConfigResponse, includeValueField, selectedQuestionsMeta };
};
