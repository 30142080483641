import { Button, IconButton } from '@/tcomponents/ui/button';
import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { OrderQuestion, Question } from '../Helpers/Types';
import { Switch } from '@tcomponents/ui/switch';
import React from 'react';

interface OrderQuestionProps {
  question: OrderQuestion;
  updateQuestion: (data: Question) => void;
}

export const OrderQue = (props: OrderQuestionProps) => {
  const { question, updateQuestion } = props;

  const addAnswer = () => {
    const newAnswer = { label: '', value: '' };
    updateQuestion({
      ...question,
      answers: [...question.answers, newAnswer],
    });
  };

  const deleteAnswer = (index: number) => {
    const newAnswers = question.answers.filter((_, i) => i !== index);
    updateQuestion({ ...question, answers: newAnswers });
  };

  const handleShuffleChange = (checked: boolean) => {
    updateQuestion({
      ...question,
      shuffle: checked,
    });
  };

  return (
    <div>
      <div className="mb-3">
        <Label>Shuffle Answers</Label>
        <Switch
          checked={question.shuffle}
          onCheckedChange={checked => {
            handleShuffleChange(checked);
          }}
          checkedLabel="Yes"
          unCheckedLabel="No"
        />
      </div>
      <Label>Answers</Label>
      <div className="flex flex-col mt-1 divide-y gap-y-0">
        {question.answers.map((answer, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === question.answers.length - 1;
          return (
            <div
              key={index}
              className={`flex py-2.5 space-x-2 ${
                isFirstItem ? 'border-t' : ''
              } ${isLastItem ? '!border-b ' : ''}`}
            >
              <Input
                className="grow"
                value={answer.label}
                type="text"
                onChange={e => {
                  const newAnswers = question.answers.map((ans, i) => {
                    if (i === index) {
                      return {
                        ...ans,
                        label: e.target.value,
                        value: e.target.value,
                      };
                    }
                    return ans;
                  });
                  updateQuestion({ ...question, answers: newAnswers });
                }}
              />

              <IconButton
                tabIndex={-1}
                className="p-2 text-2xl text-white bg-red-500 rounded-lg fa-2xl"
                icon={faXmark}
                onClick={() => deleteAnswer(index)}
                tooltip="Delete Asnwer"
                testingAttribute="delete-answer"
                disabled={question.answers.length === 1}
              />
            </div>
          );
        })}
      </div>
      <Button className="w-full mt-2.5 bg-info" onClick={addAnswer}>
        + Add Answer
      </Button>
    </div>
  );
};
