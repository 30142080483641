import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  FormConfiguration,
  SurveyResponse,
} from '@/pages/survey/Helpers/Types';
import { iPackageList } from '@/pages/survey/Helpers/Types';

type ValidateSubDomainArgTypes = {
  subdomain: string;
  id: number | undefined;
};

type ValidateSubdomainResponse = {
  status: boolean;
};

type SurveyPackageResponse = {
  data: {
    id: string;
    name: string;
    version: number;
    default: boolean;
  };
};

export const surveyApi = createApi({
  reducerPath: 'surveyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['survey', 'surveyList', 'borealisSyncData'],
  endpoints: builder => ({
    getSurveyConfig: builder.query<SurveyResponse, number>({
      query: (surveyId: number) => {
        return {
          url: '/surveys/configuration/' + surveyId,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
      providesTags: ['survey'],
    }),
    getSurveyPackageList: builder.query<iPackageList[], void>({
      query: () => {
        return {
          url: '/surveys/list',
          method: 'GET',
        };
      },
      providesTags: ['surveyList'],
    }),
    saveSurveyConfiguration: builder.mutation({
      query: (data: FormConfiguration) => {
        return {
          url: '/surveys/configuration/',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['survey'],
    }),
    assignSurveyState: builder.mutation({
      query: ({ state, featureId }: { state: string; featureId: string }) => {
        return {
          url: '/features/' + featureId + '/updateSurveyState/' + state,
          method: 'PUT',
        };
      },
      invalidatesTags: ['survey', 'surveyList'],
    }),
    validateSubdomain: builder.query<
      ValidateSubdomainResponse,
      ValidateSubDomainArgTypes
    >({
      query: ({ subdomain, id }) => {
        return {
          url: `/surveys/validate-subdomain/${subdomain}/${id}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
    uploadSurveyAsset: builder.mutation({
      query: (data: FormData) => {
        return {
          url: '/survey_asset_upload',
          method: 'POST',
          body: data,
          contentType: 'multipart/form-data',
        };
      },
    }),
    getSurveyPackage: builder.query<SurveyPackageResponse, string | number>({
      query: id => {
        return {
          url: `/surveys/${id}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
    updateSurveyPackage: builder.mutation<any, any>({
      query: data => {
        return {
          url: '/surveys/update-package',
          method: 'POST',
          body: data,
          contentType: 'multipart/form-data',
        };
      },
    }),
    makeSurveyPackageDefault: builder.mutation<any, string>({
      query: id => {
        return {
          url: '/surveys/' + id + '/default',
          method: 'PUT',
        };
      },
    }),
    deleteSurveyPackage: builder.mutation<any, string>({
      query: (id: string) => {
        return {
          url: '/surveys/' + id,
          method: 'DELETE',
        };
      },
    }),
    createSurveyPackage: builder.mutation<any, any>({
      query: data => {
        return {
          url: '/surveys/upload-package',
          method: 'POST',
          body: data,
          contentType: 'multipart/form-data',
        };
      },
    }),
    getBorealisSyncData: builder.query<any, void>({
      query: () => {
        return {
          url: 'surveys/borealis-sync',
          method: 'GET',
        };
      },
      providesTags: ['borealisSyncData'],
    }),
    retrySyncData: builder.mutation<any, number>({
      query: id => {
        return {
          url: 'surveys/borealis-sync/' + id,
          method: 'POST',
        };
      },
      invalidatesTags: ['borealisSyncData'],
    }),
    deleteSyncData: builder.mutation<any, number>({
      query: id => {
        return {
          url: 'surveys/borealis-sync/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['borealisSyncData'],
    }),
  }),
});

export const {
  useGetSurveyConfigQuery,
  useGetSurveyPackageListQuery,
  useSaveSurveyConfigurationMutation,
  useAssignSurveyStateMutation,
  useValidateSubdomainQuery,
  useUploadSurveyAssetMutation,
  useGetSurveyPackageQuery,
  useUpdateSurveyPackageMutation,
  useMakeSurveyPackageDefaultMutation,
  useDeleteSurveyPackageMutation,
  useCreateSurveyPackageMutation,
  useRetrySyncDataMutation,
  useDeleteSyncDataMutation,
  useGetBorealisSyncDataQuery,
} = surveyApi;
