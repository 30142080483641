import { RankWidgetOptions } from '../types/chart-types';
import LoadingData from '../common/LoadingData';
import ErrorData from '../common/ErrorData';
import NoData from '../common/NoData';

import { useChartDashboardContext } from '../ChartDashboardProvider';
import { Label } from '@/tcomponents/ui/label';
import useSortAndRankData from '../hooks/useSortAndRankData';

type RankChartProps = {
  widgetData: RankWidgetOptions;
  chartData?: any;
  randeredFromDashboard?: boolean;
};

export default function RankChart(props: RankChartProps) {
  const { widgetData, chartData, randeredFromDashboard } = props;
  const {
    isWidgetsDataLoading,
    isWidgetsDataError,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    singleWidgetData,
  } = useChartDashboardContext();
  const finalData = !chartData ? singleWidgetData : chartData;

  const sortedDataWithRanks = useSortAndRankData(finalData);

  if (isWidgetsDataLoading || isSingleWidgetDataLoading) return <LoadingData />;
  if (isWidgetsDataError || isSingleWidgetDataError || chartData === false)
    return <ErrorData />;
  if (finalData?.length === 0) return <NoData />;
  const isFinalDataAnArray = Array.isArray(finalData);
  if (!isFinalDataAnArray) return <NoData />;

  return (
    <div className="flex flex-col items-center justify-center w-full px-8 py-4 bg-white h-[90%] isChart gap-y-4">
      <div className="flex items-center justify-between w-full px-3 ">
        <Label>{widgetData.chartOptions?.optionLabel ?? 'Option'}</Label>
        <Label>{widgetData.chartOptions?.rankLabel ?? 'Rank'}</Label>
      </div>
      <div
        className={`flex-1 w-full flex flex-col gap-y-2 overflow-x-hidden overflow-y-auto px-1 ${
          randeredFromDashboard ? '' : 'max-h-[250px]'
        }`}
      >
        {sortedDataWithRanks.map(
          (
            data: { _name: string; _value: number; rank: number },
            index: number
          ) => (
            <div
              key={index}
              className="flex items-center justify-between w-full p-2 border rounded-md bg-background border-border gap-x-4"
            >
              <h5 className=" text-[16px]">{data._name}</h5>
              <h3 className=" text-primary text-[22px]">
                {widgetData.chartOptions?.showValues ? data._value : data.rank}
              </h3>
            </div>
          )
        )}
      </div>
    </div>
  );
}
