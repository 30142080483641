import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@tcomponents/ui/popover';
import { Button } from '@/tcomponents/ui/button';
import { PopoverClose } from '@radix-ui/react-popover';
import {
  faCaretDown,
  faFileExport,
  faFileImport,
  faFloppyDisk,
  faGear,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { formatDateAndTime } from '../../utils/helperFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt } from '@/utils/use-prompt';
import { Schema } from '@/pages/dashboard/projects/graph/components/TableSettingsView';
import { useTableDataView } from '../hooks/useTableDataView';
import { useMemo } from 'react';
import useAuth from '@/hooks/UseAuth';
import { workflowPermissions } from '@/stringConsts/permissionConsts';

type TableDataViewProps = {
  selectedRowTypeDataStore: { title: string; uuid: string } | undefined;
  rawDataResponse: any;
  featureName: string;
  setTableView: React.Dispatch<
    React.SetStateAction<'data-view' | 'settings-view'>
  >;
  schema?: Schema;
};

export const TableDataView = (props: TableDataViewProps) => {
  const {
    selectedRowTypeDataStore,
    rawDataResponse,
    featureName,
    setTableView,
    schema,
  } = props;

  const { hasPermission } = useAuth();

  const lastUpdated = rawDataResponse?.updated_at;

  const memoizedData = useMemo(() => {
    return rawDataResponse?.data ?? [];
  }, [rawDataResponse?.data]);

  const {
    tabulatorRef,
    tableDivRef,
    addNewRow,
    importSpreadsheet,
    updateDataStoreFn,
    isTableDirty,
  } = useTableDataView({
    data: memoizedData,
    schema,
    dataStoreId: selectedRowTypeDataStore?.uuid || '',
  });

  const downloadExcel = () => {
    tabulatorRef.current?.download(
      'xlsx',
      featureName + ' - ' + selectedRowTypeDataStore?.title + '.xlsx',
      {},
      'active'
    );
  };

  const recordsCount = tabulatorRef.current?.getDataCount();

  Prompt({
    message: 'Are you sure you want to leave without saving?',
    when: isTableDirty,
  });

  return (
    <div className="flex flex-col h-full max-w-full p-8 mb-8 bg-white border rounded-md">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <h3>{selectedRowTypeDataStore?.title}</h3>
          <p className="text-[15px] font-bold text-primary mt-1">
            ({recordsCount} {recordsCount === 1 ? 'Record' : 'Records'})
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <p>
            <span className="font-bold">Last Updated:</span>{' '}
            {(lastUpdated && formatDateAndTime(lastUpdated)) || 'N/A'}
          </p>
          <Popover>
            <PopoverTrigger>
              <Button variant={'warning'}>
                Actions
                <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              side="bottom"
              className="p-0 text-[14px] w-fit min-w-[200px]"
            >
              <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
                <Button
                  onClick={downloadExcel}
                  icon={faFileExport}
                  iconClassName="text-gray-400"
                  key={'empty-project'}
                  variant="ghost"
                  className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full"
                >
                  Download Excel
                </Button>
                {hasPermission(workflowPermissions.general.edit_data) && (
                  <Button
                    onClick={importSpreadsheet}
                    icon={faFileImport}
                    iconClassName="text-gray-400 -ml-0.5"
                    variant="ghost"
                    className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full gap-x-1"
                    disabled={
                      !hasPermission(workflowPermissions.general.edit_data)
                    }
                  >
                    Import Spreadsheet
                  </Button>
                )}
                {hasPermission(workflowPermissions.general.schema_settings) && (
                  <Button
                    disabled={
                      !hasPermission(
                        workflowPermissions.general.schema_settings
                      ) || isTableDirty
                    }
                    tooltip={isTableDirty ? 'Save Changes First' : undefined}
                    tooltipWrapperClasses="w-full"
                    onClick={() => setTableView('settings-view')}
                    icon={faGear}
                    iconClassName="text-gray-400 -ml-0.5"
                    variant="ghost"
                    className="font-normal text-[14px] text-gray-800 px-4 items-center justify-start min-w-full gap-x-1"
                  >
                    Settings
                  </Button>
                )}
              </PopoverClose>
            </PopoverContent>
          </Popover>
          {hasPermission(workflowPermissions.general.edit_data) && (
            <>
              <Button
                icon={faPlus}
                onClick={addNewRow}
                disabled={
                  !hasPermission(workflowPermissions.general.edit_data) ||
                  ((rawDataResponse?.data?.length === 0 ||
                    !rawDataResponse?.data) &&
                    !schema?.configuration.length)
                }
              >
                Add Row
              </Button>

              <Button
                disabled={
                  !hasPermission(workflowPermissions.general.edit_data) ||
                  !isTableDirty
                }
                icon={faFloppyDisk}
                onClick={() => updateDataStoreFn(lastUpdated)}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </div>
      <div ref={tableDivRef} className="table-component striped" />
    </div>
  );
};
