import { useAggregateListDataQuery } from '@/store/services/aggregationApi';
import { BaseWidgetOptions } from '../types/chart-types';
import { useMemo } from 'react';

export const useAggregatedChartData = (
  rawDataFromDataStore: any[],
  widgetData: BaseWidgetOptions
) => {
  const aggGroupBy = widgetData.rowFormat?.agg_group_by;
  const aggValuesFrom = widgetData.rowFormat?.agg_values_from;
  const isListAggType = widgetData.rowFormat?.agg_type === 'list';
  const isGroupAggType = widgetData.rowFormat?.agg_type === 'group';
  const isListCalculation = !widgetData.rowFormat?.agg_list_calculation
    ? 'count'
    : widgetData.rowFormat?.agg_list_calculation;
  const call = useMemo(() => {
    if (isListAggType && aggValuesFrom && isListCalculation) {
      return {
        endpoint: 'list',
        requestBody: {
          values_from: Array.isArray(widgetData.rowFormat?.agg_values_from)
            ? widgetData.rowFormat?.agg_values_from
            : widgetData.rowFormat?.agg_values_from?.split(','),
          group_by: widgetData.rowFormat?.agg_group_by,
          keys_from: widgetData.rowFormat?.agg_keys_from ?? '',
          calculation: widgetData.rowFormat?.agg_list_calculation ?? 'count',
          data_store_id: widgetData.dataSource?.dataStore,
          sort_by: widgetData.rowFormat?.agg_sort_by,
          sort_direction: widgetData.rowFormat?.agg_sort_direction ?? 'asc',
        },
      };
    } else if (isGroupAggType && aggGroupBy && aggValuesFrom) {
      return {
        endpoint: 'group',
        requestBody: {
          sort_by: widgetData.rowFormat?.agg_sort_by,
          sort_direction: widgetData.rowFormat?.agg_sort_direction ?? 'asc',
          group_by: widgetData.rowFormat?.agg_group_by,
          values_from: Array.isArray(widgetData.rowFormat?.agg_values_from)
            ? widgetData.rowFormat?.agg_values_from
            : widgetData.rowFormat?.agg_values_from?.split(','),
          calculation: widgetData.rowFormat?.agg_calculation ?? 'count',
          data_store_id: widgetData.dataSource?.dataStore ?? null,
        },
      };
    } else if (
      !(isListAggType && aggValuesFrom && isListCalculation) &&
      !(isGroupAggType && aggGroupBy && aggValuesFrom)
    ) {
      return null;
    }
    return null;
  }, [
    widgetData.dataSource?.dataStore,
    widgetData.rowFormat?.agg_calculation,
    widgetData.rowFormat?.agg_group_by,
    widgetData.rowFormat?.agg_sort_by,
    widgetData.rowFormat?.agg_sort_direction,
    widgetData.rowFormat?.agg_type,
    widgetData.rowFormat?.agg_list_calculation,
    widgetData.rowFormat?.agg_values_from,
    widgetData.dataSource?.dataStore,
    widgetData.dataSource?.workflow,
  ]);

  const { data, isLoading, isError } = useAggregateListDataQuery(
    { data: call?.requestBody, endpoint: call?.endpoint },
    {
      skip:
        !call ||
        !widgetData ||
        !widgetData.dataSource?.dataStore ||
        !aggValuesFrom,
    }
  );
  // If the call is not made, then we will use the raw data from the data store
  const finalDataForSingleWidget =
    call && data?.data?.length ? data?.data : rawDataFromDataStore;
  return {
    singleWidgetData: finalDataForSingleWidget,
    isSingleWidgetDataLoading: isLoading,
    isSingleWidgetDataError: isError,
  };
};
