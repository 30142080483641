import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const aggregationApi = createApi({
  reducerPath: 'aggregationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  endpoints: builder => ({
    aggregateListData: builder.query({
      query: ({ data, endpoint }) => {
        return {
          url: '/aggregation/' + endpoint,
          method: 'POST',
          body: data,
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});
export const { useAggregateListDataQuery } = aggregationApi;
