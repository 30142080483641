import { useContext, useEffect, useRef, useState } from 'react';
import {
  TabulatorFull as Tabulator,
  CellComponent,
  Formatter,
  ColumnDefinitionAlign,
  Editor,
} from 'tabulator-tables';
import { cloneDeep } from 'lodash';
import {
  Tracker,
  TrackerStats,
} from '../pages/dashboard/projects/graph/lib/tabulatorTracker';
import * as XLSX from 'xlsx';
import ImportSpreadsheetModule from '../pages/dashboard/projects/graph/lib/importSpreadsheetModule';
import { ModalContext } from '@/context/ModalContext';
import ModalTypes from '@/types/enums/modal/ModalTypes';

declare global {
  interface Window {
    XLSX: any;
  }
}

export const deleteRowIcon = {
  width: 40,
  resizable: false,
  hozAlign: 'center' as ColumnDefinitionAlign,
  formatter: 'deleteRow' as Formatter,
  title: '',
  editor: false as Editor,
  headerSort: false,
  headerFilter: false as Editor,
};

Tabulator.registerModule(ImportSpreadsheetModule);
Tabulator.extendModule('format', 'formatters', {
  deleteRow: (cell: CellComponent) => {
    const el = document.createElement('i');
    el.classList.add('fas', 'fa-trash-can');
    el.addEventListener('click', () => {
      cell.getRow().delete();
    });
    return el;
  },
});

export const useTabulator = ({
  config,
  trackFields,
}: {
  config: Tabulator['options'];
  trackFields?: boolean;
}) => {
  window.XLSX = XLSX;
  const tableDivRef = useRef<HTMLDivElement>(null);
  const tabulatorRef = useRef<Tabulator>();
  const trackerRef = useRef<Tracker>();
  const [isTableRowMoved, setIsTableRowMoved] = useState(false);
  const { showDialog } = useContext(ModalContext);

  const [trackerData, setTrackerData] = useState<TrackerStats | undefined>();

  const addNewRow = () => {
    tabulatorRef.current
      ?.addRow(cloneDeep(config?.tabEndNewRow) ?? {})
      .then(row => {
        row.scrollTo();
      });
  };
  const importData = (append: boolean) => {
    const oldTableRows =
      append === false ? tabulatorRef.current?.getRows() ?? [] : [];
    tabulatorRef.current
      ?.import(
        'xlsx',
        ['xlsx', 'csv', 'ods'],
        'buffer',
        //@ts-ignore because we don't have types defined in Tabulator for the extended module- Tabulator.registerModule(ImportSpreadsheetModule);
        append
      )
      .then(() => {
        if (append === false && trackerRef.current) {
          trackerRef.current.reset();
          trackerRef.current.import();
          trackerRef.current.deleteAll(oldTableRows);
        }
      });
  };

  const importSpreadsheet = () => {
    if (tabulatorRef?.current?.getData().length) {
      showDialog({
        title: 'Data Loss Alert!',
        message:
          'Do you want to replace the existing table data or append the new data at the end of the table?',
        type: ModalTypes.DANGER,
        cancel: true,
        customActions: [
          {
            label: 'Replace',
            cb: () => importData(false),
            buttonClasses: [
              ' bg-danger-error hover:bg-danger-error hover:opacity-80',
            ],
          },
          {
            label: 'Append',
            cb: () => importData(true),
            buttonClasses: [' bg-warning hover:bg-warning hover:opacity-80'],
          },
        ],
      });
    } else {
      importData(false);
    }
  };

  const isTableDirty = !!(
    trackerData?.added.length ||
    trackerData?.deleted.length ||
    trackerData?.changed.length ||
    trackerData?.fields.length ||
    isTableRowMoved
  );

  useEffect(() => {
    if (!tableDivRef.current || !config) return;
    tabulatorRef.current = new Tabulator(tableDivRef.current, config);
    // initating tracker
    tabulatorRef.current.on('tableBuilt', () => {
      if (!tabulatorRef.current) return;
      trackerRef.current = new Tracker(
        tabulatorRef.current,
        'setup-info',
        trackFields ?? false,
        setTrackerData
      );
    });
  }, [tableDivRef, config, trackFields, tabulatorRef, trackerRef]);

  tabulatorRef.current?.on('rowMoved', () => {
    setIsTableRowMoved(true);
  });

  return {
    tableDivRef,
    tabulatorRef,
    trackerRef,
    addNewRow,
    importSpreadsheet,
    trackerData,
    isTableDirty,
  };
};
