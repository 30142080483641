import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const EmptyWorkflow = () => {
  return (
    <div className="flex flex-col items-center m-auto gap-y-3 text-primary opacity-70">
      <FontAwesomeIcon icon={faBoxOpen} className=" text-[70px]" />
      <div>
        <h4 className="text-center">Empty Workflow</h4>
        <p>Please add a data store to continue</p>
      </div>
    </div>
  );
};
