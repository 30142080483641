import React from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { Input } from '@tcomponents/ui/input';
import { TagNodeData } from '@/pages/dashboard/projects/graph/types';
import { cn } from '@/lib/utils';
import { buttonVariants, IconButton } from '@tcomponents/ui/button';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { Switch } from '@tcomponents/ui/switch';
import { Label } from '@tcomponents/ui/label';

const TagFormFields: React.FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const {
    source,
    destination,
    tolerance,
    shouldReprocessRows,
    tags = [],
  } = activeNode.data as TagNodeData;

  const handleTagChange = (idx: number, val: string) => {
    const dataCopy = { ...activeNode.data };
    const tagsCopy = [...dataCopy.tags];
    tagsCopy[idx] = val;
    dataCopy.tags = tagsCopy;

    updateNodeData(dataCopy);
  };

  const handleTagDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.tags = dataCopy.tags.filter((_: any, i: number) => i !== idx);
    updateNodeData(dataCopy);
  };

  const handleAddTag = () => {
    const dataCopy = { ...activeNode.data };

    const tags = [...dataCopy.tags];
    tags.push('');

    dataCopy.tags = tags;

    updateNodeData(dataCopy);
  };

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Configuration</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Source Field</label>
            <Input
              value={source}
              onChange={e =>
                handleSingleInputFieldChange('source', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Destination Field</label>
            <Input
              value={destination}
              onChange={e =>
                handleSingleInputFieldChange('destination', e.target.value)
              }
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>

          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Similarity</label>
            <div className="flex flex-row gap-2 justify-items-center items-center">
              <label>low</label>
              <Input
                value={tolerance}
                onChange={e =>
                  handleSingleInputFieldChange('tolerance', e.target.value)
                }
                type="range"
                max="0.5"
                min="0.1"
                step="0.0001"
                defaultValue="0.25"
                className="w-3/4"
              />
              <label>high</label>
            </div>
            <Input
              value={tolerance}
              onChange={e =>
                handleSingleInputFieldChange('tolerance', e.target.value)
              }
              type="number"
              max="0.5"
              min="0.1"
              step="0.0001"
              defaultValue="0.25"
              className={
                'flex flex-1 bg-white grow' +
                (isExpanded ? 'flex-none mr-3' : 'mr-0 w-full')
              }
            />
          </div>
          <div className={'flex flex-row'}>
            <div className="flex flex-col flex-1 py-2 gap-y-2">
              <div className="flex flex-col gap-y-2">
                <label className="text-xs font-bold">
                  Reprocess Existing Values
                </label>
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={shouldReprocessRows}
                    onCheckedChange={val =>
                      handleSingleInputFieldChange('shouldReprocessRows', val)
                    }
                    id="shouldReprocessRows"
                  />
                  <Label htmlFor="shouldReprocessRows">
                    {shouldReprocessRows ? 'Yes' : 'No'}
                  </Label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
            (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <h5 className="font-bold ">Tags</h5>
          {tags?.map((tag, index) => (
            <div
              key={`tag_${index}`}
              className="flex justify-between pt-2 items-center gap-x-2"
            >
              <Input
                type="text"
                placeholder="tag"
                value={tag}
                onChange={e => handleTagChange(index, e.target.value)}
              />
              <IconButton
                icon={faXmark}
                onClick={() => handleTagDeletion(index)}
                className="bg-red-500 p-2.5 hover:bg-red-600 text-white font-bold text-sm rounded-sm"
              />
            </div>
          ))}
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddTag}
            data-testing="new_tag"
            aria-label="new tag"
          >
            + New Tag
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagFormFields;
