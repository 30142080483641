import * as React from 'react';

import { cn } from '@/lib/utils';
import { FieldError } from 'react-hook-form';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError | string;
  icon?: IconDefinition;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, error, icon, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
          error
            ? 'focus-visible:ring-destructive border-destructive'
            : 'focus-visible:ring-primary border-input',
          className
        )}
        ref={ref}
        {...props}
        data-testing={props.name}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
