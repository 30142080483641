import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const publicDataApi = createApi({
  reducerPath: 'publicDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  }),
  endpoints: builder => ({
    getPublicData: builder.query({
      query: ({ uuid }) => {
        return {
          url: '/public-data/' + uuid,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetPublicDataQuery } = publicDataApi;
