import React, { FC, ReactNode } from 'react';

interface ITitleProps {
  size: 1 | 2 | 3 | 4 | 5;
  style?: object | null;
  children: ReactNode;
  className?: string;
}
const Title: FC<ITitleProps> = ({ size, children, style, className }) => {
  const renderTitle = () => {
    switch (size) {
      case 1:
        return (
          <h1 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h1>
        );
      case 2:
        return (
          <h2 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h2>
        );
      case 3:
        return (
          <h3 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h3>
        );
      case 4:
        return (
          <h4 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h4>
        );
      case 5:
        return (
          <h5 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h5>
        );
      default:
        return (
          <h3 style={style ? style : {}} className={'ui header ' + className}>
            {children}
          </h3>
        );
    }
  };

  return <>{renderTitle()}</>;
};

export default Title;
