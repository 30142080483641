import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useGetWidgetsData } from './hooks/useGetWidgetsData';
import useWidgets from './hooks/useWidgets';
import { useGetDashboardConfig } from './hooks/useGetDashboardConfig';
import { useSaveDashboardConfig } from './hooks/useSaveDashboardConfig';
import { IChartDashboardContext } from './types/chart-types';
import { useParams } from 'react-router-dom';
import { defaultChartDashboardContextValues } from './lib/defaultDashboardContextValues';
import { useColumnTitles } from './hooks/useColumnTitles';

interface ChartDashboardProviderProps {
  children: React.ReactNode;
}

const ChartDashboardContext = createContext<IChartDashboardContext>(
  defaultChartDashboardContextValues
);
/**
 * Main Logic for chart dashboard- this is the provider which wraps the entire dashboard. useWidgets custom hook is used to handle most ofthe logic for widgets and layouts.
 * useGetWidgetsData custom hook is used to fetch data for widgets and sources bar.
 * useGetDashboardConfig custom hook is used to fetch dashboard config.
 * useGetDataForSingleWidget custom hook is used to fetch data for single widget.
 */

const ChartDashboardProvider = (props: ChartDashboardProviderProps) => {
  const { featureId: id } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isReadyToShow, setIsReadyToShow] = useState<boolean>(false);
  const { sources, information } = useGetWidgetsData();
  const hasSourcesBar =
    (typeof sources !== 'undefined' && sources.length > 0) ||
    (typeof information !== 'undefined' && information.length > 0);

  const [selectedWidgetId, setSelectedWidgetId] = useState<string | null>(null);

  /**
   * custom hook which handles most of the logic for widgets and layouts
   */
  const {
    layouts,
    setLayouts,
    widgets,
    setWidgets,
    addOrEditWidget,
    updateWidgetData,
    deleteWidget,
    handleSaveButtonClick,
    widgetData,
    widgetsDataFromServer,
    isWidgetsDataError,
    isWidgetsDataLoading,
    isSingleWidgetDataError,
    isSingleWidgetDataLoading,
    rawDataFromDataStore,
    singleWidgetData,
    cloneWidget,
  } = useWidgets({
    selectedWidgetId,
    setSelectedWidgetId,
    setDialogOpen,
    dialogOpen,
  });

  /**
   * custom hook which handles column titles
   */
  const { columnTitles, setColumnTitles, handleColumnTitlesChange } =
    useColumnTitles();

  /**
   * custom hook which handles fetching dashboard config
   */
  const { isDashboardConfigFetching } = useGetDashboardConfig({
    setColumnTitles,
    setWidgets,
    setLayouts,
  });

  /**
   * custom hook which handles saving dashboard config
   */
  const { saveDashboardConfig } = useSaveDashboardConfig();

  const handleSaveDashboard = useCallback(() => {
    saveDashboardConfig({
      featureId: Number(id),
      config: {
        columnTitles: columnTitles,
        layout: layouts.lg,
        widgets: widgets,
      },
    });
  }, [columnTitles, id, layouts.lg, saveDashboardConfig, widgets]);

  /**
   * memoized context object
   */
  const context = useMemo(
    () => ({
      handleSaveDashboard,
      selectedWidgetId,
      setSelectedWidgetId,
      hasSourcesBar,
      columnTitles,
      handleColumnTitlesChange,
      widgets,
      addOrEditWidget,
      cloneWidget,
      layouts,
      setLayouts,
      deleteWidget,
      sources,
      information,
      dialogOpen,
      setDialogOpen,
      widgetData,
      updateWidgetData,
      handleSaveButtonClick,
      isReadyToShow,
      setIsReadyToShow,
      widgetsDataFromServer,
      isWidgetsDataError,
      isWidgetsDataLoading,
      isSingleWidgetDataError,
      isSingleWidgetDataLoading,
      rawDataFromDataStore,
      singleWidgetData,
      isDashboardConfigFetching,
    }),
    [
      id,
      selectedWidgetId,
      hasSourcesBar,
      columnTitles,
      widgets,
      addOrEditWidget,
      layouts,
      sources,
      information,
      dialogOpen,
      widgetData,
      updateWidgetData,
      isReadyToShow,
      widgetsDataFromServer,
      isWidgetsDataError,
      isWidgetsDataLoading,
      isSingleWidgetDataError,
      isSingleWidgetDataLoading,
      isDashboardConfigFetching,
    ]
  );

  return (
    <ChartDashboardContext.Provider value={context}>
      {props.children}
    </ChartDashboardContext.Provider>
  );
};

export const useChartDashboardContext = () => {
  const context = useContext(ChartDashboardContext);
  if (!context) {
    throw new Error(
      'useChartDashboardContext must be used within a ChartDashboardProvider'
    );
  }
  return context;
};

export default ChartDashboardProvider;
