import { useState } from 'react';
import { Edge, Node } from 'reactflow';

// type of snapshot of graph state
export interface ProgramData {
  activeNodeId: string;
  nodes: Node[];
  edges: Edge[];
}

export function useProgramStack() {
  const [stack, setStack] = useState<ProgramData[]>([]);

  function push(data: ProgramData) {
    setStack(prevStack => [...prevStack, data]);
  }

  function pop() {
    const poppedItem = stack[stack.length - 1];
    setStack(prevStack => prevStack.slice(0, -1));
    return poppedItem;
  }

  function peek() {
    // returns the last element in the array
    return stack[stack.length - 1];
  }

  return { push, pop, peek };
}
