import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  DragEvent,
  useRef,
  useEffect,
} from 'react';
import {
  ApiNodeData,
  BorealisNodeData,
  CustomFunctionNodeData,
  CustomNodeData,
  CustomNodeRenderProps,
  CustomNodeType,
  DateTimeNodeData,
  DropKeysNodeData,
  EndpointNodeData,
  FilterNodeData,
  LocationNodeData,
  PipelineData,
  ProcessNodeData,
  SentimentNodeData,
  SpreadsheetNodeData,
  StorageNodeData,
  SummarizeNodeData,
  TagNodeData,
  SenderNodeData,
  ReceiverNodeData,
  InternalGeneratorNodeData,
} from './types';
import {
  useEdgesState,
  useNodesState,
  Node,
  OnConnect,
  addEdge,
  ReactFlowInstance,
  useNodesInitialized,
  useKeyPress,
} from 'reactflow';
import { ProgramData, useProgramStack } from './hooks/useProgramStack';
import { v4 as uniqueId } from 'uuid';
import { nodeTypes } from './lib/nodeTypes';
import { createDefaultSubGraph } from './lib/createDefaultSubgraph';
import useWindowDimensions from '@/hooks/UseWindowDimensions';
import { useParams } from 'react-router-dom';

interface CustomGraphProviderProps {
  children: React.ReactNode;
}

type ReactFlowNodeState = ReturnType<typeof useNodesState>;
type ReactFlowEdgeState = ReturnType<typeof useEdgesState>;
interface ICustomGraphContext {
  pipelineData: PipelineData;
  updateNodeData: (data: ApiNodeData | CustomNodeData) => void;
  nodes: Node<any>[];
  edges: ReactFlowEdgeState[0];
  setNodes: Dispatch<SetStateAction<Node<any, string | undefined>[]>>;
  setEdges: ReactFlowEdgeState[1];
  onNodesChange: ReactFlowNodeState[2];
  onEdgesChange: ReactFlowEdgeState[2];
  activeNodeId: string;
  activeNode: any;
  setActiveNodeId: Dispatch<SetStateAction<string>>;
  setPipelineData: Dispatch<SetStateAction<PipelineData>>;
  fullWidthSettingsView: boolean;
  setFullWidthSettingsView: Dispatch<SetStateAction<boolean>>;
  subgraphActive: boolean;
  setSubgraphActive: Dispatch<SetStateAction<boolean>>;
  push: (data: ProgramData) => void;
  pop: () => ProgramData;
  peek: () => ProgramData;
  activePipelineNodeId: string;
  reactFlowWrapper: React.RefObject<HTMLDivElement> | null;
  onConnect: OnConnect;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: DragEvent<HTMLDivElement>) => void;
  setReactFlowInstance: Dispatch<SetStateAction<ReactFlowInstance | undefined>>;
  toggleNewSubGraph: (value: boolean) => void;
  handleNodeClick: (id: string, event_type?: string) => void;
  deleteProcessNode: (deletedNodes: Node[]) => void;
  handleEdgeClick: (id: string) => void;
  onPanClickForEdges: () => void;
}

const defaultContextValue = {
  pipelineData: {},
  updateNodeData: () => {},
  nodes: [],
  edges: [],
  setNodes: () => {},
  setEdges: () => {},
  onNodesChange: () => {},
  onEdgesChange: () => {},
  activeNodeId: '',
  activeNode: {},
  setActiveNodeId: () => {},
  setPipelineData: () => {},
  fullWidthSettingsView: false,
  setFullWidthSettingsView: () => {},
  subgraphActive: false,
  setSubgraphActive: () => {},
  push: () => {},
  pop: () => {
    return { activeNodeId: '', nodes: [], edges: [] };
  },
  peek: () => {
    return { activeNodeId: '', nodes: [], edges: [] };
  },
  activePipelineNodeId: '',
  reactFlowWrapper: null,
  onConnect: () => {},
  onDrop: () => {},
  onDragOver: () => {},
  setReactFlowInstance: () => {},
  toggleNewSubGraph: () => {},
  handleNodeClick: () => {},
  deleteProcessNode: () => {},
  handleEdgeClick: () => {},
  onPanClickForEdges: () => {},
};

const CustomGraphContext =
  createContext<ICustomGraphContext>(defaultContextValue);

const CustomGraphProvider = (props: CustomGraphProviderProps) => {
  const { featureId } = useParams();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [activeNodeId, setActiveNodeId] = useState<string>('');
  const [pipelineData, setPipelineData] = useState<PipelineData>({});
  const [subgraphActive, setSubgraphActive] = useState(false);
  const [fullWidthSettingsView, setFullWidthSettingsView] = useState(false);
  const activeNode = nodes?.find(n => n.id === activeNodeId);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const [droppedNodeId, setDroppedNodeId] = useState('');
  const nodesInitialized = useNodesInitialized();
  const [selectedEdgeId, setSelectedEdgeId] = useState<string>('');
  const backspacePressed = useKeyPress('Backspace');
  const deletePressed = useKeyPress('Delete');
  const { width } = useWindowDimensions();

  const { push, pop, peek } = useProgramStack();

  const activePipelineNodeId = subgraphActive ? peek().activeNodeId : '';

  const updateNodeData = useCallback(
    (data: ApiNodeData | CustomNodeData) => {
      const copy = [...nodes];
      let node = copy.find(n => n.id === data.id);
      if (!node) {
        node = copy.find(n => n.id === activeNodeId);
        if (!node) {
          return;
        }
      }
      node.data = {
        ...node.data,
        ...data,
      };
      setNodes(copy);
    },

    [activeNodeId, nodes]
  );
  const handleNodeClick = (id: string, event_type: string = 'single') => {
    if (event_type !== 'single') {
      setActiveNodeId('');

      // timeout needed to ensure reactflow has enough time to rerender between unsetting and setting the id, to ensure
      // that the data does not copy over between node types as per IL-555
      setTimeout(() => {
        setActiveNodeId(id);
      }, 50);
    } else {
      setActiveNodeId(id);
    }
  };
  const deleteProcessNode = (deletedNodes: Node[]) => {
    if (deletedNodes.length > 0) {
      const updatedPipelineData = { ...pipelineData };
      deletedNodes.forEach(node => {
        if (node.type === CustomNodeType.Process) {
          delete updatedPipelineData[node.id];
        }
      });
      setPipelineData(updatedPipelineData);
    }
  };
  const onConnect: OnConnect = useCallback(
    params =>
      setEdges(eds =>
        addEdge(
          {
            ...params,
            animated: true,
            style: { stroke: '#4EA9FF', strokeWidth: 2 },
          },
          eds
        )
      ),

    []
  );

  const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      if (!reactFlowWrapper.current) {
        return;
      }
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const json = event.dataTransfer.getData('application/reactflow');
      const {
        type: nodeType,
        icon,
        color,
      } = JSON.parse(json) as CustomNodeRenderProps;

      // check if the dropped element is valid
      if (typeof nodeType === 'undefined' || !nodeType) {
        return;
      }
      // @ts-ignore
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      const nodeId = uniqueId();

      const useFallback = !(nodeType in nodeTypes);

      let data:
        | ApiNodeData
        | EndpointNodeData
        | SpreadsheetNodeData
        | BorealisNodeData
        | ProcessNodeData
        | CustomNodeData
        | DropKeysNodeData
        | StorageNodeData
        | CustomFunctionNodeData
        | SummarizeNodeData
        | SentimentNodeData
        | LocationNodeData
        | FilterNodeData
        | TagNodeData
        | DateTimeNodeData
        | SenderNodeData
        | ReceiverNodeData
        | InternalGeneratorNodeData = {
        id: nodeId,
        heading: nodeType,
        type: nodeType,
        meta: {
          label: `${nodeType} node`,
          bgColor: color,
          icon: icon,
        },
      };

      if (nodeType === CustomNodeType.Api) {
        data = {
          ...data,
          heading: 'API',
          requestType: 'GET',
          startingAt: {
            hour: 12,
            minute: 0,
          },
          baseurl: '',
          timeout: 30,
          enabled: false,
          descriptionTitle: '',
          descriptionUrl: 'https://',
          description: '',
          license: '',
          reviewDate: '',
          params: [],
          headers: [],
          // @ts-ignore
          intervalUnit: 'Hours',
          interval: 1,
          body: {
            contentType: '',
            content: '',
          },
          type: CustomNodeType.Api,
        };
      }

      if (nodeType === CustomNodeType.Endpoint) {
        data = {
          ...data,
          heading: 'Endpoint',
          fields: [],
        };
      }

      if (nodeType === CustomNodeType.Spreadsheet) {
        data = {
          ...data,
          heading: 'Spreadsheet',
          descriptionTitle: '',
          descriptionUrl: 'https://',
          description: '',
          license: '',
          reviewDate: 'N/A',
          type: CustomNodeType.Spreadsheet,
        };
      }

      if (nodeType === CustomNodeType.Borealis) {
        data = {
          ...data,
          engagementId: '',
          offset: 0,
          enabled: false,
          heading: 'Borealis',
          descriptionTitle: '',
          descriptionUrl: 'https://',
          description: '',
          license: '',
          reviewDate: 'N/A',
          type: CustomNodeType.Borealis,
        };
      }

      if (nodeType === CustomNodeType.Process) {
        data = {
          ...data,
          heading: 'Process',
          argument: '',
          isArgumentEdited: false,
          trigger: false,
          type: CustomNodeType.Process,
          editable: true,
        };
      }

      if (nodeType === CustomNodeType.Join) {
        data = {
          ...data,
          heading: 'Join',
          script: '',
          type: CustomNodeType.Join,
        };
      }

      if (nodeType === CustomNodeType.Input) {
        data = {
          ...data,
          heading: 'Input',
          script: '',
          type: CustomNodeType.Input,
        };
      }

      if (nodeType === CustomNodeType.Storage) {
        data = {
          ...data,
          heading: 'Storage',
          format: 'unformatted',
          behaviour: 'replace',
          indexFields: ['id'],
          type: CustomNodeType.Storage,
          editable: true,
        };
      }

      if (nodeType === CustomNodeType.DropKeys) {
        data = {
          ...data,
          heading: 'Drop Keys',
          keys: [],
          type: CustomNodeType.DropKeys,
        };
      }

      if (nodeType === CustomNodeType.CustomFunction) {
        data = {
          ...data,
          heading: 'Custom Function',
          script: 'return [];',
          type: CustomNodeType.CustomFunction,
        };
      }

      if (nodeType === CustomNodeType.Summarize) {
        data = {
          ...data,
          heading: 'Summarize',
          source: '',
          destination: '',
          instructions: '',
          type: CustomNodeType.Summarize,
        };
      }

      if (nodeType === CustomNodeType.Sentiment) {
        data = {
          ...data,
          heading: 'Sentiment',
          source: '',
          destination: '',
          type: CustomNodeType.Sentiment,
        };
      }

      if (nodeType === CustomNodeType.Location) {
        data = {
          ...data,
          heading: 'Location',
          source: '',
          destination: '',
          type: CustomNodeType.Location,
        };
      }

      if (nodeType === CustomNodeType.Filter) {
        data = {
          ...data,
          heading: 'Filter',
          filterMode: '',
          filters: [],
          type: CustomNodeType.Filter,
        };
      }

      if (nodeType === CustomNodeType.DateTime) {
        data = {
          ...data,
          heading: 'Date Time',
          source: '',
          sourceFormat: '',
          destination: '',
          destinationFormat: '',
          type: CustomNodeType.DateTime,
        };
      }

      if (nodeType === CustomNodeType.Tag) {
        data = {
          ...data,
          heading: 'Tag',
          source: '',
          destination: '',
          tags: [],
          type: CustomNodeType.Tag,
        };
      }

      if (nodeType === CustomNodeType.Sender) {
        data = {
          ...data,
          heading: 'Sender',
          type: CustomNodeType.Sender,
          receivers: [],
          namespace: '',
          addKeys: [],
        };
      }

      if (nodeType === CustomNodeType.Receiver) {
        data = {
          ...data,
          heading: 'Receiver',
          type: CustomNodeType.Receiver,
          namespace: '',
        };
      }

      if (nodeType === CustomNodeType.InternalGenerator) {
        data = {
          ...data,
          heading: 'Generator',
          type: CustomNodeType.InternalGenerator,
          keys: [],
        };
      }

      const newNode: Node<CustomNodeData> = {
        id: nodeId,
        type: useFallback ? 'fallback' : nodeType,
        position,
        data,
      };
      setDroppedNodeId(nodeId);
      setNodes(nds => nds.concat(newNode));
    },

    [reactFlowInstance]
  );
  const initiateSubGraph = () => {
    push({
      activeNodeId,
      nodes,
      edges,
    });
    // Do we already have pre-existing pipeline data linked to this process node?
    if (activeNodeId in pipelineData) {
      // Yes, so we need to restore it
      const { nodes, edges } = pipelineData[activeNodeId];
      setNodes(nodes);
      setEdges(edges);
    }
    setActiveNodeId('');
  };
  const closeSubGraph = () => {
    // latest main graph state
    const prevGraphData = pop();
    if (typeof prevGraphData !== 'undefined') {
      // update pipeline data and save new pipeline
      setPipelineData({
        ...pipelineData,
        [prevGraphData.activeNodeId]: {
          nodes,
          edges,
        },
      });
      // restore main graph state
      setActiveNodeId(prevGraphData.activeNodeId);
      setNodes(prevGraphData.nodes);
      setEdges(prevGraphData.edges);
    }
  };
  const toggleNewSubGraph = (value: boolean) => {
    if (value) {
      initiateSubGraph();
    } else {
      closeSubGraph();
    }
    setSubgraphActive(value);
  };

  const handleEdgeClick = (id: string) => {
    const updatedEdges = edges.map(edge => {
      if (edge.id === selectedEdgeId) {
        if (selectedEdgeId === id) {
          setSelectedEdgeId(''); // Clear the selected edge ID when clicked twice
        }
        return {
          ...edge,
          animated: true, // Replace 'normalColor' with the actual normal color
        };
      } else if (edge.id === id) {
        setSelectedEdgeId(id); // Update the selected edge ID
        return {
          ...edge,
          animated: false,
        };
      }
      return edge; // Return unchanged edges
    });

    setEdges(updatedEdges);
  };
  const onPanClickForEdges = () => {
    const updatedEdges = edges.map(edge => {
      return {
        ...edge,
        animated: true,
      };
    });
    setEdges(updatedEdges);
    setSelectedEdgeId('');
  };
  const handleDeleteSelectedEdge = () => {
    if (selectedEdgeId) {
      const filteredEdges = edges.filter(edge => edge.id !== selectedEdgeId);
      setEdges(filteredEdges);
      setSelectedEdgeId('');
    }
  };

  if (backspacePressed || deletePressed) {
    handleDeleteSelectedEdge();
  }
  useEffect(() => {
    if (nodesInitialized) {
      reactFlowInstance?.fitView();
    }
  }, [reactFlowInstance, nodesInitialized]);
  useEffect(() => {
    // Wrap in debounce?
    reactFlowInstance?.fitView();
  }, [width]);

  // useEffect to Create a new subgraph when a process node is dropped
  useEffect(() => {
    const isDroppedNodeProcessNode = !!nodes.find(
      n => n.id === droppedNodeId && n.type === CustomNodeType.Process
    );
    const isDroppedNodePresentInPipelineData = !!pipelineData[droppedNodeId];
    if (
      droppedNodeId &&
      isDroppedNodeProcessNode &&
      !isDroppedNodePresentInPipelineData
    ) {
      const newSubGraphData = createDefaultSubGraph();

      setPipelineData({
        ...pipelineData,
        [droppedNodeId]: newSubGraphData,
      });
    }
  }, [droppedNodeId]);

  const context = useMemo(
    () => ({
      pipelineData,
      setPipelineData,
      updateNodeData,
      nodes,
      edges,
      setNodes,
      setEdges,
      onNodesChange,
      onEdgesChange,
      activeNodeId,
      activeNode,
      setActiveNodeId,
      fullWidthSettingsView,
      setFullWidthSettingsView,
      subgraphActive,
      setSubgraphActive,
      push,
      pop,
      peek,
      activePipelineNodeId,
      reactFlowWrapper,
      onConnect,
      onDrop,
      onDragOver,
      setReactFlowInstance,
      toggleNewSubGraph,
      handleNodeClick,
      deleteProcessNode,
      handleEdgeClick,
      onPanClickForEdges,
    }),

    [
      pipelineData,
      updateNodeData,
      nodes,
      edges,
      fullWidthSettingsView,
      activeNode,
      subgraphActive,
      activePipelineNodeId,
      reactFlowWrapper,
      reactFlowInstance,
      droppedNodeId,
      selectedEdgeId,
      nodesInitialized,
      backspacePressed,
      deletePressed,
      width,
      featureId,
    ]
  );

  return (
    <CustomGraphContext.Provider value={context}>
      {props.children}
    </CustomGraphContext.Provider>
  );
};

export const useCustomGraphContext = () => useContext(CustomGraphContext);

export default CustomGraphProvider;
