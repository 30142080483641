import React from 'react';
import { useCustomGraphContext } from '@/pages/dashboard/projects/graph/CustomGraphProvider';
import { Input } from '@tcomponents/ui/input';
import { FilterNodeData } from '@/pages/dashboard/projects/graph/types';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@tcomponents/ui/button';

const FilterFormFields: React.FC = () => {
  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const { filterMode, filters } = activeNode.data as FilterNodeData;

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  const handleAddFilter = () => {
    const dataCopy = { ...activeNode.data };

    if (!Array.isArray(dataCopy.filters)) {
      dataCopy.filters = [];
    }

    const filters = [...dataCopy.filters];

    filters.push({ field: '', func: '=', value: '' });
    dataCopy.filters = filters;

    updateNodeData(dataCopy);
  };

  const handleDeleteFilter = (key: number) => {
    const dataCopy = { ...activeNode.data };
    const filters = [...dataCopy.filters];

    filters.splice(key, 1);

    dataCopy.filters = filters;
    updateNodeData(dataCopy);
  };

  const handleUpdateFilter = (key: number, item: string, value: string) => {
    const dataCopy = { ...activeNode.data };
    let filters = [...dataCopy.filters];
    let filter = { ...filters[key] };
    filter[item] = value;
    filters[key] = filter;
    dataCopy.filters = filters;

    updateNodeData(dataCopy);
  };

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Configuration</h5>
          </div>
          <div className="flex flex-col flex-1 py-2 gap-y-2">
            <label className="text-xs font-bold">Filter Mode</label>
            <Select
              value={filterMode}
              defaultValue={'AND'}
              onValueChange={val =>
                handleSingleInputFieldChange('filterMode', val)
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="AND" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="AND">AND</SelectItem>
                  <SelectItem value="OR">OR</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Filters</h5>
          </div>
          <div className="mb-4 border-bottom">
            <div className="w-full flex flex-row gap-4 border-bottom">
              <p className="w-1/4">Field</p>
              <p className="w-1/3">Func</p>
              <p className="w-1/4">Value</p>
              <p className="w-1/8"> </p>
            </div>
            {filters?.length > 0 &&
              filters.map((filter, key) => (
                <div
                  className="w-full flex flex-row gap-2"
                  key={`filter_node_fields_${key}`}
                >
                  <div className="w-1/4">
                    <Input
                      value={filter.field}
                      onChange={e =>
                        handleUpdateFilter(key, 'field', e.target.value)
                      }
                      className="w-full"
                    />
                  </div>

                  <div className="w-1/3">
                    <Select
                      value={filters[key].func}
                      onValueChange={val =>
                        handleUpdateFilter(key, 'func', val)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Function" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectItem value="=">=</SelectItem>
                          <SelectItem value="!=">!=</SelectItem>
                          <SelectItem value=">">{'>'}</SelectItem>
                          <SelectItem value=">=">{'>='}</SelectItem>
                          <SelectItem value="<">{'<'}</SelectItem>
                          <SelectItem value="<=">{'<='}</SelectItem>
                          <SelectItem value="exists">exists</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="w-1/4">
                    <Input
                      disabled={filter.func === 'exists'}
                      value={filter.value}
                      onChange={e =>
                        handleUpdateFilter(key, 'value', e.target.value)
                      }
                      className="w-full"
                    />
                  </div>

                  <div className="w-1/8">
                    <button
                      className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm mt-1.5"
                      onClick={() => handleDeleteFilter(key)}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 w-full font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddFilter}
            data-testing="new_param"
            aria-label="new param"
          >
            + New Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterFormFields;
