import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

interface ShowEmptyProps {
  title: string;
  message?: string;
  icon?: IconDefinition;
}

const ShowEmptyMessage: React.FC<ShowEmptyProps> = (props: ShowEmptyProps) => {
  const { title, message, icon = faBoxOpen } = props;
  return (
    <div className="flex flex-col items-center m-auto gap-y-3 text-primary opacity-70">
      <FontAwesomeIcon icon={icon} className=" text-[70px]" />
      <div className="text-center">
        <h4>{title}</h4>
        <p>{message}</p>
      </div>
    </div>
  );
};
export default ShowEmptyMessage;
