import React, { FC, RefObject, useEffect, useState } from 'react';
import AuthenticatedLayout from '../../../layout/AuthenticatedLayout';
import LinkInterface from '../../../types/interfaces/LinkInterface';
import TabbedNavigationBarElement from '../../../layout/tab-navigation/TabbedNavigationBarElement';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { Prompt } from '../../../utils/use-prompt';
import SidebarTypes from '../../../types/enums/common/SidebarTypes';
import TitleCase from '../../../utils/titleCase';
import GraphContainer from '../projects/graph/GraphContainer';
import useAuth from '@/hooks/UseAuth';
import WorkflowLogs from '@/pages/dashboard/features/WorkflowLogs';
import WorkflowForm from '@/pages/dashboard/features/forms/WorkflowForm';
import DashboardForm from '@/pages/dashboard/features/forms/DashboardForm';
import ChartDashboard from '../projects/chartDashboard/ChartDashboard';
import { useSaveActionContext } from '@/context/SaveActionContext';
import { Button } from '@tcomponents/ui/button';
import {
  faSave,
  faTrash,
  faEye,
  faClipboardListCheck,
} from '@fortawesome/pro-solid-svg-icons';
import RankedListForm from '@/pages/dashboard/features/forms/RankedList/RankedListForm';
import PageNotAvailableOnMobile from '@/components/common/PageNotAvailableOnMobile';
import InformationTab from '@/pages/dashboard/projects/common/InformationTab';
import TableForm from '@/pages/dashboard/features/forms/Table/TableForm';
import { useGetProjectSettings } from './featureHooks/useGetProjectSettings';
import { useGetFeatureGroup } from './featureHooks/useGetFeatureGroup';
import { useSaveTabs } from '@/hooks/useSaveTabs';
import { useGetFeature } from './featureHooks/useGetFeature';
import { useDeleteFeature } from './featureHooks/useDeleteFeature';
import { useCreateOrUpdateFeature } from './featureHooks/useCreateOrUpdateFeature';
import { useUpdateFeatureVisibility } from './featureHooks/useUpdateFeatureVisibility';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import SurveyForm from '@/pages/dashboard/features/forms/SurveyForm';
import {
  DashboardFeatureDetails,
  FeatureSubtypeFe,
  RankedListFeatureDetails,
  SurveyFeatureDetails,
  TableFeatureDetails,
  WorkflowFeatureDetails,
} from '@/types/feature/featureDetails';
import { featurePermissions } from '@/stringConsts/permissionConsts';

interface IFeatureDetailsProps {}

const FeatureDetails: FC<IFeatureDetailsProps> = () => {
  let { featureId, featureGroupId, featureType } = useParams();
  // here we are getting the feature subtype from the react router params as featureType, so we need to give it proper types and name to make it more clear and typesafe
  const featureSubtype = featureType?.toLowerCase() as FeatureSubtypeFe;

  const [tabIndex, setTabIndex] = useState(0);
  const [submitButtonRef, setSubmitButtonRef] =
    useState<RefObject<HTMLButtonElement>>();

  const { isDirty, setIsDirty, setSaveAction, saveAction } =
    useSaveActionContext();

  /* ------------
    New save-all-tabs idea
    ------------ */
  // TODO: dynamically determine how many tabs we will have for a given feature type
  const { saveAllTabs, tabRefs } = useSaveTabs({ tabCount: 3 });

  const navigate = useNavigate();
  const { hasPermission, hasAnyPermission } = useAuth();
  const { projectDetails } = useGetProjectSettings();
  const { featureGroupDetails } = useGetFeatureGroup();
  const { deleteFeatureWithDialogWarning, isDeleteSuccess } =
    useDeleteFeature();
  const { featureDetails, pageTitle } = useGetFeature(isDeleteSuccess);
  const { createFeature, updateFeature, isStoringError, isStoringSuccess } =
    useCreateOrUpdateFeature({ projectDetails });
  const { updateVisibilityWithDialogWarning } = useUpdateFeatureVisibility();

  const breadcrumbElements: Array<LinkInterface> =
    featureId !== 'new'
      ? [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
          {
            displayName: featureGroupDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/feature-groups/${featureGroupDetails?.id}`,
          },
        ]
      : [
          {
            displayName: 'Projects',
            link: '/projects',
          },
          {
            displayName: projectDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/settings`,
          },
          {
            displayName: featureGroupDetails?.name ?? '',
            link: `/projects/${projectDetails?.id}/feature-groups/${featureGroupDetails?.id}`,
          },
        ];

  const onSubmitHandler: SubmitHandler<any> = values => {
    const obj = {
      ...values,
      feature_group_id: featureGroupId,
      feature_subtype: TitleCase(featureType ?? ''),
      feature_type:
        featureType?.toLowerCase() === 'workflow' ||
        featureType?.toLowerCase() === 'survey'
          ? 'Processing'
          : 'Display',
    };

    featureId !== 'new'
      ? updateFeature(Number(featureId), obj)
      : createFeature(obj);
  };

  const canSave = () => {
    return isDirty;
  };

  Prompt({
    message: 'Are you sure you want to leave without saving?',
    when: isDirty && !isStoringSuccess && !isStoringError && !isDeleteSuccess,
  });

  const renderForm = () => {
    switch (featureSubtype) {
      case 'workflow':
        return (
          <WorkflowForm
            onSubmitHandler={onSubmitHandler}
            data={featureDetails as WorkflowFeatureDetails}
            setIsDirty={setIsDirty}
            setRefSubmitButton={setSubmitButtonRef}
          />
        );
      case 'ranked-list':
        return (
          <RankedListForm
            onSubmitHandler={onSubmitHandler}
            data={featureDetails as RankedListFeatureDetails}
            setIsDirty={setIsDirty}
            setRefSubmitButton={setSubmitButtonRef}
          />
        );
      case 'table':
        return (
          <TableForm
            onSubmitHandler={onSubmitHandler}
            data={featureDetails as TableFeatureDetails}
            setIsDirty={setIsDirty}
            setRefSubmitButton={setSubmitButtonRef}
          />
        );
      case 'survey':
        return (
          <SurveyForm
            onSubmitHandler={onSubmitHandler}
            data={featureDetails as SurveyFeatureDetails}
            setIsDirty={setIsDirty}
            setRefSubmitButton={setSubmitButtonRef}
          />
        );
    }
  };
  const renderFeatureDetails = () => {
    if (tabIndex === 0) {
      return (
        <>
          <div className="hidden md:block">{renderForm()}</div>
          <PageNotAvailableOnMobile />
        </>
      );
    }
    return null;
  };

  const renderSecondTab = () => {
    if (tabIndex === 1 && featureId) {
      switch (featureSubtype) {
        case 'workflow':
          return <GraphContainer />;
        case 'ranked-list':
        case 'table':
          return <InformationTab id={featureId} />;
        case 'map':
          return <InformationTab id={featureId} />;
        case 'survey':
          return <GraphContainer />;
      }
    }
    return null;
  };

  const renderThirdTab = () => {
    if (tabIndex === 2 && featureId) {
      switch (featureType?.toLowerCase()) {
        case 'workflow':
          return <WorkflowLogs id={featureId} />;
      }
    }
    return null;
  };

  const renderFeatureView = () => {
    if (featureId) {
      switch (featureType?.toLowerCase()) {
        case 'dashboard':
          return <ChartDashboard id={featureId} />;
      }
    }
  };

  const getTabbedNavigationElements = () => {
    switch (featureSubtype) {
      case 'workflow':
        return [
          <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Graph
          </TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Logs
          </TabbedNavigationBarElement>,
        ];
      case 'dashboard':
        return [
          <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Dashboard
          </TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Information
          </TabbedNavigationBarElement>,
        ];
      case 'ranked-list':
      case 'table':
        return [
          <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Information
          </TabbedNavigationBarElement>,
        ];
      case 'survey':
        return [
          <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Graph
          </TabbedNavigationBarElement>,
          <TabbedNavigationBarElement disabled={featureId === 'new'}>
            Logs
          </TabbedNavigationBarElement>,
        ];
    }
  };
  const isFeatureDashboard = featureType?.toLowerCase() === 'dashboard';

  const dashboardTabs = isFeatureDashboard
    ? [
        <DashboardForm
          onSubmitHandler={onSubmitHandler}
          data={featureDetails as DashboardFeatureDetails}
          ref={tabRefs[0]}
        />,
        <ChartDashboard id={featureId as string} ref={tabRefs[1]} />,
        <InformationTab id={featureId as string} ref={tabRefs[2]} />,
      ]
    : [];

  useEffect(() => {
    switch (tabIndex) {
      case 0:
      case 2:
        setSaveAction({ callback: () => submitButtonRef?.current?.click() });
    }
  }, [tabIndex, submitButtonRef, setSaveAction]);

  return (
    <AuthenticatedLayout
      breadcrumbElements={breadcrumbElements}
      logo={
        typeof projectDetails?.logo_url !== 'undefined'
          ? projectDetails.logo_url
          : process.env.REACT_APP_DEFAULT_PROJECT_LOGO
      }
      icon=""
      title={pageTitle}
      actions={
        <div style={{ display: 'inherit', alignItems: 'center' }}>
          {featureId !== 'new' && featureDetails && (
            <>
              <Button
                onClick={() => deleteFeatureWithDialogWarning(featureDetails)}
                icon={faTrash}
                variant={'danger'}
                className="mr-2"
                data-testing="feature-delete-button"
                disabled={!hasPermission(featurePermissions.general.delete)}
              >
                Delete
              </Button>
              {featureDetails.feature_type === 'Display' &&
                typeof featureDetails.feature_subtype !== 'undefined' &&
                ['Dashboard', 'Ranked List', 'Table', 'Map'].includes(
                  featureDetails.feature_subtype
                ) && (
                  <Button
                    variant={'warning'}
                    icon={faEye}
                    className="mr-2"
                    onClick={() =>
                      updateVisibilityWithDialogWarning(featureDetails)
                    }
                    data-testing={
                      featureDetails.public
                        ? 'feature-unpublish-button'
                        : 'feature-publish-button'
                    }
                  >
                    {featureDetails.public ? 'Unpublish' : 'Publish'}
                  </Button>
                )}
              {featureDetails.feature_type === 'Display' &&
                featureDetails.feature_subtype === 'Survey' && (
                  <Button
                    icon={faClipboardListCheck}
                    className="mr-2"
                    onClick={() =>
                      navigate(
                        `/projects/${projectDetails?.id}/feature-groups/${featureGroupId}/${featureId}/Survey/view`
                      )
                    }
                    disabled={featureId === 'new'}
                  >
                    Survey
                  </Button>
                )}
            </>
          )}
          {((featureId === 'new' &&
            hasPermission(featurePermissions.general.create)) ||
            (featureId !== 'new' &&
              hasPermission(featurePermissions.general.settings_access))) && (
            <Button
              disabled={!canSave()}
              icon={faSave}
              onClick={() => {
                if (isFeatureDashboard) {
                  saveAllTabs();
                  toast({
                    description:
                      featureId === 'new'
                        ? 'Feature Created'
                        : 'Your changes have been saved',
                    duration: 3000,
                  });
                } else {
                  saveAction.callback();
                }
              }}
              data-testing={
                featureId !== 'new'
                  ? 'feature-save-button'
                  : 'feature-create-button'
              }
            >
              {featureId !== 'new' ? 'Save' : 'Create'}
            </Button>
          )}
        </div>
      }
      sidebarData={
        projectDetails
          ? {
              type: SidebarTypes.PROJECT,
              data: projectDetails,
              backButtonCallback: () => navigate('/projects'),
            }
          : {
              type: SidebarTypes.PROJECT,
              data: {},
              backButtonCallback: () => navigate('/projects'),
            }
      }
      tabIndex={tabIndex}
      selectedTabIndexChanged={setTabIndex}
      tabNavigationElements={getTabbedNavigationElements()}
    >
      {hasAnyPermission([
        featurePermissions.general.settings_access,
        featurePermissions.general.create,
      ]) ? (
        <>
          {renderFeatureDetails()}
          {renderSecondTab()}
          {renderThirdTab()}
          {isFeatureDashboard && (
            <div>
              {dashboardTabs.map((tabComponent, idx) => {
                return (
                  <div
                    key={'tab-' + idx}
                    className={tabIndex === idx ? '' : 'hidden '}
                  >
                    {tabComponent}
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <>{renderFeatureView()}</>
      )}
    </AuthenticatedLayout>
  );
};

export default FeatureDetails;
