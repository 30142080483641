import { ColumnDefinition } from 'tabulator-tables';
import { deleteRowIcon } from '../../../../../hooks/useTabulator';

//build column definitions from schema and add delete row button
export function buildColumnDefinitions(schema: Record<string, any>[]) {
  const map: Record<string, any> = {
    input: {
      editor: 'input',
    },
    textarea: {
      editor: 'textarea',
      formatter: 'textarea',
    },
    number: {
      editor: 'number',
    },
    toggle: {
      formatter: 'toggle',
      formatterParams: {
        clickable: true,
      },
    },
    list: function (config: { values: string[] }) {
      const def = {
        editor: 'list',
        editorParams: {
          values: config.values ?? [],
          multiselect: false,
        },
        headerFilterParams: {
          values: config.values ?? [],
          multiselect: false,
        },
      };

      return def;
    },
    'list-multi': function (config: { values: string[] }) {
      const def = {
        editor: 'list',
        editorParams: {
          values: config.values,
          multiselect: true,
        },
        headerFilterParams: {
          values: config.values,
          multiselect: true,
        },
        headerFilterFunc: 'in',
      };

      return def;
    },
  };

  schema.forEach(col => {
    const def = map[col.type];
    if (col.field === '__id') {
      col.visible = false;
    }
    switch (typeof def) {
      case 'object':
        Object.assign(col, def);
        break;

      case 'function':
        Object.assign(col, def(col));
        break;
    }
  });

  if (schema.length) {
    schema.push(deleteRowIcon);
  }

  return schema as ColumnDefinition[];
}
