import { FormQuestion, Question } from '../Helpers/Types';
import { Input } from '@/tcomponents/ui/input';
import { Button, IconButton } from '@/tcomponents/ui/button';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';

interface FormQuestionProps {
  question: FormQuestion;
  updateQuestion: (data: Question) => void;
}

export const FormQue = (props: FormQuestionProps) => {
  const { question, updateQuestion } = props;

  const addField = () => {
    const newField = {
      personal: false,
      type: 'text',
      label: '',
      key: '',
    };
    updateQuestion({
      ...question,
      fields: [...question.fields, newField],
    });
  };

  const deleteField = (index: number) => {
    const newFields = question.fields.filter((_, i) => i !== index);
    updateQuestion({ ...question, fields: newFields });
  };

  const selectFieldData = [
    'First Name',
    'Last Name',
    'Email',
    'Phone Number',
    'Mobile Phone',
    'Home Phone',
    'Office Phone',
    'Fax',
    'Linked In',
    'Twitter',
    'Facebook',
    'Instagram',
    'House Number / Street',
    'City',
    'County',
    'Country',
    'Postcode',
  ];
  const selectTypeData = [
    { value: 'text', label: 'Text' },
    { value: 'number', label: 'Number' },
    { value: 'date', label: 'Date' },
    { value: 'email', label: 'Email' },
    { value: 'tel', label: 'Telephone' },
  ];

  return (
    <div className="p-4 ">
      <div className="flex justify-between pb-2 gap-x-4">
        <Label>Personal</Label>
        <Label className="grow ml-2.5">Fields</Label>
        <Label className="pr-[205px]">Type</Label>
      </div>
      <div className="flex flex-col mt-1 divide-y gap-y-0">
        {question.fields?.map((field, index) => {
          const isFirstItem = index === 0;
          const isLastItem = index === question.fields.length - 1;
          return (
            <div
              key={index}
              className={`flex py-2.5 space-x-2 ${
                isFirstItem ? 'border-t' : ''
              } ${isLastItem ? 'border-b' : ''}`}
            >
              <Switch
                className="ml-2.5"
                checked={field.personal}
                onCheckedChange={val => {
                  updateQuestion({
                    ...question,
                    fields: question.fields?.map((f, i) =>
                      i === index ? { ...f, personal: val } : f
                    ),
                  });
                }}
              />
              {/* select or input block */}
              <div className="pl-6 grow">
                {field.personal ? (
                  <Select
                    value={field.label}
                    onValueChange={(val: string) => {
                      updateQuestion({
                        ...question,
                        fields: question.fields?.map((f, i) =>
                          i === index ? { ...f, label: val, key: val } : f
                        ),
                      });
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Type" />
                    </SelectTrigger>
                    <SelectContent className="w-full">
                      <SelectGroup
                        className={'overflow-y-auto max-h-96 w-full'}
                      >
                        {selectFieldData.map((item, index) => (
                          <SelectItem key={index} value={item}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                ) : (
                  <Input
                    className="grow"
                    value={field.label}
                    type="text"
                    onChange={e => {
                      updateQuestion({
                        ...question,
                        fields: question.fields?.map((f, i) =>
                          i === index
                            ? {
                                ...f,
                                label: e.target.value,
                                key: e.target.value,
                              }
                            : f
                        ),
                      });
                    }}
                  />
                )}
              </div>
              {/* select type block*/}
              <div className="w-[200px]">
                <Select
                  value={field.type}
                  onValueChange={(val: string) => {
                    updateQuestion({
                      ...question,
                      fields: question.fields?.map((f, i) =>
                        i === index ? { ...f, type: val } : f
                      ),
                    });
                  }}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup className={'overflow-y-auto max-h-96'}>
                      {selectTypeData.map((item, index) => (
                        <SelectItem key={index} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <IconButton
                tabIndex={-1}
                className="p-2 text-2xl text-white bg-red-500 rounded-lg fa-2xl"
                icon={faXmark}
                onClick={() => deleteField(index)}
                tooltip="Delete Field"
                testingAttribute="delete-field"
                disabled={question.fields.length === 1}
              />
            </div>
          );
        })}
      </div>
      <Button
        className="w-full mt-2.5 border-gray-300 bg-info"
        onClick={addField}
      >
        + Add Field
      </Button>
    </div>
  );
};
