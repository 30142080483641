import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';
import { IFrameWidgetOptions } from '../../types/chart-types';
import { useChartDashboardContext } from '../../ChartDashboardProvider';

type IFrameChartOptionsProps = {
  widgetData: IFrameWidgetOptions;
};
export default function IFrameChartOptions(props: IFrameChartOptionsProps) {
  const { widgetData } = props;
  const { updateWidgetData } = useChartDashboardContext();
  return (
    <div className="flex flex-col p-3 gap-y-4">
      <h4>Chart Options</h4>
      <div className="flex flex-col gap-y-1">
        <Label>URL</Label>
        <Input
          onChange={e =>
            updateWidgetData({
              chartOptions: {
                url: e.target.value,
              },
            })
          }
          value={widgetData.chartOptions.url ?? ''}
          placeholder="Enter External Website URL"
        />
      </div>
    </div>
  );
}
