import React, { useContext } from 'react';
import { ApiNodeData, Time } from '../../types/node-types';
import { toast } from '@tcomponents/ui/toast/use-toast';
import { Input } from '@tcomponents/ui/input';
import { Label } from '@tcomponents/ui/label';
import { Switch } from '@tcomponents/ui/switch';
import { Textarea } from '@tcomponents/ui/textarea';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import { cn } from '@/lib/utils';
import { buttonVariants } from '@tcomponents/ui/button';
import { SaveActionContext } from '@/context/SaveActionContext';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import { useLazyTriggerNodeQuery } from '@/store/services/projects/graphApi';

const ApiNodeFormFields = () => {
  const { setIsDirty } = useContext(SaveActionContext);

  const {
    updateNodeData,
    fullWidthSettingsView: isExpanded,
    activeNode,
  } = useCustomGraphContext();

  const handleSingleInputFieldChange = (
    field: string,
    val: string | number | boolean
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;
    updateNodeData(dataCopy);
  };

  const handleIntervalUnitChange = (val: ['Hours', 'Minutes']) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.intervalUnit = val;
    updateNodeData(dataCopy);
  };

  const handleStartingAtChange = (val: Time) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.startingAt = val;
    updateNodeData(dataCopy);
  };

  const handleParamChange = (idx: number, key: string, val: string) => {
    const dataCopy = { ...activeNode.data };
    const paramsCopy = [...dataCopy.params];

    paramsCopy[idx] = {
      key: key,
      val: val,
    };

    dataCopy.params = paramsCopy;

    updateNodeData(dataCopy);
  };

  const handleParamDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.params = dataCopy.params.filter((_: any, i: number) => i !== idx);
    updateNodeData(dataCopy);
  };

  const handleAddParam = () => {
    const dataCopy = { ...activeNode.data };
    dataCopy.params = [...activeNode.data.params, { key: '', val: '' }];
    updateNodeData(dataCopy);
  };

  const handleHeaderChange = (idx: number, key: string, val: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.headers[idx] = { key, val };
    updateNodeData(dataCopy);
  };

  const handleHeaderDeletion = (idx: number) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.headers = dataCopy.headers.filter(
      (_: any, i: number) => i !== idx
    );
    updateNodeData(dataCopy);
  };

  const handleAddHeader = () => {
    const dataCopy = { ...activeNode.data };
    dataCopy.headers = [...activeNode.data.headers, { key: '', val: '' }];
    updateNodeData(dataCopy);
  };
  const [triggerNode] = useLazyTriggerNodeQuery();
  const triggerNodeFn = async () => {
    setIsDirty(false);
    triggerNode(activeNode.id)
      .then((response: any) => {
        if (response.isSuccess) {
          toast({
            duration: 3000,
            title: 'Success!',
            description: response.data.success,
          });
        }
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong',
          description: 'Unable to trigger node.',
        });
      });
  };

  const handleBodyChange = (content: string, contentType: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.body = { content, contentType };
    updateNodeData(dataCopy);
  };

  const handleResponseTypeChange = (contentType: string) => {
    const dataCopy = { ...activeNode.data };
    dataCopy.responseType = contentType;
    updateNodeData(dataCopy);
  };

  const {
    baseurl,
    params,
    headers,
    body,
    requestType,
    startingAt,
    interval,
    intervalUnit,
    timeout,
    enabled,
    descriptionTitle,
    descriptionUrl,
    description,
    license,
    reviewDate,
    responseType,
  } = activeNode.data as ApiNodeData;

  return (
    <div
      className={
        'flex p-2 text-base gap-y-3' + (isExpanded ? ' flex-wrap' : ' flex-col')
      }
    >
      {/* configuration block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'p-4 bg-white border rounded-md ' + (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <div className="flex items-center justify-between pb-2 border-b">
            <h5 className="font-bold">Configuration</h5>
            <button
              className="px-3 py-1 text-sm font-bold text-white bg-yellow-500 rounded-sm"
              onClick={triggerNodeFn}
              data-testing="trigger"
              aria-label="trigger"
            >
              Trigger
            </button>
          </div>
          <div className="flex flex-col py-2 gap-y-2">
            <label className="text-xs font-bold">Base URL</label>

            <div className={'flex flex-row'}>
              <Select
                value={requestType}
                onValueChange={val =>
                  handleSingleInputFieldChange('requestType', val)
                }
              >
                <SelectTrigger className="text-white w-fit bg-primary">
                  <SelectValue placeholder={'GET'} />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectItem value="GET">GET</SelectItem>
                    <SelectItem value="PUT">PUT</SelectItem>
                    <SelectItem value="POST">POST</SelectItem>
                    <SelectItem value="DELETE">DELETE</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
              <input
                className="flex-1 p-1 border rounded-sm"
                type="text"
                value={baseurl}
                onChange={e =>
                  handleSingleInputFieldChange('baseurl', e.target.value)
                }
              />
            </div>
            <div className={'flex flex-row'}>
              <div
                className={
                  'flex flex-col py-2 gap-y-2' + (isExpanded ? '' : ' flex-1')
                }
              >
                <label className="text-xs font-bold">Run every</label>
                <div className="flex flex-row">
                  <Input
                    value={interval}
                    onChange={e =>
                      handleSingleInputFieldChange(
                        'interval',
                        parseInt(e.target.value)
                      )
                    }
                    className={
                      'bg-white flex flex-1 ' +
                      (isExpanded ? 'flex-none w-20 mr-3' : 'mr-0')
                    }
                    type={'number'}
                  />
                  <Select
                    value={intervalUnit as unknown as string}
                    onValueChange={value =>
                      handleIntervalUnitChange(
                        value as unknown as ['Hours', 'Minutes']
                      )
                    }
                  >
                    <SelectTrigger
                      className={
                        'bg-white flex flex-1 ml-1 ' +
                        (isExpanded ? 'flex-none w-24 mr-5' : 'mr-2')
                      }
                    >
                      <SelectValue placeholder={'Hours'} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup className={'overflow-y-auto max-h-96'}>
                        <SelectItem value="Hours">Hours</SelectItem>
                        <SelectItem value="Minutes">Min</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex flex-col py-2 w-3/8 gap-y-2">
                <label className="text-xs font-bold">Starting At</label>
                <Input
                  className={
                    'bg-white flex flex-1 ' +
                    (isExpanded ? 'flex-none w-24 mr-3' : 'mr-0')
                  }
                  value={
                    startingAt?.hour.toString().padStart(2, '0') +
                    ':' +
                    startingAt?.minute.toString().padStart(2, '0')
                  }
                  type={'time'}
                  onChange={v => {
                    const parsedValue = v.currentTarget.value.split(':');
                    const time: Time = {
                      hour: parseInt(parsedValue[0]),
                      minute: parseInt(parsedValue[1]),
                    };
                    handleStartingAtChange(time);
                  }}
                />
              </div>
            </div>

            <div className={'flex flex-row'}>
              <div className="flex flex-col flex-1 py-2 gap-y-2">
                <label className="text-xs font-bold">
                  Timeout <small>(seconds)</small>
                </label>
                <Input
                  value={timeout}
                  onChange={e =>
                    handleSingleInputFieldChange(
                      'timeout',
                      parseInt(e.target.value)
                    )
                  }
                  className={
                    'flex flex-1 bg-white ' +
                    (isExpanded ? 'flex-none w-36 mr-3' : 'mr-0')
                  }
                  type={'number'}
                />
              </div>
              <div className="flex flex-col flex-1 py-2 gap-y-2"></div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Label htmlFor="responseType" className="font-semibold">
                Response Format
              </Label>
              <Select
                onValueChange={val => handleResponseTypeChange(val)}
                defaultValue={'JSON'}
                value={responseType}
              >
                <SelectTrigger id="responseType" className="w-full bg-white">
                  <SelectValue
                    placeholder={body?.contentType || 'Select Content type'}
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectItem value="JSON">JSON</SelectItem>
                    <SelectItem value="Raw">Raw</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className={'flex flex-row'}>
              <div className="flex flex-col flex-1 py-2 gap-y-2">
                <div className="flex flex-col gap-y-2">
                  <label className="text-xs font-bold">Enabled</label>
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={enabled}
                      onCheckedChange={val =>
                        handleSingleInputFieldChange('enabled', val)
                      }
                      id="isEnabled"
                    />
                    <Label htmlFor="isEnabled">{enabled ? 'Yes' : 'No'}</Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Source Details block */}
      <div
        className={
          'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
          (isExpanded ? ' w-1/2' : ' w-full')
        }
      >
        <h5 className="pb-1 font-bold">Source Details</h5>
        <div className="flex flex-col py-2 gap-y-4">
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Title</label>
            <Input
              value={descriptionTitle}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionTitle',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">URL</label>
            <Input
              value={descriptionUrl}
              onChange={e =>
                handleSingleInputFieldChange(
                  'descriptionUrl',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Description</label>
            <Textarea
              value={description}
              onChange={e =>
                handleSingleInputFieldChange(
                  'description',
                  e.currentTarget.value
                )
              }
              className="flex flex-1 bg-white"
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">License</label>
            <Input
              value={license}
              onChange={e =>
                handleSingleInputFieldChange('license', e.target.value)
              }
              className="flex flex-1 bg-white"
              type={'text'}
            />
          </div>
          <div className="flex flex-col flex-1 gap-y-2">
            <label className="text-xs font-bold">Review Date</label>
            <Input
              value={reviewDate}
              onChange={e =>
                handleSingleInputFieldChange('reviewDate', e.target.value)
              }
              className="flex flex-1 bg-white"
              type="date"
            />
          </div>
        </div>
      </div>
      {/* params block */}
      <div className={isExpanded ? ' w-1/2' : ' w-full'}>
        <div
          className={
            'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md ' +
            (isExpanded ? ' mr-2' : 'mr-0')
          }
        >
          <h5 className="font-bold ">Params</h5>
          {params.map((param, index) => (
            <div key={`param_${index}`} className="flex justify-between p-1">
              <input
                className={
                  'border p-1 text-sm rounded-sm mt-1.5 ' +
                  (isExpanded ? ' w-1/2 mr-3 ' : 'w-[90px]')
                }
                type="text"
                placeholder="Key"
                value={param.key}
                onChange={e =>
                  handleParamChange(index, e.target.value, param.val)
                }
              />
              <input
                className={
                  'border p-1 text-sm rounded-sm mt-1.5 ' +
                  (isExpanded ? ' w-1/2 mr-3 ' : 'w-[90px]')
                }
                type="text"
                placeholder="Value"
                value={param.val}
                onChange={e =>
                  handleParamChange(index, param.key, e.target.value)
                }
              />
              <button
                className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm mt-1.5"
                onClick={() => handleParamDeletion(index)}
              >
                X
              </button>
            </div>
          ))}
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddParam}
            data-testing="new_param"
            aria-label="new param"
          >
            + New Param
          </button>
        </div>
      </div>
      {/* headers block */}
      <div
        className={
          'flex flex-col gap-2 border p-4 text-sm bg-white divide-y-[1px] rounded-md' +
          (isExpanded ? ' w-1/2' : ' w-full')
        }
      >
        <h5 className="font-bold">Headers</h5>
        {headers.map((header, index) => (
          <div key={`header_${index}`} className="flex justify-between p-1">
            <input
              type="text"
              placeholder="Key"
              value={header.key}
              className={
                'border p-1 text-sm rounded-sm mt-1.5 ' +
                (isExpanded ? ' w-1/2 mr-3 ' : 'w-[90px]')
              }
              onChange={e =>
                handleHeaderChange(index, e.target.value, header.val)
              }
            />
            <input
              type="text"
              placeholder="Value"
              value={header.val}
              className={
                'border p-1 text-sm rounded-sm mt-1.5 ' +
                (isExpanded ? ' w-1/2 mr-3 ' : 'w-[90px]')
              }
              onChange={e =>
                handleHeaderChange(index, header.key, e.target.value)
              }
            />
            <button
              className="bg-red-500 text-sm py-1.5 px-3 hover:bg-red-600 text-white font-bold rounded-sm mt-1.5"
              onClick={() => handleHeaderDeletion(index)}
            >
              X
            </button>
          </div>
        ))}
        <div
          className={
            cn(buttonVariants({ variant: 'info', size: 'default' })) +
            'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
          }
          onClick={handleAddHeader}
        >
          + New Param
        </div>
      </div>
      {/* body block */}
      <div
        className={
          'flex flex-col gap-2 gap-y-5 border p-4 text-sm bg-white rounded-md w-full'
        }
      >
        <h5 className="pb-2 font-bold border-b">Body</h5>

        <div className="flex flex-col gap-y-2">
          <Label htmlFor="content" className="font-semibold">
            Content type
          </Label>
          <Select onValueChange={val => handleBodyChange(body?.content, val)}>
            <SelectTrigger id="content" className="w-[180px] bg-white">
              <SelectValue
                placeholder={body?.contentType || 'Select Content type'}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className={'overflow-y-auto max-h-96'}>
                <SelectLabel>Content types</SelectLabel>
                <SelectItem value="JSON">JSON</SelectItem>
                <SelectItem value="Text">Text</SelectItem>
                <SelectItem value="Raw">Raw</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <textarea
            className={
              'border p-1 text-sm rounded-sm self-center min-h-[200px] bg-gray-600 text-white text w-full'
            }
            placeholder="Script"
            value={body?.content}
            onChange={e => handleBodyChange(e.target.value, body?.contentType)}
          />
        </div>
      </div>
    </div>
  );
};

export default ApiNodeFormFields;
