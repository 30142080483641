import { useGetSurveyConfigQuery } from '@/store/services/projects/surveyApi';
import { useEffect, useState } from 'react';
import {
  defaultSurveyFormState,
  mapConfigurationToFormState,
} from '../Helpers/FormStates';
import { toast } from '@/tcomponents/ui/toast/use-toast';

export const useGetSurveyConfig = (surveyConfigId: number) => {
  const [formContent, setFormContent] = useState(defaultSurveyFormState);
  const { data, isSuccess, isError } = useGetSurveyConfigQuery(surveyConfigId, {
    skip: !surveyConfigId,
    refetchOnMountOrArgChange: true,
  });
  useEffect(() => {
    if (surveyConfigId && isSuccess && data?.data) {
      const transformedData = mapConfigurationToFormState(data?.data);
      setFormContent(transformedData);
    }
    if (isError) {
      toast({
        title: 'Uh oh! Something went wrong',
        variant: 'destructive',
        description: 'There was a problem loading survey configuration',
        duration: 24 * 60 * 60000,
      });
    }
  }, [surveyConfigId, isSuccess, isError, data?.data]);

  return {
    formContent,
    setFormContent,
  };
};
