import { useEffect, useState } from 'react';
import { TableDataView } from './TableDataView';
import { Schema, TableSettingsView } from './TableSettingsView';
import { useGetFromattedDataFromDataStoreQuery } from '@/store/services/projects/graphApi';
import { EmptyWorkflow } from '@/tcomponents/custom/EmptyWorkflow';

type WorkflowViewProps = {
  selectedRowTypeDataStore:
    | { title: string; uuid: string; schema?: Schema }
    | undefined;
  project?: any;
  featureName?: string;
};

export const WorkflowView = (props: WorkflowViewProps) => {
  const { selectedRowTypeDataStore, featureName } = props;
  const [tableView, setTableView] = useState<'data-view' | 'settings-view'>(
    'data-view'
  );
  const { uuid, schema } = selectedRowTypeDataStore || { title: '', uuid: '' };
  const { data: rawDataResponse } = useGetFromattedDataFromDataStoreQuery(
    uuid,
    {
      skip: !uuid,
    }
  );

  // Sets the default view to data-view if the selectedRowTypeDataStore changes
  useEffect(() => {
    setTableView('data-view');
  }, [selectedRowTypeDataStore?.uuid]);
  return selectedRowTypeDataStore ? (
    <>
      {tableView === 'data-view' && rawDataResponse && (
        <TableDataView
          selectedRowTypeDataStore={selectedRowTypeDataStore}
          rawDataResponse={rawDataResponse}
          featureName={featureName ?? ''}
          setTableView={setTableView}
          schema={schema}
        />
      )}
      {tableView === 'settings-view' && (
        <TableSettingsView
          selectedRowTypeDataStore={selectedRowTypeDataStore}
          setTableView={setTableView}
          schema={schema}
          rawDataResponse={rawDataResponse}
        />
      )}
    </>
  ) : (
    <EmptyWorkflow />
  );
};
