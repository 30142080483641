import React from 'react';
import { useChartDashboardContext } from '../ChartDashboardProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudBinary } from '@fortawesome/pro-solid-svg-icons';
import {
  AreaWidgetOptions,
  ChartType,
  HorizontalBarWidgetOptions,
  IFrameWidgetOptions,
  LineWidgetOptions,
  PieWidgetOptions,
  RankWidgetOptions,
  SimpleBarWidgetOptions,
  StackedBarWidgetOptions,
  StackedHorizontalBarWidgetOptions,
  StatisticsWidgetOptions,
} from '../types/chart-types';
import StatisticsChartOptions from './chartOptions/StatisticsChartOptions';
import AreaChartOptions from './chartOptions/AreaChartOptions';
import HorizontalBarChartOptions from './chartOptions/HorizontalBarChartOptions';
import IFrameChartOptions from './chartOptions/IFrameChartOptions';
import LineChartOptions from './chartOptions/LineChartOptions';
import PieChartOptions from './chartOptions/PieChartOptions';
import StackedBarChartOptions from './chartOptions/StackedBarChartOptions';
import SimpleBarChartOptions from './chartOptions/SimpleBarChartOptions';
import StackedHorizontalBarChartOptions from './chartOptions/StackedHorizontalBarChartOptions';
import RankChartOptions from './chartOptions/RankChartOptions';

export const WidgetBuilderOptions = () => {
  const { widgetData } = useChartDashboardContext();
  return (
    <div className="col-span-1 max-h-[75vh] overflow-y-auto">
      {!widgetData.chartType && (
        <div className="flex flex-col h-full">
          <h4 className="p-4">Data Styling</h4>
          <h4 className="flex flex-col items-center justify-center text-gray-400 grow">
            <FontAwesomeIcon icon={faCloudBinary} size="3x" />
            No Data Available
          </h4>
        </div>
      )}
      {widgetData.chartType === ChartType.Statistics && (
        <StatisticsChartOptions
          widgetData={widgetData as StatisticsWidgetOptions}
        />
      )}
      {widgetData.chartType === ChartType.Pie && (
        <PieChartOptions widgetData={widgetData as PieWidgetOptions} />
      )}
      {widgetData.chartType === ChartType.StackedBar && (
        <StackedBarChartOptions
          widgetData={widgetData as StackedBarWidgetOptions}
        />
      )}
      {widgetData.chartType === ChartType.HorizontalBar && (
        <HorizontalBarChartOptions
          widgetData={widgetData as HorizontalBarWidgetOptions}
        />
      )}
      {widgetData.chartType === ChartType.Line && (
        <LineChartOptions widgetData={widgetData as LineWidgetOptions} />
      )}
      {widgetData.chartType === ChartType.Area && (
        <AreaChartOptions widgetData={widgetData as AreaWidgetOptions} />
      )}
      {widgetData.chartType === ChartType.IFrame && (
        <IFrameChartOptions widgetData={widgetData as IFrameWidgetOptions} />
      )}
      {widgetData.chartType === ChartType.SimpleBar && (
        <SimpleBarChartOptions
          widgetData={widgetData as SimpleBarWidgetOptions}
        />
      )}
      {widgetData.chartType === ChartType.StackedHorizontalBar && (
        <StackedHorizontalBarChartOptions
          widgetData={widgetData as StackedHorizontalBarWidgetOptions}
        />
      )}
      {widgetData.chartType === ChartType.Rank && (
        <RankChartOptions widgetData={widgetData as RankWidgetOptions} />
      )}
    </div>
  );
};
