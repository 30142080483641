import React from 'react';

// tailwind
import { Button, IconButton } from '@tcomponents/ui/button';
import {
  AlertCircle,
  CalendarDays,
  CalendarIcon,
  ChevronsUpDown,
  Italic,
  Terminal,
} from 'lucide-react';
import { Loader2 } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/tcomponents/ui/tooltip';
import { Toggle } from '@/tcomponents/ui/toggle';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/tcomponents/ui/accordion';
import { Alert, AlertDescription, AlertTitle } from '@/tcomponents/ui/alert';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/tcomponents/ui/alert-dialog';
import { Avatar, AvatarFallback, AvatarImage } from '@/tcomponents/ui/avatar';
import { Badge } from '@/tcomponents/ui/badge';
import { Calendar } from '@/tcomponents/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/tcomponents/ui/card';
import { Label } from '@/tcomponents/ui/label';
import { Input } from '@/tcomponents/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/tcomponents/ui/select';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/tcomponents/ui/collapsible';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/tcomponents/ui/dropdown-menu';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/tcomponents/ui/hover-card';
import { ScrollArea } from '@/tcomponents/ui/scroll-area';
import { Separator } from '@/tcomponents/ui/separator';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/tcomponents/ui/sheet';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/tcomponents/ui/tabs';
import { Textarea } from '@/tcomponents/ui/textarea';
import { Switch } from '@/tcomponents/ui/switch';
import { Slider } from '@/tcomponents/ui/slider';
import { Skeleton } from '@/tcomponents/ui/skeleton';
import { RadioGroup, RadioGroupItem } from '@/tcomponents/ui/radio-group';
import { Progress } from '@/tcomponents/ui/progress';
import { Checkbox } from '@/tcomponents/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/tcomponents/ui/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import Segment from '@/components/common/Segment';
import AuthenticatedLayout from '@/layout/AuthenticatedLayout';
import LinkInterface from '../../types/interfaces/LinkInterface';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export default function TailwindShowcase() {
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  const [isOpen, setIsOpen] = React.useState(false);
  const [position, setPosition] = React.useState('bottom');
  const tags = Array.from({ length: 50 }).map(
    (_, i, a) => `v1.2.0-beta.${a.length - i}`
  );

  const breadcrumbElements: Array<LinkInterface> = [
    {
      displayName: 'Home',
      link: '/',
    },
    {
      displayName: 'Projects',
      link: '/demo-auth-my-projects',
    },
  ];

  return (
    <AuthenticatedLayout
      icon="fas fa-ghost"
      title={'Demo Page - My Projects'}
      breadcrumbElements={breadcrumbElements}
    >
      <Segment>
        <Segment>
          <form className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="name">Name</Label>
              <Input id="name" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="email">Email</Label>
              <Input id="email" type="email" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="organisation">Organisation</Label>
              <Input id="organisation" />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="role">Role</Label>
              <Select>
                <SelectTrigger id="role" className="w-[180px]">
                  <SelectValue placeholder="Select Role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectLabel>Roles</SelectLabel>
                    <SelectItem value="user">User</SelectItem>
                    <SelectItem value="manager">Manager</SelectItem>
                    <SelectItem value="admin">Admin</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex items-center my-4 gap-x-2">
              <Label htmlFor="isEnabled">Enabled</Label>
              <Switch id="isEnabled" />
            </div>
          </form>
        </Segment>
        <Segment>
          <h5>Tailwind Button Sizes</h5>
          <div className="flex items-center gap-x-2">
            <Button size={'sm'}>Small</Button>
            <Button size={'default'}>Default</Button>
            <Button size={'lg'}>Large</Button>
            <Button size={'icon'}>Icon</Button>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Button Variants</h5>
          <div className="flex items-center gap-x-2">
            <Button variant={'default'}>Primary</Button>
            <Button variant={'warning'}>Warning</Button>
            <Button variant={'danger'}>Danger</Button>
            <Button variant={'success'}>Success</Button>
            <Button variant={'info'}>Info</Button>
            <Button variant={'dark'}>Secondary</Button>
            <Button variant={'outline'}>Outline</Button>
            <Button variant={'link'}>Link</Button>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Button Other Examples</h5>
          <div className="flex items-center gap-x-2">
            <Button size="icon">
              <FontAwesomeIcon icon={faHome} size="lg" />
            </Button>
            <IconButton icon={faHome} />
            <Button icon={faSave}>Save</Button>
            <Button disabled>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Please wait
            </Button>
            {/* Tooltip example */}
            <TooltipProvider delayDuration={100}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="danger">Hover Me</Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>This can delete files.</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Button
              variant="dark"
              size="default"
              tooltip="This is the tooltip text."
            >
              Button with Tooltip
            </Button>
            <Toggle aria-label="Toggle italic">Default Toggle Button</Toggle>
            <Toggle variant="outline" size="sm" aria-label="Toggle italic">
              Outline Toggle small
            </Toggle>
            <Toggle size="lg" aria-label="Toggle italic">
              <Italic className="w-4 h-4" />
            </Toggle>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Toast Examples</h5>
          <div className="flex items-center gap-x-2">
            <Button
              variant="outline"
              onClick={() => {
                toast({
                  duration: 2000,
                  description: 'Your message has been sent.',
                  onSwipeEnd: action => {
                    // eslint-disable-next-line
                    console.log(action);
                  },
                });
              }}
            >
              Show Toast
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                toast({
                  duration: 24 * 60 * 60000,
                  title: 'Uh oh! Something went wrong.',
                  description: 'There was a problem with your request.',
                });
              }}
            >
              Toast with Title
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                toast({
                  duration: 24 * 60 * 60000,
                  variant: 'destructive',
                  title: 'Uh oh! Something went wrong.',
                  description: 'There was a problem with your request.',
                });
              }}
            >
              Error Toast
            </Button>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Accordion Examples</h5>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger className="text-base font-semibold uppercase">
                Is it accessible?
              </AccordionTrigger>
              <AccordionContent>
                Yes. It adheres to the WAI-ARIA design pattern.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-2">
              <AccordionTrigger className="text-base font-semibold uppercase">
                Is it styled?
              </AccordionTrigger>
              <AccordionContent>
                Yes. It comes with default styles that matches the other
                components&apos; aesthetic.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-3">
              <AccordionTrigger className="text-base font-semibold uppercase">
                Is it animated?
              </AccordionTrigger>
              <AccordionContent>
                Yes. It&apos;s animated by default, but you can disable it if
                you prefer.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </Segment>
        <Segment>
          <h5>Tailwind Alert Examples</h5>
          <div className="flex items-center gap-x-2">
            <Alert>
              <Terminal className="w-4 h-4" />
              <AlertTitle>Heads up!</AlertTitle>
              <AlertDescription>
                You can add components to your app using the cli.
              </AlertDescription>
            </Alert>
            <Alert variant="destructive">
              <AlertCircle className="w-4 h-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                Your session has expired. Please log in again.
              </AlertDescription>
            </Alert>
          </div>
        </Segment>
        <Segment>
          <div className="flex gap-x-8">
            <div>
              <h5>Tailwind Alert Dialog Example</h5>
              <div className="flex items-center gap-x-2">
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="dark">Show Alert Dialog</Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Are you absolutely sure?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently
                        delete your account and remove your data from our
                        servers.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction>Continue</AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </div>
            </div>
            <div>
              <h5>Tailwind Modal/Dialog Example</h5>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline">Edit Profile</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Edit profile</DialogTitle>
                    <DialogDescription>
                      Make changes to your profile here. Click save when you're
                      done.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid gap-4 py-4">
                    <div className="grid items-center grid-cols-4 gap-4">
                      <Label htmlFor="name" className="text-right">
                        Name
                      </Label>
                      <Input
                        id="name"
                        value="Pedro Duarte"
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid items-center grid-cols-4 gap-4">
                      <Label htmlFor="username" className="text-right">
                        Username
                      </Label>
                      <Input
                        id="username"
                        value="@peduarte"
                        className="col-span-3"
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button type="submit">Save changes</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Avatar Example</h5>
          <div className="flex gap-x-4">
            <Avatar>
              <AvatarImage src="https://picsum.photos/id/237/200/300" />
              <AvatarFallback>DG</AvatarFallback>
            </Avatar>
            <Avatar>
              <AvatarImage src="https://github.com/shadcn.png" />
              <AvatarFallback>CN</AvatarFallback>
            </Avatar>
            <Avatar>
              <AvatarImage src="" />
              <AvatarFallback>GS</AvatarFallback>
            </Avatar>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Badge Example</h5>
          <div className="flex gap-x-4">
            <Badge>Default</Badge>
            <Badge variant="secondary">Secondary</Badge>
            <Badge variant="outline">Outline</Badge>
            <Badge variant="destructive">Destructive</Badge>
          </div>
        </Segment>
        <Segment>
          <div className="flex gap-x-8">
            <div>
              <h5>Tailwind Calendar Example</h5>
              <Calendar
                mode="single"
                selected={date}
                onSelect={setDate}
                className="border rounded-md w-fit"
              />
            </div>
            <div>
              <h5>Tailwind Date Picker Example</h5>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={'outline'}
                    className={cn(
                      'w-[280px] justify-start text-left font-normal',
                      !date && 'text-muted-foreground'
                    )}
                  >
                    <CalendarIcon className="w-4 h-4 mr-2" />
                    {date ? format(date, 'PPP') : <span>Pick a date</span>}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={setDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </Segment>
        <Segment>
          <div className="flex gap-x-40">
            <div>
              <h5>Tailwind Card Example</h5>
              <Card className="w-[350px]">
                <CardHeader>
                  <CardTitle>Create project</CardTitle>
                  <CardDescription>
                    Deploy your new project in one-click.
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <form>
                    <div className="grid items-center w-full gap-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="name">Name</Label>
                        <Input id="name" placeholder="Name of your project" />
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="framework">Framework</Label>
                        <Select>
                          <SelectTrigger id="framework">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent position="popper">
                            <SelectItem value="next">Next.js</SelectItem>
                            <SelectItem value="sveltekit">SvelteKit</SelectItem>
                            <SelectItem value="astro">Astro</SelectItem>
                            <SelectItem value="nuxt">Nuxt.js</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </form>
                </CardContent>
                <CardFooter className="flex justify-between">
                  <Button variant="outline">Cancel</Button>
                  <Button>Deploy</Button>
                </CardFooter>
              </Card>
            </div>
            <div>
              <h5>Tailwind Hover Card Example</h5>
              <HoverCard openDelay={200}>
                <HoverCardTrigger asChild>
                  <Button variant="dark">@hover me</Button>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="flex justify-between space-x-4">
                    <Avatar>
                      <AvatarImage src="https://github.com/vercel.png" />
                      <AvatarFallback>VC</AvatarFallback>
                    </Avatar>
                    <div className="space-y-1">
                      <h4 className="text-sm font-semibold">@nextjs</h4>
                      <p className="text-sm">
                        The React Framework – created and maintained by @vercel.
                      </p>
                      <div className="flex items-center pt-2">
                        <CalendarDays className="w-4 h-4 mr-2 opacity-70" />{' '}
                        <span className="text-xs text-muted-foreground">
                          Joined December 2021
                        </span>
                      </div>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </div>
          </div>
        </Segment>
        <div className="flex divide-x-2 gap-x-10">
          <div>
            <h5>Tailwind Collapsible Example</h5>
            <Collapsible
              open={isOpen}
              onOpenChange={setIsOpen}
              className="w-[350px] space-y-2"
            >
              <div className="flex items-center justify-between px-4 space-x-4">
                <h4 className="text-sm font-semibold">
                  @peduarte starred 3 repositories
                </h4>
                <CollapsibleTrigger asChild>
                  <Button variant="ghost" size="sm" className="p-0 w-9">
                    <ChevronsUpDown className="w-4 h-4" />
                    <span className="sr-only">Toggle</span>
                  </Button>
                </CollapsibleTrigger>
              </div>
              <div className="px-4 py-3 font-mono text-sm border rounded-md">
                @radix-ui/primitives
              </div>
              <CollapsibleContent className="space-y-2">
                <div className="px-4 py-3 font-mono text-sm border rounded-md">
                  @radix-ui/colors
                </div>
                <div className="px-4 py-3 font-mono text-sm border rounded-md">
                  @stitches/react
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
          <div className="pl-8">
            <h5>Tailwind Dropdown Example</h5>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Open</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuLabel>Panel Position</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={position}
                  onValueChange={setPosition}
                >
                  <DropdownMenuRadioItem value="top">Top</DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="bottom">
                    Bottom
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="right">
                    Right
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="pl-8">
            <h5>Tailwind Popover Example</h5>
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline">Open popover</Button>
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="grid gap-4">
                  <div className="space-y-2">
                    <h4 className="font-medium leading-none">Dimensions</h4>
                    <p className="text-sm text-muted-foreground">
                      Set the dimensions for the layer.
                    </p>
                  </div>
                  <div className="grid gap-2">
                    <div className="grid items-center grid-cols-3 gap-4">
                      <Label htmlFor="width">Width</Label>
                      <Input
                        id="width"
                        defaultValue="100%"
                        className="h-8 col-span-2"
                      />
                    </div>
                    <div className="grid items-center grid-cols-3 gap-4">
                      <Label htmlFor="height">Height</Label>
                      <Input
                        id="height"
                        defaultValue="25px"
                        className="h-8 col-span-2"
                      />
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <Segment>
          <h5>Tailwind ScrollArea Example</h5>
          <div className="flex gap-x-8">
            <ScrollArea
              scrollHideDelay={200}
              className="border rounded-md w-80 h-72"
            >
              <div className="p-4">
                <h4 className="mb-4 text-sm font-medium leading-none">Tags</h4>
                {tags.map(tag => (
                  <>
                    <div key={tag} className="text-sm">
                      {tag}
                    </div>
                    <Separator className="my-2" />
                  </>
                ))}
              </div>
            </ScrollArea>
            <ScrollArea className="h-[200px] w-[350px] rounded-md border p-4">
              Jokester began sneaking into the castle in the middle of the night
              and leaving jokes all over the place: under the king's pillow, in
              his soup, even in the royal toilet. The king was furious, but he
              couldn't seem to stop Jokester. And then, one day, the people of
              the kingdom discovered that the jokes left by Jokester were so
              funny that they couldn't help but laugh. And once they started
              laughing, they couldn't stop.
            </ScrollArea>
          </div>
        </Segment>
        <Segment>
          <h5>Tailwind Sheet Example</h5>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="outline">Open</Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Edit profile</SheetTitle>
                <SheetDescription>
                  Make changes to your profile here. Click save when you're
                  done.
                </SheetDescription>
              </SheetHeader>
              <div className="grid gap-4 py-4">
                <div className="grid items-center grid-cols-4 gap-4">
                  <Label htmlFor="name" className="text-right">
                    Name
                  </Label>
                  <Input
                    id="name"
                    value="Pedro Duarte"
                    className="col-span-3"
                  />
                </div>
                <div className="grid items-center grid-cols-4 gap-4">
                  <Label htmlFor="username" className="text-right">
                    Username
                  </Label>
                  <Input
                    id="username"
                    value="@peduarte"
                    className="col-span-3"
                  />
                </div>
              </div>
              <SheetFooter>
                <SheetClose asChild>
                  <Button type="submit">Save changes</Button>
                </SheetClose>
              </SheetFooter>
            </SheetContent>
          </Sheet>
        </Segment>
        <Segment>
          <h5>Tailwind Tabs Example</h5>
          <Tabs defaultValue="account" className="w-[400px]">
            <TabsList>
              <TabsTrigger value="account">Account</TabsTrigger>
              <TabsTrigger value="password">Password</TabsTrigger>
            </TabsList>
            <TabsContent value="account">
              Make changes to your account here.
            </TabsContent>
            <TabsContent value="password">
              Change your password here.
            </TabsContent>
          </Tabs>
        </Segment>
        <Segment>
          <h5>Tailwind Others</h5>
          <div className="flex flex-wrap gap-4 divide-x">
            <div className="flex flex-col gap-y-2">
              <h6>Tailwind Textarea Example</h6>
              <Textarea placeholder="Type your message here." />
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Switch Example</h6>
              <div className="flex items-center space-x-2">
                <Switch id="airplane-mode" />
                <Label htmlFor="airplane-mode">Airplane Mode</Label>
              </div>
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Slider Example</h6>
              <Slider defaultValue={[33]} max={100} step={1} />
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Progress Example</h6>
              <Progress value={33} />
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Skeleton Example</h6>
              <Skeleton className="w-[150px] h-[20px] rounded-full" />
              <Skeleton className="w-[150px] h-[20px] rounded-full" />
              <Skeleton className="w-[150px] h-[20px] rounded-full" />
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Seperator Example</h6>
              <div>
                <div className="space-y-1">
                  <h4 className="text-sm font-medium leading-none">
                    Radix Primitives
                  </h4>
                  <p className="text-sm text-muted-foreground">
                    An open-source UI component library.
                  </p>
                </div>
                <Separator className="my-4" />
                <div className="flex items-center h-5 space-x-4 text-sm">
                  <div>Blog</div>
                  <Separator orientation="vertical" />
                  <div>Docs</div>
                  <Separator orientation="vertical" />
                  <div>Source</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Select Example</h6>
              <Select>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a fruit" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup className={'overflow-y-auto max-h-96'}>
                    <SelectLabel>Fruits</SelectLabel>
                    <SelectItem value="apple">Apple</SelectItem>
                    <SelectItem value="banana">Banana</SelectItem>
                    <SelectItem value="blueberry">Blueberry</SelectItem>
                    <SelectItem value="grapes">Grapes</SelectItem>
                    <SelectItem value="pineapple">Pineapple</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Radio Group Example</h6>
              <RadioGroup defaultValue="comfortable">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="default" id="r1" />
                  <Label htmlFor="r1">Default</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="comfortable" id="r2" />
                  <Label htmlFor="r2">Comfortable</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="compact" id="r3" />
                  <Label htmlFor="r3">Compact</Label>
                </div>
              </RadioGroup>
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Checkbox/Label Example</h6>
              <div className="flex items-center space-x-2">
                <Checkbox id="terms" />
                <Label htmlFor="terms">Accept terms and conditions</Label>
              </div>
            </div>
            <div className="flex flex-col pl-4 gap-y-2">
              <h6>Tailwind Input Example</h6>
              <Input type="email" placeholder="Email" />
            </div>
          </div>
        </Segment>
      </Segment>
    </AuthenticatedLayout>
  );
}
