import { createContext, useContext, useMemo } from 'react';
import { useGetProjectAndFeatureDetails } from './hooks/useGetProjectAndFeatureDetails';
import { useGetSurveyConfig } from './hooks/useGetSurveyConfig';
import { defaultSurveyFormState } from './Helpers/FormStates';
import { iFormState } from './Helpers/Types';
import { SurveyFeatureDetails } from '@/types/feature/featureDetails';

interface SurveyProviderProps {
  children: React.ReactNode;
}

const defaultContextValue = {
  projectDetails: null,
  pageTitle: '',
  pageSubTitle: '',
  formContent: defaultSurveyFormState,
  setFormContent: (data: iFormState) => {},
  surveyConfigId: undefined,
  surveyFeatureData: undefined,
};

interface ISurveyContext {
  projectDetails: any;
  pageTitle: string;
  pageSubTitle: string;
  formContent: iFormState;
  setFormContent: (data: iFormState) => void;
  surveyConfigId: number | undefined;
  surveyFeatureData?: SurveyFeatureDetails;
}

const SurveyContext = createContext<ISurveyContext>(defaultContextValue);

const SurveyProvider = (props: SurveyProviderProps) => {
  const {
    projectDetails,
    pageTitle,
    pageSubTitle,
    surveyConfigId,
    surveyFeatureData,
  } = useGetProjectAndFeatureDetails();

  const { formContent, setFormContent } = useGetSurveyConfig(surveyConfigId);

  const context = useMemo(() => {
    return {
      projectDetails,
      pageTitle,
      pageSubTitle,
      formContent,
      setFormContent,
      surveyConfigId,
      surveyFeatureData,
    };
  }, [
    projectDetails,
    pageTitle,
    pageSubTitle,
    formContent,
    setFormContent,
    surveyConfigId,
  ]);

  return (
    <SurveyContext.Provider value={context}>
      {props.children}
    </SurveyContext.Provider>
  );
};
export const useSurveyContext = () => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error('useSurveyContext must be used within a SurveyProvider');
  }
  return context;
};
export default SurveyProvider;
