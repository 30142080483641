import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import Sidebar from './sidebar/Sidebar';
import Topbar from './topbar/Topbar';
import Breadcrumb from '../components/common/Breadcrumb';
import LinkInterface from '../types/interfaces/LinkInterface';
import TabbedNavigationBar from './tab-navigation/TabbedNavigationBar';
import Icon from '../components/common/Icon';
import PageActions from './page-actions/PageActions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidebarDataInterface from '../types/interfaces/SidebarDataInterface';
import PageNotifications from '@/layout/page-notifications/PageNotifications';
import { Sheet, SheetContent, SheetTrigger } from '@/tcomponents/ui/sheet';
import { IconButton } from '@/tcomponents/ui/button';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import ScrollableLayout from './ScrollableLayout';
import ChartDashboardProvider from '@/pages/dashboard/projects/chartDashboard/ChartDashboardProvider';
import FeedbackFormAndGuideButton from '@/components/features/FeedbackFormAndGuideButton';

interface IAuthenticatedLayoutProps {
  children?: ReactNode;
  title: string;
  subTitle?: string;
  titleSize?: 1 | 2 | 3 | 4 | 5;
  logo?: string;
  icon: string;
  iconColour?: string;
  breadcrumbElements: Array<LinkInterface>;
  actions?: ReactNode;
  notifications?: ReactNode;
  tabNavigationElements?: Array<ReactNode>;
  selectedTabIndexChanged?: (index: number) => void;
  tabIndex?: number;
  sidebarData?: SidebarDataInterface;
  fullscreen?: boolean;
}

const AuthenticatedLayout: FC<IAuthenticatedLayoutProps> = ({
  children,
  title,
  subTitle = '',
  titleSize = 3,
  icon,
  logo = '',
  breadcrumbElements,
  actions,
  notifications,
  tabNavigationElements,
  selectedTabIndexChanged = () => {},
  tabIndex = 0,
  sidebarData,
  fullscreen = false,
}) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const { user } = useSelector((state: any) => state);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title + ' | Cavendish Insights';
  }, [title]);

  useEffect(() => {
    if (user?.userData) {
      setAuthenticated(true);
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    selectedTabIndexChanged(tabIndex);
  }, [tabIndex, selectedTabIndexChanged]);

  return (
    <ChartDashboardProvider>
      <div className="flex h-[calc(100dvh)] bg-[#FAFAFB]">
        {authenticated && (
          <Fragment>
            <div className="hidden md:block">
              <Sidebar sidebarData={sidebarData} />
            </div>
            {/* topbar, breadcrumb with content */}
            <ScrollableLayout
              fullscreen={fullscreen}
              navbarChildren={
                <>
                  <Topbar>
                    {/* sheet component is handling mobile sidebar */}
                    <Sheet
                      open={isMobileSidebarOpen}
                      onOpenChange={setIsMobileSidebarOpen}
                    >
                      <SheetTrigger>
                        <IconButton
                          className="p-2 text-xl md:hidden"
                          icon={faBars}
                        />
                      </SheetTrigger>
                      <SheetContent
                        side={'left'}
                        className="p-0 md:hidden w-[90%]"
                      >
                        <Sidebar
                          sidebarData={sidebarData}
                          setIsMobileSidebarOpen={setIsMobileSidebarOpen}
                        />
                      </SheetContent>
                    </Sheet>
                    {/* topbar icon */}
                    <div className="flex items-center ml-2 md:ml-0">
                      {logo.length > 0 && (
                        <img
                          src={logo}
                          alt="Project Logo"
                          width="40"
                          height="40"
                        />
                      )}
                      {icon.length > 0 && (
                        <Icon
                          icon={icon}
                          className={' text-brand text-[22px]'}
                        />
                      )}
                    </div>
                    {/* topbar title, breadcrumb & tabs */}
                    <div className="flex flex-col grow ml-[10px]">
                      <h3 className="m-0 text-[1.2rem] font-bold">
                        {title}
                        {subTitle.length > 0 && (
                          <span className="tall:portrait:hidden sm:wide:hidden text-[#AFAFAF] text-[0.9rem] ml-2">
                            {subTitle}
                          </span>
                        )}
                      </h3>
                      {breadcrumbElements.length ? (
                        <Breadcrumb elements={breadcrumbElements} />
                      ) : null}
                    </div>
                    {/* this is the issue due to position: fixed that was used on mobile, it was causing a strip at the bottom */}
                    {actions && <PageActions>{actions}</PageActions>}
                  </Topbar>
                  {notifications && (
                    <PageNotifications>{notifications}</PageNotifications>
                  )}
                  {tabNavigationElements && (
                    <TabbedNavigationBar
                      elements={tabNavigationElements}
                      selectedTabIndex={tabIndex}
                      onChange={index => selectedTabIndexChanged(index)}
                    />
                  )}
                </>
              }
            >
              {children}
            </ScrollableLayout>
          </Fragment>
        )}
      </div>
      <FeedbackFormAndGuideButton />
    </ChartDashboardProvider>
  );
};

export default AuthenticatedLayout;
