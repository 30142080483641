import { useGetMapShapeQuery } from '@/store/services/mapManagerApi';
import { useState, useEffect } from 'react';
import shp, { FeatureCollectionWithFilename } from 'shpjs';
import { ShapeFileWithDataProp } from './ShapeFileWithDataProp';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { MapFeatureDetails } from '@/types/feature/featureDetails';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import { boundaryTypes } from '../hooks/useHandleBoundaryTab';
import { Choropleth } from './Choropleth';
import { useAggregateListDataQuery } from '@/store/services/aggregationApi';
import { CategoryBoundary } from './CategoryBoundary';
import { useMap } from 'react-leaflet';
import { geoJSON } from 'leaflet';

type BoundaryRenderProps = {
  boundary: MapFeatureDetails['boundaries'][0];
  boundariesVisibility: Record<string, boolean>;
  setEnabledBoundaryShapeFileData: React.Dispatch<
    React.SetStateAction<FeatureCollection | undefined>
  >;
  setBoundariesLoadingState: ({
    id,
    loading,
  }: {
    id: string;
    loading: boolean;
  }) => void;
  isPublicView?: boolean;
  mapShapesResponseFromProps?: any;
  aggregatedDataFromProps?: any;
  haveMarkers: boolean;
};
export type BoundaryData = GeoJsonObject | FeatureCollectionWithFilename;

export const BoundaryRenderer: React.FC<BoundaryRenderProps> = (
  props: BoundaryRenderProps
) => {
  const {
    boundary,
    boundariesVisibility,
    setBoundariesLoadingState,
    setEnabledBoundaryShapeFileData,
    isPublicView,
    mapShapesResponseFromProps,
    aggregatedDataFromProps,
    haveMarkers,
  } = props;
  const [boundaryData, setBoundaryData] = useState<
    FeatureCollection | undefined
  >();
  const map = useMap();
  const boundaryShapeFileId = boundary.shapeFile;
  const { data: boundaryResponseFromApi } = useGetMapShapeQuery(
    Number(boundaryShapeFileId),
    {
      skip:
        !boundariesVisibility[boundary.id] ||
        !boundaryShapeFileId ||
        isPublicView,
    }
  );
  const boundaryResponse = isPublicView
    ? mapShapesResponseFromProps?.filter(
        (item: any) => item.id === Number(boundaryShapeFileId)
      )[0]
    : boundaryResponseFromApi?.data;
  const url = boundaryResponse?.target_url;
  const fileType = boundaryResponse?.file_type;
  const shouldRender = boundariesVisibility[boundary.id] && url && fileType;
  const isSimpleBoundaryType = boundary.type === boundaryTypes.Simple;
  const isHeatmapBoundaryType = boundary.type === boundaryTypes.Heatmap;
  const isCategoryBoundaryType = boundary.type === boundaryTypes.Category;
  const isListTypeDataStore = boundary?.dataStoreType === 'list';

  const dataStoreValuesFrom =
    boundary?.dataStoreHoverKeys?.length > 0
      ? boundary.dataStoreHoverKeys.map(item => item.value)
      : [];
  const valuesFrom = isListTypeDataStore
    ? ['_value']
    : boundary.valuesFrom?.split(',') ?? [];

  const finalValuesFrom = valuesFrom.concat(dataStoreValuesFrom);
  // this logic is only for the heatmap/category boundary type, we need datastore data
  const call = {
    endpoint: 'list',
    requestBody: {
      values_from: finalValuesFrom,
      group_by: '',
      keys_from: isListTypeDataStore ? '_name' : boundary.keysFrom ?? '',
      calculation: isListTypeDataStore
        ? 'lookup'
        : boundary.calculation ?? 'count',
      data_store_id: boundary.dataStore,
      sort_by: 'asc',
    },
  };

  const { data } = useAggregateListDataQuery(
    { data: call?.requestBody, endpoint: call?.endpoint },
    {
      skip:
        !call ||
        !boundary ||
        !boundary.valuesFrom ||
        !boundary.dataStore ||
        (!isHeatmapBoundaryType && !isCategoryBoundaryType) ||
        !boundariesVisibility[boundary.id] ||
        !boundaryShapeFileId ||
        isPublicView,
    }
  );
  const dataStoreData = isPublicView ? aggregatedDataFromProps : data?.data;

  useEffect(() => {
    const fetchBoundaryData = async () => {
      try {
        let data: FeatureCollection | undefined;
        setBoundariesLoadingState({ id: boundary.id, loading: true });
        const { dismiss: dismissToast } = toast({
          description: `Loading ${boundary.title}  this may take a few seconds…`,
        });
        switch (fileType) {
          case 'Shape':
            data = (await shp(url)) as FeatureCollectionWithFilename;
            break;
          case 'GeoJSON':
            const res = await fetch(url);
            data = await res.json();
            break;
          default:
            console.error('Unsupported file type.');
            return;
        }
        dismissToast();
        setBoundaryData(data);
        setBoundariesLoadingState({ id: boundary.id, loading: false });
        // Logic to handle-Zooming into the boundary which has startEnabled true on initial load if there are no markers
        if (data && !haveMarkers && boundary.startEnabled) {
          const boundaryDataGeoJson = geoJSON(data);
          const boundsForStartEnabledBoundary = boundaryDataGeoJson.getBounds();
          map.flyToBounds(boundsForStartEnabledBoundary, {
            duration: 1,
          });
        }
      } catch (err) {
        console.error(err);
        toast({
          title: 'Oh no!',
          variant: 'destructive',
          description: `Failed to fetch file for: ${boundary.title}`,
          duration: 10000,
        });
      }
    };
    if (shouldRender && !boundaryData) {
      fetchBoundaryData();
    }
  }, [boundary, url, fileType, shouldRender]);

  // use effect to set the boundary data in the parent component
  useEffect(() => {
    const anyBoundaryVisible = Object.values(boundariesVisibility).some(
      visibility => visibility
    );

    if (boundaryData && boundariesVisibility[boundary.id]) {
      setEnabledBoundaryShapeFileData(boundaryData);
    } else if (!anyBoundaryVisible) {
      setEnabledBoundaryShapeFileData(undefined);
    }
  }, [
    boundaryData,
    setEnabledBoundaryShapeFileData,
    boundariesVisibility,
    boundary.id,
  ]);

  return (
    <>
      {shouldRender && isSimpleBoundaryType && (
        <ShapeFileWithDataProp
          key={boundary.id}
          data={boundaryData}
          fileType={fileType}
          layer={boundary}
          layerType="boundary"
        />
      )}
      {shouldRender && isHeatmapBoundaryType && (
        <Choropleth
          key={boundary.id}
          shapeFiledata={boundaryData}
          layerData={boundary}
          dataStoreData={dataStoreData}
        />
      )}
      {shouldRender && isCategoryBoundaryType && (
        <CategoryBoundary
          key={boundary.id}
          shapeFiledata={boundaryData}
          layerData={boundary}
          dataStoreData={dataStoreData}
        />
      )}
    </>
  );
};
