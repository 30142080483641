import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

export function useAnalytics() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (
      typeof process.env.REACT_APP_GA_TRACKING_ID !== 'undefined' &&
      process.env.REACT_APP_GA_TRACKING_ID.length > 0
    ) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    }
    setInitialized(true);
  }, []);

  return {
    initialized,
  };
}
