import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LayerFromServer } from '@/pages/dashboard/projects/maps/types/shape';

export const mapManagerApi = createApi({
  reducerPath: 'mapManagerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['mapManager'],
  endpoints: builder => ({
    getAllShapes: builder.query<{ data: LayerFromServer[] }, void>({
      query: () => {
        return {
          url: '/map-shapes',
          method: 'GET',
        };
      },
      keepUnusedDataFor: 0.01,
      providesTags: ['mapManager'],
    }),

    getMapShape: builder.query<any, number>({
      query: id => {
        return {
          url: `map-shapes/${id}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 1,
    }),

    createMapShape: builder.mutation({
      query: (data: FormData) => {
        return {
          url: 'map-shapes',
          method: 'POST',
          body: data,
        };
      },
    }),

    updateMapShape: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `map-shapes/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),

    deleteMapShape: builder.mutation({
      query: (id: string) => {
        return {
          url: `map-shapes/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['mapManager'],
    }),
  }),
});

export const {
  useGetAllShapesQuery,
  useGetMapShapeQuery,
  useDeleteMapShapeMutation,
  useCreateMapShapeMutation,
  useUpdateMapShapeMutation,
} = mapManagerApi;
