import { ModalContext } from '@/context/ModalContext';
import { useDeleteFeatureMutation } from '@/store/services/projects/featureApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { FeatureDetails } from '@/types/feature/featureDetails';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useDeleteFeature = () => {
  let { featureGroupId, projectId } = useParams();
  const { showDialog, hideDialog } = useContext(ModalContext);
  const navigate = useNavigate();
  const [
    deleteFeature,
    { isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError },
  ] = useDeleteFeatureMutation();

  useEffect(() => {
    if (isDeleteSuccess) {
      toast({
        description: 'Feature deleted',
        duration: 3000,
      });
      navigate(`/projects/${projectId}/feature-groups/${featureGroupId}`);
    }
    if (isDeleteError && isApiErrorResponse(deleteError)) {
      hideDialog();
      showDialog({
        title: 'Error while deleting the feature.',
        type: ModalTypes.DANGER,
        message: deleteError.data.message,
        cancel: true,
      });
    }
  }, [isDeleteSuccess, isDeleteError, deleteError]);

  const deleteFeatureWithDialogWarning = (featureDetails: FeatureDetails) => {
    if (!featureDetails) return;
    showDialog({
      title: 'Confirm feature deletion',
      message: `Are you sure you want to delete feature ${featureDetails.name}?`,
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => deleteFeature(featureDetails.id),
      },
    });
  };

  return {
    deleteFeatureWithDialogWarning,
    isDeleteSuccess,
  };
};
