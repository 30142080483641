import { FeatureDetails } from '@/types/feature/featureDetails';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const featureApi = createApi({
  reducerPath: 'featureApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Feature', 'Information'],
  endpoints: builder => ({
    getFeature: builder.query<{ data: FeatureDetails }, number>({
      query: id => {
        return {
          url: '/features/' + id,
          method: 'GET',
        };
      },
      providesTags: ['Feature'],
      keepUnusedDataFor: 1,
    }),
    createOrUpdateFeature: builder.mutation({
      query: (values: object) => {
        return {
          url: '/features',
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: ['Feature', 'Information'],
    }),
    deleteFeature: builder.mutation({
      query: (id: number) => {
        return {
          url: '/features/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Feature'],
    }),
    updateVisibility: builder.mutation({
      query: (values: { id: string | number; visibility: boolean }) => {
        return {
          url: 'features/' + values.id + '/update-visibility',
          method: 'POST',
          body: {
            visibility: values.visibility,
          },
        };
      },
      invalidatesTags: ['Feature'],
    }),
    getFeatureInformation: builder.query({
      query: (id: number) => {
        return {
          url: '/features/' + id + '/information',
          method: 'GET',
        };
      },
      providesTags: ['Information'],
    }),
    createOrUpdateFeatureInformation: builder.mutation({
      query: (values: { id: string | number; markdown: string }) => {
        return {
          url: '/features/' + values.id + '/information',
          method: 'POST',
          body: {
            feature_id: values.id,
            information_text: values.markdown,
          },
        };
      },
      invalidatesTags: ['Information'],
    }),
    createEmbeddedLink: builder.mutation({
      query: ({ featureId }) => {
        return {
          url: '/features/' + featureId + '/create-embedded-link/',
          method: 'PUT',
        };
      },
    }),
    deleteEmbeddedLink: builder.mutation({
      query: ({ featureId, linkId }) => {
        return {
          url: '/features/' + featureId + '/delete-embedded-link/' + linkId,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetFeatureQuery,
  useCreateOrUpdateFeatureMutation,
  useDeleteFeatureMutation,
  useUpdateVisibilityMutation,
  useGetFeatureInformationQuery,
  useCreateOrUpdateFeatureInformationMutation,
  useCreateEmbeddedLinkMutation,
  useDeleteEmbeddedLinkMutation,
} = featureApi;
