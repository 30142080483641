import { CellComponent, RowComponent, TabulatorFull } from 'tabulator-tables';

export type TrackerStats = {
  added: { [key: string]: any }[];
  deleted: { [key: string]: any }[];
  changed: { [key: string]: any }[];
  fields: { [key: string]: any }[];
  order: { [key: string]: any }[] | [];
};

export class Tracker {
  el: HTMLElement | null;
  table: TabulatorFull;
  trackFields: boolean;
  stats: TrackerStats;
  setTrackerData: React.Dispatch<
    React.SetStateAction<TrackerStats | undefined>
  >;

  constructor(
    table: TabulatorFull,
    id: string,
    trackFields: boolean,
    setTrackerData: React.Dispatch<
      React.SetStateAction<TrackerStats | undefined>
    >
  ) {
    this.el = document.getElementById(id);
    this.table = table;
    // this trackFields flag is used to check if we want to track field in the table, in data view we don't want to track field but in settings view we want to track field
    this.trackFields = trackFields;
    this.setTrackerData = setTrackerData;

    this.stats = {
      added: [],
      deleted: [],
      changed: [],
      fields: [],
      order: [],
    };

    this.initializeListeners();
  }

  initializeListeners(): void {
    this.table.on('cellEdited', this.edit.bind(this));
    this.table.on('rowAdded', this.add.bind(this));
    this.table.on('rowDeleted', this.delete.bind(this));
    this.table.on('rowMoved', () => {
      this.recalc();
    });

    this.recalc();
  }

  recalc(): void {
    const statsData = this.report();
    this.setTrackerData(statsData);
    if (!this.el) return;
    this.el.innerHTML = `
      <strong>Rows Added:</strong> ${this.stats.added.length}, 
      <strong>Rows Deleted:</strong> ${this.stats.deleted.length}, 
      <strong>Rows Changed:</strong> ${this.stats.changed.length}, 
      <strong>Fields Changed:</strong> ${this.stats.fields.length}, 
    `;
  }

  reset(): void {
    for (let key in this.stats) {
      this.stats[key as keyof typeof this.stats] = [];
    }

    this.recalc();
  }

  edit(cell: CellComponent): void {
    const row = cell.getRow();
    const col = cell.getColumn();

    if (
      !this.stats.changed.some(item => item === row) &&
      !this.stats.added.includes(row)
    ) {
      this.stats.changed.push(row);
    }

    if (
      this.trackFields &&
      col.getField() === 'field' &&
      cell.getOldValue() !== cell.getValue()
    ) {
      if (!this.stats.fields.some(item => item === row)) {
        this.stats.fields.push(row);
      }
    } else {
      this.stats.fields = this.stats.fields.filter(item => item !== row);
    }

    this.recalc();
  }

  add(row: RowComponent): void {
    this.stats.added.push(row);

    this.recalc();
  }

  delete(row: RowComponent): void {
    const added = this.stats.added.some(item => item === row);

    if (!added) {
      this.stats.deleted.push(row);
    }

    this.stats.added = this.stats.added.filter(item => item !== row);
    this.stats.changed = this.stats.changed.filter(item => item !== row);
    this.stats.fields = this.stats.fields.filter(item => item !== row);

    this.recalc();
  }
  deleteAll(oldRows: any[]): void {
    oldRows.forEach(row => {
      this.delete(row);
    });
  }

  report(): TrackerStats {
    const report = {
      added: this.stats.added.map(row => row.getData('data')),
      deleted: this.stats.deleted.map(row => row.getData('data')),
      changed: this.stats.changed.map(row => row.getData('data')),
      // if trackFields is false then we don't want to worry about order which is the case in data view but in setting we can drag and drop the rows so we want to track the order, so sending the order array to the backend
      order: this.trackFields ? this.table.getData() : [],
      fields: this.stats.fields.map(row => {
        const cell = row.getCell('field');

        return {
          from: cell.getInitialValue(),
          to: cell.getValue(),
        };
      }),
    };

    return report;
  }

  import(): void {
    const rows = this.table.getRows();
    rows.forEach(row => {
      this.add(row);
    });
  }
}
