import { Label } from '@/tcomponents/ui/label';
import { Textarea } from '@/tcomponents/ui/textarea';
import { GalleryInfo, Info } from '../Helpers/Types';
import { Button, IconButton } from '@/tcomponents/ui/button';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CustomImageField from './CustomImageField';
import { useSurveyContext } from '../SurveyProvider';
import { useUploadSurveyAssetMutation } from '@/store/services/projects/surveyApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import { ChangeEvent } from 'react';
import { v4 as uuid } from 'uuid';

type GalleryInfoProps = {
  info: GalleryInfo;
  updateStep: (info: Info) => void;
};

export const GalleryInfoCard = (props: GalleryInfoProps) => {
  const { info, updateStep } = props;
  const { surveyConfigId } = useSurveyContext();
  const [uploadSurveyAsset] = useUploadSurveyAssetMutation();

  const addImage = () => {
    const newImages = [...info.images, { image: '', caption: '' }];
    const newInfo = {
      ...info,
      images: newImages,
    };
    updateStep(newInfo);
  };

  const deleteImage = (index: number) => {
    const newImages = info.images.filter((_, i) => i !== index);
    const newInfo = {
      ...info,
      images: newImages,
    };
    updateStep(newInfo);
  };

  const handleImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    file: Blob | string,
    index?: number
  ) => {
    e.preventDefault();
    let formBody = new FormData();
    formBody.append('id', surveyConfigId?.toString() ?? '');
    formBody.append('type', `gallery-${info.id}-image-${index}-${uuid()}`);
    formBody.append('asset', file);

    uploadSurveyAsset(formBody)
      .unwrap()
      .then(response => {
        toast({
          duration: 3000,
          description: 'Uploaded the image',
        });
        const newImages = info.images.map((img, i) => {
          if (i === index) {
            return {
              ...img,
              image: response.path,
            };
          }
          return img;
        });
        const newInfo = {
          ...info,
          images: newImages,
        };
        updateStep(newInfo);
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh!',
          description: 'Could not upload the image',
        });
        const newImages = info.images.map((img, i) => {
          if (i === index) {
            return {
              ...img,
              image: '',
            };
          }
          return img;
        });
        const newInfo = {
          ...info,
          images: newImages,
        };
        updateStep(newInfo);
      });
  };

  const handleImageRemove = (index: number) => {
    const newImages = info.images.map((img, i) => {
      if (i === index) {
        return {
          ...img,
          image: '',
        };
      }
      return img;
    });
    const newInfo = {
      ...info,
      images: newImages,
    };
    updateStep(newInfo);
  };

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-y-1">
        <Label>Description</Label>
        <Textarea
          value={info.description}
          onChange={e => {
            const newInfo = {
              ...info,
              description: e.target.value,
            };
            updateStep(newInfo);
          }}
        />
      </div>
      <div className="flex flex-col gap-y-4">
        <div className="flex py-2 border-b">
          <Label className="w-1/3">Image</Label>
          <Label className="ml-3">Caption</Label>
        </div>
        {info.images.map((item, index) => (
          <div key={index} className="flex items-center pb-4 border-b gap-x-4">
            <div className="w-1/3">
              <CustomImageField
                image={item.image ?? ''}
                handleImageUpload={e => {
                  if (e.target?.files?.length) {
                    handleImageUpload(e, e.target?.files[0], index);
                  }
                }}
                handleImageRemove={() => handleImageRemove(index)}
                index={index}
                className="w-[300px] h-[150px]"
                maxFileSize={1}
              />
            </div>
            <div className="grow">
              <Textarea
                value={item.caption}
                rows={8}
                onChange={e => {
                  const newImages = info.images.map((img, i) => {
                    if (i === index) {
                      return {
                        ...img,
                        caption: e.target.value,
                      };
                    }
                    return img;
                  });
                  const newInfo = {
                    ...info,
                    images: newImages,
                  };
                  updateStep(newInfo);
                }}
              />
            </div>
            <IconButton
              tabIndex={-1}
              className="p-2 text-2xl text-white rounded-lg bg-destructive fa-2xl"
              icon={faXmark}
              onClick={() => deleteImage(index)}
              tooltip="Delete Asnwer"
              testingAttribute="delete-answer"
              disabled={info.images.length === 1}
            />
          </div>
        ))}
      </div>
      <Button className="w-full mt-0.5 bg-info" onClick={addImage}>
        + Add Image
      </Button>
    </div>
  );
};
