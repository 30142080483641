import L from 'leaflet';
// Custom cluster icon
export const createCustomClusterIcon = (cluster: any) => {
  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className:
      'bg-white border-2 border-brand rounded-full text-center text-sm font-bold flex items-center justify-center text-gray-900',
    iconSize: L.point(40, 40), // Set the size of the icon
  });
};
