import React, { useState, useLayoutEffect } from 'react';
import spinnerImage from '@/assets/images/spinner.png';
import appLogo from '@/assets/images/logo.png';

const loadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

const AppSplashLoading = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useLayoutEffect(() => {
    Promise.all([loadImage(appLogo), loadImage(spinnerImage)])
      .then(() => setImagesLoaded(true))
      .catch(err => console.error(`Failed to load images: ${err}`));
  }, []);

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center py-[80px] bg-white">
      {imagesLoaded && (
        <div className="flex flex-col h-full gap-y-5 w-[250px]">
          <img src={appLogo} alt="App Logo" loading="eager" />
          <div className="flex flex-col items-center m-auto space-y-6 text-center">
            <img
              className="text-gray-50 animate-spin-slow"
              src={spinnerImage}
              alt="Loading spinner"
              loading="eager"
            />
            <p className="font-bold text-[22px] text-primary">Loading...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppSplashLoading;
