import ProtectedRoute from '@/routes/ProtectedRoute';
import { surveyPermissions } from '@/stringConsts/permissionConsts';
import React, { lazy } from 'react';

const SurveyList = lazy(() => import('../../pages/survey/SurveyManager'));

const SurveyView = lazy(() => import('../../pages/survey/ViewSurvey'));

const SurveyNew = lazy(() => import('../../pages/survey/NewSurvey'));

export const surveyPackageRoutes = [
  {
    element: (
      <ProtectedRoute
        permissions={[surveyPermissions.system_administration.manage_packages]}
      />
    ),
    children: [
      {
        path: '/surveys',
        element: <SurveyList />,
      },
      {
        path: '/surveys/new',
        element: <SurveyNew />,
      },
      {
        path: '/surveys/:id',
        element: <SurveyView />,
      },
    ],
  },
];
