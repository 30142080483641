import { Label } from '@/tcomponents/ui/label';
import { Switch } from '@/tcomponents/ui/switch';
import { SketchPicker } from 'react-color';
import { StatisticsWidgetOptions } from '../../types/chart-types';
import { defaultColors, useColorPicker } from '../../hooks/useColorPicker';
import { cn } from '@/lib/utils';
import { useChartDashboardContext } from '../../ChartDashboardProvider';
type StatisticsChartOptionsProps = {
  widgetData: StatisticsWidgetOptions;
};

export default function StatisticsChartOptions(
  props: StatisticsChartOptionsProps
) {
  const { widgetData } = props;
  const { singleWidgetData, updateWidgetData, isReadyToShow } =
    useChartDashboardContext();
  const {
    showColorPicker,
    selectedColorIndex,
    colors,
    handleColorClick,
    handleColorChange,
    handleColorPickerClose,
  } = useColorPicker({
    initialColors: [...widgetData.dataStyling, ...defaultColors] ?? [],
    updateWidgetData,
  });

  const isDataAnArray = Array.isArray(singleWidgetData);

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col p-3 gap-y-4">
        <h4>Chart Options</h4>
        <div className="flex flex-col">
          <Label> Show Keys</Label>
          <Switch
            checked={widgetData.chartOptions?.showKeys ?? false}
            onCheckedChange={val =>
              updateWidgetData({
                chartOptions: {
                  showKeys: val,
                },
              })
            }
            className="mt-1"
          />
        </div>
      </div>
      <div className="flex flex-col divide-y">
        <h4 className="p-3">Chart Styling</h4>
        {isReadyToShow &&
          isDataAnArray &&
          singleWidgetData.length > 0 &&
          singleWidgetData.map((data: any, index: number, arr) => (
            <div
              className={cn('flex items-center justify-between p-2', {
                'bg-gray-100': index % 2 !== 0,
                '!border-b': index === arr.length - 1,
              })}
              key={`color-${index}`}
            >
              <span>{data._name}</span>
              <span
                onClick={() => handleColorClick(index)}
                className={`w-8 h-8 rounded-md cursor-pointer`}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></span>
            </div>
          ))}
        {showColorPicker && (
          <div className="absolute z-10">
            <div
              className="fixed inset-0 bg-white opacity-20"
              onClick={handleColorPickerClose}
            ></div>
            <div className="inset-0">
              <SketchPicker
                width="200px"
                color={colors[selectedColorIndex]}
                onChange={handleColorChange}
                presetColors={[...defaultColors, 'transparent']}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
