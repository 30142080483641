import { CustomNode } from '@/pages/dashboard/projects/graph/nodes/index';
import { Handle, Node, Position } from 'reactflow';
import React from 'react';
import { ReceiverNodeData } from '@/pages/dashboard/projects/graph/types';

const ReceiverNode = ({ data, ...rest }: Node<ReceiverNodeData>) => {
  return (
    <CustomNode data={data} nodeProps={rest}>
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{
          background: '#fff',
          border: '1px solid gray',
          width: '8px',
          height: '8px',
        }}
      />
    </CustomNode>
  );
};

export default ReceiverNode;
