import FeatureView from '@/pages/dashboard/features/FeatureView';
import MapContainer from '@/pages/dashboard/projects/maps/MapContainer';
import FeatureDetails from '@/pages/dashboard/features/FeatureDetails';
import FeatureGroup from '@/pages/dashboard/projects/partials/FeatureGroup';
import { SurveyContainer } from '@/pages/survey/SurveyContainer';
import React, { lazy } from 'react';
import ProtectedRoute from '@/routes/ProtectedRoute';
import { workflowPermissions } from '@/stringConsts/permissionConsts';

const WorkflowHealth = lazy(
  () => import('../../pages/dashboard/workflow-health/View')
);

export const featuresRoutes = [
  {
    path: 'projects/:projectId/feature-groups/:featureGroupId/:featureId/:featureType/view',
    element: <FeatureView />,
  },
  {
    path: 'projects/:projectId/feature-groups/:featureGroupId/:featureId/map',
    element: <MapContainer />,
  },
  {
    path: 'projects/:projectId/feature-groups/:featureGroupId/:featureId/:featureType',
    element: <FeatureDetails />,
  },

  // feature groups
  {
    path: 'projects/:projectId/feature-groups/new',
    element: <FeatureGroup />,
  },
  {
    path: 'projects/:projectId/feature-groups/:featureGroupId',
    element: <FeatureGroup />,
  },
  {
    path: 'projects/:projectId/feature-groups/:featureGroupId/:featureId/survey/view',
    element: <SurveyContainer />,
  },
  {
    element: (
      <ProtectedRoute
        permissions={[workflowPermissions.system_administration.monitor]}
      />
    ),
    children: [{ path: 'workflow-health', element: <WorkflowHealth /> }],
  },
];
