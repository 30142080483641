import React from 'react';
import CenteredContainer from '../CenteredContainer';

interface LoadingSpinnerProps {}

export const LoadingSpinner: React.FunctionComponent<
  LoadingSpinnerProps
> = () => {
  return (
    <CenteredContainer>
      <div className="ui">
        <div className="ui active inverted dimmer">
          <div className="ui big text loader">Loading</div>
        </div>
        <p></p>
      </div>
    </CenteredContainer>
  );
};
