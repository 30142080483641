import { Button } from '@/tcomponents/ui/button';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

export default function PageNotAvailableOnMobile() {
  return (
    <div className="flex flex-col items-center justify-center m-auto md:hidden text-primary gap-y-6 w-[200px] text-center">
      <i className="fas fa-desktop text-[120px] text-[#6FA3C2]"></i>
      <h3 className=" text-[22px] text-[#518DB1]">Page Unvailable</h3>
      <p className=" text-[16px] text-[#6FA3C2]">
        Please try visiting the page in desktop browser
      </p>
      <Link to="/">
        <Button icon={faArrowLeft}>Go Back</Button>
      </Link>
    </div>
  );
}
