import React, { useContext } from 'react';
import { useChartDashboardContext } from './ChartDashboardProvider';
import { Input } from '@/tcomponents/ui/input';
import { Dialog, DialogContent, DialogTrigger } from '@/tcomponents/ui/dialog';
import { Button } from '@/tcomponents/ui/button';
import ChartDialogContent from './chartDialog/ChartDialogContent';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NavigationContext } from '@/context/NavigationContext';

export const ChartDashboardTop = () => {
  const { isPublicView } = useContext(NavigationContext);
  const { columnTitles, handleColumnTitlesChange, dialogOpen, setDialogOpen } =
    useChartDashboardContext();
  return (
    <div>
      {isPublicView ? (
        <div
          className={`flex items-center ${
            !columnTitles.left && !columnTitles.right ? 'hidden' : ''
          }`}
        >
          {columnTitles.left && columnTitles.right ? (
            <div className="flex justify-between w-full">
              <h3 className="flex-1 text-[18px]">{columnTitles.left}</h3>
              <h3 className="flex-1 text-[18px]">{columnTitles.right}</h3>
            </div>
          ) : (
            <h3 className="mx-auto text-[18px]">
              {columnTitles.left || columnTitles.right}
            </h3>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between grow gap-x-5">
            <div className="flex justify-between flex-1">
              <Input
                className="max-w-[300px] bg-white "
                placeholder="Enter optional column title"
                name="left"
                value={columnTitles.left}
                onChange={handleColumnTitlesChange}
              />
            </div>
            <div className="flex items-center justify-between flex-1 gap-x-2">
              <Input
                className="max-w-[300px] bg-white"
                placeholder="Enter optional column title"
                name="right"
                value={columnTitles.right}
                onChange={handleColumnTitlesChange}
              />

              <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                <DialogTrigger asChild>
                  <Button icon={faPlus}>Add Widget</Button>
                </DialogTrigger>
                <DialogContent
                  closeButton={false}
                  className="min-h-[80vh] min-w-[80vw] bg-card gap-0 p-0"
                >
                  <ChartDialogContent />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
