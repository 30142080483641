import { TrackerStats } from '@/pages/dashboard/projects/graph/lib/tabulatorTracker';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Schema } from '@/pages/dashboard/projects/graph/components/TableSettingsView';
import { chartApi } from '@/store/services/projects/chartApi';

type UpdateDataStoreSchemaArgs = {
  dataStoreId: string;
  processUpdates: boolean;
  enforceSchema: boolean;
  data: TrackerStats;
};

export const graphApi = createApi({
  reducerPath: 'graphApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Graph', 'RowTypeDataStores', 'DataFromDataStoreId'],
  endpoints: builder => ({
    getGraph: builder.query({
      query: (featureId: number) => {
        return {
          url: '/features/' + featureId + '/graph',
          method: 'GET',
        };
      },
    }),
    saveGraph: builder.mutation({
      query: ({ featureId, values }) => {
        return {
          url: '/features/' + featureId + '/graph',
          method: 'POST',
          body: values,
        };
      },
      invalidatesTags: ['Graph'],
    }),
    uploadSpreadsheet: builder.mutation({
      query: obj => {
        return {
          url: `/graph/upload`,
          method: 'POST',
          body: obj,
          formData: true,
        };
      },
    }),
    nodeExists: builder.query({
      query: (nodeUuid: string) => {
        return {
          url: `/graph/${nodeUuid}`,
          method: 'GET',
        };
      },
    }),
    deleteNodeData: builder.mutation({
      query: (nodeUuid: string) => {
        return {
          url: `/graph/${nodeUuid}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(chartApi.util.invalidateTags(['RawDataFromDataStore']));
      },
      invalidatesTags: ['DataFromDataStoreId'],
    }),
    resetNodeOffset: builder.mutation({
      query: (nodeUuid: string) => {
        return {
          url: `/graph/${nodeUuid}/reset-offset`,
          method: 'GET',
        };
      },
    }),
    getRowTypeDataStores: builder.query<
      [{ title: string; uuid: string; schema: Schema }],
      number
    >({
      query: (featureId: number) => {
        return {
          url: `/features/${featureId}/row-type-stores`,
          method: 'GET',
        };
      },
      providesTags: ['RowTypeDataStores'],
      keepUnusedDataFor: 0,
    }),
    updateDataStore: builder.mutation({
      query: ({
        dataStoreId,
        data,
        lastUpdated,
        bypass,
      }: {
        dataStoreId: string;
        data: TrackerStats;
        lastUpdated: string;
        bypass: boolean;
      }) => {
        return {
          url: `/data-stores`,
          method: 'POST',
          body: {
            data_store_id: dataStoreId,
            last_updated: lastUpdated,
            data,
            bypass,
          },
        };
      },
      invalidatesTags: ['RowTypeDataStores', 'DataFromDataStoreId'],
    }),
    updateDataStoreSchema: builder.mutation({
      query: ({
        dataStoreId,
        processUpdates,
        enforceSchema,
        data,
      }: UpdateDataStoreSchemaArgs) => {
        return {
          url: '/data-stores/schema',
          method: 'POST',
          body: {
            data_store_id: dataStoreId,
            process_updates: processUpdates,
            enforce_schema: enforceSchema,
            data,
          },
        };
      },
      invalidatesTags: ['RowTypeDataStores', 'DataFromDataStoreId'],
    }),
    getNodeData: builder.query({
      query: ({
        nodeId,
        type = 'none',
      }: {
        nodeId: string | number;
        type: string;
      }) => {
        return {
          url: `/graph/data/${nodeId}/${type}`,
          method: 'GET',
        };
      },
    }),
    triggerNode: builder.query({
      query: (nodeId: string) => {
        return {
          url: `/graph/trigger/${nodeId}`,
          method: 'GET',
        };
      },
    }),
    triggerProcessNode: builder.query({
      query: (nodeId: string) => {
        return {
          url: `/graph/trigger/process/${nodeId}`,
          method: 'GET',
        };
      },
    }),
    getFromattedDataFromDataStore: builder.query({
      query: nodeUuid => {
        return {
          url: 'graph/data/formatted/' + nodeUuid,
          method: 'GET',
        };
      },
      providesTags: ['DataFromDataStoreId'],
    }),
  }),
});

export const {
  useGetGraphQuery,
  useSaveGraphMutation,
  useUploadSpreadsheetMutation,
  useNodeExistsQuery,
  useDeleteNodeDataMutation,
  useResetNodeOffsetMutation,
  useGetRowTypeDataStoresQuery,
  useUpdateDataStoreMutation,
  useUpdateDataStoreSchemaMutation,
  useLazyGetNodeDataQuery,
  useLazyTriggerNodeQuery,
  useLazyTriggerProcessNodeQuery,
  useGetFromattedDataFromDataStoreQuery,
} = graphApi;
