import { Organisation } from '@/pages/dashboard/organisations/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const organisationApi = createApi({
  reducerPath: 'organisationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, _) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Organisation', 'Organisations'],
  endpoints: builder => ({
    createOrganisation: builder.mutation<
      { data: Organisation },
      { name: string; description?: string }
    >({
      query: ({ name, description }) => ({
        url: '/organisations',
        method: 'POST',
        body: { name, description },
      }),
    }),
    updateOrganisation: builder.mutation<
      void,
      { id: number; name: string; description?: string }
    >({
      query: ({ id, name, description }) => ({
        url: `/organisations/${id}`,
        method: 'PUT',
        body: { name, description },
      }),
      invalidatesTags: ['Organisation', 'Organisations'],
    }),
    getOrganisation: builder.query<{ data: Organisation }, number>({
      query: (id: number) => ({
        url: `/organisations/${id}`,
        method: 'GET',
      }),
      providesTags: ['Organisation'],
    }),
    getAllOrganisations: builder.query<{ data: Organisation[] }, void>({
      query: () => ({
        url: '/organisations',
        method: 'GET',
      }),
      providesTags: ['Organisations'],
    }),
    deleteOrganisation: builder.mutation({
      query: (id: number) => ({
        url: `/organisations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Organisations'],
    }),
  }),
});

export const {
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
  useGetOrganisationQuery,
  useDeleteOrganisationMutation,
  useGetAllOrganisationsQuery,
} = organisationApi;
