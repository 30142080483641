import React, { FC, ReactNode } from 'react';

interface ITopbarProps {
  children: ReactNode;
}

const Topbar: FC<ITopbarProps> = ({ children }) => {
  return (
    <div className="flex items-center justify-center bg-white border-b py-[10px] px-[20px]  md:px-[40px] 2xl:px-[90px] max-w-full">
      {children}
    </div>
  );
};

export default Topbar;
