import { ParagraphQuestion, Question } from '../Helpers/Types';
import { Input } from '@/tcomponents/ui/input';
import { Label } from '@/tcomponents/ui/label';

interface ParagraphQueProps {
  question: ParagraphQuestion;
  updateQuestion: (data: Question) => void;
}
export const ParagraphQue = (props: ParagraphQueProps) => {
  const { question: questionData, updateQuestion } = props;
  return (
    <div>
      <Label>Max Characters</Label>
      <Input
        className="w-[100px]"
        type="number"
        min={0}
        value={questionData.max_characters}
        onChange={e => {
          updateQuestion({
            ...questionData,
            max_characters: parseInt(e.target.value),
          });
        }}
      />
    </div>
  );
};
