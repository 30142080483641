import React, { FC, ReactNode } from 'react';

interface ITabbedNavigationBarElementProps {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  name?: string;
}
const TabbedNavigationBarElement: FC<ITabbedNavigationBarElementProps> = ({
  children,
  onClick = () => {},
  disabled = false,
  name = '',
}) => {
  return <div onClick={onClick}>{children}</div>;
};

export default TabbedNavigationBarElement;
