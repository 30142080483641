import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@/lib/utils';

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  checkedLabel?: string;
  unCheckedLabel?: string;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, checkedLabel, unCheckedLabel, ...props }, ref) => (
  <div className="flex items-center">
    <SwitchPrimitives.Root
      className={cn(
        'peer inline-flex h-[1.4rem] w-[2.8rem] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input',
        className
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
        )}
      />
    </SwitchPrimitives.Root>
    {checkedLabel && props.checked && (
      <span
        className={cn(
          ' ml-2 text-sm font-medium text-gray-700 pointer-events-none mt-1',
          props.checked ? 'opacity-100' : 'opacity-0'
        )}
      >
        {checkedLabel}
      </span>
    )}
    {unCheckedLabel && !props.checked && (
      <span
        className={cn(
          'ml-2 text-sm font-medium text-gray-700 pointer-events-none mt-1',
          props.checked ? 'opacity-0' : 'opacity-100'
        )}
      >
        {unCheckedLabel}
      </span>
    )}
  </div>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
