import { Alert, AlertDescription, AlertTitle } from '@/tcomponents/ui/alert';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CanvasNodeErrorsProps = {
  nodes: Array<any>;
};

export default function CanvasNodeErrors(props: CanvasNodeErrorsProps) {
  const errorNodes = props.nodes.filter(function (node: any) {
    return (
      typeof node.data.error_message !== 'undefined' &&
      node.data.error_message !== null &&
      node.data.error_message !== ''
    );
  });

  return (
    <>
      {errorNodes.length > 0 ? (
        <div className="flex flex-col items-center border-b gap-x-2">
          <>
            {errorNodes.map(function (node: any) {
              return (
                <>
                  <Alert className="flex items-center p-3 rounded-none bg-danger-background">
                    <div className="mr-2">
                      <FontAwesomeIcon
                        className="w-10 h-10 mr-5 text-danger-error"
                        icon={faCircleExclamation}
                        size="lg"
                      />
                    </div>
                    <div>
                      <AlertTitle className="m-0 text-lg font-bold text-[18px] text-danger-foreground">
                        Workflow Error - {node.data.heading}
                      </AlertTitle>
                      <AlertDescription className="overflow-auto text-base break-all text-danger-foreground max-h-12">
                        {node.data.error_title}
                      </AlertDescription>
                    </div>
                  </Alert>
                </>
              );
            })}
          </>
        </div>
      ) : null}
    </>
  );
}
