import React, { useState, useEffect } from 'react';
import AuthenticatedLayout from '@/layout/AuthenticatedLayout';
import { Button } from '@tcomponents/ui/button';

import SidebarTypes from '@/types/enums/common/SidebarTypes';
import { useNavigate, useParams } from 'react-router-dom';
import LinkInterface from '@/types/interfaces/LinkInterface';
import TabbedNavigationBarElement from '@/layout/tab-navigation/TabbedNavigationBarElement';
import { Prompt } from '@/utils/use-prompt';
import { useSaveActionContext } from '@/context/SaveActionContext';

import useAuth from '@/hooks/UseAuth';
import { useMapSettingsContext } from './MapSettingsProvider';
import DetailsTab from './components/DetailsTab';
import { MarkersTab } from './components/MarkersTab';
import InformationTab from '../common/InformationTab';
import { faEye, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { mapFormStateToServer } from './helpers/formStates';
import { useUpdateFeatureVisibility } from '../../features/featureHooks/useUpdateFeatureVisibility';
import { useDeleteFeature } from '../../features/featureHooks/useDeleteFeature';
import { useCreateOrUpdateFeature } from '../../features/featureHooks/useCreateOrUpdateFeature';
import OverlaysTab from './components/OverlaysTab';
import { BoundariesTab } from './components/BoundariesTab';
import { featurePermissions } from '@/stringConsts/permissionConsts';
import { faWindow } from '@fortawesome/pro-solid-svg-icons';
import { Alert, AlertDescription, AlertTitle } from '@tcomponents/ui/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetFeatureGroup } from '../../features/featureHooks/useGetFeatureGroup';

const MapSettings: React.FC = () => {
  let { featureId, featureGroupId, featureType } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const { setIsDirty, isDirty } = useSaveActionContext();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const {
    projectDetails,
    featureDetails,
    pageTitle,
    pageSubTitle,
    formContent,
    errors,
    setErrors,
  } = useMapSettingsContext();
  const { createFeature, updateFeature, isStoringError, isStoringSuccess } =
    useCreateOrUpdateFeature({
      projectDetails: projectDetails,
      featureSubType: 'Map',
    });
  const { deleteFeatureWithDialogWarning, isDeleteSuccess } =
    useDeleteFeature();

  const { updateVisibilityWithDialogWarning, handleEmbeddableLinks } =
    useUpdateFeatureVisibility();

  const { featureGroupDetails } = useGetFeatureGroup();

  Prompt({
    message: 'Are you sure you want to leave without saving?',
    when: isDirty && !isStoringSuccess && !isStoringError && !isDeleteSuccess,
  });

  const breadcrumbElements: Array<LinkInterface> = [
    {
      displayName: 'Projects',
      link: '/projects',
    },
    {
      displayName: projectDetails?.name ?? '',
      link: `/projects/${projectDetails?.id}/settings`,
    },
    {
      displayName: featureGroupDetails?.name ?? '',
      link: `/projects/${projectDetails?.id}/feature-groups/${featureGroupDetails?.id}`,
    },
  ];

  const renderTab = () => {
    switch (tabIndex) {
      case 0:
        return <DetailsTab />;
      case 1:
        return <MarkersTab />;
      case 2:
        return <OverlaysTab />;
      case 3:
        return <BoundariesTab />;
      case 4:
        return <InformationTab id={Number(featureId)} />;

      default:
        return <DetailsTab />;
    }
  };

  const getTabbedNavigationBarElements = () => {
    return [
      <TabbedNavigationBarElement>Details</TabbedNavigationBarElement>,
      <TabbedNavigationBarElement disabled={featureId === 'new'}>
        Markers
      </TabbedNavigationBarElement>,
      <TabbedNavigationBarElement disabled={featureId === 'new'}>
        Overlays
      </TabbedNavigationBarElement>,
      <TabbedNavigationBarElement disabled={featureId === 'new'}>
        Boundaries
      </TabbedNavigationBarElement>,
      <TabbedNavigationBarElement disabled={featureId === 'new'}>
        Information
      </TabbedNavigationBarElement>,
    ];
  };

  // Reset tab index to 0 (setupTab) when featureId changes
  useEffect(() => {
    setTabIndex(0);
    setIsDirty(false);
  }, [featureId]);

  const canSave = () => {
    return isDirty;
  };

  const createOrUpdateFeature = () => {
    // name validation before proceeding
    if (formContent.details.name === '') {
      setErrors({ ...errors, nameError: 'Name is required' });
      return;
    }
    setErrors({ ...errors, nameError: '' });
    const formDataForServer = mapFormStateToServer(formContent);
    const obj = {
      ...formDataForServer,
      feature_group_id: featureGroupId,
      feature_subtype: 'Map',
      feature_subtype_fe: 'map',
      feature_type: 'Display',
    };
    featureId !== 'new'
      ? updateFeature(Number(featureId), obj)
      : createFeature(obj);
    setIsDirty(false);
  };

  return (
    <AuthenticatedLayout
      fullscreen={featureType?.toLowerCase() === 'map'}
      breadcrumbElements={breadcrumbElements}
      logo={
        typeof projectDetails?.logo_url !== 'undefined'
          ? projectDetails.logo_url
          : process.env.REACT_APP_DEFAULT_PROJECT_LOGO
      }
      icon=""
      title={pageTitle}
      subTitle={pageSubTitle}
      actions={
        hasPermission(featurePermissions.general.settings_access) && (
          <div style={{ display: 'inherit', alignItems: 'center' }}>
            {featureId !== 'new' &&
              featureDetails &&
              hasPermission(featurePermissions.general.delete) && (
                <Button
                  onClick={() => deleteFeatureWithDialogWarning(featureDetails)}
                  icon={faTrash}
                  variant={'danger'}
                  className="mr-2"
                  data-testing="feature-delete-button"
                >
                  Delete
                </Button>
              )}
            {featureDetails &&
              ['Map'].includes(featureDetails.feature_subtype) &&
              hasPermission(featurePermissions.general.settings_access) && (
                <Button
                  variant={'warning'}
                  icon={faWindow}
                  className="mr-2"
                  onClick={() => handleEmbeddableLinks(featureDetails)}
                >
                  {featureDetails.embeddable_links.length > 0
                    ? 'Remove'
                    : 'Embed'}{' '}
                  Link
                </Button>
              )}
            {featureDetails &&
              ['Dashboard', 'Ranked List', 'Table', 'Map'].includes(
                featureDetails.feature_subtype
              ) && (
                <Button
                  variant={'warning'}
                  icon={faEye}
                  className="mr-2"
                  onClick={() =>
                    updateVisibilityWithDialogWarning(featureDetails)
                  }
                  data-testing={
                    featureDetails.public
                      ? 'feature-unpublish-button'
                      : 'feature-publish-button'
                  }
                >
                  {featureDetails.public ? 'Unpublish' : 'Publish'}
                </Button>
              )}
            {hasPermission(featurePermissions.general.create) && (
              <Button
                disabled={
                  !canSave() ||
                  !hasPermission(featurePermissions.general.create)
                }
                icon={faSave}
                onClick={() => {
                  createOrUpdateFeature();
                }}
                data-testing={
                  featureId !== 'new'
                    ? 'feature-save-button'
                    : 'feature-create-button'
                }
              >
                {featureId !== 'new' ? 'Save' : 'Create'}
              </Button>
            )}
          </div>
        )
      }
      notifications={
        featureDetails &&
        featureDetails.embeddable_links &&
        featureDetails.embeddable_links.length > 0 ? (
          <Alert className="flex items-center p-3 rounded-none bg-warning-background">
            <div className="flex items-center justify-center py-[5px] px-[30px] md:px-[80px]">
              <div className="mr-2">
                <FontAwesomeIcon
                  className="w-[34px] h-[34px] mr-1 text-warning-error"
                  icon={faWindow}
                  size="lg"
                />
              </div>
              <div>
                <AlertTitle className="m-0 text-lg font-bold text-[18px] text-warning-text">
                  Embedded Map
                </AlertTitle>
                <AlertDescription className="overflow-hidden text-base text-warning-text max-h-10 text-ellipsis">
                  This map has a public URL that can be used to include it in
                  other websites{' '}
                  <a
                    className="font-bold underline"
                    href={
                      process.env.REACT_APP_PUBLIC_DATA_URL +
                      'map/' +
                      featureDetails.embeddable_links[0].uuid
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {process.env.REACT_APP_PUBLIC_DATA_URL +
                      'map/' +
                      featureDetails.embeddable_links[0].uuid}
                  </a>
                </AlertDescription>
              </div>
            </div>
          </Alert>
        ) : null
      }
      sidebarData={
        projectDetails
          ? {
              type: SidebarTypes.PROJECT,
              data: projectDetails,
              backButtonCallback: () => navigate('/projects'),
            }
          : {
              type: SidebarTypes.PROJECT,
              data: {},
              backButtonCallback: () => navigate('/projects'),
            }
      }
      tabIndex={tabIndex}
      selectedTabIndexChanged={setTabIndex}
      tabNavigationElements={getTabbedNavigationBarElements()}
    >
      {renderTab()}
    </AuthenticatedLayout>
  );
};

export default MapSettings;
