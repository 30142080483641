import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function deepEqual(
  obj1: object,
  obj2: object,
  ignoreKeys: string[] = []
) {
  // Check if the objects have the same type
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // If both objects are primitive types or null, compare them directly
  if (obj1 === obj2) {
    return true;
  }

  // If either object is null, they are not equal
  if (obj1 === null || obj2 === null) {
    return false;
  }

  // If both objects are arrays, compare their elements recursively
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i], ignoreKeys)) {
        return false;
      }
    }

    return true;
  }

  // If both objects are objects, compare their properties recursively
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1).filter(key => !ignoreKeys.includes(key));
    const keys2 = Object.keys(obj2).filter(key => !ignoreKeys.includes(key));

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (
        !keys2.includes(key) ||
        // @ts-ignore
        !deepEqual(obj1[key], obj2[key], ignoreKeys)
      ) {
        return false;
      }
    }

    return true;
  }

  // If none of the above conditions are met, the objects are not equal
  return false;
}

export const generateRandomString = (length: number): string => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
};

export const handlePrependHttps = (value: string): string => {
  value = value.trim();

  if (value === '' || value.startsWith('http')) {
    return value;
  }

  return `https://${value}`;
};
