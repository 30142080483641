import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { IMarkerData } from '../types/types';
import { renderStringWithLinks } from '@/pages/dashboard/projects/maps/utils/stringWithLinks';

function customMarkerIcon(color: string) {
  const svgTemplate = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
          <path fill-opacity=".25" d="M16 32s1.427-9.585 3.761-12.025c4.595-4.805 8.685-.99 8.685-.99s4.044 3.964-.526 8.743C25.514 30.245 16 32 16 32z"/>
          <path fill="${color}" stroke="#fff" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16 6a4 4 0 100 8 4 4 0 000-8z"/>
        </svg>`;

  return new L.DivIcon({
    className: 'test',
    html: svgTemplate,
    iconSize: [40, 40],
    iconAnchor: [12, 24],
    popupAnchor: [7, -16],
  });
}

const Markers = ({ data }: { data?: IMarkerData[] }) => {
  const visibleMarkers = data?.filter(item => item.visible) || [];

  return (
    <>
      {visibleMarkers.map((item, index: number) => (
        <Marker
          key={index}
          icon={customMarkerIcon(item?.color as string)}
          position={{ lat: item?.lat, lng: item?.lng }}
        >
          {(Object.entries(item.displayedFields).length >= 1 ||
            item.includeValueField) && (
            <Popup>
              <div className="flex flex-col gap-y-1">
                {item.includeValueField && (
                  <div className="flex flex-col">
                    <p className="!m-0 font-bold">{item?.quetsionTitle}</p>
                    <p className="!m-0">
                      {renderStringWithLinks(item?.markerAnswer)}
                    </p>
                  </div>
                )}
                {item?.displayedFields &&
                  Object.entries(item.displayedFields).map(
                    ([key, val], idx) => (
                      <div key={key} className="flex flex-col">
                        {/* only works if val is a string, will break on {} or [] */}
                        {/* {key}: {val} */}
                        <p className="!m-0 font-bold">{key}</p>
                        <p className="!m-0">{renderStringWithLinks(val)}</p>
                      </div>
                    )
                  )}
              </div>
            </Popup>
          )}
        </Marker>
      ))}
    </>
  );
};

export default Markers;
