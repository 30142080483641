import { cloneDeep } from 'lodash';
import { RowComponent, Tabulator } from 'tabulator-tables';

const columnTemplate = {
  title: '',
  field: '',
  type: 'input',
  editor: 'input',
  personal: false,
};

export const generateSchemaFromData = (
  data: any[],
  tabulatorRef: React.MutableRefObject<Tabulator | undefined>
) => {
  const fields: { [key: string]: string } = {};
  const columns: any[] = [];

  // detect all fields in data
  data.forEach(row => {
    for (let key in row) {
      let value = row[key];

      if (!fields[key]) {
        switch (typeof value) {
          case 'number':
            fields[key] = 'number';
            break;
          case 'boolean':
            fields[key] = 'toggle';
            break;
          case 'string':
            fields[key] = value.length > 100 ? 'textarea' : 'input';
            break;
          default:
            fields[key] = 'input';
            break;
        }
      }
    }
  });

  // build column definitions
  for (let key in fields) {
    if (key === '__id') continue;
    var type = fields[key];
    var column = cloneDeep(columnTemplate);
    column.title = key;
    column.field = key;
    column.type = type;
    column.editor = type;
    columns.push(column);
  }
  const tableRows = tabulatorRef.current?.getRows();
  tableRows?.forEach((row: RowComponent) => {
    row.delete();
  });
  columns?.forEach(column => {
    tabulatorRef.current?.addRow(column);
  });
};
