import SimplePieChart from '../charts/PieChart';
import HorizontalBarChart from '../charts/HorizontalBarChart';
import SimpleLineChart from '../charts/LineChart';
import SimpleAreaChart from '../charts/AreaChart';
import IFrameChart from '../charts/IFrameChart';
import {
  AreaWidgetOptions,
  BaseWidgetOptions,
  ChartType,
  HorizontalBarWidgetOptions,
  IFrameWidgetOptions,
  LineWidgetOptions,
  PieWidgetOptions,
  RankWidgetOptions,
  SimpleBarWidgetOptions,
  StackedBarWidgetOptions,
  StackedHorizontalBarWidgetOptions,
  StatisticsWidgetOptions,
} from '../types/chart-types';
import StatisticsChart from '../charts/StatisticsChart';
import StackedBarChart from '../charts/StackedBarChart';
import SimpleBarChart from '../charts/SimpleBarChart';
import StackedHorizontalBarChart from '../charts/StackedHorizontalBarChart';
import RankChart from '../charts/RankChart';

export const getChartComponent = (
  widget: BaseWidgetOptions,
  chartData: any
) => {
  switch (widget.chartType) {
    case ChartType.Statistics:
      return (
        <StatisticsChart
          widgetData={widget as StatisticsWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.Pie:
      return (
        <SimplePieChart
          widgetData={widget as PieWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.StackedBar:
      return (
        <StackedBarChart
          widgetData={widget as StackedBarWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.HorizontalBar:
      return (
        <HorizontalBarChart
          widgetData={widget as HorizontalBarWidgetOptions}
          renderedFromDashboard={true}
          chartData={chartData}
        />
      );
    case ChartType.Line:
      return (
        <SimpleLineChart
          widgetData={widget as LineWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.Area:
      return (
        <SimpleAreaChart
          widgetData={widget as AreaWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.IFrame:
      return <IFrameChart widgetData={widget as IFrameWidgetOptions} />;
    case ChartType.SimpleBar:
      return (
        <SimpleBarChart
          widgetData={widget as SimpleBarWidgetOptions}
          chartData={chartData}
        />
      );
    case ChartType.StackedHorizontalBar:
      return (
        <StackedHorizontalBarChart
          widgetData={widget as StackedHorizontalBarWidgetOptions}
          renderedFromDashboard={true}
          chartData={chartData}
        />
      );
    case ChartType.Rank:
      return (
        <RankChart
          widgetData={widget as RankWidgetOptions}
          chartData={chartData}
          randeredFromDashboard={true}
        />
      );
    default:
      return null;
  }
};
