import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function ErrorData() {
  return (
    <div className="flex flex-col items-center justify-center h-full text-danger-error gap-y-1">
      <FontAwesomeIcon icon={faTriangleExclamation} size="2xl" />
      <h6>Unable To Load Data</h6>
    </div>
  );
}
