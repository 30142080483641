import { useContext } from 'react';
import { clear } from '../../store/features/users/userSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useLogoutMutation } from '../../store/services/users/authApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faGears,
  faRightFromBracket,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import { Button, IconButton } from '@/tcomponents/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/tcomponents/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';

const Profile = () => {
  const { user } = useAppSelector((state: any) => state);
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useContext(ModalContext);

  const logoutHandler = async () => {
    navigate('/login');
    dispatch(clear());
    await logout({});
    hideDialog();
  };

  const showLogoutModal = () => {
    showDialog({
      title: 'Are you sure?',
      message: `Are you sure you want to log out of Cavendish Insights?`,
      type: ModalTypes.DEFAULT,
      cancel: true,
      ok: {
        cb: () => logoutHandler(),
      },
    });
  };

  return (
    <div
      className="hidden py-3 border-y border-sidebar-user md:block"
      id="user-profile"
    >
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faUserCircle}
          className="w-8 h-8 text-sidebar-user"
        />
        <p className="flex-1 text-white pl-[8px]">{user.userData?.name}</p>
        <Popover>
          <PopoverTrigger>
            <IconButton
              icon={faEllipsisVertical}
              tooltip="Actions"
              className="mr-2 text-white"
            />
          </PopoverTrigger>
          <PopoverContent side="top" className="p-0 w-[175px]">
            <PopoverClose className="flex flex-col w-full divide-y divide-gray-100">
              <Button
                icon={faGears}
                onClick={() => navigate('/user/profile')}
                variant="ghost"
                className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                aria-label="user settings"
              >
                User Settings
              </Button>
              <Button
                icon={faRightFromBracket}
                onClick={() => showLogoutModal()}
                variant="ghost"
                className="font-normal text-[14px] w-full text-gray-800 items-center justify-start px-4"
                aria-label="log out"
              >
                Logout
              </Button>
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export default Profile;
