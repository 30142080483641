import { faCloudBinary } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function NoData() {
  return (
    <div className="flex flex-col items-center justify-center h-full text-gray-400 gap-y-1">
      <FontAwesomeIcon icon={faCloudBinary} size="2xl" />
      <h6>No Data Available</h6>
    </div>
  );
}
