import React, { useEffect, useImperativeHandle } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { boolean, coerce, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@tcomponents/ui/form';
import { Input } from '@tcomponents/ui/input';
import { Textarea } from '@tcomponents/ui/textarea';
import { Switch } from '@tcomponents/ui/switch';
import { Card, CardContent, CardHeader, CardTitle } from '@tcomponents/ui/card';
import { SaveRefHandle } from '@/hooks/useSaveTabs';
import { useSaveActionContext } from '@/context/SaveActionContext';

interface IDashboardFormProps {
  onSubmitHandler: SubmitHandler<any>;
  data: any;
}

const formSchema = object({
  name: string().min(1, 'Name is required'),
  description: string().optional(),
  header_info: string().optional(),
  icon: string().min(1, 'Icon is required'),
  refresh: coerce.number().min(0, 'The number should not be less than 0'),
  allowFullscreen: boolean(),
  showSources: boolean(),
});

export type FormInput = TypeOf<typeof formSchema>;

const DashboardForm = React.forwardRef<SaveRefHandle, IDashboardFormProps>(
  (props, ref) => {
    const form = useForm<FormInput>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: '',
        description: '',
        header_info: '',
        icon: 'fas fa-chart-bar',
        refresh: 0,
        allowFullscreen: true,
        showSources: true,
      },
    });
    const {
      reset,
      handleSubmit,
      formState: { errors, isDirty },
    } = form;

    const { setIsDirty } = useSaveActionContext();

    useEffect(() => {
      if (typeof props.data !== 'undefined') {
        let obj = {
          ...props.data,
          ...{ header_info: props.data.header_info ?? '' },
          ...{ description: props.data.description ?? '' },
        };
        reset(obj);
      }
    }, [props.data, reset]);

    useEffect(() => {
      setIsDirty(isDirty);
    }, [isDirty, setIsDirty]);

    const handleFormattedSubmit = (formInput: FormInput) => {
      const obj = {
        name: formInput.name,
        header_info: formInput.header_info ?? '',
        description: formInput.description ?? '',
        icon: formInput.icon,
        config: {
          refresh: formInput.refresh,
          allowFullscreen: formInput.allowFullscreen,
          showSources: formInput.showSources,
        },
      };
      props.onSubmitHandler(obj);
    };

    useImperativeHandle(ref, () => {
      return {
        save: handleSubmit(handleFormattedSubmit),
      };
    });

    return (
      <Form {...form}>
        <form onSubmit={handleSubmit(handleFormattedSubmit)}>
          <div className="grid grid-cols-2 space-x-1.5">
            <Card>
              <CardHeader>
                <CardTitle>Feature Details</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex items-center justify-between">
                          <FormLabel required>Name</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.name} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="header_info"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel>Header Info</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input {...field} error={errors.header_info} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel>Description</FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Textarea {...field} error={errors.description} />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="icon"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel required>Icon</FormLabel>
                          <FormMessage />
                        </div>
                        <div className="flex items-center">
                          <i
                            className={
                              field.value +
                              ' w-10 h-10 border rounded-l-md flex items-center justify-center bg-gray-500 text-white text-xl'
                            }
                          ></i>
                          <FormControl>
                            <Input
                              {...field}
                              error={errors.icon}
                              className="rounded-l-none "
                            />
                          </FormControl>
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Dashboard Setup</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="refresh"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex justify-between">
                          <FormLabel required>
                            Refresh interval (minutes)
                          </FormLabel>
                          <FormMessage />
                        </div>
                        <FormControl>
                          <Input
                            {...field}
                            error={errors.refresh}
                            className="max-w-[50px]"
                            type="number"
                          />
                        </FormControl>
                        <FormField
                          control={form.control}
                          name="allowFullscreen"
                          render={({ field }) => (
                            <FormItem className="flex flex-col">
                              <FormLabel className="text-base">
                                Allow Fullscreen
                              </FormLabel>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  // @ts-ignore
                                  onCheckedChange={field.onChange}
                                  checkedLabel="Yes"
                                  unCheckedLabel="No"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="showSources"
                          render={({ field }) => (
                            <FormItem className="flex flex-col">
                              <FormLabel className="text-base">
                                Show Sources
                              </FormLabel>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  // @ts-ignore
                                  onCheckedChange={field.onChange}
                                  checkedLabel="Yes"
                                  unCheckedLabel="No"
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </form>
      </Form>
    );
  }
);

export default DashboardForm;
