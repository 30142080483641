import { useContext } from 'react';
import Icon from '@/components/common/Icon';
import { clear } from '@/store/features/users/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useLogoutMutation } from '@/store/services/users/authApi';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import { useNavigate } from 'react-router-dom';

export default function LogoutMobile() {
  const { user } = useAppSelector((state: any) => state);
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showDialog, hideDialog } = useContext(ModalContext);

  const logoutHandler = async () => {
    await logout({});
    hideDialog();
    dispatch(clear());
    navigate('/');
  };

  const showLogoutModal = () => {
    showDialog({
      title: 'Are you sure?',
      message: `Are you sure you want to log out of Cavendish Insights?`,
      type: ModalTypes.DEFAULT,
      cancel: true,
      ok: {
        cb: () => logoutHandler(),
      },
    });
  };

  return (
    <div className="md:hidden">
      <h5 className=" text-[16px] text-sidebar-icon font-bold mb-3">
        {user?.userData?.name}
      </h5>
      <div className="w-full py-3 text-white border-y border-sidebar-user ">
        <div
          onClick={() => {
            showLogoutModal();
          }}
          className="flex items-center ml-4 gap-x-2 hover:cursor-pointer"
        >
          <Icon
            className=" text-sidebar-icon"
            icon={'fas fa-right-from-bracket'}
          />
          <h5 className=" text-[16px] font-normal">Logout</h5>
        </div>
      </div>
    </div>
  );
}
