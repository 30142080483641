import React, { useContext, useEffect } from 'react';
import { StorageNodeData, CustomNodeData } from '../../types/node-types';
import { toast } from '@tcomponents/ui/toast/use-toast';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@tcomponents/ui/select';
import listSampleFormat from '../../static/list-sample-format.js';
import groupedListSampleFormat from '../../static/grouped-list-sample-format.js';
import rowSampleFormat from '../../static/row-sample-format.js';
import { useParams } from 'react-router-dom';
import { useGetFeatureQuery } from '@/store/services/projects/featureApi';
import { Node } from 'reactflow';
import { faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button, buttonVariants } from '@tcomponents/ui/button';
import { useCustomGraphContext } from '../../CustomGraphProvider';
import ReactGA from 'react-ga4';
import { useGetProjectSettingsQuery } from '@/store/services/projects/projectApi';
import { Input } from '@tcomponents/ui/input';
import ModalTypes from '@/types/enums/modal/ModalTypes';
import { ModalContext } from '@/context/ModalContext';
import {
  useDeleteNodeDataMutation,
  useGetGraphQuery,
  useLazyGetNodeDataQuery,
} from '@/store/services/projects/graphApi';
import { useGetDataFromDataStoreQuery } from '@/store/services/projects/rankedListApi';
import { cn } from '@/lib/utils';

export function StorageNodeFormFields(props: {
  subGraphParentNode?: Node<CustomNodeData>;
}) {
  const { updateNodeData, activeNode, activePipelineNodeId } =
    useCustomGraphContext();

  const { featureId, projectId } = useParams();
  const feature = useGetFeatureQuery(Number(featureId), {
    skip: !featureId || projectId === 'new',
  });
  const project = useGetProjectSettingsQuery(Number(projectId), {
    skip: !projectId,
  });

  const { data: graphDataFromServer } = useGetGraphQuery(Number(featureId), {
    skip: !featureId,
  });

  const subGraphObjectFromServer = graphDataFromServer?.data?.subGraphs;
  const subGraphExist = !!subGraphObjectFromServer[activePipelineNodeId];

  const [getNodeData] = useLazyGetNodeDataQuery();
  const { showDialog } = useContext(ModalContext);

  const { format } = activeNode.data as StorageNodeData;
  let { behaviour, indexFields } = activeNode.data as StorageNodeData;

  if (typeof behaviour === 'undefined') {
    behaviour = 'replace';
  }

  if (typeof indexFields === 'undefined') {
    indexFields = ['id'];
  }

  const handleFieldChange = (
    field: string,
    val: string | number | boolean | string[]
  ) => {
    const dataCopy = { ...activeNode.data };
    dataCopy[field] = val;

    // Reset the index field when the behaviour has changed to anything other than update
    if (field === 'behaviour' && val !== 'update') {
      dataCopy.indexField = 'id';
    }

    updateNodeData(dataCopy);
  };

  const downloadData = async () => {
    if (!activeNode.id) return;
    ReactGA.event('workflow_data_download', {
      storage_name: props.subGraphParentNode?.data.heading,
      workflow_name: feature?.data?.data.name,
      project_name: project?.data?.data.name,
    });

    getNodeData({
      nodeId: activeNode.id,
      type: 'download',
    })
      .then((response: any) => {
        if (response.isSuccess) {
          const filename =
            feature?.data?.data?.name +
            ' - ' +
            props.subGraphParentNode?.data.heading +
            '.json';
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(() => {
        toast({
          duration: 24 * 60 * 60000,
          className: 'bg-red-500',
          variant: 'destructive',
          title: 'Uh oh! Something went wrong',
          description: 'Unable to fetch node data.',
        });
      });
  };

  const renderSamples = () => {
    switch (format) {
      case 'unformatted':
        return <></>;
      case 'row':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{rowSampleFormat}</pre>
          </div>
        );
      case 'list':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{listSampleFormat}</pre>
          </div>
        );
      case 'list-grouped':
        return (
          <div className="p-1 text-gray-600 bg-[#ECECEC]">
            <pre className="whitespace-pre-wrap">{groupedListSampleFormat}</pre>
          </div>
        );
    }
  };

  const handleAddIndex = () => {
    let indexFields = activeNode.data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields, ''];
    } else {
      indexFields = [indexFields, ''];
    }

    handleFieldChange('indexFields', indexFields);
  };

  const updateIndexField = (index: number, value: string) => {
    let indexFields = activeNode.data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields];
    } else {
      indexFields = [indexFields];
    }

    indexFields[index] = value;

    handleFieldChange('indexFields', indexFields);
  };

  const removeIndexField = (index: number) => {
    let indexFields = activeNode.data.indexFields;

    if (Array.isArray(indexFields)) {
      indexFields = [...indexFields];
    } else {
      indexFields = [indexFields];
    }

    indexFields.splice(indexFields.indexOf(index), 1);
    handleFieldChange('indexFields', indexFields);
  };

  const showIndexFieldInput = () => {
    if (behaviour === 'update') {
      return (
        <div className="flex flex-col flex-1 gap-y-2">
          <label className="text-xs font-bold pt-2 mb-0 text-[#333333DE]">
            Index Field
          </label>
          {indexFields.map((indexField, idx) => {
            return (
              <div className="flex flex-row gap-x-2">
                <Input
                  key={idx}
                  value={indexField}
                  onChange={e => updateIndexField(idx, e.target.value)}
                  className="flex flex-1 w-[230px]"
                  type={'text'}
                />
                {idx !== 0 && (
                  <button
                    className="bg-red-500 py-1.5 px-3 hover:bg-red-600 text-white font-bold text-sm rounded-sm mt-1.5"
                    onClick={() => removeIndexField(idx)}
                  >
                    X
                  </button>
                )}
              </div>
            );
          })}
          <button
            className={
              cn(buttonVariants({ variant: 'info', size: 'default' })) +
              'p-2 font-bold text-center text-white rounded-sm cursor-pointer'
            }
            onClick={handleAddIndex}
            data-testing="new_param"
            aria-label="new param"
          >
            + New Index
          </button>
        </div>
      );
    }

    return <></>;
  };

  const showNodeWipeModal = () => {
    showDialog({
      title: 'Confirm data deletion',
      message:
        'Are you sure you want to wipe all the data in this node, this cannot be undone?',
      type: ModalTypes.WARNING,
      cancel: true,
      ok: {
        cb: () => callDeleteNodeDataRequest(activeNode.id),
      },
    });
  };

  const { refetch } = useGetDataFromDataStoreQuery(activeNode.id as string, {
    skip: !activeNode.id || !subGraphExist,
    refetchOnMountOrArgChange: true,
  });

  const [deleteNodeData, { isSuccess, isError }] = useDeleteNodeDataMutation();

  useEffect(() => {
    if (isSuccess) {
      toast({
        description: 'Storage successfully wiped',
        duration: 3000,
      });
      refetch();
    }
    if (isError) {
      showDialog({
        title: 'Uh oh! Something went wrong',
        type: ModalTypes.DANGER,
        message: 'Error while clearing node data',
        cancel: true,
      });
    }
  }, [isSuccess, isError]);

  const callDeleteNodeDataRequest = async (id: string) => {
    await deleteNodeData(id);
  };

  return (
    <div>
      <div className="flex flex-col divide-y-[1px] gap-2 border p-2 text-sm shadow-md m-1 bg-white">
        <div className="flex items-center justify-between">
          <h4 className="font-bold ml-1 text-[15px]">Data Storage</h4>
          <Button
            variant="danger"
            size="sm"
            onClick={showNodeWipeModal}
            icon={faTrashCan}
          >
            Wipe
          </Button>
        </div>
        <div>
          <Button
            className="w-full py-4 mt-2"
            onClick={downloadData}
            icon={faSave}
          >
            Download Data
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2 p-2 m-1 text-sm bg-white border shadow-md">
        <h4 className="font-bold ml-1 text-[15px]">Data Processing</h4>
        <p className={'text-xs font-bold pt-2 mb-0 text-[#333333DE]'}>
          New Data Behaviour
        </p>
        <Select
          value={behaviour}
          onValueChange={val => handleFieldChange('behaviour', val)}
        >
          <SelectTrigger className="w-[230px] !border-b-[1px]">
            <SelectValue placeholder="Replace" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup className={'overflow-y-auto max-h-96'}>
              <SelectItem value="replace">Replace</SelectItem>
              <SelectItem value="append">Append</SelectItem>
              <SelectItem value="update">Update</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        {showIndexFieldInput()}
      </div>

      <div className="flex flex-col divide-y-[1px] gap-2 border p-2 text-sm shadow-md m-1 bg-white">
        <h4 className="font-bold ml-1 text-[15px]">Data Structure</h4>
        <p className="text-xs font-bold pt-2 mb-0 text-[#333333DE]">Format</p>
        <Select
          value={format}
          onValueChange={val => handleFieldChange('format', val)}
        >
          <SelectTrigger className="w-[230px] !border-b-[1px]">
            <SelectValue placeholder="Unformatted" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup className={'overflow-y-auto max-h-96'}>
              <SelectItem value="unformatted">Unformatted</SelectItem>
              <SelectItem value="row">Row</SelectItem>
              <SelectItem value="list">List</SelectItem>
              <SelectItem value="list-grouped">Grouped List</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        {renderSamples()}
      </div>
    </div>
  );
}
