import React, { useEffect, useImperativeHandle } from 'react';
import WidgetContainer from './WidgetContainer';
import FeatureSourceBar from '../common/FeatureSourceBar';
import { cn } from '@/lib/utils';
import { SaveRefHandle } from '@/hooks/useSaveTabs';
import { useChartDashboardContext } from './ChartDashboardProvider';
import { ChartDashboardTop } from './ChartDashboardTop';
import ShowEmptyMessage from '@/tcomponents/custom/ShowEmptyMessage';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import useAuth from '@/hooks/UseAuth';
import { featurePermissions } from '@/stringConsts/permissionConsts';
import { useNavigationContext } from '@/context/NavigationContext';

interface IChartDashboardProps {
  id: string | number;
  project?: any;
  feature?: any;
  className?: string;
}

const ChartDashboard = React.forwardRef<SaveRefHandle, IChartDashboardProps>(
  (props, ref) => {
    const { project, feature } = props;
    const {
      handleSaveDashboard,
      setSelectedWidgetId,
      hasSourcesBar,
      sources,
      information,
      dialogOpen,
      isDashboardConfigFetching,
      widgets,
    } = useChartDashboardContext();

    const { isPublicView } = useNavigationContext();

    const { hasPermission } = useAuth();

    useImperativeHandle(
      ref,
      () => {
        return {
          save: handleSaveDashboard,
        };
      },
      [handleSaveDashboard]
    );

    useEffect(() => {
      if (!dialogOpen) {
        setSelectedWidgetId(null);
      }
    }, [dialogOpen]);

    return !isDashboardConfigFetching ? (
      <>
        {widgets.length ? (
          <div className={cn('w-full', hasSourcesBar ? 'pb-20' : '')}>
            {/* top input columns and add widget button section */}
            {hasPermission(featurePermissions.general.settings_access) && (
              <ChartDashboardTop />
            )}
            {/* Widget section */}
            <WidgetContainer />
          </div>
        ) : (
          <div className="flex flex-col h-full m-auto gap-y-5">
            {hasPermission(featurePermissions.general.settings_access) && (
              <ChartDashboardTop />
            )}
            {isPublicView && (
              <ShowEmptyMessage
                icon={faBoxOpen}
                title="Empty Dashboard!"
                message="Looks like this dashboard is empty"
              />
            )}
          </div>
        )}
        {/* feature source bar section */}
        <FeatureSourceBar
          project={project}
          feature={feature}
          sources={sources}
          information={information}
        />
      </>
    ) : null;
  }
);

export default ChartDashboard;
