import { useGetProjectSettingsQuery } from '@/store/services/projects/projectApi';
import { toast } from '@/tcomponents/ui/toast/use-toast';
import ProjectResponseInterface from '@/types/interfaces/api-response/ProjectResponseInterface';
import { isApiErrorResponse } from '@/utils/isApiErrorResponse';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useGetProjectSettings = () => {
  let { projectId } = useParams();
  const [projectDetails, setProjectDetails] =
    useState<ProjectResponseInterface>();

  const navigate = useNavigate();

  const {
    data,
    isSuccess,
    isError,
    error,
    refetch: projectRefetch,
  } = useGetProjectSettingsQuery(Number(projectId), {
    skip: !projectId,
  });

  // useEffect for fetching project details
  useEffect(() => {
    if (projectId && isSuccess) {
      setProjectDetails(data?.data);
    }
    if (projectId && isError) {
      if (isApiErrorResponse(error) && error.status !== 403) {
        toast({
          title: 'Uh oh! Something went wrong',
          variant: 'destructive',
          description: 'There was a problem loading project details',
          duration: 24 * 60 * 60000,
        });

        navigate('/not-found');
      } else {
        navigate('/unauthorized');
      }
    }
  }, [projectId, data?.data, isSuccess, isError]);

  return {
    projectDetails,
    projectRefetch,
  };
};
