import { createErrorHandler } from '@/store/utils/createErrorHandler';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const featureGroupApi = createApi({
  reducerPath: 'featureGroupApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['FeatureGroup'],
  endpoints: builder => ({
    getFeatureGroup: builder.query({
      query: ({ id, projectId }: { id: number; projectId: number }) => {
        return {
          url: `/feature-groups/${id}?project_id=${projectId}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 1,
    }),
    getFeatureGroups: builder.query({
      query: (id: number) => {
        return {
          url: '/feature-groups/?project_id=' + id,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 1,
      onQueryStarted: createErrorHandler(
        'There was a problem loading feature groups'
      ),
    }),
    createOrUpdateFeatureGroup: builder.mutation({
      query: (values: object) => {
        return {
          url: '/feature-groups',
          method: 'POST',
          body: values,
        };
      },
    }),
    deleteFeatureGroup: builder.mutation({
      query: ({ id, projectId }: { id: number; projectId: number }) => {
        return {
          url: `/feature-groups/${id}?project_id=${projectId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['FeatureGroup'],
    }),
  }),
});

export const {
  useGetFeatureGroupQuery,
  useGetFeatureGroupsQuery,
  useCreateOrUpdateFeatureGroupMutation,
  useDeleteFeatureGroupMutation,
} = featureGroupApi;
